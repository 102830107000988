/**
 * @author Claudio Baumgartner
 */

(function () {
  'use strict';

  ui.NavigationComponentView = ui.ComponentView.extend({
    initialize: function () {
      this.direction = null;
      this.isOpen = false;
      this.$pageContainer = this.$el.find('.js-navigation-page-container');
      this.$currentPage = this.$el.find('.js-is-current-page');
      this.fetchedCache = {};

      this.buildCache();
      this.bindEventListeners();
      this.setNewMaxHeight(this.$currentPage);
      this.isAnimating = false;

      // set metanav list item class for spacing on tablet
      this.$el
        .find('.meta-nav-list-item:not(.is-mobile-only)')
        .last()
        .addClass('remove-space-right');
    },

    bindEventListeners: function () {
      this.$el.on('transitionend', '.js-is-current-page', (event) =>
        this.activateCurrentNode(event)
      );
      this.$el.on('click', '.js-has-children', (event) => this.drillDown(event));
      this.$el.on('click', '.js-drill-up', (event) => this.drillUp(event));
      this.$el.on('click', '.js-close-navigation, .navigation-background', () => {
        ui.trigger(ui.GlobalEvents.MAIN_MENU_CLOSE);
        this.toggleNavigation();
      });
      $(window).on(
        'resize',
        _.debounce(() => this.setNewMaxHeight(this.$currentPage), 200, {
          leading: true,
        })
      );
      ui.on(ui.GlobalEvents.MAIN_MENU_TOGGLE_MEGA_MENU, (event) => this.toggleNavigation(event));
      ui.on(ui.GlobalEvents.MAIN_MENU_FORCE_CLOSE, () => this.forceCloseNavigation());
      ui.on('bootstrap.activemediaquery.changed', () => this.setBodyOverflow());
    },

    setNewMaxHeight: function ($nextNavigationLevel) {
      // return if nav is empty or element is not defined
      if ($nextNavigationLevel.length < 1) return;

      const contentHeight = $nextNavigationLevel.innerHeight();
      this.$pageContainer.css('min-height', contentHeight);
    },

    drillDown: function (event) {
      event.preventDefault();
      if (this.isAnimating) return;

      this.isAnimating = true;
      const $clickedItem = $(event.currentTarget);
      const setup = this.parseSetup($clickedItem.data('setup'));
      this.$el.addClass('no-initial-delay');

      this.fetchNode(setup.ajaxUrl, setup.uuid).then((response) => {
        const $nextNavigationLevel = $(response).addClass('is-next');
        this.direction = 'down';

        window.requestAnimationFrame(() => {
          this.$pageContainer.append($nextNavigationLevel);
          this.setNewMaxHeight($nextNavigationLevel);
          this.$currentPage.addClass('animate-out-to-left');
          window.requestAnimationFrame(() => {
            this.$currentPage.next().removeClass('is-next');
          });
        });
      });
    },

    drillUp: function (event) {
      event.preventDefault();
      if (this.isAnimating) return;

      this.isAnimating = true;
      const $clickedItem = $(event.currentTarget);
      const setup = this.parseSetup($clickedItem.data('setup'));
      this.$el.addClass('no-initial-delay');

      this.fetchNode(setup.ajaxUrl, setup.uuid).then((response) => {
        const $nextNavigationLevel = $(response).addClass('is-prev');
        this.direction = 'up';

        window.requestAnimationFrame(() => {
          this.$pageContainer.prepend($nextNavigationLevel);
          this.setNewMaxHeight($nextNavigationLevel);
          this.$currentPage.addClass('animate-out-to-right');
          window.requestAnimationFrame(() => {
            this.$currentPage.prev().removeClass('is-prev');
          });
        });
      });
    },

    activateCurrentNode: function (event) {
      if (!this.direction || !$(event.target).hasClass('js-is-current-page')) return;

      this.$currentPage.removeClass('is-current-page js-is-current-page');
      if (this.direction === 'up') {
        this.$currentPage = this.$currentPage.prev();
        this.$currentPage.next().remove();
      } else {
        this.$currentPage = this.$currentPage.next();
        this.$currentPage.prev().remove();
      }
      this.$currentPage
        .addClass('is-current-page js-is-current-page')
        .find('.page-title-link')
        .focus();

      this.direction = null;
      this.buildCache();
      this.isAnimating = false;
    },

    fetchNode: function (url, uuid) {
      return new Promise((resolve, reject) => {
        if (Object.prototype.hasOwnProperty.call(this.fetchedCache, uuid)) {
          resolve(this.fetchedCache[uuid]);
        } else {
          this.fetchedCache[uuid] = $.ajax({
            url,
            type: 'GET',
            success: (data) => {
              resolve(data);
            },
            error: (error) => {
              reject(error);
            },
          });
        }
      });
    },

    parseSetup: function (setup) {
      if (setup && typeof setup === 'string') {
        try {
          setup = JSON.parse(setup.replace(/\\'/g, "'").replace(/'/g, '"'));
        } catch (error) {
          console.log('Error parsing JSON -> data', setup);
          console.log('Error Msg', error);
        }
      }
      return setup;
    },

    buildCache: function () {
      const $itemsWithSubtrees = this.$currentPage.find('.js-has-children, .js-drill-up');
      $itemsWithSubtrees.each((i, item) => {
        const setup = this.parseSetup($(item).data('setup'));
        this.fetchNode(setup.ajaxUrl, setup.uuid);
      });
    },

    toggleNavigation: function () {
      // close safety teaser so navigation alignes properly
      ui.trigger(ui.GlobalEvents.SAFETY_TEASER_CLOSE);
      document.body.scrollIntoView(true);
      this.isOpen = !this.isOpen;
      this.setBodyOverflow();
      this.$el.toggleClass('is-visible');
      this.$el.removeClass('no-initial-delay');

      window.requestAnimationFrame(() => {
        this.setNewMaxHeight(this.$currentPage);
        window.requestAnimationFrame(() => {
          this.$el.find('.js-navigation-container').toggleClass('in-view');

          if (this.isOpen) {
            this.$el.find('.js-close-navigation').focus();
          }
        });
      });
    },

    setBodyOverflow: function () {
      if (/(xs|ms)/.test(ui.Bootstrap.activeView) && this.isOpen) {
        $('body').addClass('body-is-fixed');
        bodyScrollLock.disableBodyScroll(this.el);
      } else {
        $('body').removeClass('body-is-fixed');
        bodyScrollLock.enableBodyScroll(this.el);
      }
    },

    forceCloseNavigation: function () {
      this.isOpen = false;
      $('body').removeClass('body-is-fixed');
      this.$el.removeClass('is-visible');
      this.$el.find('.js-navigation-container').removeClass('in-view');
      this.$el.removeClass('no-initial-delay');
      this.setBodyOverflow();
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'NavigationComponent',
    View: ui.NavigationComponentView,
    selector: '.ui-js-g120-navigation',
  });

  $(ui.bootstrapNavigationComponent());
}).call(this);
