/**
 * @author Nils von Rymon-Lipinski
 */
(function () {
  'use strict';

  /**
   * Data-setup:
   * @params {Object} [autoinsert] - holds specific rules for autoinsert
   * @params {Object} [autoinsert.config] - holds config settings for autoinsert
   * @params {String} [autoinsert.config.serviceUrl] - Server side URL or callback function that returns serviceUrl string. Optional if local lookup data is provided
   * @params {Object} [autoinsert.autoinsertDependencies] -  holds data for depended elements
   */
  ui.FormAutoInsertComponentView = ui.ComponentView.extend({
    name: 'ui.FormAutoInsertComponentView',

    $autoinsertNode: null,
    $autoinsertNodeSel: '.ui-js-autoinsert-node',
    $autoinsertValue: null,
    $autoinsertInputSel: '.ui-js-autoinsert-value',
    autoInsertRules: false,
    dependencies: null,

    initialize: function (options) {
      this.$autoinsertNode = $(this.$autoinsertNodeSel, this.$el);
      this.$autoinsertValue = $(this.$autoinsertInputSel, this.$el);
      this.dependencies = options.autoinsert.autoinsertDependencies;
      this.autoInsertRules = options.autoinsert.autoinsertRules;
      this.ajaxUrl = options.autoinsert.config.serviceUrl;
      this.bindChangeDependencies();
      this.checkIfValidAndAddParams();
    },

    /**
     * bind dependencies
     */
    bindChangeDependencies: function () {
      var self = this;
      this.dependencies.forEach(function (name) {
        var $depInput = self.$el.closest('form').find('[name="' + name + '"]');
        if ($depInput.length) {
          $depInput.on('focusout', function (e) {
            var inputTimeout = setTimeout(function () {
              clearTimeout(inputTimeout);
              self.checkIfValidAndAddParams();
            }, 400);
          });
        }
      });
    },

    /**
     * check if all dependencies valid and get the request parameters
     */
    checkIfValidAndAddParams: function () {
      var self = this;
      var allValid = false;
      var paramsObj = {};
      var param;
      var value;
      var i = 0;

      for (i; i < this.dependencies.length; i++) {
        var name = this.dependencies[i];
        var $depInput = self.$el.closest('form').find('[name="' + name + '"]');
        if (
          $depInput.length &&
          $depInput.data('queryparam') &&
          (($depInput.val() !== '' && $depInput.val() !== undefined) ||
            ($depInput.data('queryvalue') !== '' && $depInput.data('queryvalue') !== undefined))
        ) {
          allValid = !$depInput.closest('.form-group').hasClass('invalid');
          if (!allValid) {
            self.setHidden();
            return false;
          }
          if (self.autoInsertRules) {
            if (
              self.autoInsertRules[name] &&
              self.autoInsertRules[name] !== undefined &&
              self.autoInsertRules[name] !== $depInput.val()
            ) {
              self.setHidden();
              allValid = false;
              return false;
            }
          }
          param = $depInput.data('queryparam');
          value = $depInput.data('queryvalue') || $depInput.val();
          if (param && param.length && value.length) {
            paramsObj[param] = value;
          }
        } else {
          self.setHidden();
          self.setText(false, false);
          allValid = false;
          return false;
        }
      }
      if (allValid) {
        self.sendRequest(self.ajaxUrl + '?' + $.param(paramsObj));
      }
    },

    /**
     * set the result to result node
     * * @param {String} result - result as string
     * * @param {String} value - value as string
     */
    setText: function (result, value) {
      this.$autoinsertNode.text(result);

      if (!result) {
        this.$autoinsertNode.text('');
      }
      this.$autoinsertNode.text(result);
      if (this.$autoinsertValue.length) {
        if (value) {
          this.$autoinsertValue.val(value);
        } else {
          this.$autoinsertValue.val('');
        }
        this.$autoinsertValue.trigger('change');
      }
    },

    /**
     * show the result-node element
     */
    setVisible: function () {
      this.$el.removeClass('is-hidden');
      this.$el.removeClass('hidden');
    },

    /**
     * hide the result-node element
     */
    setHidden: function () {
      this.$el.addClass('is-hidden');
      this.$el.addClass('hidden');
    },

    /**
     * send request
     * @param {String} url - url as string with parameters
     */
    sendRequest: function (url) {
      var self = this;
      $.ajax({
        type: 'GET',
        dataType: 'json',
        url,
      })
        .done(function (data) {
          if (data.status === 'success') {
            self.setText(data.result, data.value);
            self.setVisible();
          } else if (data.status === 'error') {
            if (data.error && data.error.msg && data.error.msg !== '') {
              self.setText(data.error.msg, '');
              self.setVisible();
            } else {
              self.setHidden();
            }
          }
        })
        .fail(function (jqxhr, textStatus, error) {
          self.setHidden();
        });

      ui.trigger(ui.GlobalEvents.FORM_TRY_TO_ENABLE, {
        name: ui.GlobalEvents.FORM_TRY_TO_ENABLE,
      });
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FormAutoInsertComponent',
    View: ui.FormAutoInsertComponentView,
    selector: '.ui-js-autoinsert',
  });

  $(ui.bootstrapFormAutoInsertComponent());
}).call(this);
