/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Autocomplete API and methods
   * @see https://www.devbridge.com/sourcery/components/jquery-autocomplete
   *
   *
   * Data-setup:
   * @params {Object} [autocomplete] - holds specific rules for autocomplete
   * @params {Object} [autocomplete.config] - holds config settings for autocomplete
   * @params {String} [autocomplete.config.serviceUrl] - Server side URL or callback function that returns serviceUrl
   *     string. Optional if local lookup data is provided
   * @params {Number} [autocomplete.config.minChars] - Minimum number of characters required to trigger autosuggest
   * @params {Object} [autocomplete.autocompleteDependencies] -  holds data for depended elements
   * @params {String} [autocomplete.autocompleteDependencies.fieldName] - name selector to get ajax request params,
   *     removes disabled-fields
   */
  ui.FormAutocompleteComponentView = ui.ComponentView.extend({
    name: 'ui.FormAutocompleteComponentView',

    events: {
      focusin: 'toggleAutocomplete',
      keyup: 'toggleResetSearchBtn',
    },

    $input: null,
    autocompleteNode: null,
    dependencies: null,

    initialize: function (options) {
      this.$input = this.$('input');
      this.autocompleteNode = this.$('.ui-js-results-autocomplete')[0];
      this.$resetSearchBtn = $('.ui-js-reset-search');
      this.$searchInput = $('.ui-js-searchinput');
      this.toggleResetSearchBtn();

      if (!options.autocomplete || !options.autocomplete.config || !this.autocompleteNode) {
        return;
      }

      this.dependencies = options.autocomplete.autocompleteDependencies;
      var setup = options.autocomplete.config;

      /**
       *provide an url callback instead of url string
       * @see ui.AssociationMapView
       */
      if (setup.serviceUrlParamField) {
        setup.serviceUrl = this.serviceUrlCallback.bind(
          this,
          setup.serviceUrl,
          this.$(setup.serviceUrlParamField),
          setup.serviceFormat
        );
      }

      this.initializeAutocomplete(setup);

      // we need this for validation reasons
      ui.autocompleteLookup = ui.autocompleteLookup || {};
    },

    /**
     * triggers a value change, this way it works on "minChars": 0
     * @param {Event} e - focusin event
     */
    toggleAutocomplete: function (e) {
      if (this.$(e.target).autocomplete()) {
        if (typeof this.minCharTemp === 'undefined') {
          this.minCharTemp = this.$(e.target).autocomplete().options.minChars;
          if (this.toggleSelectList) {
            this.$(e.target).autocomplete().options.minChars = 0;
          }
        }
        this.$(e.target).autocomplete().onValueChange();
      }
    },

    /**
     * triggers a value change, this way it works on "minChars": 0
     * @param {Event} e - focusin event
     */
    killAutocomplete: function () {
      if (this.$el.autocomplete()) {
        this.$el.autocomplete().killSuggestions();
      }
    },

    serviceUrlCallback: function (serviceUrl, $paramEl, format) {
      format = format || '.json';
      return serviceUrl + encodeURIComponent($paramEl.val().trim()) + format;
    },

    /**
     * Initializes autosuggest plugin, response must be transformed by callback to following supported format
     * countries = [
     *   { value: 'Andorra', data: 'AD' }, { ... }
     * ]
     */
    initializeAutocomplete: function (setup) {
      const self = this;
      const paramName = this.$input.data('queryparam') || 'query';

      this.$input.on('autocomplete:getResults', function (e, inputType, sender) {
        self.toggleSelectList = inputType || 'CH_TOGGLEMODUS';
        self.oldValue = self.$input.val();
        if (inputType === 'advancedSelect') {
          const newValueOfSender = sender.$element.val();
          if (newValueOfSender !== self.oldValueOfSender) {
            self.$input.val('');
            self.resetAdvancedSelect();
            self.oldValueOfSender = newValueOfSender;
          }
        }

        self.toggleAutocomplete(e);
      });

      this.$input.on('autocomplete:removeSelectBox', function (e, type) {
        self.$input.val('');
        self.resetAutocomplete();
        if (type === 'advancedSelect') {
          self.resetAdvancedSelect();

          setTimeout(function () {
            self.$input.attr('disabled', 'disabled');
          }, 100);
        }
      });

      var autocompleteConfig = {
        dataType: 'json',
        minChars: setup.minChars || 0,
        serviceUrl: setup.serviceUrl,
        paramName,
        appendTo: this.autocompleteNode,
        tabDisabled: false,
        deferRequestBy: 500,

        /**
         * Callback that is called before each Ajax request
         * @param query
         * @returns {Object} - extended query by dependent input fields
         */
        onSearchStart: _.throttle(function (query) {
          // if input has a value but the value only consist of white-spaces → cancle request
          const inputValue = $(this).val();
          if (inputValue.length > 0 && inputValue.trim().length === 0) {
            return false;
          }

          var extendedParams, updatedParams;

          if (self.dependencies) {
            updatedParams = self.updateDependencies(query, this);
            extendedParams = $.extend(query, updatedParams);
          }

          self.$input.trigger('autocomplete:start');

          return extendedParams || query;
        }, 150),

        onSearchComplete: _.throttle(function (query, suggestions) {
          var $suggestionBox = $('.autocomplete-no-suggestion');

          if (self.toggleSelectList === 'basicSelect') {
            if (suggestions.length === 0) {
              self.resetAutocomplete();
              if (self.oldValue !== '') {
                self.$input.val(self.oldValue);
              }
              self.$input.autocomplete().clear();
            }

            // set input readonly
            if (suggestions.length === 1) {
              if (self.responseArrayOld === JSON.stringify(suggestions)) {
                self.$input.val(suggestions[0].value);
                self.$input.attr('readonly', 'readonly');
                self.$input.autocomplete().hide();
                return;
              }

              self.unsetInputReadOnlyOrDisabled();

              self.responseArrayOld = JSON.stringify(suggestions);

              self.$input.val(suggestions[0].value);

              self.$input.trigger('autocomplete:selected');

              self.$input.removeAttr('disabled');
              self.$input.attr('readonly', 'readonly');

              self.$input.autocomplete().hide();
            }
            // create select-box & set input disabled
            if (suggestions.length > 1) {
              var filterResult = suggestions.filter(self.checkValues.bind(self));

              self.$input[0].disabled = true;
              setTimeout(function () {
                self.$input.attr('disabled', 'disabled');
              }, 100);

              if (self.responseArrayOld === JSON.stringify(suggestions)) {
                self.$input.autocomplete().hide();
                return;
              }

              self.responseArrayOld = JSON.stringify(suggestions);

              self.$input.css({
                display: 'none',
              });

              self.removeSelectList();

              // create select & copy attr from input
              var $selectElm = $('<select class="select-box js-template-select-box"></select>');
              $selectElm.attr('id', self.$input.attr('id'));
              $selectElm.attr('name', self.$input.attr('name'));

              // create option
              var optionList = '';
              suggestions.forEach(function (obj, idx) {
                if (filterResult.length && filterResult[0].value === obj.value) {
                  optionList =
                    optionList +
                    '<option value="' +
                    obj.value +
                    '" selected>' +
                    obj.value +
                    '</option>';
                } else {
                  optionList =
                    optionList + '<option value="' + obj.value + '">' + obj.value + '</option>';
                }
              });

              self.selectList = $selectElm.append(optionList);

              // insert select & set input tabindex
              self.$input.after(self.selectList);
              self.$input.attr('tabindex', -1);

              if (filterResult.length === 0) {
                self.$input.next().trigger('autocomplete:selected');
              }
              // self.$input.trigger('keyup');
              self.$input.autocomplete().hide();
            }
          }

          if (self.toggleSelectList === 'advancedSelect') {
            // override config to prevent further requests to backend
            if (!self.$input.autocomplete().options.lookup) {
              const currentOptions = self.$input.autocomplete().options;
              // please don't judge. spread operator was not supported by current babel version and i needed deep copy
              self.oldOptions = JSON.parse(JSON.stringify(currentOptions));
              currentOptions.lookup = suggestions;
              // disable filter since we opt for a select look and feel not filter
              currentOptions.lookupFilter = (suggestion, query, queryLowerCase) =>
                self.advancedSelectLookupFilter(suggestion, query, queryLowerCase);
              self.$input.autocomplete().setOptions(currentOptions);
            }

            if (suggestions.length === 0) {
              self.resetAutocomplete();
              if (self.oldValue !== '') {
                self.$input.val(self.oldValue);
              }
              self.$input.autocomplete().clear();
              return;
            }

            if (suggestions.length === 1) {
              self.setValue(suggestions[0].value);
              self.unsetInputReadOnlyOrDisabled();

              self.$input.trigger('autocomplete:selected');

              self.$input.removeAttr('disabled');
              self.$input.attr('readonly', 'readonly');

              self.$input.autocomplete().hide();
              self.$input.trigger('blur');
              return;
            }

            // only focus if input is not focused yet to avoid memoryleak
            if (!self.$input.is(':focus') && suggestions.length > 1 && !self.wasAutofocusedYet) {
              self.wasAutofocusedYet = true;
              self.$input.focus();
              self.setValue(suggestions[0].value);
              self.unsetInputReadOnlyOrDisabled();

              self.$input.trigger('autocomplete:selected');
            } else if (!self.$input.is(':focus')) {
              self.$input.autocomplete().hide();
            }
          }

          // replace suggestion message if "no number message" is in data setup
          if (setup.noNumberMessage || setup.noResultMessage) {
            var containsNumber = query.match(/\d+/g);

            if (!suggestions.length && containsNumber) {
              $suggestionBox.html(setup.noNumberMessage);
            } else {
              $suggestionBox.html(setup.noResultMessage);
            }
          }
          if (!suggestions.length) {
            self.$el.addClass('is-hint');
          } else {
            self.$el.removeClass('is-hint');
          }
        }, 150),

        onSelect: function (suggestion) {
          self.$input.trigger('autocomplete:selected');
        },

        onHide: function () {
          if (self.$input.length) {
            self.$input.trigger('autocomplete:selected');
          }
        },

        transformResult: function (response, currentValue) {
          self.responseArray = response;

          const suggestionsArray = response.reduce((arr, item) => {
            if (item.toLowerCase().indexOf(currentValue.toLowerCase().trim()) !== -1) {
              arr.push(item);
            }

            return arr;
          }, []);

          // we store this for validation reasons (to find out if correct item picked)
          ui.autocompleteLookup[self.$input[0].name] = suggestionsArray;

          return { suggestions: suggestionsArray };
        },

        formatResult: (suggestion, currentValue) =>
          $.Autocomplete.defaults.formatResult(suggestion, currentValue.trim()),
      };

      // overrides standard form widget transformresult, with custom callback
      if (typeof setup.transformResult === 'function') {
        autocompleteConfig.transformResult = setup.transformResult;
      }

      // overrides standard form widget onSelect, with custom callback
      if (typeof setup.onSelect === 'function') {
        autocompleteConfig.onSelect = setup.onSelect;
      }

      // display no results message
      if (setup.noResultMessage) {
        autocompleteConfig.showNoSuggestionNotice = true;
        autocompleteConfig.noSuggestionNotice = setup.noResultMessage;
      }

      this.$input.autocomplete(autocompleteConfig);
    },

    updateDependencies: function (query, input) {
      const paramsObj = {};
      this.dependencies.forEach(function (name) {
        const $depInput = $(input)
          .closest('form')
          .find('[name="' + name + '"]');
        if ($depInput.length) {
          const param = $depInput.data('queryparam');
          const value = $depInput.val() || $depInput.data('queryvalue');
          if (param && param.length && value && value.length) {
            paramsObj[param] = value;
          }
        }
      });
      return paramsObj;
    },

    checkValues: function (obj) {
      return obj.value === this.oldValue;
    },

    resetAutocomplete: function () {
      this.unsetInputReadOnlyOrDisabled();
      if (this.minCharTemp !== undefined) {
        this.$input.autocomplete().options.minChars = this.minCharTemp;
      }
      delete this.minCharTemp;
      delete this.toggleSelectList;
      delete this.responseArrayOld;
    },

    unsetInputReadOnlyOrDisabled: function () {
      var self = this;
      self.$input.css({
        display: 'block',
      });
      self.$input.removeAttr('tabindex');
      self.$input.removeAttr('readonly');
      if (self.selectList) {
        self.removeSelectList();
      }
    },

    removeSelectList: function () {
      var self = this;
      var $oldSelect = self.$el.find('.js-template-select-box');
      if ($oldSelect.length) {
        $oldSelect.remove();
      }
      if (self.selectList) {
        self.selectList.unbind();
        self.selectList.remove();
        delete self.selectList;
      }
    },

    resetAdvancedSelect: function () {
      if (this.oldOptions) {
        this.wasAutofocusedYet = false;
        this.$input.val('');
        this.$input.autocomplete().hide();
        this.$input.autocomplete().clear();
        this.$input.autocomplete().setOptions(this.oldOptions);
      }
    },

    advancedSelectLookupFilter: function (suggestion, query, queryLowerCase) {
      const autocompleteInput = this.$input.autocomplete();
      const foundIndexes = autocompleteInput.suggestions.reduce(
        (collection, currSuggestion, index) => {
          if (currSuggestion.value.toLowerCase().startsWith(queryLowerCase.trim())) {
            collection.push(index);
          }
          return collection;
        },
        []
      );

      const firstMatchIndex = Math.min(...foundIndexes);

      if (foundIndexes.length && !isNaN(firstMatchIndex)) {
        autocompleteInput.activate(firstMatchIndex);
      }

      return true;
    },

    toggleResetSearchBtn: function () {
      var searchInputval = this.$searchInput.val();
      this.$resetSearchBtn.removeClass('is-show');

      if (searchInputval !== '') {
        this.$resetSearchBtn.addClass('is-show');
      }
    },

    setValue: function (value) {
      /** if the value was set programmatically immediately hide the selection dropdown.
       * This is needed if e.G. the value was prefilled by the migros login that way the
       * dropdown is not open after the prefill was done.
       * But it's also used in other instances where the value is set programmatically.
       * */
      if (this.$el.data('prefillValue')) {
        this.$input.val(this.$el.data('prefillValue'));
        this.$el.removeData('prefillValue');
        this.$input.trigger('blur');
        this.$input.trigger('change');
        ui.trigger(ui.GlobalEvents.FORM_TRY_TO_ENABLE);
        this.$input.autocomplete().hide();
      } else {
        this.$input.val(value);
      }
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FormAutocompleteComponent',
    View: ui.FormAutocompleteComponentView,
    selector: '.ui-js-autocomplete',
  });

  $(ui.bootstrapFormAutocompleteComponent());
}).call(this);
