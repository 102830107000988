(function () {
  'use strict';

  ui.events = ui.events || {};

  ui.events.EventAccordion = ui.ComponentView.extend({
    name: 'ui.events.EventAccordion',

    events: {
      'click .js-event-accordion-toggle-button': 'onToggleButtonClick',
    },

    initialize: function () {},

    onToggleButtonClick: function (evt) {
      // do not execute anchor method
      evt.preventDefault();

      this.collapsableEl.collapse('toggle');
    },

    onShowCollapsable: function () {
      this.collapsableButton.text(this.closeText).removeClass('collapsed');
    },

    onHideCollapsable: function () {
      this.collapsableButton.text(this.openText).addClass('collapsed');
    },

    render: function () {
      this.collapsableEl = this.$('.collapse');
      this.collapsableButton = this.$('.js-event-accordion-toggle-button');

      this.openText = this.collapsableButton.data('openText');
      this.closeText = this.collapsableButton.data('closeText');

      this.collapsableEl.on('show.bs.collapse', this.onShowCollapsable.bind(this));
      this.collapsableEl.on('hide.bs.collapse', this.onHideCollapsable.bind(this));

      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'EventAccordionComponent',
    View: ui.events.EventAccordion,
    selector: '.ui-js-events-item',
  });

  $(ui.bootstrapEventAccordionComponent());
}).call(this);
