/**
 * @author Jan Suwart, Peter Dematté
 * @description validation methods
 */

(function (window) {
  'use strict';

  var groupValidations = {
    fileSizes: {},
  };

  /**
   * Contains validating functions that accept 2 parameters, the element (node) and an optional
   * validation attribute from the input's data-setup
   */
  window.ui.validator = {
    /**
     * Checks the file size choosen with the <input type="file"> (element)
     * according to its data-max-size or max-size attribute
     * or an <input name="MAX_FILE_SIZE" value="9999"> value
     *
     * @param  {Element} element the input field
     * @return {Boolean}         true if file size smaller or equal to limit or no limit
     */
    fileSize: function (element, value) {
      var fileSizeData = element.form.querySelector('[name="MAX_FILE_SIZE"]');
      var maxSize = parseInt(
        element.getAttribute('data-max-size') ||
          element.getAttribute('max-size') ||
          (fileSizeData ? fileSizeData.value : 2e10)
      );
      var size = element.files && element.files[0] ? +element.files[0].size : 0;

      return size <= maxSize;
    },

    /**
     *
     * @param  {Element} element the input field
     * @param  {string} value name of the group which together should not hit a file size limit
     * @return {boolean}   true if group of file inputs does not exceed size
     */
    formFileSizeGroup: function (element, value) {
      var fileSizeData = element.form.querySelector(
        '[name="GROUP_MAX_FILE_SIZE"][data-file-size-group-max="' + value + '"]'
      );
      var groupElements = element.form.querySelectorAll(
        '[data-file-size-group="' + value + '"] input[type="file"]'
      );
      var maxSize = parseInt(fileSizeData ? fileSizeData.value : 2e10);
      var size = element.files && element.files[0] ? +element.files[0].size : 0;

      // reset when starting at beginning
      if (element === groupElements[0]) {
        groupValidations.fileSizes[value] = 0;
      }

      groupValidations.fileSizes[value] += size;

      return groupValidations.fileSizes[value] <= maxSize;
    },

    /**
     * Checks the file type chosen with the <input type="file"> (element)
     * according to its accept attribute value (fileAPI or just value)
     *
     * @param  {Element} element the input field
     * @return {Boolean}         true if file type matches accept or no accept attribute
     */
    fileType: function (element, value) {
      var types = element.getAttribute('accept');
      var fileType = (
        element.files && element.files[0]
          ? element.files[0].type.replace(/^.*[\\/]/g, '') // file API is supported
          : element.value.replace(/^.*\./g, '')
      ).toLowerCase();

      if (types && fileType) {
        types = types.replace(/,\s+/g, ',').split(',');
        for (var n = types.length; n--; ) {
          var type = types[n].replace(/^.*[\\/]/g, '').toLowerCase();
          if (fileType.indexOf(type) >= 0) {
            return true;
          }
        }
      }

      // Type not recognized, decide what to do
      if (!fileType) {
        return false;
      } else {
        if (types) {
          return false;
        } else {
          return true;
        }
      }
    },

    oneCheckRequired: function (element) {
      return !!$(element).closest('.ui-js-form-item').find('input:checked').length;
    },

    autoSuggest: function (element) {
      return (
        ui.autocompleteLookup &&
        ui.autocompleteLookup[element.name] &&
        ui.autocompleteLookup[element.name].indexOf(element.value) !== -1
      );
    },

    required: function (element) {
      return element.checked || element.value !== '';
    },

    /**
     * @param {Object} element - input node
     * @param {Number} value - number to compare the elements value with
     * @returns {boolean} true if valid
     */
    minlength: function (element, value) {
      return $(element).val().length >= value;
    },

    maxlength: function (element, value) {
      return $(element).val().length <= value;
    },

    dynamicSelectionRules: function (element, selector, bool) {
      var $input = $(element);
      var $item = $input.closest('form').find('[name="' + selector + '"]');
      var $option = $item.find('option:selected');
      var setup = $option[0] ? $option[0].getAttribute('data-setup') || '' : '';
      var rules = setup
        ? JSON.parse(setup.replace(/\\'/g, "'").replace(/'/g, '"')).dynamicSelectionRules
        : null;
      var ruleCallback;
      var isValid;

      if (bool) {
        if (rules && rules.minlength) {
          $input.attr('minlength', rules.minlength);
        }
        if (rules && rules.maxlength) {
          $input.attr('maxlength', rules.maxlength);
        }
      } else {
        for (var rule in rules) {
          ruleCallback = ui.validator[rule];
          isValid = ruleCallback && ruleCallback($(element)[0], rules[rule]);
          if (!isValid) {
            break;
          }
        }
        return isValid;
      }
    },

    min: function (element, value) {
      return +element.value >= value;
    },

    max: function (element, value) {
      return +element.value <= value;
    },

    // Primitive check for alphanumeric characters
    alphanumeric: function (element) {
      return /^\w+$/i.test(element.value);
    },

    // Primitive check for digits
    integer: function (element) {
      return /^-?\d+$/.test(element.value);
    },

    // Primitive check for letters
    lettersonly: function (element) {
      return /^[a-z]+$/i.test(element.value);
    },

    // Obtains View attached to ui-js-password-checker (PasswordCheckerView) and calls its method 'passed'
    passwordSufficient: function () {
      return $('.ui-js-password-checker').prop('View').passed();
    },

    // Check date format for ISO and DIN-5008, @see http://www.regexr.com/3c1ka
    isDate: function (element) {
      return ui.validator.isISODate(element.value) || ui.validator.isDINDate(element.value);
    },

    // Tests for pattern yyyy-mm-dd
    isISODate: function (date) {
      return /^([0-9]{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]))$/gm.test(date);
    },

    // Tests for pattern dd-mm-yyyy
    isDINDate: function (date) {
      return /^((0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.[0-9]{4})$/gm.test(date);
    },

    // Check date format for DIN-5008 without leading zero (i. e. 1.12.2015)
    isSimpleDate: function (element) {
      return /^(([1-9]|[12][0-9]|3[01])\.([1-9]|1[012])\.[0-9]{4})$/gm.test(element.value);
    },

    // Tests for pattern mm.yyyy
    isMYDate: function (date) {
      return /^(0[1-9]|1[0-2])\.([0-9]{4})$/gm.test(date);
    },

    isProductDate: function (element) {
      return ui.validator.isDINDate(element.value) || ui.validator.isMYDate(element.value);
    },

    // Checks combined date inputs via concatenation
    partialDate: function (element) {
      var dateValue = ui.validator.getConcatenateDate(element);
      // Check if password matches either simple date or complex date
      return (
        ui.validator.isSimpleDate({ value: dateValue }) || ui.validator.isDate({ value: dateValue })
      );
    },

    getConcatenateDate: function (element) {
      var $items = $(element).parent().find('input');
      var day = $items.eq(0).val().length < 2 ? '0' + $items.eq(0).val() : $items.eq(0).val();
      var month = $items.eq(1).val().length < 2 ? '0' + $items.eq(1).val() : $items.eq(1).val();
      return day + '.' + month + '.' + $items.eq(2).val();
    },

    // Returns Date if string can be parsed, null otherwise
    parseDate: function (string) {
      if (ui.validator.isISODate(string)) {
        return new Date(string);
      } else if (ui.validator.isDINDate(string)) {
        var parts = string.split('.');
        var date = new Date(parts[2] + '-' + parts[1] + '-' + parts[0]);
        return date instanceof Date ? date : null;
      } else {
        return null;
      }
    },

    // Trims/removes spaces
    trim: function (string) {
      return string.replace(/\s/g, '');
    },

    // Ensures that date is not smaller than the value's date
    minDate: function (element, value) {
      var date = ui.validator.parseDate(element.value);
      if (date) {
        return date.getTime() >= new Date(value).getTime();
      }
      return false;
    },

    // Ensures that date is not greater than the value's date
    maxDate: function (element, value) {
      var date = ui.validator.parseDate(element.value);
      if (date) {
        return date.getTime() <= new Date(value).getTime();
      }
      return false;
    },

    // Primitive check for digits
    number: function (element) {
      return /^\d*$/.test(element.value);
    },

    // Check for Cumulus-nr length, trim before
    isCumulusNr: function (element) {
      var number = ui.validator.trim(element.value);
      return /^\d*$/.test(number) && number.length === 9;
    },

    // check date format for ISO and DIN-5008
    isEmail: function (element) {
      return /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
        element.value
      );
    },

    // check birthday against current date ( day precisions, only against year-month-day by 00:00:00 time)
    checkAgeMinMax: function (element, minMax) {
      var $formGroup = $(element).closest('.form-group');
      var $errorMsgContainer = $formGroup.find('.ws-errormessage');
      if (!ui.validator.partialDate(element)) {
        if ($formGroup.data('originMsg') !== undefined) {
          $errorMsgContainer.text($formGroup.data('originMsg'));
        }
        return;
      }
      if ($formGroup.data('originMsg') === undefined) {
        $formGroup.data('originMsg', $errorMsgContainer.text());
      }
      var dateValue = ui.validator.getConcatenateDate(element);
      var tmpCurrentDate = new Date();
      var currentDate = new Date(
        tmpCurrentDate.getFullYear(),
        tmpCurrentDate.getMonth(),
        tmpCurrentDate.getDate()
      ); // convert to 00:00:00 time
      var tmpBirthday = ui.validator.parseDate(dateValue);
      var birthday = new Date(
        tmpBirthday.getFullYear(),
        tmpBirthday.getMonth(),
        tmpBirthday.getDate()
      ); // convert to 00:00:00 time
      if (birthday == null) {
        console.warn('No valid Date Format!');
        return false;
      }
      var age = (currentDate.getTime() - birthday.getTime()) / 31557600000; // Divide by 1000*60*60*24*365.25
      if (age < minMax.min) {
        $errorMsgContainer.text(minMax.minError);
        return false;
      }
      if (age > minMax.max) {
        $errorMsgContainer.text(minMax.maxError);
        return false;
      }
      $errorMsgContainer.text($formGroup.data('originMsg'));
      return true;
    },

    /**
     * @param {Object} element - input node
     * @param {String} value - id of input that should have an equal value
     * @returns {boolean} true if valid
     */
    equalTo: function (element, value) {
      return $(element).val() === $(value).val();
    },

    url: function (element, value) {
      return (
        !value || /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(element.value)
      );
    },

    checkMembershipPrefix: function (element, value) {
      var $formGroup = $(element).closest('.form-group');
      var $errorMsgContainer = $formGroup.find('.ws-errormessage');
      if ($formGroup.data('originMsg') === undefined) {
        $formGroup.data('originMsg', $errorMsgContainer.text());
      }
      var testResult = /^(01|02|03|04|05|06|07|08|09|10|12|88|98|99)/.test(element.value);

      if (testResult) {
        $errorMsgContainer.text($formGroup.data('originMsg'));
        return true;
      } else {
        $errorMsgContainer.text(value);
        return false;
      }
    },
  };
})(window);
