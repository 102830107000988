/**
 * @author Viktoriia Khardina
 *
 */
(function () {
  'use strict';

  ui.GuideComponentView = ui.ComponentView.extend({
    name: 'ui.GuideComponentView',
    guideNav: '[data-js-item="ui-js-guide-nav"]',
    mobileCloseArrow: '[data-js-item="ui-js-guide-mobile-arrow"]',
    collapsePanels: '.panel-collapse',

    events: {
      'show.bs.tab': 'toggleMobileNav',
      'click [data-js-item="ui-js-guide-mobile-arrow"]': 'handleMobileGuideNav',
      'click [data-toggle="collapse"]': 'disabledClick',
    },

    // Todo refactoring code and add function comments

    initialize: function () {
      this.$guideNavigation = this.$el.find(this.guideNav);
      this.$tabs = this.$el.find('.tab-pane');
      this.$tabsAnchor = this.$el.find('.nav-item');
      this.$collapsePanels = this.$el.find(this.collapsePanels);
      this.isMobile = /(ms|xs)/.test(ui.Bootstrap.activeView);

      if (this.isMobile) {
        this.$guideNavigation.addClass('is-open');
        this.deactivateTabs();

        this.$collapsePanels.on('shown.bs.collapse', function (e) {
          const $panel = $(this).closest('.panel');

          $('html,body').animate(
            {
              scrollTop: $panel.offset().top - 7,
            },
            300
          );
        });
      }

      this.$collapsePanels.on('show.bs.collapse', this.onShowCollapsable.bind(this));
      this.$collapsePanels.on('hide.bs.collapse', this.onHideCollapsable.bind(this));
    },

    render: function () {
      const $activeNavItem = this.$el.find('.nav-item.active');
      const $activeTab = $activeNavItem.find('.ui-js-data-layer-item');
      const $activePanel = this.$el.find(
        '.tab-pane.active .panel.is-collapse .ui-js-data-layer-item'
      );

      $("[property='og:title']").attr('content', $activeNavItem.text().trim());
      this.pushToDataLayer($activeTab);
      this.pushToDataLayer($activePanel);
    },

    pushToDataLayer: function (element) {
      if (!window.dataLayer || !element || element.length === 0) return;

      const { data } = ui.getElementSetup({ el: element });

      if (!data || !data.event || data.event !== 'guide-widget' || !data.navigationLevel) return;

      window.dataLayer.push(data);

      if (window.logTracking) {
        console.info('added dataLayer data:', JSON.stringify(data));
        console.info('Tracking Obj', data);
      }
    },

    toggleMobileNav: function (event) {
      const $target = $(event.target);
      const $activePanel = this.$el.find(
        `${$target.attr('href')} .panel.is-collapse .ui-js-data-layer-item`
      );

      if (this.isMobile && this.$guideNavigation.hasClass('is-open')) {
        this.$guideNavigation.removeClass('is-open');
      }

      this.pushToDataLayer($activePanel);
    },

    handleMobileGuideNav: function () {
      if (this.$guideNavigation.hasClass('is-open')) {
        this.$guideNavigation.removeClass('is-open');
      } else {
        this.$guideNavigation.addClass('is-open');
      }
    },

    deactivateTabs: function () {
      this.$tabs.removeClass('in active');
      this.$tabsAnchor.removeClass('active');
    },

    onShowCollapsable: function (e) {
      const $target = $(e.currentTarget);
      const targetParent = $target.parent();

      targetParent.addClass('is-collapse');
    },

    onHideCollapsable: function (e) {
      const $target = $(e.currentTarget);

      if (!this.isMobile) {
        $target.parent().removeClass('is-collapse');
      }
    },

    disabledClick: function (e) {
      const $target = $(e.currentTarget);
      if ($target.parents('.panel').children('.panel-collapse').hasClass('in')) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'GuideComponent',
    View: ui.GuideComponentView,
    selector: '.ui-js-guide',
  });

  $(ui.bootstrapGuideComponent());
}).call(this);
