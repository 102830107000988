(function () {
  'use strict';

  /**
   * data-setup
   * - anchor: string DOM-selector
   * - speed: number in ms
   * - offset: number in px
   */

  ui.AnimatedScrollToClassComponentView = ui.ComponentView.extend({
    name: 'ui.AnimatedScrollToClassComponentView',

    defaults: {
      speed: 750,
      offset: 80,
    },

    initialize: function () {
      this.scrollContainer = $('html,body');

      // don't initiate scrolling functionality
      if (!this.scrollContainer.hasClass('prevent-scroll-to-class')) {
        this.checkCSSClassMethod = this.checkCSSClass.bind(this);

        // wait for images to be loaded to calculate the right
        // scroll postion
        $(window).on('load', this.checkCSSClassMethod);
        // check again for first error if fragment is placed
        ui.on(ui.GlobalEvents.FRAGMENT_PLACED, this.checkCSSClassMethod);
      }
    },

    checkCSSClass: function () {
      this.firstElement = $('.js-success, .js-invalid').first();
      if (this.firstElement.length) {
        this.scrollToCSSClass();
      }
    },

    scrollToCSSClass: function () {
      console.info(this + '.scrollToCSSClass()');
      var offSet = $('.ui-js-header-panel').height() + this.setup.offset;
      var targetOffset = this.firstElement.offset().top - offSet;

      this.scrollContainer.animate({ scrollTop: targetOffset }, this.setup.speed);

      // we have an error so no need to scroll somewhere else after
      // fragments are loaded
      ui.off(ui.GlobalEvents.FRAGMENT_PLACED, this.checkCSSClassMethod);
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'AnimatedScrollToClassComponent',
    View: ui.AnimatedScrollToClassComponentView,
    selector: document,
    reinitialize: false,
  });

  $(ui.bootstrapAnimatedScrollToClassComponent());
}).call(this);
