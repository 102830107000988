/**
 * @author: Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Mediates between parent frame and child frame, uses ba-postmessage plugin
   * @see https://github.com/cowboy/jquery-postmessage/
   *
   * Data-setup:
   * @params {Object} url - object containing urls
   * @params {String} url.default - url to non-mobile frame
   * @params {String} [url.mobile] - optional, url to mobile frame (xs and ms viewports)
   */
  ui.IFrameMediatorComponentView = ui.ComponentView.extend({
    name: 'ui.IFrameMediatorComponentView',

    $IFrame: null,
    ifHeight: 0,
    mobileSrc: null,
    defaultSrc: null,

    initialize: function () {
      var viewport = ui.Bootstrap.activeView;
      var iFrameHandler = this.loadIFrame.bind(this);

      this.$IFrame = this.$('iframe');
      this.mobileSrc = this.setup.url.mobile;
      this.defaultSrc = this.setup.url.default;

      this.installMessageReceiver();

      if (/ms|xs/.test(viewport)) {
        this.loadIFrame(viewport);
      }
      ui.on('bootstrap.activemediaquery.changed', iFrameHandler);
    },

    render: function () {
      return this;
    },

    /**
     * Installs a message receiver for postMessage from the iframe. If the iframe's height
     * can be extracted from the passed data, reset the iframe tag's height
     */
    installMessageReceiver: function () {
      var self = this;

      if (!$.receiveMessage) {
        return;
      }
      // Initialize ba-postmessage plugin to be able to receive iframe's height
      $.receiveMessage(function (e) {
        // Get height from the passed data
        var height = Number(e.data.replace(/.*if_height=(\d+)(?:&|$)/, '$1'));
        if (!isNaN(height) && height > 0 && height !== self.ifHeight) {
          // Height has changed, update the iframe
          self.$IFrame.height(height);
          self.ifHeight = height;
        }
      });
    },

    /**
     * Toggles between mobile and default iframe by switching the source, appends hostname as hash
     * @param {String} viewport - current viewport
     */
    loadIFrame: function (viewport) {
      if (!this.mobileSrc || !this.defaultSrc) {
        return;
      }

      var currentSrc = this.$IFrame.attr('src').split('#')[0];
      var parentUrl = this.$IFrame.attr('src').split('#')[1];
      var hostname = window.location.protocol + '//' + window.location.host;

      if (viewport && /ms|xs/.test(viewport)) {
        // Load mobile iframe
        if (currentSrc !== this.mobileSrc) {
          this.$IFrame.attr('src', this.mobileSrc + '#' + (parentUrl || hostname));
        }
      } else if (viewport) {
        // Load default iframe
        if (currentSrc !== this.defaultSrc) {
          this.$IFrame.attr('src', this.defaultSrc + '#' + (parentUrl || hostname));
        }
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'IFrameMediatorComponent',
    View: ui.IFrameMediatorComponentView,
    selector: '.ui-js-iframe-mediator',
  });

  $(ui.bootstrapIFrameMediatorComponent());
}).call(this);
