/**
 * @author Jan Suwart
 * @modified by Nils von Rymon-Lipinski
 * @namespace ui.requests
 * Handles Ajax-requests and browser-history states
 */
(function () {
  'use strict';

  ui.requests = {
    /**
     * @see http://regexr.com/3bcp0
     * Custom regex for Magnolia caching, extracts tilde-separated parameters from url.
     * Groups following URL string https://www.foo.com/bar/baz~s1=v1~s2=v2~.html#bar/b:a-z into:
     * (1) https://www.foo.com/bar/baz
     * (2) s1=v1~s2=v2~
     * (3) .html#bar/b:a-z
     */
    customRegExpPattern: /^([^~]*)~?(.*)(?=~\.html)?~?(\.html#?[^#~\n]*)$/,
    // Custom parameter separator to stringify the parameter object
    customSeparatorChar: '~',

    /**
     * Reference to the singleton
     * @private
     */
    queryModel: null,

    /**
     * Get the model's singleton if one exists, create one if it doesn't
     * @returns {Object} Backbone model filled with url parameters
     */
    getURLParamModel: function () {
      var self = this;

      // Create new instance of Backbone.Model and initializes its attributes and methods
      function createModel() {
        var UrlParamModel = Backbone.Model.extend({
          // Sets parameters from window location as model attributes
          fetchParameters: function () {
            var params = self.parseLocationParameters(window.location);
            this.set(params);
          },
          // Removes and sets attributes from model triggering model change event
          setAttributes: function (attributes, unsetArray) {
            if (unsetArray && unsetArray.length) {
              var unsetObj = {};
              $.map(unsetArray, function (n) {
                unsetObj[n] = undefined;
              });
              this.set(unsetObj, { unset: true });
            }
            if (attributes && !$.isEmptyObject(attributes)) {
              this.set(attributes);
            }
          },
        });
        var urlParamModel = new UrlParamModel();
        urlParamModel.fetchParameters();
        return urlParamModel;
      }

      if (!this.queryModel) {
        this.queryModel = createModel();
      }
      return this.queryModel;
    },

    /**
     * Updates browser history via pushState, merges current URL params with given parameter.
     * Returns false, if pushState is not supported.
     *
     * @param {String} newUrlParams - new (stringified) get parameters, f.e. "p=3&e=1"
     * @param {Object} getParamObj - new parameters as object, f.e. { p:3, e:1 }
     * @param {Boolean} [customSubmitMethod] - if true, custom url-encoding is used for history updates
     * @returns {string || Boolean} url if successful, false if history not supported
     */
    updateBrowserHistory: function (newUrlParams, getParamObj, customSubmitMethod) {
      if (!history.pushState) {
        return false;
      }
      var page = '';
      var scrollTop;
      var historyObj = {};

      if (!customSubmitMethod) {
        if (newUrlParams) {
          // Use standard url-parameters, add ? as separator and optional hash at the end
          page = '?' + newUrlParams + window.location.hash;
        } else {
          // Reset url to pathname + hash if all params were cleared
          page = window.location.pathname + window.location.hash;
        }
      } else {
        // Stringify url using custom methods
        page = this.stringifyCustomUrl(window.location.href, newUrlParams);
      }

      scrollTop = $(window).scrollTop();

      // Push new history entry consisting of scroll-offset and parameter object
      if (scrollTop) {
        historyObj.scrollTop = scrollTop;
      }
      historyObj.params = getParamObj;
      history.pushState(historyObj, '', page);

      return page;
    },

    /**
     * @param {String} location - could be window.location
     * @param {String} params - custom separated get parameters, f.e. "~p=3~&e=1~"
     * @returns {string} stringified url containing parameters
     */
    stringifyCustomUrl: function (location, params) {
      // Create url-string containing path ($1), tilde-separated-params ($2) and filetype ($3)
      return location.replace(this.customRegExpPattern, '$1' + params + '$3');
    },

    /**
     * Stringify parameter object in either standard url-parameters or custom tilde-separated url-string
     * @param {Object} params - Object with parameters
     * @param {Boolean} [customSubmitMethod] - if true, custom character and syntax will be used for url-encoding
     * @returns {string} key-value-pairs (f.e. p=1) separated by chosen method if params not empty, empty string otherwise
     */
    stringifyParameters: function (params, customSubmitMethod) {
      if (!params || $.isEmptyObject(params)) {
        return '';
      }

      if (customSubmitMethod) {
        var paramString = this.customSeparatorChar;
        for (var key in params) {
          paramString += key + '=' + params[key] + this.customSeparatorChar;
        }
        return paramString;
      } else {
        // Use jQuery param to stringify in standard mode
        return $.param(params);
      }
    },

    /**
     * Parses parameters from window.location, returns object with key/value according to & and = in params
     * @param {Object} location - location object containing href or search attributes, f.e. ?p=3&s=date
     * @returns {Object} parsed string as object
     */
    parseLocationParameters: function (location) {
      var paramObj = {};
      var paramArray = [];

      if (!location || !location.href) {
        return paramObj;
      }

      // If href contains the separator character and tests positive for the regex, use custom pattern
      if (this.locationHasCustomParams(location)) {
        // Extract parameters (f.e. ~s1=v1~s2=v2~) that are contained in the second group $2
        paramArray = location.href
          .replace(this.customRegExpPattern, '$2')
          .split(this.customSeparatorChar);
      } else if (location.search) {
        // Regex for classical url-parameters pattern
        paramArray = location.search.replace(/^\?/, '').split('&');
      }

      paramArray.forEach(function (param) {
        var kv = param.split('=');
        if (kv[0] && (kv[1] || kv[1] === '0')) {
          paramObj[kv[0]] = kv[1];
        }
      });

      return paramObj;
    },

    /**
     * @param {Object} location - window.location
     * @returns {boolean} - true, if custom parameter pattern was recognized
     */
    locationHasCustomParams: function (location) {
      return (
        location.href.search(this.customSeparatorChar) > 0 &&
        this.customRegExpPattern.test(location.href)
      );
    },

    /**
     * Removes parameters that are staged for deletion from the temporary query obj
     * @param {Object} obj - temporary query
     * @param {Array} attrArray - Array with params
     * @returns {Object} - temporary query without params in attrArray
     */
    deleteStagedParams: function (obj, attrArray) {
      if (!attrArray || !attrArray.length || $.isEmptyObject(obj)) {
        return obj;
      }
      $.each(attrArray, function (index, value) {
        if (Object.prototype.hasOwnProperty.call(obj, value)) {
          delete obj[value];
        }
      });
      return obj;
    },

    /**
     * Performs an asynchronous Ajax request and inserts returned data (f.e. html fragment) into given
     * node (or selector), calls passed callback depending on success or failure.
     *
     * @param {Object} setup - object containing configuration attributes
     * @param {String} setup.url - string containing GET parameters
     * @param {Object} setup.query - query as object, f. e. { p: 1, s: 2 }
     * @param {Object} setup.$el - jQuery node where ajax content should be placed
     * @param {Boolean} [setup.customSubmitMethod] - if true, ajax request will be send as tilde-separated string
     * @param {Boolean} [setup.isPopState] - if false, new entry will be pushed to browser history
     * @param {Array} [setup.deleteParams] - if available, the parameter will be removed from the query and from the model
     * @param {Function} [setup.successCb] - optional callback, called when request was successful
     * @param {Function} [setup.failCb] - optional callback, called when request failed or $element is missing
     */
    ajaxRequestPage: function (setup) {
      // Get the model with current url parameters
      var urlParameterModel = this.getURLParamModel();
      var stringifiedParams;
      var ajaxReqUrl;
      var getParams;

      if ($.isEmptyObject(setup) || !setup.url || !setup.query || !setup.$el) {
        console.warn('missing parameters for ajax call', setup);
        return;
      } else if ((setup.failCb && !setup.$el) || !setup.$el.length) {
        return setup.failCb();
      }

      // If a popstate occurred, use the temporary query, otherwise merge the model's parameters with the query
      if (setup.isPopState) {
        getParams = $.extend({}, setup.query);
      } else {
        getParams = this.deleteStagedParams(
          $.extend({}, urlParameterModel.attributes, setup.query),
          setup.deleteParams
        );
      }

      // Prepare parameters for use in Ajax and History
      stringifiedParams = this.stringifyParameters(getParams, setup.customSubmitMethod || false);

      // Prepare ajax url string containing path and parameters
      if (setup.customSubmitMethod) {
        ajaxReqUrl = this.stringifyCustomUrl(setup.url, stringifiedParams);
      } else {
        ajaxReqUrl = setup.url + '?' + stringifiedParams;
      }

      // Send Ajax request
      $.ajax({
        type: 'GET',
        dataType: 'html',
        url: ajaxReqUrl,
      })
        .done(function (data) {
          // remove events before removing DOM
          ui.bootstrapper.defuse({
            context: setup.$el,
          });
          // Insert data into node
          setup.$el.html(data);

          if (setup.isPopState) {
            // Clear parameter model on popstate, replace with old params and trigger the change event
            urlParameterModel.clear();
            urlParameterModel.setAttributes(getParams, null);
          } else {
            // Update history if supported
            ui.requests.updateBrowserHistory(
              stringifiedParams,
              getParams,
              setup.customSubmitMethod || false
            );
            // Update model with additional and (optional) deleted parameters and and trigger the change event
            urlParameterModel.setAttributes(getParams, setup.deleteParams || null);
          }
          // try component reinitialization on loaded html
          ui.bootstrapper.reinitialize({
            context: setup.$el,
          });

          // Inform about placing the ajax content
          ui.trigger(ui.GlobalEvents.FRAGMENT_PLACED, setup.$el);

          // Execute success callback if available
          if (setup.successCb && typeof setup.successCb === 'function') {
            return setup.successCb();
          }
        })
        .fail(function (err) {
          console.log('request error', err.responseText);
          if (setup.failCb && typeof setup.failCb === 'function') {
            return setup.failCb();
          }
        });
    },
  };
}).call(this);
