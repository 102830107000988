/**
 * @author Dr. js. Claudio Baumgartner
 *
 */

(function () {
  'use strict';

  ui.FooterView = ui.ComponentView.extend({
    name: 'ui.FooterView',

    initialize: function () {
      this.$el.find('.js-footer-link-list-title').on('click', (event) => this.toggleList(event));
    },

    toggleList: function (event) {
      const $wrapperElement = $(event.currentTarget).closest('.js-footer-list-container');
      if (!$wrapperElement.is(this.$wrapperElement)) {
        this.$el.find('.js-footer-list-container').removeClass('is-active');
        this.$wrapperElement = $wrapperElement;
      }
      $wrapperElement.toggleClass('is-active');
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FooterComponent',
    View: ui.FooterView,
    selector: '.ui-js-g107-12-footer',
    reinitialize: true,
  });

  $(ui.bootstrapFooterComponent());
}).call(this);
