/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * This form logic implements following requirements:
   * - handles form dependencies of connected input fields (removeDisableOnSelect)
   * - does scrolling to first error occurrence
   * - can insert default input values after page load or fragment load
   * - can disable a submit button temporarily to prevent duplicates (ui-js-disable-onsubmit)
   *
   * Data-setup:
   * @params {Object} setup - object with configuration
   * @params {String} setup.removeDisableOnSelect - name of field that is dependant and should be disabled
   *   if input becomes invalid
   * @params {Object} setup.insertValues - object containing key (name attribute) value (string) pairs that will
   *   overwrite present value on each render
   * @params {Object} [setup.insertDelay] - optional delay in ms of insert operation
   */
  ui.FormBusinessLogicComponentView = ui.ComponentView.extend({
    name: 'ui.FormBusinessLogicComponentView',

    $submitBtn: null,

    events: {
      'validates:valid': 'validateEvent',
      'validates:invalid': 'validateEvent',
      'change select': 'clearSelectDependencies',
    },

    initialize: function () {
      this.$submitBtn = this.$('.ui-js-disable-onsubmit');

      // Install submit event handler if class is set
      if (this.$submitBtn.length) {
        var handler = this.disableSubmitBtn.bind(this);
        this.$el.on('submit', handler);
      }
    },

    render: function () {
      if (this.setup && this.setup.insertValues) {
        this.insertOnRender(this.setup.insertValues, this.setup.insertDelay);
      }
      return this;
    },

    /**
     * @param {Event | null} e - validation Event from form-validation.js or null if called by own view
     * @param {Object} obj - object with properties {$item, $element, view, isValid, silent, errors}
     */
    validateEvent: function (e, obj) {
      var setup, addDisableOnSelect, removeDisableOnSelect, resetValueOnChange, $dependentInput;

      if (!obj || !obj.$item) {
        return;
      }

      setup = obj.$item.data('setup');

      if (
        obj &&
        obj.$element &&
        (obj.$element.val() === '' ||
          (obj.$element.get(0).type === 'checkbox' && obj.$element.get(0).checked === false))
      ) {
        obj.isValid = false;
      }

      if (setup && setup.toggleSelectList && setup.toggleSelectListSource) {
        const $selectListTarget = this.$('[name="' + setup.toggleSelectList + '"]');
        const $selectListSource = this.$('[name="' + setup.toggleSelectListSource + '"]');
        const isCH =
          $selectListSource.attr('data-queryvalue') === 'CH' || $selectListSource.val() === 'CH';
        const option =
          $selectListSource[0].nodeName === 'SELECT'
            ? $selectListSource.find('option[value=' + $selectListSource.val() + ']')
            : false;
        const isCoop = !!(
          option.length &&
          option[0].dataset.setup &&
          JSON.parse(option[0].dataset.setup).showNext
        );
        if (obj.isValid && (isCH || isCoop)) {
          $selectListTarget.trigger('autocomplete:getResults', ['basicSelect']);
        } else if (!obj.isValid && (isCH || isCoop)) {
          $selectListTarget.trigger('autocomplete:removeSelectBox');
        } else if (obj.isValid === undefined && (!isCH || !isCoop)) {
          $selectListTarget.trigger('autocomplete:removeSelectBox');
        }
      }

      if (setup && setup.toggleAutocompleteList) {
        const $selectListTarget = this.$('[name="' + setup.toggleAutocompleteList + '"]');
        if (obj.isValid) {
          $selectListTarget.trigger('autocomplete:getResults', ['advancedSelect', obj]);
        } else {
          $selectListTarget.trigger('autocomplete:removeSelectBox', ['advancedSelect']);
        }
      }

      if (setup && setup.addDisableOnSelect) {
        addDisableOnSelect = this.toArray(setup.addDisableOnSelect);

        // Iterate array with remove-rules (usually it's length is 1)
        for (var n = addDisableOnSelect.length; n--; ) {
          $dependentInput = this.$('[name="' + addDisableOnSelect[n] + '"]');

          if ($dependentInput.length) {
            if (obj.isValid !== undefined) {
              if (obj.isValid) {
                this.toggleDisabled($($dependentInput[$dependentInput.length - 1]), !obj.isValid);
              } else {
                this.toggleDisabled($($dependentInput[0]), !obj.isValid);
              }
            }
          }
        }
      }

      if (setup && setup.removeDisableOnSelect) {
        removeDisableOnSelect = this.toArray(setup.removeDisableOnSelect);

        // Iterate array with remove-rules (usually it's length is 1)
        for (var m = removeDisableOnSelect.length; m--; ) {
          $dependentInput = this.$('[name="' + removeDisableOnSelect[m] + '"]');

          if ($dependentInput.length) {
            if (obj.isValid !== undefined) {
              if (obj.isValid) {
                this.toggleDisabled($($dependentInput[$dependentInput.length - 1]), obj.isValid);
              } else {
                this.toggleDisabled($($dependentInput[0]), obj.isValid);
              }
            }
          }
        }
      }
      if (setup && setup.resetValueOnChange) {
        resetValueOnChange = this.toArray(setup.resetValueOnChange);

        // Iterate array with remove-rules (usually it's length is 1)
        for (var o = resetValueOnChange.length; o--; ) {
          $dependentInput = this.$('[name="' + resetValueOnChange[o] + '"]');

          if ($dependentInput.length) {
            if (obj.isValid !== undefined) {
              this.toggleValue($($dependentInput[0]), obj.isValid);
            }
          }
        }
      }
    },

    /**
     * Toggles disabled attribute on given item
     * @param {Object} $item - jQuery element
     * @param {Boolean} toggleOn - if true, remove disabled attribute, add otherwise
     */
    toggleDisabled: function ($dependentItem, toggleOn) {
      if (toggleOn) {
        if (this.checkIfActive($dependentItem)) {
          return;
        }
        if (this.checkIfDisabled($dependentItem)) {
          return;
        }
        $dependentItem.removeAttr('disabled');
        $dependentItem.removeAttr('readonly');

        ui.trigger(ui.GlobalEvents.FORM_TRY_TO_ENABLE, {
          name: ui.GlobalEvents.FORM_TRY_TO_ENABLE,
        });
      } else {
        if (!$dependentItem.is(':disabled')) {
          $dependentItem.attr('disabled', 'disabled');
          $dependentItem.val('');
          $dependentItem.trigger('reset');
        }
        this.disableNextInChain($dependentItem);
      }
    },

    /**
     * Toggles disabled attribute on given item
     * @param {Object} $item - jQuery element
     * @param {Boolean} toggleOn - if true, remove disabled attribute, add otherwise
     */
    toggleValue: function ($dependentItem, toggleOn) {
      if (!toggleOn) {
        if (!$dependentItem.is(':disabled')) {
          $dependentItem.val('');
        }
      }
    },

    checkIfActive: function ($item) {
      var setup, checkIfActive;

      if (!$item || !$item.length) {
        return;
      }

      setup = $item.closest('.ui-js-form-item').data('setup');

      if (setup && setup.checkIfActive) {
        checkIfActive = setup.checkIfActive;
        var $checkBoxInput = $('[name="' + checkIfActive + '"]');
        if ($checkBoxInput.length) {
          return $checkBoxInput.get(0).checked;
        }
      }
    },

    checkIfDisabled: function ($item) {
      var setup, checkIfDisabled;

      if (!$item || !$item.length) {
        return;
      }

      setup = $item.closest('.ui-js-form-item').data('setup');

      if (setup && setup.checkIfDisabled) {
        checkIfDisabled = setup.checkIfDisabled;
        var $items = $('[name="' + checkIfDisabled + '"]');

        for (var n = $items.length; n--; ) {
          if ($items[n].disabled === false) {
            return false;
          }
        }

        return true;
      }
    },

    /**
     * Checks for existence of removeDisableOnSelect in items data-setup, continues toggle if true
     * @param {Object} $item - field that has been disabled, may contain another removeDisableOnSelect attribute
     */
    disableNextInChain: function ($item) {
      var setup, $next, removeDisableOnSelect, resetValueOnChange;

      if (!$item || !$item.length) {
        return;
      }

      setup = $item.closest('.ui-js-form-item').data('setup');

      if (setup && setup.removeDisableOnSelect) {
        removeDisableOnSelect = this.toArray(setup.removeDisableOnSelect);
        for (var n = removeDisableOnSelect.length; n--; ) {
          $next = $('[name="' + removeDisableOnSelect[n] + '"]');
          $next.length && this.toggleDisabled($next, false);
        }
      }
      if (setup && setup.resetValueOnChange) {
        resetValueOnChange = this.toArray(setup.resetValueOnChange);
        for (var o = resetValueOnChange.length; o--; ) {
          $next = $('[name="' + resetValueOnChange[o] + '"]');
          $next.length && this.toggleValue($next, false);
        }
      }
    },

    /**
     * Handles extraordinary requirement to clear next input and disable all following inputs
     * if a select field (f. e. country) has been changed
     * @param {Event} e - change event on select node
     */
    clearSelectDependencies: function (e) {
      var setup = $(e.target).closest('.ui-js-form-item').data('setup');
      var $dependentInput;
      var removeDisableOnSelect;

      if (setup && setup.removeDisableOnSelect) {
        removeDisableOnSelect = this.toArray(setup.removeDisableOnSelect);

        for (var n = removeDisableOnSelect.length; n--; ) {
          $dependentInput = this.$('[name="' + removeDisableOnSelect[n] + '"]');
          if ($dependentInput.length) {
            $dependentInput.val(''); // TODO: check again...
            var $item = $dependentInput.closest('.ui-js-form-item');
            if ($item.length) {
              this.validateEvent(null, {
                $item,
              });
            }
          }
        }
      }

      if (setup && setup.toggleSelection) {
        const selectedOption = $(e.target).find('option:selected');
        const selectedSetup = selectedOption[0]
          ? selectedOption[0].getAttribute('data-setup') || ''
          : '';
        const rules = selectedSetup
          ? JSON.parse(selectedSetup.replace(/\\'/g, "'").replace(/'/g, '"'))
          : false;

        if (rules) {
          if (rules.showNext) {
            this.showHideNext(rules.showNext, true);
          }
          if (rules.hideNext) {
            for (var i = 0; i < rules.hideNext.length; i++) {
              this.showHideNext(rules.hideNext[i], false);
            }
          }
        }
      }
    },

    showHideNext: function (elmName, show) {
      var currentItem = $('#' + elmName);
      var parent = currentItem.parent();

      if (show) {
        parent.removeClass('hidden');
        currentItem.removeAttr('disabled');
      } else {
        parent.addClass('hidden');
        currentItem.attr('disabled', 'disabled');
      }
    },

    /**
     * Fills input fields on render if inputs can be found by names from valueObj keys
     * @param {Object} valueObj - key-value-pairs with selector and new value
     * @param {Number} [insertDelay] - delay in ms (necessary for inputs loaded and animated via fragment-loader)
     */
    insertOnRender: function (valueObj, insertDelay) {
      var keys = Object.keys(valueObj);
      var self = this;
      var delay = insertDelay || 0;

      setTimeout(function () {
        keys.forEach(function (name) {
          var $input = self.$('input[name="' + name + '"]');
          if ($input.length) {
            $input.val(valueObj[name]);
          }
        });
      }, delay);
    },

    /**
     * Disables submit button on submit if form is valid and button has class 'ui-js-disable-onsubmit'
     * @param {Event} e - submit event
     */
    disableSubmitBtn: function (e) {
      var self = this;
      var $form = $(e.target);
      var downtime = 3000;

      // Return if either no button found or form is invalid
      if (!this.$submitBtn.length || !$form.valid(true)) {
        return;
      }

      this.$submitBtn.attr('disabled', 'disabled');
      setTimeout(function () {
        self.$submitBtn.removeAttr('disabled');
      }, downtime);
    },

    /**
     * Converts item to array
     * @param {Array|String} item - input 'name' will become ['name']
     * @returns {Array}
     */
    toArray: function (item) {
      return Object.prototype.toString.call(item) === '[object Array]' ? item : [item];
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FormBusinessLogicComponent',
    View: ui.FormBusinessLogicComponentView,
    selector: '.ui-js-form-logic',
  });

  $(ui.bootstrapFormBusinessLogicComponent());

  $('form').on('validation', function (e, data) {
    var formGroupClass = 'form-group';
    var errorBoxClass = 'error-box';
    var invalidClass = 'invalid';
    var invalidFormGroup;
    var errorBoxesGroup;

    if (!data.isValid && !data.silent) {
      invalidFormGroup = data.view.$('.' + formGroupClass + '.' + invalidClass).first();
      errorBoxesGroup = data.view
        .$('.' + errorBoxClass + ':not(:hidden)')
        .closest('.' + formGroupClass);
      if (invalidFormGroup.hasClass('ui-js-autoinsert')) {
        $('html, body').animate(
          {
            scrollTop: invalidFormGroup.eq(0).offset().top,
          },
          500
        );
      } else if (errorBoxesGroup.length) {
        $('html, body').animate(
          {
            scrollTop: errorBoxesGroup.eq(0).offset().top,
          },
          500
        );
      }
    }
  });
}).call(this);
