/**
 * @author Viktoriia Khardina
 * @description On mouse hover video
 */

(function () {
  'use strict';

  ui.VideoHover = ui.ComponentView.extend({
    name: 'ui.VideoHover',

    events: {
      'mouseenter video': 'playVideo',
      'mouseleave video': 'pauseVideo',
    },

    initialize: function () {
      this.vid = this.$el.find('video').get(0);
      this.checkViewport();
      $(window).on('resize', this.checkViewport.bind(this));
    },

    checkViewport: function () {
      if (/(md|lg)/.test(ui.Bootstrap.activeView)) {
        this.pauseVideo();
      } else {
        if (this.isPaused()) {
          this.playVideo();
        }
      }
    },
    isPaused: function () {
      return this.vid.paused;
    },

    playVideo: function () {
      this.vid.play();
    },

    pauseVideo: function () {
      this.vid.pause();
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'VideoHoverComponent',
    View: ui.VideoHover,
    selector: '.ui-js-video-hover',
  });

  // $(window).on('load', ui.bootstrapVideoHoverComponent());
  $(ui.bootstrapVideoHoverComponent());
}).call(this);
