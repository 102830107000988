/**
 * @author Sang-In Haetzel
 * @description handles geneeric filter flyout, show/hide flyout, reset filter
 */

(function () {
  'use strict';

  ui.MasonryLayoutComponentView = ui.ComponentView.extend({
    name: 'ui.MasonryLayoutComponentView',
    loadMoreClassSel: 'ui-js-masonry-layout__load-more',
    resultMEssageClassSel: 'ui-js-masonry-layout__results-message',
    $loadMoreBtn: null,
    pageIndex: null,
    pageIndexDataAttr: 'page-index',
    defaults: {
      ajaxUrl: '',
      customSubmitMethod: false,
      customEvent: false,
      gridSelector: 'ui-js-masonry-grid',
      itemSelector: 'ui-js-masonry-item',
      pageParam: 'p',
      loadItemsOnly: 'true',
      loadItemsOnLoad: true,
      spinner: false,
      offset: false,
      lastItemClass: 'ui-js-last-masonry-item',
    },

    events: {
      'click .ui-js-masonry-layout__load-more': 'loadMore',
    },

    masonry: null,

    initialize: function (options) {
      this.$loadMoreBtn = $('.' + this.loadMoreClassSel, this.$el);
      this.$masonryGrid = $('.' + this.setup.gridSelector, this.$el);
      this.$spinner = this.$el.find('.ui-js-masonry-spinner');

      this.initMasonry();

      if (this.setup.loadItemsOnLoad) {
        this.ajaxGetMasonryItems();
      }
    },

    initMasonry: function () {
      this.$masonryGrid.masonry({
        itemSelector: '.' + this.setup.itemSelector,
        percentPosition: true,
        transitionDuration: 0,
      });
    },

    loadMore: function (event) {
      event.preventDefault();

      this.$spinner.addClass('fade-in');

      this.ajaxGetMasonryItems();
    },

    ajaxGetMasonryItems: function () {
      var that = this;

      var ajaxUrl = this.setup.ajaxUrl;
      var ajaxParams = ui.requests.stringifyParameters(
        this.getAjaxParams(),
        this.setup.customSubmitMethod || false
      );

      var requestUrl = '';

      if (!ajaxUrl || !ajaxUrl.length) {
        console.warn('MasonryLayoutComponentView: No ajaxUrl specified');
        return;
      }

      if (this.setup.customSubmitMethod) {
        // Stringify url using custom methods
        requestUrl = ui.requests.stringifyCustomUrl(ajaxUrl, ajaxParams);
      } else {
        requestUrl = ajaxUrl + '?' + ajaxParams + window.location.hash;
      }

      // Send Ajax request
      $.ajax({
        type: 'GET',
        dataType: 'html',
        url: requestUrl,
      })
        .done(function (html) {
          // check if response contains html
          if ($.trim(html) === '') {
            that.$loadMoreBtn.addClass('is-hidden');
            return;
          }

          var $tempDiv = $('<div>emtpy</div>').html(html);
          var $newItems = $tempDiv.find('.' + that.setup.itemSelector);

          if (that.setup.spinner) {
            $newItems.addClass('is-hidden');
          }

          that.appendToGrid($newItems);
          that.reloadLayout();
          that.incrementPageIndex();
          // Inform about placing the ajax content
          // ##############
          ui.trigger(ui.GlobalEvents.FRAGMENT_PLACED, that.$el);

          that.$masonryGrid.imagesLoaded().always(function () {
            if (that.setup.spinner) {
              that.$spinner.removeClass('fade-in');
              $newItems.removeClass('is-hidden');
              $newItems.addClass('fade-in');
            }

            that.$masonryGrid.masonry('layout');
          });

          // check if response contains html
          if ($(html).filter('.' + that.setup.lastItemClass).length) {
            that.$loadMoreBtn.addClass('is-hidden');
            return;
          }

          that.$loadMoreBtn.removeClass('is-hidden');
        })
        .fail(function (err) {
          console.log('request error', err.responseText);
        });
    },

    getPageIndex: function () {
      this.pageIndex = this.$loadMoreBtn.data(this.pageIndexDataAttr);
      return this.pageIndex;
    },

    incrementPageIndex: function () {
      this.$loadMoreBtn.data(this.pageIndexDataAttr, this.pageIndex + 1);
    },

    getAjaxParams: function () {
      var URLParamModel = ui.requests.getURLParamModel();
      var pageParamObj = { loadItemsOnly: this.setup.loadItemsOnly };

      if (this.setup.offset) {
        pageParamObj.offset = this.setup.offset;
      }

      pageParamObj[this.setup.pageParam] = this.getPageIndex();

      return $.extend({}, URLParamModel.attributes, pageParamObj);
    },

    appendToGrid: function (html) {
      this.$masonryGrid.append(html);
    },

    reloadLayout: function () {
      this.$masonryGrid.masonry('reloadItems').masonry();
    },

    closeView: function () {
      this.$masonryGrid.masonry('destroy');
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'MasonryLayoutComponent',
    View: ui.MasonryLayoutComponentView,
    selector: '.ui-js-masonry-layout',
    reinitialize: true,
  });

  $(ui.bootstrapMasonryLayoutComponent());
}).call(this);
