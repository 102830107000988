/**
 * @author Jan Suwart
 */

(function () {
  'use strict';

  /**
   * Measures password strength according to defined sufficient and excellent criteria
   * and displays it graphically in a colored indicator bar.
   *
   * Data-setup
   * @param {Object} [pwExcellent]
   * @param {Number} [pwExcellent.matchCriteria] - amount of criteria that must match for excellent
   * @param {Object} [pwSufficient]
   * @param {Number} [pwSufficient.minlen] - minimum password length to match sufficient
   * @param {Number} [pwSufficient.matchCriteria] - amount of criteria that must match for sufficient
   */
  ui.PasswordCheckerView = ui.ComponentView.extend({
    name: 'ui.PasswordCheckerComponentView',

    events: {
      'change input.ui-js-password': 'checkStrength',
      'keyup input.ui-js-password': 'checkStrength',
      'paste input.ui-js-password': 'checkStrength',

      reset: 'checkStrength',
    },

    $password: null,
    $passRepeat: null,
    $hints: null,
    lastValue: '',

    // Current password strength: -1: empty, 0: insufficient, 1 - 5: amount of matched criteria
    strength: -1,

    stopCriteria: {
      whitespace: /\s/g, // Matches white space, incl. space, tab, feed etc.
    },

    negCriteria: {
      stopwords: /(password|qwert|asdf|fghj|hello|world|welt|pwd|migros)/g, // Matches stopwords within word boundaries
    },

    posCriteria: {
      special: /\W/g, // Matches any character that is not a word character,
      uppercase: /[A-Z]/g, // Matches uppercase letters
      lowercase: /[a-z]/g, // Matches uppercase letters
      digit: /[0-9]/g, // Matches digits
    },

    // Default values for sufficient and excellent passwords
    defaults: {
      pwExcellent: {
        matchCriteria: 4,
      },
      pwSufficient: {
        minlength: 5,
        matchCriteria: 2,
      },
    },

    initialize: function () {
      this.$password = this.$('.ui-js-password');
      this.$passRepeat = this.$('.ui-js-password-repeat');
      this.$hints = this.$('.strength');
    },

    /**
     * @param {String} value - the password
     * @param {Regex} regex - one regular expression
     * @returns {number} amount of matches
     */
    matchRegex: function (value, regex) {
      var matched = value.match(regex);
      return matched ? matched.length : 0;
    },

    /**
     * @param {String} value - the password
     * @param {Object} criteria - object that contains regular expressions
     * @returns {number} quantity of expressions that matched
     */
    countMatches: function (value, criteria) {
      var quantity = 0;

      for (var pattern in criteria) {
        if (this.matchRegex(value, criteria[pattern])) {
          quantity++;
        }
      }
      return quantity;
    },

    /**
     * Tests password for at least one occurrence of the following
     * +1 uppercase letter
     * +1 lowercase letter
     * +1 digit
     * +1 special character
     * -1 stopword (pwd etc.)
     * Each match adds/subtracts one point. The sum results in the strength level.
     */
    checkStrength: function (e) {
      var value = this.$password.val();

      if (e.type === 'reset') {
        value = '';
        this.strength = -1;
      } else if (value === this.lastValue) {
        return;
      }

      // Password has insufficient length
      if (value.length > 0 && value.length < this.setup.pwSufficient.minlength) {
        this.strength = 0;
      } else if (value.length > 0) {
        // Only give strength level >0 if none stop criteria has been found
        if (this.countMatches(value, this.stopCriteria)) {
          this.strength = 0;
        } else {
          this.strength = 0;
          // Calculate strength level by adding and subtracting matches
          this.strength += this.countMatches(value, this.posCriteria);
          this.strength -= this.countMatches(value, this.negCriteria);
        }
      }

      this.lastValue = value;
      this.render();
    },

    /**
     * returns true if password is sufficient.
     */
    passed: function () {
      if (this.strength < this.setup.pwSufficient.matchCriteria) {
        return false;
      }

      return true;
    },

    // Render green/red border indicating strength level
    render: function () {
      this.$hints.addClass('hidden');
      this.$password.removeClass('lev-0 lev-1 lev-2 lev-3 lev-4 is-green');

      if (this.$password.val() && this.strength >= 0) {
        if (this.strength >= this.setup.pwExcellent.matchCriteria) {
          this.$('.strength.excellent').removeClass('hidden');
          this.$password.addClass('is-green');
        } else if (this.strength >= this.setup.pwSufficient.matchCriteria) {
          this.$('.strength.sufficient').removeClass('hidden');
          this.$password.addClass('is-green');
        } else {
          this.$('.strength.insufficient').removeClass('hidden');
        }
        this.$password.addClass('lev-' + this.strength);
      }

      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'PasswordCheckerComponent',
    View: ui.PasswordCheckerView,
    selector: '.ui-js-password-checker',
  });

  $(ui.bootstrapPasswordCheckerComponent());
}).call(this);
