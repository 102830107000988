/**
 * @author Henri Podolski
 */
(function () {
  'use strict';

  ui.TabSliderComponentView = ui.ComponentView.extend({
    name: 'ui.TabSliderComponentView',

    events: {
      'click .js-milestone': 'onMilestoneClick',
      'slide.bs.carousel .ui-js-carousel': 'onBeforeSliding',
      'slid.bs.carousel .ui-js-carousel': 'onAfterSliding',
      'keydown .js-milestone': 'onMilestoneKeyDown',
      'touchstart .carousel-inner .item': 'onTouchstart',
      'touchend .carousel-inner .item': 'onTouchend',
    },

    defaults: {
      pxThreshold: 40,
    },

    initialize: function () {
      this.setMilestoneStates = this.setMilestoneStates.bind(this);
      this.setMilestoneProgressBars = this.setMilestoneProgressBars.bind(this);
      this.onWindowResize = this.onWindowResize.bind(this);
      this.applyResponsiveView = this.applyResponsiveView.bind(this);
      this.onCarouselTouchend = this.onCarouselTouchend.bind(this);
      this.onDragEnd = this.onDragEnd.bind(this);
      this.onDragStart = this.onDragStart.bind(this);

      this.topSliderTouch = false;
      this.topSliderTouchEventsRemoved = false;
      this.syncSliderTouch = false;
      this.syncSliderTouchEventsRemoved = false;
      this.isActive = false;

      this.index = 0;
      this.milestonePositions = [];

      this.$milestones = this.$('.js-milestone');
      this.$dragPill = this.$('.js-active-pill');
      this.$progressbar = this.$('.js-progress-indicator');
      this.$topSlider = this.$('.ui-js-carousel.top-slider');
      this.$syncSlider = this.$('.ui-js-carousel.sync-slider');

      this.$milestones.find('.milestone').attr('tabindex', 0);

      this.$dragPill.draggabilly({
        axis: 'x',
        containment: true,
      });

      this.$dragPill.on('dragStart', this.onDragStart);
      this.$dragPill.on('dragEnd', this.onDragEnd);

      this.setMilestoneStates();
      this.setMilestoneProgressBars();

      this.applyResponsiveView();

      this.$el.on(ui.GlobalEvents.CAROUSEL_TOUCHEND, this.onCarouselTouchend);

      $(window).on('resize', this.onWindowResize);
      ui.on('bootstrap.activemediaquery.changed', this.applyResponsiveView);
    },

    onCarouselTouchend: function (evt, data) {
      const index = data.nextIndex || data.targetPos;
      let force = $(data.currentTarget).hasClass('top-slider') ? 'sync' : false;
      force = $(data.currentTarget).hasClass('sync-slider') ? 'top' : force;

      this.activate(index, force);
    },

    onWindowResize: function () {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }

      this.resizeTimeout = setTimeout(
        function () {
          this.setMilestoneStates();
          this.setMilestoneProgressBars();
        }.bind(this),
        1
      );
    },

    applyResponsiveView: function () {
      var tempHeadlineExists =
        this.$temporaryMilestoneHeadline && this.$temporaryMilestoneHeadline.length;

      if (!tempHeadlineExists && !/lg|md/.test(ui.Bootstrap.activeView)) {
        this.$('.milestones').append(
          '<div class="tmp-milestone-headline js-temp" style="display: block;"></div>'
        );

        this.$temporaryMilestoneHeadline = this.$('.js-temp');
      } else if (tempHeadlineExists && /lg|md/.test(ui.Bootstrap.activeView)) {
        this.$temporaryMilestoneHeadline.remove();

        this.$temporaryMilestoneHeadline = null;
      }

      this.activate(this.index);
    },

    setMilestoneStates: function () {
      // clear previous cached milestones
      this.milestonePositions = [];

      $(this.$milestones[0]).addClass('is-first');
      $(this.$milestones[this.$milestones.length - 1]).addClass('is-last');

      this.$milestones.each(
        function (i, el) {
          var $milestoneElement = $(el);
          var $milestoneTarget = $milestoneElement.find('.milestone');
          this.cacheMilestonePosition($milestoneTarget);

          if ($milestoneElement.hasClass('is-active')) {
            this.index = i;
            this.$currentMilestone = $milestoneElement;
          }
        }.bind(this)
      );
    },

    setMilestoneProgressBars: function () {
      this.$milestones.each((i, el) => {
        const $currentMilestone = $(el);
        const $nextMilestone = $(this.$milestones[i + 1]);

        if (!$nextMilestone.length) {
          return;
        }

        const $currentMilestoneTarget = $currentMilestone.find('.milestone');
        const $nextMilestoneTarget = $nextMilestone.find('.milestone');
        const $milestoneProgressBar = $currentMilestoneTarget.find('.js-milestone-progress-bar');

        const currentMilestoneYPosition = $currentMilestoneTarget.offset().left;
        const nextMilestoneYPosition = $nextMilestoneTarget.offset().left;

        const progressBarMarginY = 10;
        const progressBarWidth =
          nextMilestoneYPosition -
          currentMilestoneYPosition -
          progressBarMarginY -
          $currentMilestoneTarget.width() / 2;

        $milestoneProgressBar.css('width', progressBarWidth);
      });
    },

    cacheMilestonePosition: function ($milestoneTarget) {
      var leftprogressBarPosition = this.$progressbar.offset().left;
      var leftMilestonePosition = $milestoneTarget.offset().left;
      this.milestonePositions.push({
        left: leftMilestonePosition - leftprogressBarPosition,
      });
    },

    getIndexByLeftPosition: function (direction, leftPos) {
      var index = this.index;

      if (!direction || isNaN(leftPos)) {
        return index;
      }

      var closest = this.milestonePositions.reduce(function (previousMilestone, currentMilestone) {
        if (!previousMilestone) {
          return currentMilestone;
        }

        return Math.abs(currentMilestone.left - leftPos) <
          Math.abs(previousMilestone.left - leftPos)
          ? currentMilestone
          : previousMilestone;
      });

      index = this.milestonePositions.indexOf(closest);

      return index;
    },

    onMilestoneKeyDown: function (evt) {
      if (evt.keyCode === 13) {
        this.activate($(evt.currentTarget).data('index'));
      }
    },

    onTouchstart: function (evt) {
      document.activeElement.blur();

      this.topSliderTouch = this.$topSlider.is($(evt.target).closest('.ui-js-carousel.top-slider'));
      this.syncSliderTouch = this.$syncSlider.is(
        $(evt.target).closest('.ui-js-carousel.sync-slider')
      );

      if (this.topSliderTouch) {
        this.syncSliderTouchEventsRemoved = true;
        this.$syncSlider.prop('View').undelegateEvents();
      } else if (this.syncSliderTouch) {
        this.topSliderTouchEventsRemoved = true;
        this.$topSlider.prop('View').undelegateEvents();
      }
    },

    onTouchend: function (evt) {
      if (this.syncSliderTouchEventsRemoved) {
        this.$syncSlider.prop('View').delegateEvents();
        this.syncSliderTouchEventsRemoved = false;
      }

      if (this.topSliderTouchEventsRemoved) {
        this.$topSlider.prop('View').delegateEvents();
        this.topSliderTouchEventsRemoved = false;
      }

      this.topSliderTouch = false;
      this.syncSliderTouch = false;
    },

    onDragStart: function (evt) {
      this.startLeftPosition = parseInt(this.$dragPill.css('left'), 10);
      clearTimeout(this.draggingClassDelay);
      this.$progressbar.addClass('is-dragging');
    },

    onDragEnd: function (evt) {
      var thresholdMin = this.startLeftPosition - this.setup.pxThreshold;
      var thresholdMax = this.startLeftPosition + this.setup.pxThreshold;
      // default is startLeftPosition
      var endLeftPosition = this.startLeftPosition;
      // default is start index
      var direction = null;

      this.draggingClassDelay = setTimeout(
        function () {
          endLeftPosition = parseInt(this.$dragPill.css('left'), 10);
          this.$progressbar.removeClass('is-dragging');

          if (endLeftPosition < thresholdMin) {
            direction = 'left';
          } else if (endLeftPosition > thresholdMax) {
            direction = 'right';
          }
          this.activate(this.getIndexByLeftPosition(direction, endLeftPosition));
        }.bind(this),
        100
      );
    },

    onMilestoneClick: function (evt) {
      this.activate($(evt.currentTarget).data('index'));
    },

    onBeforeSliding: function (evt) {
      const topCarousel = this.$topSlider.prop('View');
      const syncCarousel = this.$syncSlider.prop('View');

      if (topCarousel.isMoving || syncCarousel.isMoving) {
        syncCarousel.undelegate('touchstart');
        syncCarousel.undelegate('touchmove');
        syncCarousel.undelegate('touchend');
        topCarousel.undelegate('touchstart');
        topCarousel.undelegate('touchmove');
        topCarousel.undelegate('touchend');
      }
    },

    onAfterSliding: function (evt) {
      var topCarousel = this.$topSlider.prop('View');
      var syncCarousel = this.$syncSlider.prop('View');

      topCarousel.undelegateEvents();
      topCarousel.delegateEvents();
      syncCarousel.undelegateEvents();
      syncCarousel.delegateEvents();
    },

    activate: function (index, force = false) {
      if (this.isActive) return;

      this.isActive = true;

      this.index = index;

      this.$progressbar.removeClass('is-dragging');

      this.$previousMilestone = this.$currentMilestone;
      this.$previousMilestone.removeClass('is-active');
      this.$previousMilestone.find('.milestone').attr('tabindex', 0);
      this.$currentMilestone = this.$milestones.eq(this.index);
      this.$currentMilestone.addClass('is-active');
      this.$currentMilestone.find('.milestone').attr('tabindex', -1);

      this.position(this.milestonePositions[index].left);

      this.forceCarouselSync(force);
    },

    forceCarouselSync: function (force) {
      var topCarousel = this.$topSlider.prop('View');
      var syncCarousel = this.$syncSlider.prop('View');

      if (this.index !== topCarousel.index || force === 'top') {
        topCarousel.$carousel.carousel(this.index);
        topCarousel.isManualSlide = true;
      }

      if (this.index !== syncCarousel.index || force === 'sync') {
        syncCarousel.$carousel.carousel(this.index);
      }

      if (!force) {
        setTimeout(() => (this.isActive = false), 600);
      } else {
        this.isActive = false;
      }
    },

    position: function (pos) {
      this.$dragPill.css('left', pos + 'px');

      var $textNode = this.$currentMilestone.find('.milestone-headline');
      if (this.$temporaryMilestoneHeadline && $textNode.length) {
        this.$temporaryMilestoneHeadline.text($textNode.text());
      }
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'TabSliderComponent',
    View: ui.TabSliderComponentView,
    selector: '.ui-js-tab-slider',
  });

  $(ui.bootstrapTabSliderComponent());
}).call(this);
