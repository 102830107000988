/**
 * @author Chuck Norris
 *
 */

(function () {
  'use strict';

  ui.AsyncButtonComponentView = ui.ComponentView.extend({
    name: 'ui.AsyncButtonComponentView',

    events: {
      click: 'sendRequest',
    },

    initialize: function () {
      this.isSending = false;
      this.isActive = this.$el.hasClass('is-active');
    },

    sendRequest: function (event) {
      event.preventDefault();
      if (this.isSending) {
        return;
      }

      this.pushToDataLayer();
      this.setSendingStyle();

      const ajaxUrl = this.isActive ? this.setup.deactivationUrl : this.setup.activationUrl;
      const requestPromise = new Promise((resolve, reject) => {
        $.ajax({
          method: 'POST',
          url: ajaxUrl,
        })
          .fail(() => reject(new Error('call failed')))
          .always(function (payload) {
            payload.success ? resolve() : reject(new Error('call failed'));
            resolve();
          });
      });

      /* This timeout was specifically demanded by the design.
       * After the animation is finnished (300ms) there will
       * be a 1500ms timeout until the next button state will be triggered */
      const timeOutPromise = new Promise((resolve) => setTimeout(() => resolve(), 1800));
      const promises = [requestPromise, timeOutPromise];
      Promise.all(promises).then(() => this.updateButton());
    },

    updateButton: function () {
      this.$el.removeClass('is-animating');

      this.isSending = false;

      if (!this.isActive) {
        this.setActiveStyle();
      } else {
        this.setInactiveStyle();
      }

      ui.trigger(ui.GlobalEvents.ASYNC_BTN_STATE, {
        isSending: this.isSending,
        isActive: this.isActive,
        $el: this.$el,
      });
    },

    setSendingStyle: function () {
      this.isSending = true;
      this.$el.addClass('is-animating');
      if (!this.isActive) {
        const activationText = this.setup.textDuringActivation || this.setup.textInactiveState;
        this.$el.find('.js-text-container').text(activationText);
      } else {
        const deactivationText = this.setup.textDuringDeactivation || this.setup.textActiveState;
        this.$el.find('.js-text-container').text(deactivationText);
      }
    },

    setActiveStyle: function () {
      this.isActive = true;
      this.$el.addClass('btn-outline is-active');
      this.$el.find('.js-text-container').text(this.setup.textActiveState);
    },

    setInactiveStyle: function () {
      this.isActive = false;
      this.$el.removeClass('btn-outline is-active');
      this.$el.find('.js-text-container').text(this.setup.textInactiveState);
    },

    pushToDataLayer: function () {
      if (!window.dataLayer) return;

      const buttonText = this.isActive ? this.setup.textActiveState : this.setup.textInactiveState;
      const dataLayerObj = {
        event: 'buttonClick',
        buttonText,
      };

      window.dataLayer.push(dataLayerObj);

      if (window.logTracking) {
        console.info('added dataLayer data:', JSON.stringify(dataLayerObj));
        console.info('Tracking Obj', dataLayerObj);
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'AsyncButtonComponent',
    View: ui.AsyncButtonComponentView,
    selector: '.ui-js-async-button',
    reinitialize: true,
  });

  $(ui.bootstrapAsyncButtonComponent());
}).call(this);
