/**
 * @author Nils von Rymon-Lipinski (Aperto AG - An IBM Company)
 * @description prefill forms with userdata if user logged in
 */

(function () {
  'use strict';

  ui.FormPrefillView = ui.ComponentView.extend({
    name: 'ui.FormPrefillView',

    initialize: function (options) {
      if (!this.$el.data('prefill')) {
        return false;
      }
      if (this.$el.closest('.ui-js-form').find('> .invalid').length === 1) {
        return false;
      }
      this.viewport = ui.Bootstrap ? ui.Bootstrap.activeView : '';
      this.prefillData = this.formatData(this.$el.data('prefill'));
      this.prefillEventData = this.formatData(this.$el.data('prefillEvents'));
      this.autocompleteListTargets = [];

      this.bindEvents();
      this.triggerInit();
    },

    bindEvents: function () {
      var self = this;
      ui.on(ui.GlobalEvents.LOGIN_PREFILL, function (e) {
        if (localStorage.getItem('logLogin') === 'true') {
          console.info('LOGIN_PREFILL', e, JSON.stringify(e));
        }
        if (!document.body.contains(self.$el[0])) {
          return;
        }
        if (e && e.data) {
          self.profileData = e.data;
        }
        if (e && ((e.cid && e.cid === self.cid) || e.cid === undefined)) {
          if (e.cid === self.cid) {
            window.setLater = true;
          }
          self.startReplacer(self.profileData);
          window.prefill = true;
          ui.trigger(ui.GlobalEvents.LOGIN_PREFILL_DONE, {
            name: ui.GlobalEvents.LOGIN_PREFILL_DONE,
            cid: self.cid,
          });
        } else {
          window.prefill = false;
        }
      });
    },

    triggerInit: function () {
      ui.trigger(ui.GlobalEvents.LOGIN_PREFILL_INIT, {
        name: ui.GlobalEvents.LOGIN_PREFILL_INIT,
        cid: this.cid,
      });
    },

    /**
     * Formats data to right format to use JSON.parse
     * @param {Array|String} item - input 'name' will become ['name']
     * @returns {Array}
     */
    formatData: function (data) {
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data.replace(/\\'/g, "'").replace(/'/g, '"'));
        } catch (e) {
          console.log('Error parsing JSON -> data', data);
          console.log('Error Msg', e);
        }
      }
      return data;
    },

    /**
     * Converts item to array
     * @param {Array|String} item - input 'name' will become ['name']
     * @returns {Array}
     */
    toArray: function (item) {
      return Object.prototype.toString.call(item) === '[object Array]' ? item : [item];
    },

    startReplacer: function (data) {
      var self = this;
      $.each(this.prefillData, function (k, v) {
        self.findAndReplace(k, v, data);
      });
      setTimeout(() => {
        // try to enable submit if every field is prefilled
        // Set timeout because of race conditions
        ui.trigger(ui.GlobalEvents.FORM_TRY_TO_ENABLE);
      }, 500);
    },

    triggerEvent: function ($target, event) {
      if (event === 'focusout' && window.setLater) {
        setTimeout(() => {
          $target.trigger(event);
        }, 200);
      } else {
        $target.trigger(event);
      }
    },

    /**
     *
     * @param selector
     * @param field
     * @param data
     */
    findAndReplace: function (selector, field, data) {
      const $targetFormItem = this.$el.find('[name="' + selector + '"]');
      const fieldData = field.split('.');
      const getValue = (field, data) => {
        // MGRSR-6743 - [WWWMIG-3321]
        // check locale and reduce to 'de', 'it' or 'fr' from '{language}-CH'
        if (field === 'locale') {
          return data[field].split('-')[0];
        }
        // MGRSR-6743 - [WWWMIG-3321]
        // check gender and reduce to 'f' or 'm' from 'male/female'
        if (field === 'gender') {
          return data[field].charAt(0);
        }

        if (fieldData.length === 2 && data[fieldData[0]] && data[fieldData[0]][fieldData[1]]) {
          return data[fieldData[0]][fieldData[1]];
        }

        return data[field] ? data[field] : undefined;
      };

      const value = getValue(field, data);

      if ($targetFormItem && $targetFormItem.length && value && value.length) {
        const $formItemLogicWrapper = $targetFormItem.closest('.ui-js-form-item');
        const setup = this.formatData($formItemLogicWrapper.data('setup'));

        if ($targetFormItem.length === 1) {
          // SELECT FIELDS
          if ($targetFormItem.get(0).tagName === 'SELECT') {
            this.setSelect($targetFormItem, value);
          }
          // INPUT FIELDS
          if ($targetFormItem.get(0).tagName === 'INPUT') {
            var placeholder = $targetFormItem.attr('placeholder');
            var type = $targetFormItem.attr('type');
            var isDate = $targetFormItem.parents('.ff-date').length;
            var isDatePicker = $targetFormItem.parents('.ui-datepicker').length;

            // DATE
            if (isDate) {
              this.setDates($targetFormItem, placeholder, value);
            }
            // CHECKBOXES
            else if (type === 'checkbox') {
              this.setCheckbox($targetFormItem);
            }
            // RADIOS
            else if (type === 'radio') {
              this.setRadio($targetFormItem, value);
              // DATEPICKER
            } else if (isDatePicker) {
              var currentViewport = ui.Bootstrap.activeView || 'unknown';
              var isNativeDatePicker =
                /xs|ms|sm|md/.test(currentViewport) && ui.browser.supportsTouch();

              if (isNativeDatePicker) {
                this.setInputNativeDatepicker($targetFormItem, value);
              } else {
                this.setInputDatepicker($targetFormItem, value);
              }
            } else if (this.autocompleteListTargets.indexOf(selector) > -1) {
              $formItemLogicWrapper.data('prefillValue', value);

              return;
            }
            // OTHER INPUTS like text, number...
            else {
              this.setInputValue($targetFormItem, value);
            }
          }
        }

        if ($targetFormItem.length > 1) {
          if (
            $targetFormItem.get(0).tagName === 'INPUT' &&
            $targetFormItem.get(0).type === 'radio'
          ) {
            this.setRadio($targetFormItem, value);
          }
        }

        if (this.prefillEventData && this.prefillEventData[selector]) {
          this.triggerEvent($targetFormItem, this.prefillEventData[selector]);

          if (!setup || !setup.removeDisableOnSelect) return;

          const removeDisableOnSelectFields = this.toArray(setup.removeDisableOnSelect);
          const prefillData = { ...this.prefillData };

          removeDisableOnSelectFields.forEach((fieldSelector) => {
            if (fieldSelector !== undefined) {
              setTimeout(() => {
                var $targetRemoveDisabled = this.$el.find(
                  '[name="' + setup.removeDisableOnSelect + '"]'
                );
                $targetRemoveDisabled.prop('disabled', false);
                this.findAndReplace(fieldSelector, prefillData[fieldSelector], this.profileData);
              }, 100);
            }
          });
        }

        if (setup.toggleAutocompleteList) {
          if (!this.autocompleteListTargets.includes(setup.toggleAutocompleteList)) {
            this.autocompleteListTargets.push(setup.toggleAutocompleteList);
          }
        }
      }
    },

    /**
     *
     * @param $targetFormItem
     * @param value
     */
    setSelect: function ($targetFormItem, value) {
      var $options = $targetFormItem.find('option');
      var $targetOption = $options.filter(function (idx, elm) {
        return elm.value.toLowerCase() === value.toLowerCase();
      });
      if ($targetOption.length === 1) {
        $targetOption.get(0).selected = true;
      }
    },

    /**
     *
     * @param $targetFormItem
     * @param placeholder
     * @param value
     */
    setDates: function ($targetFormItem, placeholder, value) {
      var dateSplit = value.split('-');
      if ($targetFormItem.attr('name').indexOf('.day') > -1) {
        $targetFormItem.val(dateSplit[2]);
      } else if ($targetFormItem.attr('name').indexOf('.month') > -1) {
        $targetFormItem.val(dateSplit[1]);
      } else if ($targetFormItem.attr('name').indexOf('.year') > -1) {
        $targetFormItem.val(dateSplit[0]);
      }
    },

    /**
     *
     * @param $targetFormItem
     * @param value
     */
    setInputValue: function ($targetFormItem, value) {
      $targetFormItem.val(value);
      if ($targetFormItem.attr('disabled')) {
        $targetFormItem.removeAttr('disabled');
      }
    },

    /**
     *
     * @param $targetFormItem
     * @param value
     */
    setInputDatepicker: function ($targetFormItem, value) {
      var dateSplit = value.split('-');
      var formatedDate = dateSplit[2] + '.' + dateSplit[1] + '.' + dateSplit[0];
      $targetFormItem.val(formatedDate);
    },

    /**
     *
     * @param $targetFormItem
     * @param value
     */
    setInputNativeDatepicker: function ($targetFormItem, value) {
      var dateSplit = value.split('.');
      var formatedDate = dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0];
      $targetFormItem.val(formatedDate);
    },

    /**
     * set value of checkbox
     * @param $targetFormItem - checkbox to change
     */
    setCheckbox: function ($targetFormItem) {
      $targetFormItem.get(0).checked = true;
    },

    /**
     * set value of radio
     * @param $targetFormItem - radio to change
     * @param value - value to equal
     */
    setRadio: function ($targetFormItem, value) {
      if ($targetFormItem.length > 1) {
        var $targetRadio = $targetFormItem.filter(function (idx, elm) {
          return elm.value.toLowerCase() === value.toLowerCase();
        });
        if ($targetRadio.length === 1) {
          $targetRadio.get(0).checked = true;
        }
      } else {
        $targetFormItem.get(0).checked = true;
      }
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FormPrefill',
    View: ui.FormPrefillView,
    selector: '.ui-js-prefill-form',
    reinitialize: true,
  });

  $(ui.bootstrapFormPrefill());
}).call(this);
