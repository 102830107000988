/**
 * @author Nils von Rymon-Lipinski (Aperto GmbH - An IBM Company)
 */
(function () {
  'use strict';

  ui.StageDecoratorComponentView = ui.ComponentView.extend({
    name: 'ui.StageDecoratorComponentView',

    defaults: {},

    events: {},

    initialize: function () {
      this.imageSet = false;
      this.resizeHandler = this.render.bind(this);
      this.bindEvents();
    },

    bindEvents: function () {
      if (!this.checkDisplayProperty()) {
        $(window).on('resize', this.resizeHandler);
      }
    },

    checkDisplayProperty: function () {
      return this.$el.css('display') !== 'none';
    },

    render: function () {
      if (this.checkDisplayProperty() && !this.imageSet) {
        this.imageSet = true;
        this.$el.html('<img src=' + this.setup.imgSrc + ' alt=' + this.setup.imgAlt + '>');
        $(window).off('resize', this.resizeHandler);
      }
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'StageDecoratorComponent',
    View: ui.StageDecoratorComponentView,
    selector: '.ui-js-stage-decorator',
  });

  $(ui.bootstrapStageDecoratorComponent());
}).call(this);
