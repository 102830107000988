/**
 * @author Nils von Rymon-Lipinski (Aperto GmbH - An IBM Company)
 *
 */

(function () {
  'use strict';

  ui.HistoryComponentView = ui.ComponentView.extend({
    name: 'ui.HistoryComponentView',
    masonry: null,
    defaults: {
      gridSelector: '.ui-js-history__timeline-grid',
      timeLineSelector: '.ui-js-history__timeline-line',
      itemSelector: '.ui-js-history__item',
      stickyFilterSel: '.ui-js-history__filter-sticky',
      stickyFilterTextSel: '.ui-js-history__filter-sticky-text',
      filterChoiceSel: '.ui-js-history__filter',
      filterChoiceTextSel: '.ui-js-history__filter-choice-text',
      filterChoiceOpenSel: '.ui-js-history__filter-choice-open',
      filterChoiceCloseSel: '.ui-js-history__filter-choice-close',
      filterChooserWrapperSel: '.ui-js-history__filter-chooser',
      filterChooserItemSel: '.ui-js-history__filter-chooser-list-item',
      isLoadedClass: 'is-loaded',
      isStickyClass: 'is-sticky',
      isFadeOutClass: 'is-fade-out',
      isActiveClass: 'is-active',
      amountOfStartItems: {
        xs: 12,
        ms: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      amountOfToScrollItems: 12,
      amountOfLoadingItems: {
        xs: 12,
        ms: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
    },

    initialize: function () {
      $('body').addClass('is-overflow-x-hidden');
      this.$grid = this.$el.find(this.defaults.gridSelector);
      this.stickyFilter = this.$el.find(this.defaults.stickyFilterSel);
      this.$timeLine = this.$el.find(this.defaults.timeLineSelector);
      this.$items = this.$el.find(this.defaults.itemSelector);
      this.$years = this.$items.filter('[data-sticky]');
      this.$stickyText = this.$el.find(this.defaults.stickyFilterTextSel);

      // history-filter (not sticky)
      this.$filterChoice = this.$el.find(this.defaults.filterChoiceSel);
      this.$filterChoiceText = this.$el.find(this.defaults.filterChoiceTextSel);
      this.$filterChoiceOpen = this.$filterChoice.find(this.defaults.filterChoiceOpenSel);
      this.$filterChoiceClose = this.$filterChoice.find(this.defaults.filterChoiceCloseSel);
      this.$filterChoiceWrapper = this.$filterChoice.find(this.defaults.filterChooserWrapperSel);
      this.$filterChoiceLink = this.$filterChoice.find(this.defaults.filterChooserItemSel);

      // history-filter (sticky)
      this.$filterStickyChoiceOpen = this.stickyFilter.find(this.defaults.filterChoiceOpenSel);
      this.$filterStickyChoiceClose = this.stickyFilter.find(this.defaults.filterChoiceCloseSel);
      this.$filterStickyChoiceWrapper = this.stickyFilter.find(
        this.defaults.filterChooserWrapperSel
      );
      this.$filterStickyChoiceLink = this.stickyFilter.find(this.defaults.filterChooserItemSel);

      // scroll prevent vars
      this.scrollCheck = false;
      this.scrollReset = false;
      this.ticking = false;

      // anchor
      this.$anchorTaget = false;
      this.scrollToAnchorBool = false;

      this.lastScrollTop = 0;
      this.$stickyText.text($(this.$years[0]).attr('data-sticky'));
      this.itemsAjaxCounter = 0;

      this.$grid.addClass(this.defaults.isActiveClass);
      this.$timeLine.addClass(this.defaults.isActiveClass);

      this.bindEvents();
      this.setActiveYear();
      this.toggleStickyFilter();
      this.loadFirstItems();
      this.callAfterTimeout();
    },

    callAfterTimeout: function () {
      var self = this;
      setTimeout(function () {
        self.$items.trigger('history:scroll:item:check');
      }, 500);
    },

    /**
     * bind some events
     */
    bindEvents: function () {
      // local events
      this.$filterChoiceOpen.on('click', this.openFilterHandler.bind(this));
      this.$filterChoiceClose.on('click', this.closeFilterHandler.bind(this));
      this.$filterChoiceLink.on(
        'click',
        '.ui-history__filter-chooser-link',
        this.clickFilterLinkHandler.bind(this)
      );
      this.$filterStickyChoiceOpen.on('click', this.openStickyFilterHandler.bind(this));
      this.$filterStickyChoiceClose.on('click', this.closeStickyFilterHandler.bind(this));
      this.$filterStickyChoiceLink.on(
        'click',
        '.ui-history__filter-chooser-link',
        this.clickStickyFilterLinkHandler.bind(this)
      );

      // window events
      $(window).on('scroll', this.onScroll.bind(this));
      $(window).on('hashchange', this.jumpToStory.bind(this));

      // ui events
      ui.on(ui.GlobalEvents.EXPANDER_UPDATEVIEW, this.onExpanderToggle.bind(this));
      ui.on(ui.GlobalEvents.EXPANDER_SCROLLBACK, this.resetScrollCheck.bind(this));

      // element events
      // load item
      this.$el.on('history:ajax:item', '.ui-js-history__item', this.loadItem.bind(this));
      // show item after it is loaded
      this.$el.on('history:item:loaded', '.ui-js-history__item', this.showItem.bind(this));
      // scroll to anchor
      this.$el.on('history:scroll-to-anchor', this.scrollToAnchor.bind(this));
      // reset scroll check
      this.$el.on('history:scroll:check', this.resetScrollCheck.bind(this));
      // call scroll check items
      this.$el.on(
        'history:scroll:item:check',
        '.ui-js-history__item',
        this.itemInViewport.bind(this)
      );
    },

    /**
     * set active year with text
     */
    setActiveYear: function () {
      var activeYear = this.$years.filter(function (idx, elm) {
        return $(elm).attr('data-sticky') !== undefined && elm.getBoundingClientRect().top < 100;
      });

      var $activeYear = $(activeYear[activeYear.length - 1]);
      var newText = $activeYear.attr('data-sticky');
      var rangeId = $activeYear.attr('data-range-id');

      if (newText) {
        this.$stickyText.text(newText);
      }
      if (rangeId) {
        this.updateRangeText(rangeId);
      }
    },

    /**
     * fire event to items to check if they in view
     */
    checkItemsInView: function () {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      this.setActiveYear();
      this.toggleStickyFilter();
      if (st < this.lastScrollTop || st > this.lastScrollTop) {
        this.$items.trigger('history:scroll:item:check', {
          direction: st < this.lastScrollTop ? 'top' : st > this.lastScrollTop ? 'bottom' : false,
        });
      }
      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },

    /**
     * check if item is in view and trigger ajax load
     * @param e
     * @param data
     * @returns {boolean}
     */
    itemInViewport: function (e, data) {
      var viewportHeight = window.innerHeight || document.documentElement.clientHeight;
      var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
      var bounding = e.currentTarget.getBoundingClientRect();
      var $item = $(e.currentTarget);

      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= viewportWidth &&
        bounding.bottom <= viewportHeight * 1.5
      ) {
        if (data && data.direction) {
          if (data.direction === 'top') {
            var $prevItems = $item.prevUntil('[data-ajax]');
            var $prevAjaxItem = $($prevItems[$prevItems.length - 1]).prev();
            var boundingAjax = $prevAjaxItem.length
              ? $prevAjaxItem[0].getBoundingClientRect()
              : false;
            if (
              boundingAjax !== false &&
              boundingAjax.top >= -(viewportHeight * 3) &&
              boundingAjax.left >= 0 &&
              boundingAjax.right <= viewportWidth &&
              boundingAjax.bottom <= viewportHeight
            ) {
              if (!$prevAjaxItem.data('loaded') && !$prevAjaxItem.data('ajaxTrigger')) {
                var $prevAjaxItemIdx = this.$items.index($prevAjaxItem);
                var $prevAjaxLastAmountItem = $(
                  this.$items[
                    $prevAjaxItemIdx - this.defaults.amountOfLoadingItems[ui.Bootstrap.activeView]
                  ]
                );
                var $prevAmountAjaxItems = $prevAjaxItem.prevUntil($prevAjaxLastAmountItem);

                $prevAjaxItem.data('ajaxTrigger', true);
                $prevAmountAjaxItems.data('ajaxTrigger', true);
                $prevAjaxLastAmountItem.data('ajaxTrigger', true);
                $prevAjaxItem.trigger('history:ajax:item', {
                  direction: data.direction,
                });
                $prevAmountAjaxItems.trigger('history:ajax:item', {
                  direction: data.direction,
                });
                $prevAjaxLastAmountItem.trigger('history:ajax:item', {
                  direction: data.direction,
                });
              }
            }
          }

          if (data.direction === 'bottom') {
            var $nextItems = $item.nextUntil('[data-ajax]');
            var $nextAjaxItem = $($nextItems[$nextItems.length - 1]).next();
            var boundingAjaxNext = $nextAjaxItem.length
              ? $nextAjaxItem[0].getBoundingClientRect()
              : false;
            if (
              boundingAjaxNext !== false &&
              boundingAjaxNext.top >= 0 &&
              boundingAjaxNext.left >= 0 &&
              boundingAjaxNext.right <= viewportWidth &&
              boundingAjaxNext.bottom <= viewportHeight * 3
            ) {
              if (!$nextAjaxItem.data('loaded') && !$nextAjaxItem.data('ajaxTrigger')) {
                var $nextAjaxItemIdx = this.$items.index($nextAjaxItem);
                var $nextAjaxLastAmountItem = $(
                  this.$items[
                    $nextAjaxItemIdx + this.defaults.amountOfLoadingItems[ui.Bootstrap.activeView]
                  ]
                );
                var $nextAmountAjaxItems = $nextAjaxItem.nextUntil($nextAjaxLastAmountItem);

                $nextAjaxItem.data('ajaxTrigger', true);
                $nextAmountAjaxItems.data('ajaxTrigger', true);
                $nextAjaxLastAmountItem.data('ajaxTrigger', true);
                $nextAjaxItem.trigger('history:ajax:item', {
                  direction: data.direction,
                });
                $nextAmountAjaxItems.trigger('history:ajax:item', {
                  direction: data.direction,
                });
                $nextAjaxLastAmountItem.trigger('history:ajax:item', {
                  direction: data.direction,
                });
              }
            }
          }
        }
      }

      if (
        bounding.top >= -(viewportHeight * 1.5) &&
        bounding.left >= 0 &&
        bounding.right <= viewportWidth &&
        bounding.bottom <= viewportHeight * 1.5
      ) {
        if (!$item.data('loaded') && !$item.data('ajaxTrigger')) {
          $item.data('ajaxTrigger', true);
          $item.trigger('history:ajax:item', { direction: 'inview' });
        }
      }
    },

    /**
     * set anchor
     * @param isHash
     */
    setAnchor: function (isHash) {
      var hash = isHash ? this.anchor : window.location.hash;
      if (hash && $(document.getElementById(hash.slice(1))).length) {
        this.$anchorTaget = $(document.getElementById(hash.slice(1)));
      }
    },

    /**
     * load first items
     */
    loadFirstItems: function () {
      var firstItemsIdx = 0;
      var amountOfItems = this.defaults.amountOfStartItems[ui.Bootstrap.activeView];
      var scrollItemsIdx = false;
      var amountOfToScrollItems = this.defaults.amountOfToScrollItems;

      this.setAnchor();

      this.addItemsToQueue(firstItemsIdx, amountOfItems);

      if (this.$anchorTaget && this.$anchorTaget.length) {
        scrollItemsIdx = this.$items.index(this.$anchorTaget);
        this.addItemsAnchorToQueue(scrollItemsIdx, amountOfToScrollItems);
      }
    },

    /**
     * reset scroll bool
     */
    resetScrollCheck: function () {
      this.scrollCheck = true;
    },

    /**
     * scroll to story and trigger items to add to ajax queue
     * @param e
     */
    jumpToStory: function (e) {
      if (e) {
        e.preventDefault();
        this.setAnchor(false);
      } else {
        this.setAnchor(true);
      }
      this.scrollCheck = false;

      if (this.$anchorTaget && this.$anchorTaget.length) {
        this.setUrlHash(this.$anchorTaget);
        this.addItemsAnchorToQueue(this.$items.index(this.$anchorTaget), 12);
      }
    },

    /**
     * check if scroll bool is set and trigger scroll events
     */
    checkToScroll: function () {
      if (this.scrollToAnchorBool) {
        this.$el.trigger('history:scroll-to-anchor');
      } else {
        this.$el.trigger('history:scroll:check');
      }
    },

    /**
     * scroll to anchor item
     * @returns {boolean}
     */
    scrollToAnchor: function () {
      var self = this;
      var element = self.$anchorTaget;
      var stickyFilterHeight = self.$stickyText.outerHeight(true);
      self.scrollCheck = false;

      setTimeout(function () {
        $('html, body').animate(
          { scrollTop: $(element).offset().top - stickyFilterHeight },
          {
            duration: 1000,
            complete: function () {
              if (!self.scrollToAnchorBool) {
                return false;
              }
              self.anchor = false;
              self.$anchorTaget = false;
              self.scrollToAnchorBool = false;

              setTimeout(function () {
                self.setActiveYear();
                self.toggleStickyFilter();
                var st = window.pageYOffset || document.documentElement.scrollTop;
                self.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
                self.scrollCheck = true;
              }, 500);
            },
          }
        );
      }, 400);
      return false;
    },

    /**
     * set anchor bool and trigger items to add to ajax queue
     * @param idx
     * @param amount
     */
    addItemsAnchorToQueue: function (idx, amount) {
      this.scrollToAnchorBool = true;
      this.addItemsToQueue(idx - Math.round(amount / 2), amount);
    },

    /**
     * add items to ajax queue
     * @param idx
     * @param amount
     */
    addItemsToQueue: function (idx, amount) {
      var endSize = idx + amount;

      for (idx; idx < endSize; idx++) {
        if (idx > -1 && idx < this.$items.length) {
          if ($(this.$items[idx]).attr('data-ajax') !== undefined) {
            $(this.$items[idx]).trigger('history:ajax:item', {
              direction: 'inview',
            });
          }
        }
      }
      if (!this.itemsAjaxCounter) {
        this.checkToScroll();
      }
    },

    /**
     * set ajax data and trigger ajax call
     * @param e
     * @param data
     */
    loadItem: function (e, data) {
      var $item = $(e.currentTarget);
      this.itemsAjaxCounter++;
      $item.addClass('is-loading');
      $item.attr('data-ajax-load', true);
      this.ajaxCall($item, $item.data('ajax'), data.direction);
    },

    /**
     * ajax call
     * @param $item
     * @param url
     * @param direction
     */
    ajaxCall: function ($item, url, direction) {
      var self = this;

      $.ajax({
        async: true,
        url,
      })
        .done(function (response, e) {
          self.ajaxSucces($item, response, direction);
        })
        .fail(function () {
          console.log('request failed', url);
        });
    },

    /**
     * ajax success func
     * @param $item : object jquery
     * @param response : xhr response
     * @param direction : string
     */
    ajaxSucces: function ($item, response, direction) {
      var self = this;

      $item.html(response);

      if ($item.find('.ui-js-expander').length) {
        $item.one('init-expander', function (e, data) {
          if (data.idx === $item.index()) {
            self.itemLoaded($item, direction);
          }
        });
      } else {
        setTimeout(function () {
          self.itemLoaded($item, direction);
        }, 1);
      }

      ui.bootstrapper.reinitialize({
        context: $item.get(0),
      });
    },

    /**
     * set and trigger item is loaded
     * @param $item
     * @param direction
     */
    itemLoaded: function ($item, direction) {
      $item.data('loaded', true);
      $item.removeAttr('data-ajax');
      $item.trigger('history:item:loaded');
    },

    /**
     * show item and decrease ajax queue counter
     * @param e : object
     */
    showItem: function (e) {
      var $item = $(e.currentTarget);

      if (this.itemsAjaxCounter < 0) {
        this.itemsAjaxCounter = 0;
      }

      if (this.itemsAjaxCounter > 0) {
        this.itemsAjaxCounter--;
      }

      $item.removeClass('is-loading');
      $item.addClass('is-loaded');

      if (!this.itemsAjaxCounter) {
        this.checkToScroll();
      }
    },

    /**
     * on expand handler for expander
     * @param data
     */
    onExpanderToggle: function (data) {
      var $item = $(data.context).parents('.ui-js-history__item');
      if ($item.length) {
        var idx = $item.index();
        if (data.init) {
          $item.trigger('init-expander', {
            idx,
          });
        }
      }
    },

    /**
     * open handler filter chooser top
     */
    openFilterHandler: function () {
      this.$filterChoiceOpen.addClass(this.defaults.isFadeOutClass);
      this.$filterChoiceClose.removeClass(this.defaults.isFadeOutClass);
      this.$filterChoiceWrapper.addClass(this.defaults.isActiveClass);
    },

    /**
     * close handler sticky filter chooser
     */
    openStickyFilterHandler: function () {
      this.$filterStickyChoiceOpen.addClass(this.defaults.isFadeOutClass);
      this.$filterStickyChoiceClose.removeClass(this.defaults.isFadeOutClass);
      this.$filterStickyChoiceWrapper.addClass(this.defaults.isActiveClass);
    },

    /**
     * close handler filter chooser
     */
    closeFilterHandler: function () {
      this.$filterChoiceClose.addClass(this.defaults.isFadeOutClass);
      this.$filterChoiceOpen.removeClass(this.defaults.isFadeOutClass);
      this.$filterChoiceWrapper.removeClass(this.defaults.isActiveClass);
    },

    /**
     * close handler sticky filter chooser
     */
    closeStickyFilterHandler: function () {
      this.$filterStickyChoiceClose.addClass(this.defaults.isFadeOutClass);
      this.$filterStickyChoiceOpen.removeClass(this.defaults.isFadeOutClass);
      this.$filterStickyChoiceWrapper.removeClass(this.defaults.isActiveClass);
    },

    /**
     * click handler filter chooser top
     * @param e : object
     */
    clickFilterLinkHandler: function (e) {
      e.preventDefault();
      var self = this;
      this.$filterChoiceLink.removeClass(this.defaults.isActiveClass);
      var $currentChoiceLink = $(e.currentTarget);
      this.$filterChoiceText.text($currentChoiceLink.attr('data-choice-text'));
      var $currentItem = $currentChoiceLink.parent(this.defaults.filterChooserItemSel);
      $currentItem.addClass(this.defaults.isActiveClass);
      this.$filterStickyChoiceLink.removeClass(this.defaults.isActiveClass);
      $(this.$filterStickyChoiceLink[$currentItem.index()]).addClass(this.defaults.isActiveClass);
      this.anchor = $currentChoiceLink.attr('href');
      this.closeFilterHandler();
      setTimeout(function () {
        self.jumpToStory();
      }, 400);
    },

    /**
     * click handler sticky filter chooser
     * @param e : object
     */
    clickStickyFilterLinkHandler: function (e) {
      e.preventDefault();
      this.$filterStickyChoiceLink.removeClass(this.defaults.isActiveClass);
      var $currentChoiceLink = $(e.currentTarget);
      this.$filterChoiceText.text($currentChoiceLink.attr('data-choice-text'));
      var $currentItem = $currentChoiceLink.parent(this.defaults.filterChooserItemSel);
      $currentItem.addClass(this.defaults.isActiveClass);
      this.$filterChoiceLink.removeClass(this.defaults.isActiveClass);
      $(this.$filterChoiceLink[$currentItem.index()]).addClass(this.defaults.isActiveClass);
      this.anchor = $currentChoiceLink.attr('href');
      this.closeStickyFilterHandler();
      this.jumpToStory();
    },

    /**
     * update text for item by index
     * @param index
     */
    updateRangeText: function (index) {
      this.$filterChoiceLink.removeClass(this.defaults.isActiveClass);
      this.$filterStickyChoiceLink.removeClass(this.defaults.isActiveClass);
      $(this.$filterStickyChoiceLink[index]).addClass(this.defaults.isActiveClass);
      $(this.$filterChoiceLink[index]).addClass(this.defaults.isActiveClass);
      this.$filterChoiceText.text(
        $(this.$filterChoiceLink[index])
          .find('.ui-history__filter-chooser-link')
          .attr('data-choice-text')
      );
    },

    /**
     * set url hash for target item
     * @param $targetElement : object jquery
     */
    setUrlHash: function ($targetElement) {
      var locationNew = $targetElement
        ? '#' + $targetElement.attr('id')
        : location.href.replace(location.hash, '');

      if (history.pushState) {
        history.pushState(null, null, locationNew);
      } else {
        location.href = locationNew;
      }
    },

    /**
     * request animation frame and call callback function
     * @param callback : callback function
     */
    requestTick: function (callback) {
      if (!this.ticking) {
        requestAnimationFrame(callback);
      }
      this.ticking = true;
    },

    /**
     * on scroll handler pre-handler
     */
    onScroll: function () {
      this.requestTick(this.scrollHandler.bind(this));
    },

    /**
     * on scroll handler
     */
    scrollHandler: function () {
      this.ticking = false;
      if (!this.scrollCheck) {
        this.scrollReset = true;
        return;
      }
      if (this.scrollReset) {
        this.scrollReset = false;
        return;
      }
      this.checkItemsInView();
    },

    /**
     * toggle sticky year chooser
     */
    toggleStickyFilter: function () {
      var gridRect = this.$grid[0].getBoundingClientRect();
      if (gridRect.top <= 0) {
        this.stickyFilter.addClass(this.defaults.isStickyClass);
      } else {
        this.stickyFilter.removeClass(this.defaults.isStickyClass);
        this.closeStickyFilterHandler();
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'HistoryComponent',
    View: ui.HistoryComponentView,
    selector: '.ui-js-history',
    reinitialize: false,
  });

  $(ui.bootstrapHistoryComponent());
}).call(this);
