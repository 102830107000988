/**
 * @author Chuck Norris
 *
 */

(function () {
  'use strict';

  ui.AsyncButtonListenerHeadlineComponentView = ui.ComponentView.extend({
    name: 'ui.AsyncButtonListenerHeadlineComponentView',

    initialize: function () {
      this.$headline = this.$el.find(this.setup.headline);
      ui.on(ui.GlobalEvents.ASYNC_BTN_STATE, this.asyncBtnStateListener.bind(this));
    },

    asyncBtnStateListener: function (state) {
      if (this.$el.is(state.$el.closest('.ui-js-async-button-listener-headline'))) {
        if (state.isActive) {
          this.setActiveStyleHeadline();
        } else {
          this.setInactiveStyleHeadline();
        }
      }
    },

    setActiveStyleHeadline: function () {
      this.$el.addClass('is-active');
      this.$headline.text(this.setup.headlineActiveState);
    },

    setInactiveStyleHeadline: function () {
      this.$el.removeClass('is-active');
      this.$headline.text(this.setup.headlineInactiveState);
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'AsyncButtonListenerHeadlineComponent',
    View: ui.AsyncButtonListenerHeadlineComponentView,
    selector: '.ui-js-async-button-listener-headline',
    reinitialize: true,
  });

  $(ui.bootstrapAsyncButtonListenerHeadlineComponent());
}).call(this);
