/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Restful ajax item filter that uses (GET or custom parameters) and browser the history.
   * If combined with pagination, set pagination's popState to false.
   *
   * Data-Setup:
   * @param {String} $resultNodeId - id of node where response will be appended
   * @param {String} ajaxUrl - relative URL for ajax request
   * @param {Boolean} [popState] - if to install popstate-listener, true by default
   * @param {Boolean} [customSubmitMethod] - whether custom character should be used for params
   * @param {Boolean} [pageParam] - page param name, whether to reset page on filtering, i.e. 'p'
   */
  ui.ItemFilterComponentView = ui.ComponentView.extend({
    name: 'ui.ItemFilterComponentView',

    ajaxPageUrl: '',
    popState: false,

    popStateHandler: null,

    fadeInCallback: null,
    // Whether url-parameters are separated by custom character (~)
    customSubmMeth: false,
    // Whether pushState is supported
    state: false,
    // Object that holds the data for the ajax-request (urlparams)
    queryObj: {},
    // Pagination parameter name (optional)
    paginationParam: null,
    // optional parameter that is every time in query
    optionalParams: null,
    // jQuery result node where the ajax result should be inserted
    $resultNode: null,
    // Filter's reset button
    $resetBtn: null,

    events: {
      'click .ui-js-reset': 'resetQuery',
      'click .ui-js-reset-by-id': 'resetQueryById',
      'click .ui-js-reset-search': 'resetSearch',
      'submit .ui-js-searchbox': 'searchSubmit',
      'change .ui-js-input-activate-select': 'toggleDisableSelect',
      'change .ui-js-input-select': 'selectChanged',
      'change .ui-js-checkbox-all': 'checkDependedCheckboxes',
    },

    // Initializes all variables and parses urlparams if any, installs listeners
    initialize: function (options) {
      this.ajaxPageUrl = options.ajaxUrl;
      this.popState = options.popState;
      this.customSubmMeth = options.customSubmitMethod;
      this.historySupport = !!window.history.pushState || false;
      this.paginationParam = options.pageParam || null;
      this.optionalParams = options.optionalParams || null;
      this.$resultNode = options.resultNodeId && $('#' + options.resultNodeId);
      this.$resetBtn = this.$('.ui-js-reset');
      this.metaData = '';
      this.fadeInCallback = this.fadeInCB.bind(this);
      this.$searchField = this.$('.ui-js-searchinput');
      this.searchValue = '';
      this.$checkBoxAllInput = this.$el.find('.ui-js-checkbox-all');
      this.initMetaObject();
      // Defined in initialize because of view depth (backbone bug)
      this.childViews = [];

      // this.checkIfInputsActive();

      // Optional parameters that should be merged into the request, additional_parameters = {key1: value1, key2: value2}
      if (options.additional_parameters) {
        this.queryObj = $.extend(this.queryObj, options.additional_parameters || {}); // todo: why OR if you already checked???
      }

      if (!this.$resultNode) {
        console.warn('item-filter.js: No container for results found!');
      }

      this.model = ui.requests.getURLParamModel();

      // Install handler for reset button rendering
      for (var n = this.$resetBtn.length; n--; ) {
        var button = $(this.$resetBtn[n]);
        this.installResetBtnSwitch(button);
      }

      // Install popState listener to prevent page-reloads on history changes
      if (this.popState && this.historySupport) {
        this.popStateHandler = this.installPopState.bind(this);
        $(window).on('popstate', this.popStateHandler);
      }

      this.addAllFilterItems();
      this.checkIfAllCheckBoxesActive();
      this.setMetaContent(this.getLastModelAttributeMetaData());
    },

    render: function () {
      // Show reset buttons for each filter if at least one corresponding filter is set
      for (var n = this.$resetBtn.length; n--; ) {
        var $button = $(this.$resetBtn[n]);
        var resetParams = this.parseResetBtnParams($button);
        this.toggleResetBtn($button, this.model, resetParams);
      }
      return this;
    },

    resetSearch: function (event) {
      event.preventDefault();
      var self = this;
      var locationParameter = ui.requests.parseLocationParameters(location);
      var searchName = this.$searchField.attr('name');
      var searchValue = this.$searchField.val();
      var deletion = [];

      /*
       * Commented out because of ticket https://jira.aperto.de/browse/MGRSR-5668
       * */
      // if (searchValue === '') {
      //     return false;
      // }

      // if (this.$searchField.val() !== '') {
      setTimeout(function () {
        self.$searchField.closest('form')[0].reset();
      }, 150);

      if (self.queryObj[searchName]) {
        deletion.push(searchName);

        $.each(locationParameter, function (key) {
          if (self.optionalParams && self.optionalParams[key]) {
            deletion.push(key);
          }
        });

        self.$el.trigger('delete:after:search');

        self.requestPage(false, deletion, self.fadeInCallback);
      } else if (searchValue && searchName) {
        this.$searchField.val('');
        self.requestPage(false, [searchName], self.fadeInCallback);
      }
      // }
    },

    searchSubmit: function (event) {
      event.preventDefault();

      var self = this;
      var searchName = this.$searchField.attr('name');
      var searchValue = this.$searchField.val();
      var locationParameter = ui.requests.parseLocationParameters(location);
      var deletion = [];

      /*
       * Commented out because of ticket https://jira.aperto.de/browse/MGRSR-5668
       * */
      // if value is empty
      // if (searchValue === '') {
      //     return false;
      // }
      //

      // if value is already searched
      if (this.queryObj[searchName] === searchValue) {
        return false;
      }

      this.queryObj[searchName] = searchValue;

      $.each(locationParameter, function (key, value) {
        if (
          (!searchName || searchName !== key) &&
          (!self.optionalParams || typeof self.optionalParams[key]) === 'undefined'
        ) {
          deletion.push(key);
        }
      });

      this.$el.trigger('delete:after:search');

      this.requestPage(false, deletion, this.fadeInCallback);
    },

    checkIfAllCheckBoxesActive: function () {
      if (this.$checkBoxAllInput.length) {
        $.each(this.$checkBoxAllInput, function (idx, elm) {
          var resultFilter;
          var $allCheckboxes = $(elm)
            .closest('.form-group')
            .find('.ui-subcategory')
            .find('input[type=checkbox]:not(:disabled)');
          resultFilter = $allCheckboxes.filter(function (idx, elm) {
            return elm.checked;
          });
          elm.checked = resultFilter.length === $allCheckboxes.length;
        });
      }
    },

    checkDependedCheckboxes: function (e) {
      var deletion;
      if (e.target.checked) {
        deletion = this.toggleCheckboxes(e.target, true);
      } else {
        deletion = this.toggleCheckboxes(e.target, false);
      }
      this.requestPage(false, deletion, this.fadeInCallback);
    },

    setQueryObject: function (add, $elm) {
      if (add) {
        this.queryObj[$elm.attr('name')] = $elm.attr('value');
        return null;
      } else {
        return $elm.attr('name');
      }
    },

    toggleCheckboxes: function (elm, checked) {
      var self = this;
      var deletion = [];
      var $allCheckboxes = $(elm)
        .closest('.form-group')
        .find('.ui-subcategory')
        .find('input[type=checkbox]:not(:disabled)');
      $.each($allCheckboxes, function (idx, elm) {
        $(elm)[0].checked = checked;
        var resultSetQueryObject = self.setQueryObject(checked, $(elm));
        if (resultSetQueryObject !== null) {
          deletion.push(resultSetQueryObject);
        }
      });
      return deletion;
    },

    toggleDisableSelect: function (event) {
      var radio = event.target;
      var $select = this.getToggleSelect();
      var deletion = [];

      $select.prop('disabled', !radio.checked);
      if (radio.checked) {
        this.queryObj[radio.name] = radio.value;
        this.queryObj[$select.attr('name')] = $select.val();

        // Stage pagination parameter for deletion if available in data-setup
        if (this.paginationParam) {
          deletion.push(this.paginationParam);
        }
        this.$resultNode.addClass('loading');
        this.$resultNode.find('.fade-in').attr('aria-busy', true);
        this.requestPage(false, deletion, this.fadeInCallback);
      }
    },

    getToggleSelect: function () {
      var $selectActivatorElm = this.$el.find('.ui-js-input-activate-select');
      var $nearestGroup = $selectActivatorElm.closest('.ui-js-radio-group');
      var $selectInput = $nearestGroup.find('.ff-select select');
      return $selectInput;
    },

    selectChanged: function (event) {
      event.preventDefault();
      var name = $(event.currentTarget).attr('name');
      var isNameDefined = typeof name !== 'undefined';
      isNameDefined && (ui.activeElement = '[name=' + name + ']');
      this.queryObj[event.target.name] = event.target.value;
      this.requestPage(false, null, this.fadeInCallback);
    },

    initMetaObject: function () {
      var $title = $('title');
      var $description = $('[name="description"]');
      var $robots = $('[name="robots"]');

      this.meta = {
        robots: $robots,
        robotsContent: $robots.attr('content'),
        title: $title,
        titleText: $title.length ? $title.text() : '',
        ogTitle: $('[property="og:title"]'),
        description: $description,
        descriptionText: $description.length ? $description.attr('content') : '',
        ogDescription: $('[property="og:description"]'),
        canonical: $('[rel="canonical"]'),
        ogUrl: $('[property="og:url"]'),
      };
    },

    formatData: function (data) {
      if (data && typeof data === 'string') {
        data = JSON.parse(data.replace(/\\'/g, "'").replace(/'/g, '"'));
      }
      return data;
    },

    setMetaContentText: function (elm, data, property, elmAttribute, fallbackDataText) {
      if (elm.length) {
        if (data && data[property]) {
          if (!elmAttribute) {
            elm.text(data[property]);
          } else {
            elm.attr(elmAttribute, data[property]);
          }
        } else {
          if (!elmAttribute) {
            elm.text(fallbackDataText);
          } else {
            elm.attr(elmAttribute, fallbackDataText);
          }
        }
      }
    },

    getLastModelAttributeMetaData: function () {
      var lastAttribute = Object.keys(this.model.attributes)[
        Object.keys(this.model.attributes).length - 1
      ];
      var $lastElm = this.$el.find('[name="' + lastAttribute + '"]');
      if (!$lastElm.length) {
        $lastElm = this.$el.find(
          '[data-name="' +
            lastAttribute +
            '"][data-value="' +
            this.model.attributes[lastAttribute] +
            '"]'
        );
      }
      return this.formatData($lastElm.data('meta'));
    },

    setMetaContent: function (dataMeta) {
      var modelAttributesLength = Object.keys(this.model.attributes).length;
      if (modelAttributesLength > 1 || modelAttributesLength === 0) {
        dataMeta = '';
      }
      var currentLocation = location.origin + location.pathname;

      if (modelAttributesLength > 0) {
        $(this.meta.canonical).remove();
        this.setMetaContentText(
          this.meta.robots,
          { robotsContent: 'noindex' },
          'robotsContent',
          'content',
          this.meta.robotsContent
        );
      } else {
        if (this.meta.canonical.length === 0) {
          $('head').append('<link rel="canonical" href="./"/>');
          this.meta.canonical = $('[rel="canonical"]');
        }
        this.setMetaContentText(
          this.meta.canonical,
          dataMeta,
          'canonical',
          'href',
          currentLocation
        );
      }

      this.setMetaContentText(this.meta.title, dataMeta, 'title', false, this.meta.titleText);
      this.setMetaContentText(this.meta.ogTitle, dataMeta, 'title', 'content', this.meta.titleText);
      this.setMetaContentText(
        this.meta.description,
        dataMeta,
        'description',
        'content',
        this.meta.descriptionText
      );
      this.setMetaContentText(
        this.meta.ogDescription,
        dataMeta,
        'description',
        'content',
        this.meta.descriptionText
      );
      this.setMetaContentText(this.meta.ogUrl, dataMeta, 'ogUrl', 'content', currentLocation);
    },

    /**
     * @param {Object} $button - reset button as jQuery Object
     * @returns {Array} split reset parameter names cropped by pagination-parameter
     */
    parseResetBtnParams: function ($button) {
      var self = this;

      if (!$button.data('reset')) {
        return;
      }

      return $.grep($button.data('reset').split(' '), function (name) {
        // remove pagination parameter from reset params (if available)
        return name !== self.paginationParam;
      });
    },

    /**
     * Installs model listener on the button
     * @param {Object} $button - jQuery element
     */
    installResetBtnSwitch: function ($button) {
      var resetParams = this.parseResetBtnParams($button);
      var self = this;
      if (resetParams && resetParams.length > 0) {
        var toggleCallback = function (model) {
          self.toggleResetBtn($button, model, resetParams);
        };
        // Model listener checks for existence of reset-param in model
        this.model.on('change', toggleCallback);
      }
    },

    /**
     * Iterates reset parameters and hides/shows reset button according to its existence in the model.
     * In case of button has a data-reset-value attribute it checks equality of values
     * @param {Object} $button - reset button as jQuery Object
     * @param {Object} model - Backbone model with parameters
     * @param {Array} resetParams - reset parameter names
     */
    toggleResetBtn: function ($button, model, resetParams) {
      if (!this.customSubmMeth) {
        var show = false;

        if (resetParams && resetParams.length) {
          $.each(resetParams, function (i, param) {
            if (model.has(param)) {
              if ($button.data('reset-value') !== undefined) {
                if (model.attributes[param] === $button.data('reset-value')) {
                  show = true;
                  return false; // break loop when at least one param found
                }
              } else {
                show = true;
                return false; // break loop when at least one param found
              }
            }
          });
        }
        $button.toggleClass('is-collapsed', !show);
        $button.toggleClass('hidden', !show); // toggle also hidden classes
        $button.attr('aria-hidden', !show);
        $button.attr('tabindex', !show ? -1 : 0);
      }
    },

    /**
     * Creates a particular item view (depending on type) and installs its event listener
     * @param {String} type - input type
     * @param {Number} i - index
     * @param {Object} element - root node for item view
     */
    addOneFilter: function (type, i, element) {
      var ComponentView, view;

      switch (type) {
        case 'dropdown':
          ComponentView = ui.bootstrapDropdownComponent;
          break;
        case 'checkbox':
          ComponentView = ui.bootstrapCheckboxComponent;
          break;
        case 'radio':
          ComponentView = ui.bootstrapRadioComponent;
          break;
        case 'linklist':
          ComponentView = ui.bootstrapLinklistComponent;
          break;
        case 'daterange':
          ComponentView = ui.bootstrapDateRangeComponent;
          break;
        default:
          return;
      }

      view = ComponentView(element, this.$el, {
        model: this.model,
      }).call(this);

      var handler = this.handleEvent.bind(this);
      view.$el.on(view.setup.activeChangeEvent, handler);
      this.childViews.push(view);
    },

    addAllFilterItems: function () {
      var addFilter;

      // Add views for dropdowns groups
      addFilter = this.addOneFilter.bind(this, 'dropdown');
      this.$('.dropdown').each(addFilter);

      // Add view for each checkbox
      addFilter = this.addOneFilter.bind(this, 'checkbox');
      this.$('.ui-js-input[name][value][type="checkbox"]').each(addFilter);

      // Add views for Radio button groups
      addFilter = this.addOneFilter.bind(this, 'radio');
      this.$('.ui-js-radio-group').each(addFilter);

      // Add views for linklist groups
      addFilter = this.addOneFilter.bind(this, 'linklist');
      this.$('.linklist').each(addFilter);

      // Add views for linklist groups
      addFilter = this.addOneFilter.bind(this, 'daterange');
      this.$('.ui-js-daterange-filter').each(addFilter);
    },

    /**
     * Installs a popstate-listener on window, retrieves parameters either from state or location on popstate
     */
    installPopState: function (e) {
      var self = this;
      var callback;
      var state = window.history.state;
      // If history state is available and contains params object
      if (state && state.params) {
        self.queryObj = state.params;
        // Else if location has search params or custom params
      } else if (
        window.location &&
        (window.location.search || ui.requests.locationHasCustomParams(window.location))
      ) {
        self.queryObj = ui.requests.parseLocationParameters(window.location);
        // Else reset the query
      } else {
        self.queryObj = {};
      }

      // Define a scroll callback for after the request to return to the last scrollTop position
      callback = function () {
        if (state && state.scrollTop && !e.target.location.hash) {
          document.body.scrollTop = state.scrollTop;
        }
        self.fadeInCB.apply(self);
      };
      self.$resultNode.addClass('loading');
      self.$resultNode.find('.fade-in').attr('aria-busy', true);
      // Request page based on params from history without pushing the history
      self.requestPage(true, null, callback);
    },

    /**
     * Determines type of filter (dropdown, checkbox, radio or link) and adds data/value to temporary query.
     * If pagination parameter available, removes it and return to first result page after each filtering.
     * @param {Event} e - captured event from child view
     * @param {Obejct} obj - object that holds input information
     * @param {String} obj.name - input name (key)
     * @param {String} obj.value - input value
     * @param {String} obj.type - input type (dropdown, checkbox etc.)
     */
    handleEvent: function (e, obj) {
      var self = this;
      var name = obj.name;
      var value = obj.value;
      var type = obj.type;
      var deletion = [];
      var deleteBool = false;

      // Update value of clicked element's key in queryObj depending on input type
      switch (type) {
        case 'dropdown':
        // next case
        case 'linklist':
        // next case
        case 'radio':
          this.queryObj[name] = value;
          break;
        // Target is a checkbox that contains name and value attributes
        case 'checkbox':
          if (this.model.attributes[name] === undefined) {
            // The model lacks the key, add it to the query obj
            // delete the model parameter to consists it will be the last element in the queryObj
            delete this.queryObj[name];
            this.queryObj[name] = value;
          } else {
            // The model contains the key, stage the key for deletion
            deletion.push(name);
            deleteBool = true;
          }
          break;
        // Target is a daterange array that contains 2 name/value pairs
        case 'daterange':
          // Daterange submits either 2 Arrays with key/value pairs or a single String pair
          if (value instanceof Array && value.length > 1 && name.length > 1) {
            this.queryObj[name[0]] = value[0];
            this.queryObj[name[1]] = value[1];
          } else {
            this.queryObj[name] = value;
          }
          break;
        // Target lacks required attributes, do not process the request
        default:
          return;
      }

      var locationParaObj = ui.requests.parseLocationParameters(location);
      if (locationParaObj) {
        var paramsLength = Object.keys(locationParaObj).length;
        var queryLength = Object.keys(self.queryObj).length;
        var differenceParamsVsQuery = queryLength - paramsLength;
        // if difference between old parameters and new parameters more than 1, clean model
        if (differenceParamsVsQuery > 1 || deletion.length > 0) {
          // kicks out wrong model parameters
          Object.keys(self.queryObj).forEach(function (key) {
            if (!locationParaObj[key]) {
              // don´t remove if it no deletion and added as new parameter
              if (deleteBool && key !== name) {
                deletion.push(key);
              }
            }
          });
        }
      }

      // Stage pagination parameter for deletion if available in data-setup
      if (this.paginationParam) {
        deletion.push(this.paginationParam);
      }

      // delete Year Select
      var $select = this.getToggleSelect();
      if ($select.length) {
        if ($select.prop('disabled')) {
          deletion.push($select.attr('name'));
        }
      }

      this.$resultNode.addClass('loading');
      this.$resultNode.find('.fade-in').attr('aria-busy', true);
      this.requestPage(false, deletion, this.fadeInCallback);
    },

    /**
     * Triggers ajax request and calls render() after request was successfully executed.
     * @param {Boolean} isPopState - if true, new entry will be pushed to browser history
     * @param {Array} deletion - parameters that are staged for removal
     * @param {function} successCb - success callback that will be executed after the request
     */
    requestPage: function (isPopState, deletion, successCb) {
      var self = this;
      if (deletion && deletion.length) {
        // Delete keys staged for deletion from the temporary query
        $.each(deletion, function (index, value) {
          delete self.queryObj[value];
        });
      }

      if (this.optionalParams) {
        this.queryObj = $.extend(this.queryObj, this.optionalParams || {});
      }

      ui.requests.ajaxRequestPage({
        url: this.ajaxPageUrl,
        query: this.queryObj,
        $el: this.$resultNode,
        customSubmitMethod: this.customSubmMeth,
        isPopState,
        deleteParams: deletion,
        successCb,
        failCb: function () {
          console.log('request failed', self.ajaxPageUrl);
        },
      });
    },

    /**
     * Callback function to be executed after a successful Ajax load
     */
    fadeInCB: function () {
      var self = this;
      // Defer for animation purpose
      setTimeout(function () {
        self.$resultNode.removeClass('loading');
        self.$resultNode.find('.fade-in').attr('aria-busy', false);
        self.setMetaContent(self.getLastModelAttributeMetaData());
      }, 0);
    },

    /**
     * Prepares request with staged parameters for deletion
     * @param {Event} e - click event
     */
    resetQuery: function (e) {
      e.preventDefault();
      var resetParamString = $(e.target).data('reset');
      if (resetParamString) {
        this.$resultNode.addClass('loading');
        this.$resultNode.find('.fade-in').attr('aria-busy', true);
        this.requestPage(false, resetParamString.split(' ').concat('p'), this.fadeInCallback);
      }
    },

    /**
     * Prepares request with staged parameters for deletion, uses id of a filter for parsing inputs
     * @param {Event} e - click event
     */
    resetQueryById: function (e) {
      e.preventDefault();
      var resetParamString = $(e.target).data('reset');
      var $elements;
      var parts = [];

      if (resetParamString) {
        $elements = $(
          '#' +
            resetParamString +
            ' .ui-js-input[name][value], ' +
            '#' +
            resetParamString +
            ' .ui-js-input-select, ' +
            '#' +
            resetParamString +
            ' .ui-js-filter-btn.is-active, ' +
            '#' +
            resetParamString +
            ' .ui-js-filter-link.is-active, ' +
            '#' +
            resetParamString +
            ' .ui-js-page[data-name]:first'
        );
        $elements.each(function (index, element) {
          var name = element.name || $(element).data('name');
          if (parts.indexOf(name) < 0) {
            parts.push(name);
          }
        });

        if (parts.length) {
          this.requestPage(false, parts, null);
        }
      }
    },

    /**
     * Unbinds and removes all handlers from this view and all it's child views.
     * To be used if item-filter is reinitialized (i. e. inside an ajax loaded fragment)
     */
    closeView: function () {
      $(window).off('popstate', this.popStateHandler);

      this.childViews.forEach(function (view) {
        view.$el.off(view.setup.activeChangeEvent);
        view.unbind();
        view.remove();
      });

      this.unbind();
      this.remove();
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ItemFilterComponent',
    View: ui.ItemFilterComponentView,
    selector: '.ui-js-item-filter',
    reinitialize: true,
  });

  $(ui.bootstrapItemFilterComponent());
}).call(this);
