(function () {
  'use strict';

  /**
   * Headerbar
   * data-setup
   * @param {number} [delayUntilAutoActivate] time in ms to display buttons whether events
   * occur or not
   *
   */

  ui.Headerbar = ui.ComponentView.extend({
    name: 'ui.Headerbar',

    /**
     * @member {object} defaults is merged to this.setup
     * @member {number} defaults.delayUntilAutoActivate time to set the
     * collapse button to the visible state
     */
    defaults: {
      delayUntilAutoActivate: 3000 /* ms */,
    },

    isOpen: false,

    isLoggedIn: false,

    isSafetyInfoVisible: false,

    dataString: '',

    HIDDEN_CLASS: 'hidden',

    LOGGEDOUT_ID: 'headerbar-collapsable-logged-out',

    LOGGEDIN_ID: 'headerbar-collapsable-logged-in',

    TRANSITION_IN_CLASS: 'in',

    widget: null,

    initialize: function (options) {
      this.collapsableButton = this.$('.js-collapsable-btn');
      this.safetyTeaserInfo = this.$('.ui-js-safety-info');
      this.$safetyTeaserInfoBtn = this.$('.ui-js-safety-info-btn');
      this.otherButtons = this.$('.js-headerbar-btn');
      if (this.safetyTeaserInfo.length > 0) {
        /* eslint-disable no-unused-expressions */
        this.safetyTeaserInfo[0].clientWidth; // trigger ff to render it
        this.safetyTeaserInfo.addClass(this.HIDDEN_CLASS);
        /* eslint-enable no-unused-expressions */
      }

      // show safety teaser info btn if the safety-teaser-component found a recall message
      ui.on(ui.GlobalEvents.PERSONALIZED_HEADERBAR_SAFETY_INFO, this.showSafetyIcon.bind(this));
      // mark safety teaser info active or inactive depending on state
      ui.on(ui.GlobalEvents.SAFETY_TEASER_OPEN, this.activateSafetyTeaserInfo.bind(this));
      ui.on(ui.GlobalEvents.SAFETY_TEASER_CLOSE, this.deactivateSafetyTeaserInfo.bind(this));
      ui.on(ui.GlobalEvents.SAFETY_TEASER_HIDE, this.hideSafetyTeaserInfo.bind(this));

      // ui.on(ui.GlobalEvents.LOGIN_ERROR, this.onDisplayError.bind(this));
      // activate both buttons right away if delay is over
      setTimeout(this.activate.bind(this), this.setup.delayUntilAutoActivate);

      this.$safetyTeaserInfoBtn.on('click', this.safetyInfoClick.bind(this));
    },

    safetyInfoClick: function (e) {
      var href = $(e.currentTarget).attr('href');
      if (href && href === '#') {
        // Prevent default if href is not a hash
        e.preventDefault();
      }
      if (this.isSafetyInfoVisible) {
        ui.trigger(ui.GlobalEvents.SAFETY_TEASER_HIDE);
      } else {
        ui.trigger(ui.GlobalEvents.SAFETY_TEASER_OPEN);
      }
    },

    activate: function () {
      this.activateOtherBtns();
    },

    activateOtherBtns: function () {
      this.otherButtons.removeClass(this.HIDDEN_CLASS);
    },

    showSafetyIcon: function () {
      this.safetyTeaserInfo.removeClass(this.HIDDEN_CLASS);
    },

    activateSafetyTeaserInfo: function () {
      this.isSafetyInfoVisible = true;
      this.safetyTeaserInfo.addClass('is-open');
    },

    deactivateSafetyTeaserInfo: function () {
      this.isSafetyInfoVisible = false;
      this.safetyTeaserInfo.removeClass('is-indicator').removeClass('is-open');
    },

    hideSafetyTeaserInfo: function () {
      this.isSafetyInfoVisible = false;
      this.safetyTeaserInfo.removeClass('is-open');
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'HeaderbarComponent',
    View: ui.Headerbar,
    selector: '.ui-js-headerbar',
  });

  $(ui.bootstrapHeaderbarComponent());
}).call(this);
