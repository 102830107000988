(function () {
  /**
   * @author: Nils von Rymon-Lipinski
   * Modified by: Fabio Tedesco
   *
   * Manage the navigation interrupter and display it on first load and/or
   * when its content changes or a new one is inserted
   * Managed via updateId in data-setup
   *
   * Hide it on click wherever it happens except on the warenrückruf
   *
   */

  'use strict';

  ui.NavigationInterrupterComponentView = ui.ComponentView.extend({
    name: 'ui.NavigationInterrupterComponentView',
    desktop: /md|lg/,
    mobil: /xs|ms|sm/,
    tabletPortrait: /sm/,
    isDesktop: false,
    isMobil: false,
    isActiveClass: 'is-active',
    showNavigationInterrupter: true,

    events: {
      'click .close-button': 'closeNavigationInterrupter',
    },

    initialize: function (options) {
      this.$arrow = this.$el.find('.arrow');
      this.$targetElement = $(
        '[data-navigation-interrupter-id=' + options.navigationInterrupterId + ']'
      );
      if (this.$targetElement.length === 0) {
        return false;
      }
      this.updateId = options.updateId || null;

      this.bindEvents();
    },

    bindEvents: function () {
      ui.on(ui.GlobalEvents.LOGIN_AUTHENTICATED, this.isLoggedIn.bind(this));
      ui.on(ui.GlobalEvents.LOGIN_ANONYMOUS, this.isLoggedOut.bind(this));
    },

    bindOtherEvents: function () {
      this.handleVisibility();
      $(window).on('resize', this.handleVisibility.bind(this));
      ui.on('bootstrap.activemediaquery.changed', this.handleVisibility.bind(this));
      $(document).on('click.interrupter', this.closeNavigationInterrupter.bind(this));
    },
    isLoggedIn: function () {
      this.loggedIn = true;
      this.bindOtherEvents();
    },
    isLoggedOut: function () {
      this.loggedIn = false;
      this.bindOtherEvents();
    },

    handleVisibility: function () {
      const wasLoggedin = $.docCookies('navigation-interrupter-login') === 'true';

      if (wasLoggedin !== this.loggedIn) {
        $.docCookies('navigation-interrupter-login', '');
        $.docCookies('navigation-interrupter', '');
        $.docCookies('navigation-interrupter-domain', '');
        $.docCookies('navigation-interrupter-update', '');
      }
      this.checkViewport();
      this.toggleShowHide();
    },

    checkViewport: function () {
      this.isDesktop = this.desktop.test(ui.Bootstrap.activeView);
      this.isMobil = this.mobil.test(ui.Bootstrap.activeView);
      this.isTabletPortrait = this.tabletPortrait.test(ui.Bootstrap.activeView);
    },

    closeNavigationInterrupter: function (e) {
      var $target = $(e.target); // don't close if click happens on warenrückruf
      var isNotSafetyTeaser = !$target.closest('.ui-js-safety-teaser').length;
      if (isNotSafetyTeaser) {
        this.close();
        this.toggleClass(false);
        $(document).off('click.interrupter');
      }
    },

    close: function () {
      const options = {
        path: '/',
        secure: true,
        sameSite: 'Lax',
      };
      $.docCookies('navigation-interrupter-login', this.loggedIn ? 'true' : 'false', options);
      $.docCookies('navigation-interrupter', 'false', options);
      $.docCookies('navigation-interrupter-domain', 'false', {
        ...options,
        domain: '.migros.ch',
      });
      $.docCookies('navigation-interrupter-update', '' + this.updateId, options);
    },

    toggleClass: function (bool) {
      var _this = this;

      if (
        ($.docCookies('navigation-interrupter-update') === this.updateId &&
          ($.docCookies('navigation-interrupter') === 'false' ||
            $.docCookies('navigation-interrupter-domain') === 'false')) ||
        $.docCookies('navigation-interrupter-login') === ''
      ) {
        bool = false;
        this.$el.removeClass(this.isActiveClass);
      } else {
        setTimeout(function () {
          _this.$el.addClass(_this.isActiveClass);
        }, 1000);

        // remove active class when both el have the active class contemporary
        // happens f.e. on tablet switching from portrait to landscape and again on portrait
        // delayed after the previous setTimeout in order to properly check the active class
        // TODO improve toggle logic
        setTimeout(function () {
          if (
            _this.$el.hasClass(_this.isActiveClass) &&
            ((_this.el.dataset.viewport === 'desktop' && _this.isMobil) ||
              (_this.el.dataset.viewport === 'mobil' && _this.isDesktop))
          ) {
            _this.$el.removeClass(_this.isActiveClass);
          }
        }, 1001);
      }
    },

    setPositionMobil: function () {
      var left = this.$targetElement.offset().left;
      var width = this.$targetElement.width();
      var newPos = left + width / 2;
      var landscapeLeft = this.getLandscapeLeft(left, width);
      if (window.screen.width > 360) {
        var possibleLeft = window.screen.width - 360;
        if (possibleLeft > left) {
          possibleLeft = left;
        }
        if (possibleLeft < 20) {
          possibleLeft = 0;
        }
        if (possibleLeft > 20) {
          possibleLeft = possibleLeft - 20;
        }

        var isShorterAndOnLandscape = this.isShorterThanTargetEl(left, width) && this.isLandscape();
        var elLeft = isShorterAndOnLandscape ? landscapeLeft : possibleLeft;

        if (this.isTabletPortrait) {
          newPos = Math.round(newPos);
          var elWidth = this.$el.width();
          var halfEl = elWidth / 2;
          var isFirstItem = newPos < elWidth && !(newPos > halfEl);
          var notLastItem = !(newPos + halfEl > window.screen.width);

          if (isFirstItem) {
            elLeft = 0;
          } else if (notLastItem) {
            elLeft = newPos - halfEl;
          }
        }

        this.$el.css('left', elLeft + 'px');
        this.$arrow.css('left', newPos + 'px');
      } else {
        this.$el.css('left', 0);
        this.$arrow.css('left', newPos + 'px');
      }
    },

    getLandscapeLeft: function (left, width) {
      return left + width - (this.$el.width() + width) + width;
    },

    isShorterThanTargetEl: function (left, width) {
      return left + width > this.$el.width() + width;
    },

    isLandscape: function () {
      return window.innerWidth > window.innerHeight;
    },

    toggleShowHide: function () {
      if (this.el.dataset.viewport === 'mobil') {
        this.setPositionMobil();
        this.toggleClass(this.isMobil);
      }
      if (this.el.dataset.viewport === 'desktop') {
        this.$el.detach();
        this.$el.appendTo(this.$targetElement.closest('li'));
        this.toggleClass(this.isDesktop);
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'NavigationInterrupterComponent',
    View: ui.NavigationInterrupterComponentView,
    selector: '.ui-js-navigation-interrupter',
  });

  $(ui.bootstrapNavigationInterrupterComponent());
}).call(this);
