/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Represents a date range filter, contains methods for rendering and state storage.
   * To be combined with datepicker plugin.
   */
  ui.DateRangeComponentView = ui.ComponentView.extend({
    name: 'ui.DateRangeComponentView',

    nameFrom: undefined,
    nameUntil: undefined,

    activeState: undefined,
    markupState: undefined,
    filterType: 'daterange',

    $datepickerView: null,

    $rangeInputs: null,

    defaults: {
      activeChangeEvent: 'daterange:change',
    },

    events: {
      'click .ui-js-submit-daterange': 'onItemSubmit',
      'keydown .ui-js-input-daterange input': 'onItemSubmit',
    },

    initialize: function () {
      this.$rangeInputs = this.$('.ui-js-input-daterange input');
      this.nameFrom = this.$rangeInputs.eq(0).attr('name');
      this.nameUntil = this.$rangeInputs.eq(1).attr('name');
    },

    render: function () {
      return this;
    },

    /**
     * Reports change event to parent view, passes input parameters
     * @param {Event} e - click event
     */
    onItemSubmit: function (e) {
      if (e.type === 'click' || e.which === 13) {
        e.preventDefault();

        var isvalid = this.$rangeInputs.filter(function (index, elm) {
          return $(elm).closest('.ui-js-form-item').hasClass('invalid');
        });

        if (isvalid.length > 0) {
          return false;
        }

        if (!this.datepickerView) {
          var $datepickerNode = $(e.currentTarget).closest('.ui-js-datepicker');
          if ($datepickerNode.length) {
            // Retrieve view from node to access the view methods
            this.datepickerView = $datepickerNode.prop('View');
          }
        }
        if (this.datepickerView) {
          var params = this.serializeParameters();
          if (!$.isEmptyObject(params)) {
            // Check if both input fields are valid
            this.$el.trigger(this.setup.activeChangeEvent, {
              type: this.filterType,
              name: params.names,
              value: params.values,
            });
          }
        }
      }
    },

    /**
     * Serializes datepicker parameters into strings if dateRangeSubmitName is set,
     * otherwise returns arrays with key/value pairs
     * @returns {object}
     */
    serializeParameters: function () {
      var params = {};
      if (
        this.datepickerView.setup &&
        this.datepickerView.setup.dateRangeSubmitName &&
        this.datepickerView.getUTCRange()
      ) {
        var separator = this.datepickerView.setup.dateRangeSeparator || '_';
        var dateString = this.datepickerView.getUTCRange().join(separator);
        params.names = this.datepickerView.setup.dateRangeSubmitName;
        params.values = dateString;
      } else {
        params.names = [this.nameFrom, this.nameUntil];
        params.values = this.datepickerView.getUTCRange();
      }
      return params;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'DateRangeComponent',
    View: ui.DateRangeComponentView,
  });
}).call(this);
