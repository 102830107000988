/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Add-on for 'ui-preview-teaser' component that animates invisible teasers vertically.
   *
   * Data-setup:
   * @param {Number} [minVisibleXS] - defines max amount of visible items in viewport XS
   * @param {Number} {minVisibleMS] - defines max amount of visible items in viewport MS
   */
  ui.TeaserSliderCollapseComponentView = ui.ComponentView.extend({
    name: 'ui.TeaserSliderCollapseComponentView',

    TRANSITION_TIMEOUT: 400,

    minVisible: 0,
    allVisible: false,
    inactiveClass: '',
    inactiveXSClass: 'hidden-xs',
    inactiveMSClass: 'hidden-ms',
    transitionClass: 'is-collapsed',
    $slides: null,

    events: {
      'click .ui-js-show-all': 'showAllSlides',
    },

    // Max visible amount of slides while collapsed
    defaults: {
      minVisibleMS: 4,
      minVisibleXS: 4,
    },

    initialize: function () {
      this.allowMobil = this.setup && this.setup.type && this.setup.type === 'allow-mobil';
      if (this.allowMobil) return; // exit if mobil is allowed

      this.$slides = this.$('.js-slider-item:not(.clone)');

      // Render and on every mediaquery change (collapsed items might have changed)
      var render = this.render.bind(this);
      ui.on('bootstrap.activemediaquery.changed', render);
    },

    render: function () {
      if (this.allowMobil) return;
      var viewport = ui.Bootstrap ? ui.Bootstrap.activeView : '';
      var isXS = /xs/.test(viewport);
      var isMS = /ms/.test(viewport);

      if (isXS) {
        this.minVisible = this.setup.minVisibleXS;
        this.inactiveClass = this.inactiveXSClass;
      } else if (isMS) {
        this.minVisible = this.setup.minVisibleMS;
        this.inactiveClass = this.inactiveMSClass;
      }
      var $slidesAbove = this.$slides.filter(':gt(' + (this.minVisible - 1) + ')');

      if ($slidesAbove.length) {
        if (!this.allVisible) {
          // Toggle visibility class on slides above if allVisible is false
          this.$slides.removeClass(
            this.inactiveMSClass + ' ' + this.inactiveXSClass + ' ' + this.transitionClass
          );
          $slidesAbove.addClass(this.inactiveClass + ' ' + this.transitionClass);
        }
      } else {
        this.$slides.removeClass(this.transitionClass);
      }
      if (this.setup.type === 'menu-list') {
        this.$slides.removeClass(this.inactiveClass).removeClass(this.transitionClass);
      }
      if (this.setup.type === 'brand-list') {
        this.$slides.removeClass(this.inactiveClass).removeClass(this.transitionClass);
      }
      if (this.setup.type === 'allow-mobil') {
        this.$slides.removeClass(this.inactiveClass);
      }
    },

    /**
     * Either shows or hides slides above minVisible limit, applies transition classes.
     */
    showAllSlides: function (e) {
      e.preventDefault();
      // Shows all slides that are no clones (only in xs/sm)
      if (!this.allVisible) {
        this.$slides.removeClass(this.inactiveClass).height(); // force reflow for css animation
        this.$slides.removeClass('is-collapsed');
        this.allVisible = true;
        // Hide all slides down to minimum amount that should be visible
      } else {
        var self = this;
        var $sliderElements = this.$slides.filter(':gt(' + (this.minVisible - 1) + ')');
        $sliderElements.addClass('is-collapsed');

        setTimeout(function () {
          $sliderElements.addClass(self.inactiveClass);
        }, this.TRANSITION_TIMEOUT);

        this.allVisible = false;
      }

      // Toggle button label text (show/hide)
      if (e.target && e.target.dataset) {
        var label = this.allVisible ? 'closeText' : 'openText';
        $(e.target).text(e.target.dataset[label]);
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'TeaserSliderCollapseComponent',
    View: ui.TeaserSliderCollapseComponentView,
    selector: '.ui-js-preview-teaser',
  });

  $(ui.bootstrapTeaserSliderCollapseComponent());
}).call(this);
