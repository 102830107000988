/**
 * @author Nils von Rymon-Lipinski (Aperto GmbH - An IBM Company)
 *
 */

(function () {
  /**
   * Expand or Collapse Text with custom height
   */

  'use strict';

  ui.ExpanderComponentView = ui.ComponentView.extend({
    name: 'ui.ExpanderComponentView',

    defaults: {
      hiddenClass: 'is-hidden',
      expandableClass: 'is-expandable',
      expandableAgainClass: 'is-expandable-again',
      expandClass: 'is-expanded',
      lineHeight: 18,
      lines: 4,
      // arrays of height breaks
      expandBetween: {
        xs: false,
        ms: false,
        sm: false,
        md: false,
        lg: false,
      },
      parentSelectJumpSel: false,
      stickyHeaderSel: false,
      maxHeight: '500',
      viewports: 'md|lg',
      scopeSel: '.ui-js-expander-scope',
      btnMore: '.ui-js-expander-more',
      btnMoreBetween: '.ui-js-expander-more-between',
      btnLess: '.ui-js-expander-less',
      contentSel: '.ui-js-expander-content',
    },

    initialize: function (options) {
      this.lineHeight = options.lineHeight || this.defaults.lineHeight;
      this.lines = options.lines || this.defaults.lines;
      this.expandBetween = options.expandBetween || this.defaults.expandBetween;
      this.parentSelectJumpSel = options.parentSelectJumpSel || this.defaults.parentSelectJumpSel;
      this.$parentJump = this.parentSelectJumpSel
        ? this.$el.parents(this.parentSelectJumpSel)
        : false;
      this.stickyHeaderSel = options.stickyHeaderSel || this.defaults.stickyHeaderSel;
      this.$stickyHeader = this.stickyHeaderSel ? $(this.stickyHeaderSel) : false;
      this.contentHeight = this.lineHeight * this.lines;
      this.$btnMore = this.$el.find(this.defaults.btnMore);
      this.$btnMoreBetween = this.$el.find(this.defaults.btnMoreBetween);
      this.$btnLess = this.$el.find(this.defaults.btnLess);
      this.viewportRegEx = new RegExp(options.viewports || this.defaults.viewports);
      this.$modalElm = this.$el.parents('.ui-js-modal-gallery');
      this.$carouselElm = this.$el.parents('.ui-js-carousel');
      this.$carouselItemElm = this.$el.parents('.item');
      this.$contentExpander = this.$el.find(this.defaults.contentSel);
      this.$scopeElements = this.$el.find(this.defaults.scopeSel);
      this.$allContentElements = this.$contentExpander.find('> *');
      this.$btnMore.addClass(this.defaults.hiddenClass);
      this.$btnMoreBetween.addClass(this.defaults.hiddenClass);
      this.$btnLess.addClass(this.defaults.hiddenClass);
      this.expanded = false;
      this.betweenExpanded = false;
      this.check = true;
      this.sum = 0;
      this.init = true;

      this.bindEvents();
      this.checkContentHeight();
    },

    bindEvents: function () {
      ui.on('bootstrap.activemediaquery.changed', this.updateView.bind(this));
      this.$btnMore.on('click', this.setExpand.bind(this));
      this.$btnMoreBetween.on('click', this.setExpand.bind(this));
      this.$btnLess.on('click', this.setCollapse.bind(this));
      if (this.$modalElm.length) {
        this.$modalElm.on('shown.bs.modal', this.checkContentHeight.bind(this));
      }
      if (this.$carouselElm.length) {
        this.$carouselElm.on('slide.bs.carousel', this.checkContentHeight.bind(this));
      }
    },

    getContentHeight: function ($elements) {
      var sum = 0;
      $elements.each(function (idx, elm) {
        sum += elm.clientHeight;
      });
      return sum;
    },

    checkContentHeight: function (e) {
      if (!this.check) {
        return;
      }
      var self = this;
      setTimeout(function () {
        self.checkIfUpdateView();
      }, 100);
    },

    checkIfUpdateView: function () {
      var self = this;
      self.sum === 0 ? (self.sum = self.getContentHeight(self.$scopeElements)) : (self.sum = 0);
      if (self.sum >= self.contentHeight) {
        self.updateView();
        self.check = false;
      } else if (self.sum > 0 && self.sum < self.contentHeight) {
        self.check = false;
        ui.trigger(ui.GlobalEvents.EXPANDER_UPDATEVIEW, {
          context: self.$el,
          init: self.init,
        });
        self.init = false;
      } else {
        ui.trigger(ui.GlobalEvents.EXPANDER_UPDATEVIEW, {
          context: self.$el,
          init: self.init,
        });
        self.init = false;
      }
    },

    setExpand: function (e) {
      if (e) {
        e.preventDefault();
      }
      var self = this;
      var newHeight = parseInt(this.getContentHeight(this.$allContentElements)) + 4;
      this.expanded = true;
      this.$btnMore.addClass(this.defaults.hiddenClass);
      this.$btnMoreBetween.addClass(this.defaults.hiddenClass);
      this.$btnLess.removeClass(this.defaults.hiddenClass);
      this.$contentExpander.addClass(this.defaults.expandClass);

      if (
        this.expandBetween[ui.Bootstrap.activeView] &&
        newHeight > this.expandBetween[ui.Bootstrap.activeView] &&
        !this.betweenExpanded
      ) {
        newHeight = this.expandBetween[ui.Bootstrap.activeView];
        this.expanded = false;
        this.betweenExpanded = true;
        this.$btnMoreBetween.removeClass(this.defaults.hiddenClass);
        this.$contentExpander.removeClass(this.defaults.expandClass);
      }
      this.$contentExpander.css({
        height: newHeight + 'px',
      });
      ui.trigger(ui.GlobalEvents.EXPANDER_UPDATEVIEW, {
        context: self.$el,
        init: self.init,
      });
      this.init = false;
    },

    setCollapse: function (e) {
      if (e) {
        e.preventDefault();
      }
      var self = this;
      this.expanded = false;
      this.betweenExpanded = false;
      this.$btnMore.removeClass(this.defaults.hiddenClass);
      this.$btnMoreBetween.addClass(this.defaults.hiddenClass);
      this.$btnLess.addClass(this.defaults.hiddenClass);
      this.$contentExpander
        .css({
          height: this.contentHeight - this.defaults.lineHeight / 2 + 'px',
        })
        .removeClass(this.defaults.expandClass);
      ui.trigger(ui.GlobalEvents.EXPANDER_UPDATEVIEW, {
        context: self.$el,
        init: self.init,
      });
      this.init = false;

      if (e && this.$parentJump) {
        var stickyHeaderHeight = 0;
        if (this.$stickyHeader) {
          stickyHeaderHeight = this.$stickyHeader.outerHeight(true);
        }
        setTimeout(function () {
          $('html, body').animate(
            {
              scrollTop: self.$parentJump.offset().top - stickyHeaderHeight,
            },
            {
              duration: 400,
              complete: function () {
                ui.trigger(ui.GlobalEvents.EXPANDER_SCROLLBACK);
              },
            }
          );
        }, 200);
      }
    },

    removeExpander: function () {
      this.expanded = false;
      this.$btnMore.addClass(this.defaults.hiddenClass);
      this.$btnMoreBetween.addClass(this.defaults.hiddenClass);
      this.$btnLess.addClass(this.defaults.hiddenClass);
      this.$contentExpander
        .css({
          height: '',
        })
        .removeClass(this.defaults.expandClass);
    },

    checkViewport: function () {
      return this.viewportRegEx.test(ui.Bootstrap.activeView);
    },

    updateView: function () {
      if (this.checkViewport()) {
        this.$contentExpander.addClass(this.defaults.expandableClass);
        if (this.expanded) {
          this.setExpand();
        } else {
          this.setCollapse();
        }
      } else {
        this.$contentExpander.removeClass(this.defaults.expandableClass);
        this.removeExpander();
      }
    },

    render: function () {},
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ExpanderComponent',
    View: ui.ExpanderComponentView,
    selector: '.ui-js-expander',
    reinitialize: true,
  });

  $(ui.bootstrapExpanderComponent());
}).call(this);
