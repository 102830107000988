/**
 * @author: Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Controls safety-teaser features like Ajax-calls and cookie handling
   *
   * Data-setup:
   * @params {String} name - the cookie's key (name)
   * @params {String} prodId - product's unique id that will be used for the cookie
   * @params {Number} [daysValid] - cookie validity in days
   */
  ui.SafetyTeaserComponentView = ui.ComponentView.extend({
    name: 'ui.SafetyTeaserComponentView',

    defaults: {
      name: 'safetyTeaser',
      daysValid: 365,
    },

    events: {
      'click .close': 'dismiss',
      'click a': 'dismiss',
    },

    initialize: function () {
      // Install global listeners for showing and hiding
      var appear = this.appear.bind(this);
      var disappear = this.disappear.bind(this);
      ui.on(ui.GlobalEvents.SAFETY_TEASER_OPEN, appear);
      ui.on(ui.GlobalEvents.SAFETY_TEASER_CLOSE, disappear);
      ui.on(ui.GlobalEvents.SAFETY_TEASER_HIDE, disappear);
      // if this is in the page an initialized by fragmentloader, the html was succesfully requested
      this.htmlRequested();
    },

    render: function () {
      return this;
    },

    htmlRequested: function () {
      ui.trigger(ui.GlobalEvents.PERSONALIZED_HEADERBAR_SAFETY_INFO);
      // If a cookie doesn't contain the data-setup's prodId, show the teaser
      if (!this.findCookie(this.setup.name)) {
        ui.trigger(ui.GlobalEvents.SAFETY_TEASER_OPEN);

        // Now set the cookie
        this.setCookie(false);
      } else if (this.showIndicator(this.setup.name)) {
        $('.ui-js-safety-info').addClass('is-indicator');
      }
    },

    /**
     * @param {String} name - cookie name (key)
     * @returns {boolean} - true if cookie's value contains data-setup's prodId
     */
    findCookie: function (name) {
      if ($.docCookies) {
        var cookieValue = $.docCookies(name);
        if (cookieValue && cookieValue.indexOf(this.setup.prodId) > -1) {
          return true;
        }
      }
      return false;
    },

    /**
     * @param {String} name - cookie name (key)
     * @returns {boolean} - true if cookie's value contains data-setup's prodId
     */
    showIndicator: function (name) {
      if ($.docCookies) {
        var cookieValue = $.docCookies(name);
        if (cookieValue && cookieValue.indexOf('|true') === -1) {
          return true;
        }
      }
      return false;
    },

    /**
     * Set a cookie that holds the data-setup's name as key and prodId as value
     *
     * @param withFlag with seen flag
     */
    setCookie: function (withFlag) {
      var domain = window.location.hostname;
      var parts = domain.split('.');
      if (parts.length > 1) {
        domain = '.' + parts[parts.length - 2] + '.' + parts[parts.length - 1];
      }

      var options = {
        expires: this.defaults.daysValid,
        path: '/',
        domain,
        secure: true,
        sameSite: 'Lax',
      };

      if ($.docCookies) {
        if ($.docCookies(this.setup.name)) {
          // remove old cookie with default domain to prevent a second cookie
          $.docCookies(this.setup.name, '', { path: '/' });
        }
        $.docCookies(this.setup.name, this.setup.prodId + (withFlag ? '|true' : ''), options);
      }
    },

    dismiss: function () {
      ui.trigger(ui.GlobalEvents.SAFETY_TEASER_CLOSE);
      this.setCookie(true);
    },

    disappear: function () {
      this.$el.addClass('is-collapsed');
    },

    appear: function () {
      this.$el.removeClass('is-collapsed');
      this.$el.trigger('safety-teaser:open');
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'SafetyTeaserComponent',
    View: ui.SafetyTeaserComponentView,
    selector: '.ui-js-safety-teaser',
    reinitialize: true,
  });

  $(ui.bootstrapSafetyTeaserComponent());
}).call(this);
