(function () {
  'use strict';

  /**
   * Interactive associations map popover, hover effects and search form
   *
   * Data-setup
   */
  ui.AssociationSearchView = ui.ComponentView.extend({
    name: 'ui.AssociationSearchView',

    events: {
      'submit .js-association-map-search-form': 'onAssociationSearch',
      'click .button-search': 'onAssociationSearch',
      'click .button-reset': 'onAssociationReset',
      'keydown .js-association-search-input': 'onSearchFieldInput',
      'keyup .js-association-search-input': 'countInputLength',
    },

    initialize: function () {
      this.associationErrorMessage = this.el.querySelector('#er-ref-address');
      this.associationLocationWrapper = this.el.querySelector('.js-association-search-wrapper');
      this.associationLocationInput = this.associationLocationWrapper.querySelector(
        '.js-association-search-input'
      );
      this.inputLength = 0;
      this.associationsMapping = false;
      this.associationMap = this.$el.parents('.ui-js-association-map');
      this.$outputSearch = $(this.associationMap).find('.ui-js-output-search');
      this.$outputSearchPLZ = this.$outputSearch.find('.ui-js-output-plz');
      this.$outputSearchPLZBtn = this.$outputSearch.find('.ui-output-plz');
      this.$outputSearchState = this.$outputSearch.find('.ui-js-output-state');
      this.forceActive = false;

      if (this.associationMap.length === 0) {
        this.associationMap = false;
      } else {
        this.associationMap = this.associationMap.get(0).View;
      }
      this.initMapSearchAutocomplete();
      this.bindEvents();
    },

    bindEvents: function () {
      ui.on(ui.GlobalEvents.LOGIN_AUTHENTICATED, this.onIdentified.bind(this));
      this.$outputSearchPLZBtn.on('click', this.fadeInSearchContainer.bind(this));
    },

    onIdentified: function (evt) {
      console.log('association-search', 'onIdentified', 'evt', evt);
      if (
        evt.data &&
        evt.data.profile &&
        evt.data.profile.address &&
        evt.data.profile.address.postal_code
      ) {
        var _this = this;
        setTimeout(function () {
          $(_this.associationLocationInput).val(evt.data.profile.address.postal_code);
          _this.countInputLength();
          _this.onAssociationSearch(null, true);
        }, 500);
      }
    },

    fadeInSearchContainer: function () {
      this.$el.removeClass('is-fade-out');
      this.$outputSearch.addClass('is-hidden');
    },

    fadeOutSearchContainer: function () {
      this.$el.addClass('is-fade-out');
      this.$outputSearch.removeClass('is-hidden');
    },

    initMapSearchAutocomplete: function () {
      var setup = ui.getElementSetup({
        el: this.associationLocationWrapper,
      });
      var _this = this;

      if (setup.associationsMapping) {
        this.associationsMapping = setup.associationsMapping;
      }

      if (!this.associationsMapping && this.associationMap) {
        this.associationsMapping = this.associationMap.associationsMapping;
      }

      // transform api result for autocomplete
      setup.autocomplete.config.transformResult = function (response) {
        return {
          suggestions: $.map(response.items, function (dataItem) {
            var label = _this.decodeHtmlEntities(dataItem.label);
            return { value: label, data: dataItem };
          }),
        };
      };

      setup.autocomplete.config.onSelect = this.onAutocompleteSelect.bind(this);

      this.autoCompleteSetup = {
        serviceUrl: setup.autocomplete.config.serviceUrl,
      };

      // merge everything in config object for
      // autocomplete instantiation
      setup = ui.utils.extend({}, setup, {
        el: this.associationLocationWrapper,
      });

      // autocomplete component creation via js API instead of markup API
      // because of custom data transform method passing
      // move to the end of the callstack, because grunt uglify decides sort
      // order for packaging
      new ui.FormAutocompleteComponentView(setup);
    },

    decodeHtmlEntities: function (str) {
      return $('<textarea />').html(str).text();
    },

    onAutocompleteSelect: function (data) {
      // convert html entities coming from api
      this.associationLocationInput.value = this.decodeHtmlEntities(data.value);

      this.onAssociationSearch(null, false);
    },

    onAssociationReset: function (evt) {
      evt.preventDefault();
      var _this = this;
      _this.associationLocationInput.focus();
      setTimeout(function () {
        _this.associationLocationInput.value = '';
        _this.associationLocationInput.focus();
      }, 100);
    },

    onAssociationSearch: function (evt, forceActive) {
      this.forceActive = forceActive;
      var _this = this;

      if (evt) {
        evt.preventDefault();
      }

      var param = this.extractPostCodeParam(this.associationLocationInput.value);
      var associationUrl = this.assembleAssociationUrl(param);

      if (param) {
        $.ajax({
          url: associationUrl,
        }).then(
          _this.onAssociationSearchSuccess.bind(_this),
          _this.onAssociationSearchFail.bind(_this)
        );
      } else {
        this.displayAssociationSearchError();
      }
    },

    extractPostCodeParam: function (searchVal) {
      searchVal = searchVal.split(' ')[0];
      return encodeURIComponent($.trim(searchVal));
    },

    assembleAssociationUrl: function (param) {
      var url = this.autoCompleteSetup.serviceUrl;

      return url + param + '.json';
    },

    onAssociationSearchSuccess: function (data) {
      var associationShortHand = '';
      var associationAreaEl;
      var hasSingleAssociation = true;

      if (typeof data === 'string') {
        data = JSON.parse(data);
      }

      // check if response array has an unambiguous association/cooperative
      if (data.items && data.items.length && data.items.length > 0) {
        for (var i = 0, len = data.items.length; i < len; i++) {
          if (associationShortHand && associationShortHand !== data.items[i].cooperative) {
            hasSingleAssociation = false;
          }
          associationShortHand = data.items[i].cooperative;
        }
      } else {
        hasSingleAssociation = false;
      }

      // check if associationShort could be mapped
      if (this.associationsMapping[associationShortHand]) {
        associationAreaEl =
          this.associationsMapping[associationShortHand].associationEl.firstElementChild;
      }

      if (!hasSingleAssociation) {
        // timeout for preventing validation lib to hide the custom error
        this.displayAssociationSearchError();
      } else if (associationAreaEl) {
        if (this.associationMap !== false) {
          this.associationMap.showAssociationAreaWithPopOver(
            associationAreaEl,
            this.forceActive,
            true
          );
          this.forceActive = false;
        }
        if (data.items[0].zip && this.associationsMapping[associationShortHand]) {
          this.setZipAndCity(
            data.items[0].zip,
            this.associationsMapping[associationShortHand].popover
          );
        }
        this.hideAssociationSearchError();
      } else {
        this.displayAssociationSearchError();
      }
    },

    setZipAndCity: function (zip, popover) {
      var title = $($(popover).data('content'))[0].innerText;
      this.$outputSearchPLZ.text(zip);
      this.$outputSearchState.text(title);
      this.$outputSearchState[0].href = $(popover).data('areaUrl');
      this.fadeOutSearchContainer();
    },

    onAssociationSearchFail: function (jqXHR, textStatus, errorThrown) {
      console.error('Requesting association search API failed.', textStatus, errorThrown);

      this.displayAssociationSearchError();
    },

    /**
     * Handles ENTER event, prevents submitting the form if input length is < 3 chars
     * @param {Event} evt - key event
     */
    onSearchFieldInput: function (evt) {
      if (evt.keyCode && evt.keyCode !== 13) {
        this.hideAssociationSearchError();
      } else if (evt.keyCode && evt.keyCode === 13 && this.inputLength < 1) {
        evt.preventDefault();
        this.displayAssociationSearchError();
      }

      if (evt.keyCode && evt.keyCode === 13) {
        evt.preventDefault();
        this.onAssociationSearch();
      }
    },

    /**
     * Counts input string length (for deviant behaviour <3 characters)
     */
    countInputLength: function () {
      this.inputLength = $(this.associationLocationInput).val().length;
    },

    displayAssociationSearchError: function () {
      this.hasError = true;
      this.$(this.associationErrorMessage).removeClass('hidden');
      this.$(this.associationLocationInput).addClass('invalid');
    },

    hideAssociationSearchError: function () {
      this.hasError = false;
      this.$(this.associationErrorMessage).addClass('hidden');
      this.$(this.associationLocationInput).removeClass('invalid');
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'AssociationSearchComponent',
    View: ui.AssociationSearchView,
    selector: '.js-association-map-search-form',
    reinitialize: true,
  });

  /* globals ui: true */
  $(ui.bootstrapAssociationSearchComponent());
}).call(this);
