/**
 * @author Henri Podolski
 */
(function () {
  'use strict';

  ui.FlyoutComponentView = ui.ComponentView.extend({
    name: 'ui.FlyoutComponentView',
    locationLinkElSelClass: '.ui-js-location-link',
    locationLinkTitleSelClass: '.ui-flyout-locationlink__title',

    defaults: {},

    events: {
      'click .js-close': 'onCloseClick',
      'click .js-flyout-deeplink': 'onOpenSlide',
      'mouseenter .ui-flyout-link': 'onLinkMouseOver',
      'mouseleave .ui-flyout-link': 'onLinkMouseOut',
    },

    initialize: function () {
      this.lastOpenIndex = null;
      this.$flyoutItems = this.$('.js-flyout-item');
      this.$overlayBackground = this.$('.js-background');

      this.$locationLinkEl = this.$(this.locationLinkElSelClass);
      this.$locationLinkTitle = this.$(this.locationLinkTitleSelClass);

      this.prepare();
      this.bindEvents();
    },

    prepare: function () {
      this.hideOverlay();
      this.$el.removeAttr('hidden');

      // preload hover images
      this.$('[data-image-hover]').each(function () {
        var image = new Image();
        image.src = $(this).data('image-hover');
      });
    },

    bindEvents: function () {
      var that = this;
      this.open = this.open.bind(this);
      this.close = this.close.bind(this);
      ui.on(ui.GlobalEvents.GENERIC_HEADER_FLYOUT_OPEN, this.open);
      ui.on(ui.GlobalEvents.GENERIC_HEADER_FLYOUT_MENU_CLOSE, function () {
        that.close(false);
      });

      ui.on(ui.GlobalEvents.STORE_PREFERRED, this.setLocationLink.bind(this));
    },

    onLinkMouseOver: function (evt) {
      var $iconElement = this.$(evt.currentTarget).find('[data-image-hover]');

      if ($iconElement.length) {
        if (!$iconElement.get(0).imageHoverReplace) {
          $iconElement.data('image-hover', 'url(' + $iconElement.data('image-hover') + ')');
          $iconElement.attr('data-image-hover', $iconElement.data('image-hover'));
          $iconElement.data('image-before-hover', $iconElement.css('background-image'));
          $iconElement.attr('data-image-before-hover', $iconElement.data('image-before-hover'));

          $iconElement.get(0).imageHoverReplace = true;
        }

        $iconElement.css('background-image', $iconElement.data('image-hover'));
      }
    },

    onLinkMouseOut: function (evt) {
      var $iconElement = this.$(evt.currentTarget).find('[data-image-before-hover]');
      if ($iconElement.length) {
        $iconElement.css('background-image', $iconElement.data('image-before-hover'));
      }
    },

    flyoutFromIndex: function (index) {
      return this.$flyoutItems.filter('[data-flyout-uid="' + index + '"]');
    },

    onOpenSlide: function (evt) {
      var $eventElement = $(evt.currentTarget);

      evt.preventDefault();

      if ($eventElement.data('navigation-item') || !isNaN($eventElement.data('navigation-item'))) {
        this.open($eventElement.data('navigation-item'));
      }
    },

    open: function (index) {
      if (index === this.lastOpenIndex) {
        return;
      }

      if (this.lastOpenIndex !== null) {
        this.openAndClose(index);
      } else {
        this.showContent(index);
        this.showOverlay();
      }

      this.lastOpenIndex = index;
    },

    /**
     * close one thing and open another,
     * without hiding the whole flyout
     */
    openAndClose: function (index) {
      var $openElement = this.flyoutFromIndex(this.lastOpenIndex);
      $openElement.one(
        ui.consts.ON_TRANSITION_END,
        function (evt) {
          this.showContent(index);
        }.bind(this)
      );
      this.close(true);
      this.lastOpenIndex = null;
    },

    close: function (isOpenAnotherFlyout) {
      if (this.lastOpenIndex !== null) {
        this.hideContent(this.lastOpenIndex);
        this.lastOpenIndex = null;
      }

      if (!isOpenAnotherFlyout) {
        this.hideOverlay();
        ui.trigger(ui.GlobalEvents.GENERIC_HEADER_FLYOUT_CLOSE);
      }
    },

    hideOverlay: function () {
      this.$overlayBackground.removeClass('is-show');
      this.$el.removeClass('is-show');
    },

    showOverlay: function () {
      this.$overlayBackground.addClass('is-show');
      this.$el.addClass('is-show');
    },

    hideContent: function (index) {
      var $openElement;

      $openElement = this.flyoutFromIndex(index);
      $openElement.removeClass('is-show');
    },

    showContent: function (index) {
      var $openElement;

      $openElement = this.flyoutFromIndex(index);
      $openElement.addClass('is-show');
    },

    onCloseClick: function () {
      this.close();
    },

    setLocationLink: function (event) {
      if (
        !event ||
        !event.attributes ||
        !event.attributes.store ||
        !event.attributes.store.attributes
      ) {
        return;
      }

      this.setLocationLinkTarget(event);
      this.setLocationLinkTitle(event);
    },

    setLocationLinkTarget: function (event) {
      if (!this.$locationLinkTitle || !event.attributes.store.attributes.name) {
        return;
      }

      this.$locationLinkTitle.text(event.attributes.store.attributes.name);
    },

    setLocationLinkTitle: function (event) {
      if (!this.$locationLinkEl || !event.attributes.store.attributes.slug) {
        return;
      }

      var oldHref = this.$locationLinkEl.attr('href');
      var locationIdentifierString = '/' + event.attributes.store.attributes.slug;

      if (oldHref.indexOf(locationIdentifierString) > 0) {
        return;
      }

      this.$locationLinkEl.attr('href', oldHref + locationIdentifierString);
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FlyoutComponent',
    View: ui.FlyoutComponentView,
    selector: '.ui-js-flyout',
  });

  $(ui.bootstrapFlyoutComponent());
}).call(this);
