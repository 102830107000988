/**
 * @author Claudio Baumgartner
 *
 */

(function () {
  'use strict';

  ui.NavigationalHeaderComponentView = ui.ComponentView.extend({
    name: 'ui.NavigationalHeaderComponentView',

    events: {
      'click .js-open-search': 'toggleSearch',
      'click .js-close-search': 'toggleSearch',
      'click .js-burger': 'toggleMegaMenu',
      'focus .js-search-widget input': 'forceCloseMegaMenu',
    },

    initialize: function () {
      this.$burger = this.$el.find('.js-burger');

      this.$el.find('.ui-js-profile').on('click', () => this.forceCloseMegaMenu());
      ui.on(ui.GlobalEvents.MAIN_MENU_CLOSE, () => this.changeBurger());
    },

    toggleMegaMenu: function () {
      this.changeBurger();
      ui.trigger(ui.GlobalEvents.MAIN_MENU_TOGGLE_MEGA_MENU);
    },

    changeBurger: function () {
      this.$burger.toggleClass('is-open');
      this.$el.toggleClass('mega-menu-open');

      const isOpen = this.$burger.hasClass('is-open');
      this.$burger.attr('aria-expanded', isOpen);

      if (!isOpen) {
        this.$burger.focus();
      }
    },

    forceCloseMegaMenu: function () {
      this.$burger.removeClass('is-open');
      this.$burger.attr('aria-expanded', false);
      this.$el.removeClass('mega-menu-open');
      ui.trigger(ui.GlobalEvents.MAIN_MENU_FORCE_CLOSE);
    },

    toggleSearch: function () {
      const $searchButtons = this.$el.find('.js-open-search, .js-close-search');
      this.$el.toggleClass('search-active');

      if (this.$el.hasClass('search-active')) {
        this.$el.find('.js-search-widget input').focus();
        $searchButtons.attr('aria-expanded', true);
      } else {
        $searchButtons.attr('aria-expanded', false);
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'NavigationalHeaderComponent',
    View: ui.NavigationalHeaderComponentView,
    selector: '.ui-js-103-12-navigational-header',
    reinitialize: true,
  });

  $(ui.bootstrapNavigationalHeaderComponent());
}).call(this);
