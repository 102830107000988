/**
 * @author Vika Khardina
 *
 */

(function () {
  /**
   * Counter numbers with custom separator
   */

  'use strict';

  ui.CounterModuleComponentView = ui.ComponentView.extend({
    name: 'ui.CounterModuleComponentView',

    events: {
      'scrollhandler:inview-once': 'toggleInview',
      'scrollhandler:inview': 'toggleInview',
    },

    initialize: function (options) {
      this.$counter = this.$el.find('.ui-js-count');
      this.isAnimated = false;
      this.wasOut = true;
      this.requestID = undefined;
      this.startTime = undefined;
      this.endTime = undefined;
    },

    toggleInview: function (e, container) {
      if (container.inViewport && this.wasOut) {
        this.setCounter();
        this.wasOut = false;
      } else if (!container.inViewport && !this.wasOut) {
        this.wasOut = true;
      }
    },

    setCounter: function () {
      if (!this.isAnimated) {
        var self = this;
        this.isAnimated = true;
        this.$counter.each(function () {
          $(this)
            .prop('counter', 0)
            .animate(
              {
                counter: $(this).data('number'),
              },
              {
                duration: $(this).data('duration'),
                easing: 'swing',
                step: function (now) {
                  $(this).text(self.separateNumber(Math.ceil(now)));
                },
                complete: function () {
                  self.isAnimated = false;
                },
              }
            );
        });
      }
    },

    separateNumber: function (val) {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, '$1' + '’' + '$2');
      }

      return val;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'CounterModuleComponent',
    View: ui.CounterModuleComponentView,
    selector: '.ui-counter-module',
    reinitialize: true,
  });

  $(ui.bootstrapCounterModuleComponent());
}).call(this);
