(function () {
  $('.modal').on('shown.bs.modal', function (evt) {
    $(document).trigger('modal:show', {
      type: 'modal:show',
      currentTarget: evt.currentTarget,
    });
  });

  ui.on(ui.GlobalEvents.LOGOUT_HASH_NOTIFICATION, function () {
    $('.ui-js-logout-modal').modal('show');
  });

  document.addEventListener('DOMContentLoaded', function (event) {
    var forcedAnchor = $('.js-forced-anchor-jump');
    if (forcedAnchor.length) {
      setTimeout(function () {
        $('html, body').animate({ scrollTop: $(forcedAnchor.get(0)).offset().top - 10 }, 'slow');
      }, 500);
    }

    $(document).on('click', '.ui-js-link-redirect', function (e) {
      e.preventDefault();

      if ($(e.target).data('back')) {
        return history.back();
      }

      if (!e.target.href) return;

      window.location.href = e.target.href + window.location.hash;
    });
  });
}).call(this);

(function (window) {
  // Polyfill Math.sign
  Math.sign =
    Math.sign ||
    function (x) {
      x = +x; // convert to a number
      if (x === 0 || window.isNaN(x)) {
        return x;
      }
      return x > 0 ? 1 : -1;
    };

  // Polyfill Math.trunc for ie and other browser which didnt support Math.trunc
  Math.trunc =
    Math.trunc ||
    function (x) {
      return ~~x;
    };

  // Prevent IE9 going wild if no console window present

  if (typeof PRODUCTION === 'undefined') {
    PRODUCTION = false;
  }
})(window);
