/**
 * @author Lutz Möbius, Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Applies smooth scroll animation on body. Can be attached to single buttons or anchor groups.
   *
   * Data-setup
   * @params {String} [anchor] - selector
   * @params {Number} [speed] - scroll duration in ms
   * @params {Number} [offset] - top offset in px (will be subtracted from scroll target offset)
   * @params {Number} [delay] - time of delay after that the site is scroll to the element ( e.g. if some height animations r before)
   */
  ui.AnimatedScrollComponentView = ui.ComponentView.extend({
    name: 'ui.AnimatedScrollComponentView',

    events: {
      click: 'onScrollToAnchorClick',
    },

    defaults: {
      speed: 750,
    },

    initialize: function () {
      this.$scrollContainer = $('html,body');

      this.onHashSet(window.location.hash);
    },

    getTargetOffset: function ($anchor) {
      return $anchor.offset();
    },

    onHashSet: function (hash) {
      var self = this;
      var targetOffset = null;
      var $anchor = null;
      var delay = this.setup.delay || 0;

      if (hash && hash.match(/^#[a-z0-9-_]+$/)) {
        $anchor = $(hash);

        var delayTimer = setTimeout(function () {
          clearTimeout(delayTimer);
          if ($anchor.length) {
            targetOffset = self.getTargetOffset($anchor);
          }

          self.scrollToAnchor(targetOffset);
        }, delay);
      }
    },

    onScrollToAnchorClick: function (e) {
      var self = this;
      var anchor = this.setup.anchor;
      var targetOffset = null;
      var delay = this.setup.delay || 0;
      e.preventDefault();

      var delayTimer = setTimeout(function () {
        clearTimeout(delayTimer);

        if (e.target && e.target.nodeName !== 'A' && anchor) {
          targetOffset = self.getTargetOffset($(anchor).first());
        } else {
          var $linkTarget = $(e.target).closest('a');
          if ($linkTarget.length && $linkTarget.attr('href')) {
            targetOffset = self.getTargetOffset($($linkTarget.attr('href')));
          }
        }

        self.scrollToAnchor(targetOffset);
      }, delay);
    },

    scrollToAnchor: function (targetOffset) {
      var offset = this.setup.offset;

      if (targetOffset && targetOffset.top) {
        this.$scrollContainer.animate(
          {
            scrollTop: offset ? targetOffset.top - offset : targetOffset.top,
          },
          this.setup.speed
        );
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'AnimatedScrollComponent',
    View: ui.AnimatedScrollComponentView,
    selector: '.ui-js-animated-scroll',
    reinitialize: false,
  });

  $(ui.bootstrapAnimatedScrollComponent());
}).call(this);
