/**
 * @modified by Nils von Rymon-Lipinski
 * Liip/Smartive event bindings API mapping
 */

/**
 * Liip/Smartive login widget event bindings
 * @param {*} [context] Selector string or DOM element or undefined for using global
 */
const vendorEventBindings = {
  /**
   * trigger authenticated/prefill event and pass user obj
   * @param user
   * @param attemptAutoLogin
   */
  onAuthenticated: (user, attemptAutoLogin) => {
    if (localStorage.getItem('logLogin') === 'true') {
      console.log('user', user, JSON.stringify(user));
    }

    if (user) {
      vendorEventBindings.toggleAuthenticatedAnonymousVisibility(true);
      $('.ui-js-login-firstname').text(user.given_name); // mui-js-user-firstname
      $('.ui-js-login-lastname').text(user.family_name); // mui-js-user-lastname
    }

    // wait for prefill script is ready
    if (window.prefill === undefined) {
      ui.on(ui.GlobalEvents.LOGIN_PREFILL_INIT, function (data) {
        ui.trigger(ui.GlobalEvents.LOGIN_PREFILL, {
          data: user,
          name: ui.GlobalEvents.LOGIN_PREFILL,
          cid: data.cid,
          init: false,
        });
      });
    }

    // trigger login
    ui.trigger(ui.GlobalEvents.LOGIN_AUTHENTICATED, {
      data: user,
      autoLogin: attemptAutoLogin,
      name: ui.GlobalEvents.LOGIN_AUTHENTICATED,
    });

    // trigger prefill formular
    ui.trigger(ui.GlobalEvents.LOGIN_PREFILL, {
      data: user,
      name: ui.GlobalEvents.LOGIN_PREFILL,
      init: true,
    });
  },

  /**
   * trigger anonymous event
   * @param attemptAutoLogin {string}
   */
  onAnonymous: (attemptAutoLogin) => {
    vendorEventBindings.toggleAuthenticatedAnonymousVisibility(false);
    ui.trigger(ui.GlobalEvents.LOGIN_ANONYMOUS, {
      autoLogin: attemptAutoLogin,
      name: ui.GlobalEvents.LOGIN_ANONYMOUS,
    });
  },

  /**
   * toggle visibility of authenticated wrapper and anonymous wrapper
   * @param authenticated {boolean}
   */
  toggleAuthenticatedAnonymousVisibility: (authenticated) => {
    $('.ui-js-login-authenticated').css('display', authenticated ? 'block' : 'none'); // mui-js-user-authenticated
    $('.ui-js-login-anonymous').css('display', authenticated ? 'none' : 'block'); // mui-js-user-anonymous
  },

  /**
   * trigger error event
   */
  onError: () => {
    ui.trigger(ui.GlobalEvents.LOGIN_ERROR);
  },
};

/**
 * wait for msrc messenger instance and here to the authenticated event
 */
(async () => {
  let loginProcessed = false;

  await (
    await window.msrc.messenger.getInstance()
  ).subscribe('login:authenticate', async ({ isManualLogin, loggedIn, error }) => {
    if (loginProcessed) {
      return;
    } else {
      loginProcessed = true;
    }

    if (loggedIn) {
      await window.msrc.utilities.login.getUser().then((user) => {
        if (user) {
          vendorEventBindings.onAuthenticated(user, 1);
        }
      });
    } else if (error !== null) {
      vendorEventBindings.onError();
    } else {
      vendorEventBindings.onAnonymous(1);
    }
  });
})();
