/**
 * @author Sang-In Haetzel
 */

(function () {
  'use strict';

  /**
        @description
        Get image width and set imagewidth to $('.js-image-wrapper'). Image must not scale, only native dimensions is allowed.

     */

  ui.RibbonScaleComponentView = ui.ComponentView.extend({
    name: 'ui.RibbonScaleComponentView',

    $ribbonScaleElements: null,

    initialize: function () {
      this.$ribbonScaleElements = $('.bk-t374-12-small-ribbon');

      this.setImageWidthToElement();
    },

    setImageWidthToElement: function () {
      var $imageWrapper, $imageElement, $textBoxPrev, $contentElementLeft;
      var image;

      this.$ribbonScaleElements.each(function (index, item) {
        $imageWrapper = $(item).find('.js-image-wrapper').first();

        if ($imageWrapper.length > 0) {
          $imageElement = $imageWrapper.find('img').first();
          $textBoxPrev = $(item).find('.text-box.prev').first();
          $contentElementLeft = $(item).find('.content-element.left').first();

          if (
            $imageElement.length > 0 &&
            $textBoxPrev.length > 0 &&
            $contentElementLeft.length > 0
          ) {
            image = new Image();
            image.src = $imageElement.attr('src');

            // read native image dimensions and set to imageWrapper onLoad
            image.onload = function () {
              $imageWrapper.css({ width: this.width + 'px' });
              $textBoxPrev.css({
                'min-width': $contentElementLeft.width() - this.width + 'px',
              });
            };
          }
        }
      });
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'RibbonScaleComponent',
    View: ui.RibbonScaleComponentView,
    selector: 'body',
  });

  $(ui.bootstrapRibbonScaleComponent());
}).call(this);
