(function () {
  'use strict';

  /**
   * handles formItems which contain elements type="file"
   * Data-setup:
   * @params {Object} [validationRules] - see documentation in ui.FormItemComponentView
   * */
  ui.FormFieldUploadComponent = ui.ComponentView.extend({
    events: {
      change: 'render',
    },

    initialize: function () {
      this.$fileInput = this.$el;
      this.$item = this.$fileInput.closest('.ff-file');
      this.$isFileAddedWrapper = this.$fileInput.closest('.ui-js-file-added'); // see form-file-add.js
      this.noFileSelected = this.$el.attr('placeholder');
      this.$filenameText = this.$item.find('.filename-text');
      this.$uploadBtnWrapper = this.$item.find('.file-button-wrapper');
      this.$uploadReset = this.$item.find('.upload-reset');

      this.setPlaceholderText();
      // eventlistener upload reset button
      this.onClickUploadReset();

      if (this.$isFileAddedWrapper.length === 1) {
        this.$fileInput.click();
      }
    },

    /**
     * creates/changes filename-text element
     */
    setPlaceholderText: function () {
      if (this.noFileSelected !== '') {
        // placeholder text, if nothing is selected
        this.$filenameText.html(this.noFileSelected);
      }
    },

    /**
     * binds onClick-eventlistener for fileReset
     */
    onClickUploadReset: function () {
      var self = this;

      // initial hide
      if (this.$uploadReset) {
        this.$uploadReset.addClass('hidden');
        this.$uploadReset.on('click', function (event) {
          self.fileReset(event);
        });
      }
    },

    /**
     * resests form for file reset
     * @param {Event} e - click event
     */
    fileReset: function (event) {
      this.$fileInput.get(0).value = '';
      this.$fileInput.wrap('<form></form>');
      this.$fileInput.closest('form').get(0).reset();

      var self = this;
      setTimeout(function () {
        self.$fileInput.unwrap();
      }, 100);

      // set focus on inputfield caused of validation
      this.$fileInput.focus();
      this.render(event, true);
      this.$fileInput.trigger('change');
      this.$fileInput.trigger('file-reset'); // see form-file-add.js
    },

    /**
     * Renders text inside an element, ellipses earlier and shows end of text
     * like: 'this_is_a_long_text.jpg' could become 'this_is_a...text.jpg'
     * depending on the width of an element
     *
     * @param  {jQuery element]} $elm element the text should be rendered to
     * @param  {String} txt  the Text that should be rendered
     * @param  {Integer} len  optinal length of ending (digits)
     * @return {Boolean}     true if extension was added to $elm
     */
    $renderLongText: function ($elm, txt, len) {
      var elmWidth = $elm
        .css({
          // reset
          position: '',
          paddingRight: '',
          width: '',
        })
        .text(txt)
        .outerWidth();

      if ($elm[0].scrollWidth > elmWidth) {
        ($elm._$elmEnd = $elm._$elmEnd || $('<span>')) // cache
          .text(txt.substring(txt.length - (len || 8))) // let op!!!
          .css({
            position: 'absolute',
            right: 0,
            paddingRight: $elm.css('padding-right'),
          })
          .appendTo($elm);

        $elm
          .css({
            position: 'relative',
            paddingRight: $elm._$elmEnd.outerWidth(),
          })
          .outerWidth(elmWidth !== $elm.outerWidth() ? elmWidth : ''); // if no box-sizing: border-box
        return true;
      }

      return false;
    },

    render: function (e, fromReset) {
      // replace(/^.*[\\\/]/g, removes all of the path infront of the file name (c:\fakePath\data.jpg -> data.jpg)
      var value = (this.$fileInput.val() || '').replace(/^.*[\\/]/g, '');

      if (!e) {
        return;
      }

      if (this.$isFileAddedWrapper.length === 1) {
        if (value !== '') {
          this.$isFileAddedWrapper.removeClass('is-hidden');
          this.$isFileAddedWrapper.trigger('file-added', true);
        }
      }

      this.$renderLongText(this.$filenameText, value);

      if (this.$filenameText) {
        // make file name bolder than standard text
        this.$filenameText.toggleClass('show-fat', !!value);
        this.$uploadBtnWrapper.toggleClass('show-fat', !!value);
      }

      if (this.$uploadReset) {
        // reset button
        this.$uploadReset.toggleClass('hidden', !value);
      }
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FormFieldUploadComponent',
    View: ui.FormFieldUploadComponent,
    selector: '.ff-file [type="file"]',
  });

  $(ui.bootstrapFormFieldUploadComponent('.ff-file [type="file"]'));
}).call(this);
