/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Migros store locator implemented with Google-Maps-API 3 with custom map styles and dynamic markers.
   * To load asynchrounously the API needs a callback function attached to window, the callback
   * function name has to be attached as a parameter to the API src (JSONP):
   * ?callback=initializeGoogleMaps
   *
   * @see https://developers.google.com/maps/tutorials/customizing/styling-the-base-map
   * @see https://developers.google.com/maps/documentation/javascript/styling (Styling Rules)
   * @see https://developers.google.com/maps/documentation/business/clientside?hl=de#MapsJS
   *
   * Data-setup:
   * @param {number} lat - initial latitude
   * @param {number} long - initial longitude
   * @param {number} [zoom] - optional, initial zoom level (0 - 18)
   * @param {Boolean} [scrollwheel] - optional, zoom-in/out by mouse wheel enabled
   * @param {string} [iconpath] - optional, marker path and filetype (i.e. mmm.png)
   * @param {string} [iconBase] - optional, relative path to marker icons, must be set if type is used
   * @param {string} [title] - optional, html title attribute for marker
   * @param {string} [key] - optional, Google Maps API 3 Key
   * @param {number} [panOffset.x] - optional, Offset in pixel for the map to pan after showing a marker
   * @param {number} [panOffset.y] - optional, Offset in pixel for the map to pan after showing a marker
   */
  ui.googleMapComponentView = ui.ComponentView.extend({
    name: 'ui.googleMapComponentView',

    events: {
      // event-listener for setting a new marker on the map
      setMarkerEvent: 'clearMapSetMarker',
      'click .ui-js-find-route': 'openGoogleNavigation',
    },

    controls: null,
    map: null,
    marker: null,
    // Cached navigator coordinates
    currentPosition: null,
    // Geographical center of CH
    CENTER_LAT_DEFAULT: 46.8,
    CENTER_LONG_DEFAULT: 8.22,
    ZOOM_DEFAULT: 8,
    // Offset in pixel for the map to pan after showing a marker
    PX_PAN_OFFSET: {
      x: -150,
      y: -75,
    },

    mapSetup: {},

    // Source of stable Google Maps API3 with JSONP callback
    googleMapsAPI3Src: 'maps.googleapis.com/maps/api/js?callback=initializeGoogleMaps',
    // key and stable API version used here, can be overwritten by data-setup
    defaultGoogleMapsAPIVersion: '3',
    // Hostnames of which the key should not be transmitted to the API
    hostnameWhitelist: ['localhost'],
    navigationURL: 'maps.google.com/',
    protocol: document.location.protocol === 'https:' ? 'https://' : 'http://',

    // Fixed map-control options
    mapOptions: {
      streetViewControl: false,
      zoomControl: true,
      panControl: false,
      mapTypeControl: false,
    },

    iconPath: undefined,

    // Custom Migros Google Maps Styles
    styles: [
      {
        featureType: 'landscape',
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 60,
          },
        ],
      },
      {
        featureType: 'road.local',
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 40,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit',
        stylers: [
          {
            saturation: -100,
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'administrative.province',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        stylers: [
          {
            visibility: 'on',
          },
          {
            lightness: 30,
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#ef8c25',
          },
          {
            lightness: 40,
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#b6c54c',
          },
          {
            lightness: 40,
          },
          {
            saturation: -40,
          },
        ],
      },
      {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {},
    ],

    initialize: function () {
      // Load options from data-attributes, use defaults if none provided
      if (!this.setup) {
        return;
      }
      // Setup map with variable options from setup, if none provided assign default values
      this.mapSetup = {
        lat: this.setup.lat || this.CENTER_LAT_DEFAULT,
        long: this.setup.long || this.CENTER_LONG_DEFAULT,
        zoom: this.setup.zoom || this.ZOOM_DEFAULT,
        scrollwheel: this.setup.scrollwheel || false,
        iconBase: this.setup.iconBase ? this.setup.iconBase + '/' : false,
        iconPath: this.setup.iconpath || '',
        title: this.setup.title || undefined,
        panOffset: this.setup.panOffset ? this.setup.panOffset : this.PX_PAN_OFFSET,
      };

      this.loadGoogleMapsApi();
    },

    render: function () {
      return this;
    },

    /**
     * Creates new customized Marker (with title and icon) and centers the map at lat/long
     * @param {Number} lat
     * @param {Number} long
     * @param {String} title - html title attribute for marker
     * @param {String} iconPath - relative path to marker icon
     * @param {Object} panOffset - contains x and y coordinate
     */
    setMarker: function (lat, long, title, iconPath, panOffset) {
      var latLngMarker = new google.maps.LatLng(lat, long);
      var markerIcon = {
        url: this.mapSetup.iconBase && iconPath ? this.mapSetup.iconBase + iconPath : undefined,
        scaledSize: new google.maps.Size(75, 75),
      };
      var isDesktop = /(sm|md|lg)/.test(ui.Bootstrap.activeView);

      this.marker = new google.maps.Marker({
        position: latLngMarker,
        animation: google.maps.Animation.DROP,
        map: this.map,
        optimized: false,
        icon: markerIcon,
        title,
      });

      this.map.setCenter(latLngMarker);

      if (isDesktop && panOffset) {
        // Offset map position in some viewports to show the marker in the right bottom quadrant
        this.map.panBy(panOffset.x, panOffset.y);
      }
    },

    // Listener-function for setting new markers on the map
    clearMapSetMarker: function (e, lat, long, title, iconPath) {
      // Reset all markers
      this.marker.setMap(null);
      this.setMarker(lat, long, title, iconPath);
    },

    /**
     * Is executed asynchronously after the Google Maps API has been fully loaded.
     * Initializes and renders the map, replaces placeholder div.
     */
    renderMap: function () {
      var mapEl = this.$('.js-map-canvas').get(0);
      var options = $.extend({}, this.mapOptions, this.mapSetup, {
        // Add options that are dependent on
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
      });
      this.map = new google.maps.Map(mapEl, options);
      this.map.set('styles', this.styles);
      this.setMarker(
        this.mapSetup.lat,
        this.mapSetup.long,
        this.mapSetup.title,
        this.mapSetup.iconPath,
        this.mapSetup.panOffset
      );
    },

    /**
     * Retrieve client's geolocation if allowed, redirects to maps.google with serialized parameters
     */
    openGoogleNavigation: function (e) {
      var mode = $(e.currentTarget).data('directionsmode');
      var self = this;

      e.preventDefault();

      if (!this.currentPosition && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            // Geolocation supported and allowed, open map with destination and source
            self.currentPosition = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            window.open(self.createNavigationUrl(mode), '_blank');
          },
          function () {
            // Geolocation not allowed by user, redirect to map with destination coordinates
            window.open(self.createNavigationUrl(), '_blank');
          }
        );
      } else {
        // Geolocation not supported or already known from previous request
        if (this.currentPosition) {
          window.open(this.createNavigationUrl(mode), '_blank');
        } else {
          window.open(this.createNavigationUrl(), '_blank');
        }
      }
    },

    /**
     * Creates a url-string for google-navigation that contains source, destination and mode
     * @param {String} [mode] - direction mode, i. e. 'driving', 'transit' etc.
     * @returns {string} valid google maps url
     */
    createNavigationUrl: function (mode) {
      var url =
        this.protocol + this.navigationURL + '?daddr=' + this.marker.getPosition().toUrlValue();

      if (this.currentPosition) {
        url += '&saddr=' + this.currentPosition.toUrlValue();
      }
      if (mode) {
        url += '&dirflg=' + mode;
      }
      return url;
    },

    /**
     * Loads Google Maps API asynchronously, callback-function (JSONP)
     * needs to be appended on window to be called by maps.googleapis.com
     * @see https://developers.google.com/maps/documentation/javascript/tutorial#Loading_the_Maps_API
     */
    loadGoogleMapsApi: function () {
      var self = this;
      var script = document.createElement('script');

      if (!window.initializeGoogleMaps) {
        window.initializeGoogleMaps = function () {
          self.renderMap();
        };
      }
      script.type = 'text/javascript';

      script.src = this.protocol + this.googleMapsAPI3Src;
      script.src += '&v=' + this.defaultGoogleMapsAPIVersion;
      script.src += this.setup.language ? '&language=' + this.setup.language : '';

      // Append key to API source if hostname is not on whitelist
      if (this.setup.key && this.hostnameWhitelist.indexOf(window.location.hostname) < 0) {
        script.src += '&key=' + this.setup.key;
      }

      document.body.appendChild(script);
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'GoogleMapComponent',
    View: ui.googleMapComponentView,
    selector: '.ui-js-google-map',
  });

  $(ui.bootstrapGoogleMapComponent('.ui-js-google-map'));

  /**
   * Expose function for setting new markers, can be called by window.ui.bootstrapGoogleMapComponent.setMarker(47, 8, "TITLE", "m.png")
   * @param lat - latitude for new marker
   * @param long - longitude for new marker
   * @param [title] - title of new marker, can be undefined
   * @param iconPath - marker path and filetype (i.e. mmm.png)
   */
  ui.bootstrapGoogleMapComponent.setMarker = function (lat, long, title, iconPath) {
    $('.ui-js-google-map').trigger('setMarkerEvent', [lat, long, title, iconPath]);
  };
}).call(this);
