(function($) {
	'use strict';
	/**
	 * Ucbs module implementation.
	 *
	 * @author ernscht <ernscht@gmail.com>
	 * @namespace T.Module
	 * @class Ucbs
	 * @extends T.Module
	 */
	T.Module.Ucbs = T.createModule({

		start: function(resolve) {
			var mod = this,
				$ctx = $(mod._ctx);

			//console.log('START Ucbs');

			// bind
			mod._events.on('t.sync', mod._sync.bind(mod));

			// start
			if (mod.service.jqxhr && mod.service.jqxhr.readyState !== 4) {
				mod.service.jqxhr.abort(); // abort pending ajax requests
			}
			mod.service.jqxhr = $.ajax({
				url: mod.service.uri,
				type: 'get',
				timeout: mod.service.timeout,
				contentType: 'application/json',
				//dataType: 'json',
				async: true,
				cache: false
			})
				.done(function(data) {
					if (data) {
						mod.ucbs = $.isArray(data) ? data : [data];
						if (mod.ucbs.length) {
							mod._renderAll();
						}
						else {
							var info = {
								type: 'info',
								tpl: mod.template.info,
								msg: $ctx.data('msg-info-zero-ucbs') || '',
								wrap: true
							};
							mod._renderInfo(info);
						}
					}
				})
				.fail(function(jqXHR, textStatus) {
					if ('abort' !== textStatus) {
						var info = {
							type: 'error',
							tpl: mod.template.info,
							msg: $ctx.data('msg-error') || 'Error',
							wrap: true
						};
						mod._renderInfo(info);
					}
				})
				.always(function() {
					resolve();
				});
		},

		_sync: function() {
			// implement in skin if necessary
		},

		_init: function() {
			var mod = this,
				$ctx = $(mod._ctx);

			mod.CONST = {
				// Global
				TYPE: {
					DETAIL: 'detail',
					OVERVIEW: 'overview',
					PREVIEW: 'preview'
				},
				SIZE: {
					BIG: 'BIG',
					SMALL: 'SMALL'
				},
				// Ucb
				STATE: {
					ACTIVE: 'ACTIVE',
					INACTIVE: 'INACTIVE',
					DISABLED: 'DISABLED', // Multi/Bonus-Ucbs that are inactive but disabled (not activatable)
					REDEEMED: 'REDEEMED'
				},
				ACTION: {
					ACTIVATE: 'activate',
					DEACTIVATE: 'deactivate',
					NONE: 'none'
				},
				SORT: {
					HIGHEST: 'highest',
					LOWEST: 'lowest',
					NEWEST: 'newest',
					OLDEST: 'oldest',
					DEFAULT: 'highest'
				},
				STORAGENAME: 'ucb.state.overview'
			};

			mod.service = {
				jqxhr: null,
				uri: $ctx.data('service-uri'),
				activateUri: $ctx.data('service-activate-uri'),
				deactivateUri: $ctx.data('service-deactivate-uri'),
				timeout: $ctx.data('service-timeout') || 10000
			};

			mod.template = {
				id: $ctx.data('tpl'),
				container: $ctx.data('tpl-container'),
				controls: $ctx.data('tpl-controls'),
				info: $ctx.data('tpl-info')
			};

			mod.state = { // filled & used in decorator overview
				// tabId: null
				// tab1: {channel:'',sort:''}
				// ...
			};

			mod.backgroundColor = $ctx.data('bg-color') || '#eeeae8';

			mod.ucbs = null;          // save all ucbs
			mod.type = '';               // contains one of mod.CONST.TYPE

			mod.$containers = null;      // ucbs container
			mod.$controls = null;        // controls container (only overview)
		},

		_bindStateActionEvents: function() {
			var mod = this,
				$ctx = $(mod._ctx);

			$ctx.on('click', '.js-ucbs-action', function(e) {
				e.preventDefault();
				var $this = $(this),
					$ucb = $this.closest('.m-ucb'),
					$tab = $ctx.find('[data-tab-ref="' + mod.state.tabId + '"]'), // on detailpage length is null
					ucbItemId = '' + $ucb.data('item-id'),
					model = mod._getModelByItemId(ucbItemId),

					ucbId = '' + $ucb.data('id'),
					ucbGroup = '' + $ucb.data('group'),
					$fly = $ucb.find('.js-ucbs__flyout'),
					doActivate = $this.hasClass('m-ucb__button--activate') ? true : false,
					serviceUri = doActivate ? mod.service.activateUri : mod.service.deactivateUri,
					ucbAction = (doActivate) ? 'aktivierung' : 'deaktivierung',
					jqxhr, i, length;

				mod._track({
					event: 'ucbInteraction',
					ucbAction: ucbAction,
					ecommerce: {
						click: {
							actionField: {
								list: $tab.length ? $tab.data('tracking-key') : 'detail-ansicht'
							},
							products: [
								{
									name: model.titleText,
									id: '',
									position: ($ucb.siblings(':visible').addBack().index($ucb) + 1) + '',
									price: '',
									brand: 'cumulus bon',
									category: model.amount + '.- Fr.',
									variant: 'gueltig_' + model.expirationDate
								}
							]
						}
					}
				});

				serviceUri += ucbId;
				$fly.addClass('state-ucb-flyout--load');


				/* Change state of all other ucbs on click and revert on error.
				 * This prevents the ability to activate more than one bonus/multi-ucbs,
				 * as described in DIRACW-119
				 */
				var ucbs = mod.ucbs,
					len = ucbs.length,
					timeouts = [];

				for (i = 0, length = ucbs.length; i < length; i++) {
					if (ucbItemId === '' + ucbs[i].itemId) {
						ucbs[i].state = doActivate ? mod.CONST.STATE.ACTIVE : mod.CONST.STATE.INACTIVE;
						ucbs[i].action = doActivate ? mod.CONST.ACTION.DEACTIVATE : mod.CONST.ACTION.ACTIVATE;
					}
					else if (ucbs[i].group && ucbGroup === '' + ucbs[i].group && ucbs[i].state === mod.CONST.STATE.INACTIVE) {
						ucbs[i].itemDisabled = !ucbs[i].itemDisabled;
					}
				}

				mod._stateAction($ucb, timeouts);

				jqxhr = $.ajax({
					url: '' + serviceUri,
					type: 'post',
					timeout: mod.service.timeout,
					contentType: 'application/json',
					//dataType: 'json',
					async: true,
					cache: false
				}).done(function() {
					if (doActivate) {
						$fly.addClass('state-ucb-flyout--activate');
					}
					else {
						$fly.addClass('state-ucb-flyout--deactivate');
					}

					$ucb.toggleClass('m-ucb--state-active');
					$ucb.toggleClass('m-ucb--state-inactive');

					timeouts.push(
						window.setTimeout(function() {
							$fly.removeClass('state-ucb-flyout--load');
						}, 5000)
					);

					timeouts.push(
						window.setTimeout(function() {
							$fly.removeClass('state-ucb-flyout--activate state-ucb-flyout--deactivate');
						}, 6000)
					);
				}).fail(function(jqXHR, textStatus) {
					if ('abort' !== textStatus) {
						$fly.removeClass('state-ucb-flyout--activate state-ucb-flyout--deactivate');
						if (jqXHR.responseJSON && jqXHR.responseJSON.errorMessage) {
							$fly.find('.js-status-error-text').html(jqXHR.responseJSON.errorMessage);

							if (jqXHR.responseJSON.redirectUrl) {
								$fly.find('.js-status-error-confirmation').attr('href', jqXHR.responseJSON.redirectUrl);
							}
						}
						$fly.addClass('state-ucb-flyout--error');

						// Revert changes
						for (i = 0; i < len; i++) {
							if (ucbItemId === '' + ucbs[i].itemId) {
								ucbs[i].state = doActivate ? mod.CONST.STATE.INACTIVE : mod.CONST.STATE.ACTIVE;
								ucbs[i].action = '';
							}
							else if (ucbs[i].group && ucbGroup === '' + ucbs[i].group && ucbs[i].state === mod.CONST.STATE.INACTIVE) {
								ucbs[i].itemDisabled = !ucbs[i].itemDisabled;
							}
						}
						mod._stateAction($ucb, timeouts);
					}
				});
			});

			$ctx.on('click', '.js-status-error-confirmation', function(e) {
				var $link = $(this),
					href = $link.attr('href');

				if (href === '#') {
					e.preventDefault();
					e.stopPropagation();
					window.location.reload(true);
				}
			});
		},

		// state change action
		_stateAction: function() {
			// implement in skin if necessary
		},

		// enrich ucb data for GUI
		_prepareUcbData: function() {
			// implement in skin if necessary
		},

		// render everything for the first time
		_renderAll: function() {
			var mod = this,
				$ctx = $(mod._ctx),
				tpl;

			$ctx.hide();
			mod._prepareUcbData(); // add skin-specific data

			if (mod.template.container) {
				// additional parent container
				tpl = T.Utils.Template.get(mod.template.container);

				mod._render({'bgColor': mod.backgroundColor}, tpl, $ctx);

				mod.$containers = $ctx.find('.js-ucbs__container');
				mod.$containers.each(function() {
					mod._renderUcbsContainer($(this));
				});

				if (mod.template.controls) {
					mod.$controls = $ctx.find('.js-ucbs__controls');
					mod.$controls.each(function() {
						mod._renderControls($(this));
					});
				}
			}
			else {
				// eg. for detail (without parent container)
				mod.$containers = $ctx;
				var meta = mod._getBaseUcbsTemplateInfo();

				mod._renderUcbs($ctx, meta);
			}

			$ctx.fadeIn(300);
		},

		// check for meta values (of $container) and render ucbs in $container
		_renderUcbsContainer: function($container) {
			var mod = this,
				$ctx = $(mod._ctx),
				meta = mod._getBaseUcbsTemplateInfo(),
				currencySign = $ctx.data('currency-sign');

			meta.size.small = mod.type === mod.CONST.TYPE.OVERVIEW || $container.data('size') === mod.CONST.SIZE.SMALL ? true : false;
			meta.size.big = $container.data('size') === mod.CONST.SIZE.BIG ? true : false;
			meta.currencySign = currencySign ? currencySign : '.-';

			mod._renderUcbs($container, meta);
		},

		// render ucbs in $container with given meta values
		_renderUcbs: function($container, meta) {
			var mod = this,
				$ctx = $(mod._ctx),
				ucbs = mod.ucbs,
				tpl = T.Utils.Template.get(mod.template.id),
				data = {
					ucbs: ucbs,
					meta: meta || {}
				},
				$_container = $container || $ctx,
				states = $_container.data('states'),
				showStates = states ? states.split(',') : []; // empty array shows all states;

			if (!!data.ucbs) {
				for (i = 0, ucbLength = data.ucbs.length; i < ucbLength; i++) {
					var ucb = ucbs[i], ucbLength, i;

					ucb._show = !showStates.length || $.inArray(ucb.state, showStates) !== -1;

					ucb._state = {
						active: ucb.state === mod.CONST.STATE.ACTIVE,
						redeemed: ucb.state === mod.CONST.STATE.REDEEMED,
						inactive: ucb.state === mod.CONST.STATE.INACTIVE,
						new: ucb.newUcb,
						disabled: ucb.itemDisabled || false
					};


					///////////////////////////////////////////////////
					// more ucb logic for visualisation
					///////////////////////////////////////////////////
					ucb._prop = {};

					// product image
					ucb._prop.image = {};
					ucb._prop.image.src = ucb._state.redeemed && ucb.imageRedeemed ? ucb.imageRedeemed : ucb.image;

				}
			}
			mod._render(data, tpl, $_container);
		},

		//render the controls in $container with client template
		_renderControls: function($container) {

			var mod = this,
				ref = $container.data('ref'),
				tpl = T.Utils.Template.get(mod.template.controls),
				data = {
					state: ref && mod.state[ref] ? mod.state[ref] : {},
					ref: ref,
					hasChannelFilter: false,
					hasSortFilter: false,
					sort: {},
				},
				boxes = $container.data('boxes');

			if (boxes) {
				if (boxes.indexOf('filter') !== -1) {
					data.hasChannelFilter = true;
				}
				if (boxes.indexOf('sort') !== -1) {
					data.hasSortFilter = true;
					data.sort[data.state.sort] = true;
				}
			}

			mod._render(data, tpl, $container);
		},

		// render an info message with
		_renderInfo: function(info) {
			var mod = this,
				$ctx = $(mod._ctx),
				tpl = T.Utils.Template.get(info.tpl),
				data = {
					msg: info.msg || '',
					type: info.type || '',
					wrap: info.wrap || false
				};

			$ctx.hide();
			mod._render(data, tpl, $ctx);
			$ctx.fadeIn(300);
		},

		// render
		_render: function(data, tpl, $container) {
			var mod = this,
				$ctx = $(mod._ctx),
				content = tpl(data),
				$content = $(content),
				//$_container = arguments[2] || $ctx;
				$_container = arguments[2] || $container || $ctx;

			$_container.html($content);

			// maybe: start modules
		},

		// get the meta defaults
		_getBaseUcbsTemplateInfo: function() {
			var mod = this;
			return {
				type: {
					overview: mod.type === mod.CONST.TYPE.OVERVIEW,
					detail: mod.type === mod.CONST.TYPE.DETAIL,
					preview: mod.type === mod.CONST.TYPE.PREVIEW
				},
				size: { // default is big
					big: true,
					small: false
				}
			};
		},

		// return the model by its itemId
		_getModelByItemId: function(itemId) {
			var mod = this,
				ucbs = mod.ucbs;

			if (ucbs.length === 1) {
				return ucbs[0];
			}

			for (var i = 0, length = ucbs.length; i < length; i++) {
				var ucb = ucbs[i];

				if (ucb.itemId === itemId) {
					return ucb;
				}
			}
		},


		// client tracking
		_track: function(data) {
			if (typeof window.dataLayer !== 'undefined') {
				window.dataLayer.push(data);
			}
			else {
				if (typeof window.console !== 'undefined') {
					window.console.log(data);
				}
			}
		}
	});
}(jQuery));

(function($) {
	'use strict';

	/**
	 * Overview decorator implementation for the Ucbs module.
	 *
	 * @author ernscht <ernscht@gmail.com>
	 * @namespace T.Module.Ucbs
	 * @class Overview
	 * @extends T.Module
	 */
	T.Module.Ucbs.Overview = T.createDecorator({
		start: function(resolve) {
			var mod = this,
				$ctx = $(mod._ctx);

			//console.log('START Ucbs Overview');

			// init //////////////////////////////
			mod._init();
			mod.type = mod.CONST.TYPE.OVERVIEW;
			mod.CONST.TABIDS = ['tab1', 'tab2', 'tab3'];

			mod.state = T.Utils.Store.getState(mod.CONST.STORAGENAME);
			if (!mod.state) {
				// set default
				mod.state = {
					tabId: 'tab1',
					tab1: {sort: mod.CONST.SORT.DEFAULT},
					tab2: {sort: mod.CONST.SORT.DEFAULT},
					tab3: {}
				};
			}


			// bind //////////////////////////////
			mod._bindStateActionEvents();

			// bind tab change
			$ctx.on('click', '.js-ucbs__tab-link', function() {
				var $this = $(this);
				if (!$this.hasClass('state-active')) {
					mod._showTab($this.data('tab-ref'));
					mod._trackUcbs({action: 'tab-wechsel'});
				}
			});

			// bind filter/sort
			$ctx.on('click', '.js-ucbs__dropdown-button', function(e) {
				e.preventDefault();
				e.stopPropagation();
				var $this = $(this),
					$dropdown = $this.closest('.js-ucbs__dropdown');

				$ctx.find('.js-ucbs__dropdown.open').not($dropdown).removeClass('open');  // close others
				$dropdown.toggleClass('open');
			});
			$ctx.on('click', function() {
				// close all filters
				$ctx.find('.js-ucbs__dropdown.open').removeClass('open');
			});

			$ctx.on('click', '.js-ucbs__filter-item', function(e) {
				e.preventDefault();
				var $item = $(this),
					$dropdown = $item.closest('.js-ucbs__dropdown'),
					$menu = $dropdown.find('.js-ucbs__dropdown-menu'),
					$button = $dropdown.find('.js-ucbs__dropdown-button'),
					refs = $button.attr('id').split('_'),
					tabId = refs[0],
					isSort = refs[1] === 'sort',
					text = $item.text(),
					value = $item.data('value');

				if (e.originalEvent && $item.hasClass('is-active')) { // same clicked node
					return false;
				}

				$button.find('.js-ucbs__dropdown-button__text').text(text);
				$menu.find('.js-ucbs__filter-item').removeClass('is-active');
				$item.addClass('is-active');

				if (isSort) {
					mod._doSort(tabId, value);
					if (e.originalEvent) {
						mod._trackUcbs({action: 'sortieren'});
					}
				}
			});

			// parent //////////////////////////////
			mod._parent.start(resolve);
		},

		_sync: function() {
			var mod = this,
				$ctx = $(mod._ctx);

			//console.log('Ucbs Overview - sync [id:' + $ctx.data('t-id') + ']');

			mod.$tabLinks = $ctx.find('.js-ucbs__tab-link');
			mod.$tabContents = $ctx.find('.js-ucbs__tab-content');

			// content is here
			mod._countUcbs();

			// default filter sort from state
			mod.$controls.each(function() {

				var $control = $(this),
					tabId = $control.closest('.js-ucbs__controls').data('ref');

				// initial filter/sort
				if (mod.state[tabId].hasOwnProperty('sort')) {
					$control.find('.js-ucbs__sort .js-ucbs__filter-item[data-value="' + mod.state[tabId].sort + '"]').trigger('click');
				}
				else {
					mod.state[tabId].sort = mod.CONST.SORT.DEFAULT;
					$control.find('.js-ucbs__sort .js-ucbs__filter-item[data-value="' + mod.state[tabId].sort + '"]').trigger('click');
				}
			});

			// sort redeemed
			$('#' + mod.CONST.TABIDS[2]).find('.m-ucb').sortElements(function(a, b) {
				return $(a).data('sort-redeemed') < $(b).data('sort-redeemed') ? 1 : -1;
			});

			// show tab for the first time
			mod._showTab(T.Utils.Url.getUrlParamValue('tab') || mod.state.tabId); // undocumented url param tab ;-)

			// Count and show sum of available ucbs
			mod._sumUcbs();

			// track on page load
			mod._trackUcbs({action: 'seite-load'});
		},

		_doSort: function(tabId, value) {
			var mod = this,
				$tab = $('#' + tabId),
				$container = $tab.find('.js-ucbs__sort'),
				_value = arguments[1] ? value : $container.find('.js-ucbs__filter-item.is-active').data('value');

			mod.state[tabId].sort = _value;
			T.Utils.Store.setState(mod.CONST.STORAGENAME, mod.state);

			if (_value === 'newest') {
				$tab.find('.m-ucb').sortElements(function(a, b) {
					var prioritySort = mod._sortPriorityUcbs(a, b);
					if (prioritySort) {
						return prioritySort;
					}
					// second comparison criteria is 'highest'
					if ($(a).data('sort-date') === $(b).data('sort-date')) {
						return $(a).data('sort-amount') < $(b).data('sort-amount') ? 1 : -1;
					}
					return $(a).data('sort-date') < $(b).data('sort-date') ? 1 : -1;
				});
			}
			else if (_value === 'oldest') {
				$tab.find('.m-ucb').sortElements(function(a, b) {
					var prioritySort = mod._sortPriorityUcbs(a, b);
					if (prioritySort) {
						return prioritySort;
					}
					// second comparison criteria is 'highest'
					if ($(a).data('sort-date') === $(b).data('sort-date')) {
						return $(a).data('sort-amount') < $(b).data('sort-amount') ? 1 : -1;
					}
					return $(a).data('sort-date') > $(b).data('sort-date') ? 1 : -1;
				});
			}
			else if (_value === 'highest') {
				$tab.find('.m-ucb').sortElements(function(a, b) {
					var prioritySort = mod._sortPriorityUcbs(a, b);
					if (prioritySort) {
						return prioritySort;
					}
					// second comparison criteria is 'oldest'
					if ($(a).data('sort-amount') === $(b).data('sort-amount')) {
						return $(a).data('sort-date') > $(b).data('sort-date') ? 1 : -1;
					}
					return $(a).data('sort-amount') < $(b).data('sort-amount') ? 1 : -1;
				});
			}
			else if (_value === 'lowest') {
				$tab.find('.m-ucb').sortElements(function(a, b) {
					var prioritySort = mod._sortPriorityUcbs(a, b);
					if (prioritySort) {
						return prioritySort;
					}
					// second comparison criteria is 'oldest'
					if ($(a).data('sort-amount') === $(b).data('sort-amount')) {
						return $(a).data('sort-date') > $(b).data('sort-date') ? 1 : -1;
					}
					return $(a).data('sort-amount') > $(b).data('sort-amount') ? 1 : -1;
				});
			}

			//console.log('sort: ' + tabId + ' / ' + _value);
		},

		_sortPriorityUcbs: function(a, b) {
			if ($(a).data('sort-fix')) {
				if (!$(b).data('sort-fix')) {
					return -1;
				}
			}
			else if ($(b).data('sort-fix')) {
				return 1;
			}
			return 0;
		},

		_countUcbs: function() {
			var mod = this;

			mod.$tabLinks.each(function(idx, ele) {
				mod._updateTabCount($(ele).data('tab-ref'));
			});
		},

		_updateTabCount: function(tabId) {
			var mod = this,
				$tabLink = mod.$tabLinks.filter('[data-tab-ref=' + tabId + ']'),
				count = $('#' + tabId).find('.m-ucb').length;

			$tabLink.find('.js-ucbs__tab-nr').text(count);

			if (tabId === "tab2") {
				window.setTimeout(function () {
					var $ctx = $(mod._ctx),
						$tab = $ctx.find('#' + mod.CONST.TABIDS[0]),
						sum = 0;
					$tab.find('.m-ucb.m-ucb--state-active').each(function () {
						sum += $(this).data('amount');
					});
					// Set thousand seperator
					sum = sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "’");

					$('[data-target-id="ucb"]').text(sum + $ctx.data('currency-sign'));
				}, 4000);
			}
		},

		_sumUcbs: function() {
			var mod = this,
				$ctx = $(mod._ctx),
				$sum = $ctx.find('.js-ucbs__sum'),
				$tab = $ctx.find('#' + mod.CONST.TABIDS[0]),
				sum = 0;

			$tab.find('.m-ucb.m-ucb--state-active, .m-ucb.m-ucb--state-inactive').each(function() {
				sum += $(this).data('amount');
			});

			// Set thousand seperator
			sum = sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "’");

			var sumText = $ctx.data('text-sum');
			$sum.find('.js-m-ucb-container__sum-text').text(sumText);
			$sum.find('.js-m-ucb-container__sum-amount').text($ctx.data('currency') + ' ' + sum + $ctx.data('currency-sign'));
		},

		_showTab: function(tabId) {
			var mod = this,
				$tabLinks = mod.$tabLinks,
				$contents = mod.$tabContents,
				$tabLink;

			$contents.hide();
			$tabLinks.removeClass('state-active');

			if (tabId) {
				$tabLink = $tabLinks.filter('[data-tab-ref=' + tabId + ']');
			}
			else { // first tab, optimisation: check if we need this
				$tabLink = $tabLinks.eq(0);
				tabId = $tabLink.data('tab-ref');
			}

			mod.state.tabId = tabId;
			T.Utils.Store.setState(mod.CONST.STORAGENAME, mod.state);

			$tabLink.addClass('state-active');
			$('#' + tabId).fadeIn(300);
			mod._handleFilters(tabId);
		},

		// skin specific state change action
		_stateAction: function($ucb, timeouts) {
			var mod = this,
				thisTabId = $ucb.closest('.js-ucbs__container').data('ref'),
				otherTabId = thisTabId === mod.CONST.TABIDS[0] ? mod.CONST.TABIDS[1] : mod.CONST.TABIDS[0],
				$otherTabContent = $('#' + otherTabId),
				$otherTabUcbsContainer = $otherTabContent.find('.js-ucbs__container');

			// render other tab
			mod._renderUcbsContainer($otherTabUcbsContainer);

			// first or second tab
			if (thisTabId === mod.CONST.TABIDS[0]) {

				mod._renderControls($otherTabContent.find('.js-ucbs__controls'));
				mod._doSort(otherTabId);
				mod._updateTabCount(otherTabId);
				mod._handleFilters(otherTabId);
			}
			else {
				var $thisTab = $('#' + thisTabId);

				$.each(timeouts, function(idx, val) {
					window.clearTimeout(val);
				});

				// render new filters immediately before ucb is removed ...
				mod._renderControls($thisTab.find('.js-ucbs__controls'));

				// remove inactive ucb & refilter/resort
				window.setTimeout(function() {
					$ucb.fadeOut(300, function() {
						$(this).remove();
						mod._doSort(thisTabId);
						mod._updateTabCount(thisTabId);
						mod._handleFilters(thisTabId);
					});
				}, 4000);
			}
		},

		// enrich ucb data for GUI
		_prepareUcbData: function() {
			var mod = this,
				ucbs = mod.ucbs,
				len = ucbs.length,
				i,
				ucb,
				activatedGroups = [],
				activatedUcbs = [],
				pubDate, expDate;

			// first loop, we need to collect some things for 2nd loop (activatedGroups)
			for (i = 0; i < len; i++) {
				ucb = ucbs[i];

				if (ucb.state === mod.CONST.STATE.ACTIVE) {
					activatedUcbs.push(ucb.id);
				}

				ucb.itemId = ucb.id + '-' + Math.random().toString(24).substring(6);

				pubDate = ucb.publicationDate ? parseInt(ucb.publicationDate.replace(/-/g, ''), 10) : 10000101; // default 1000 AD.
				expDate = ucb.expirationDate ? parseInt(ucb.expirationDate.replace(/-/g, ''), 10) : 90000101;    // default 9000 AD.
				ucb.itemSortDate = pubDate * 10000000 - expDate;       // biggest number for newest (pubDate closer to today, else closest expiry date)
				ucb.itemSortAmount = ucb.amount ? ucb.amount : null;
				ucb.itemSortRedeemed = ucb.redeemedDate ? ucb.redeemedDate : null;
			}

			// second iteration
			for (i = 0; i < len; i++) {
				ucb = ucbs[i];

				// check disabled ucbs
				ucb.itemDisabled = (ucb.state === mod.CONST.STATE.INACTIVE && $.inArray(ucb.group, activatedGroups) !== -1) ? true : false;
				ucb.hasDetailLink = ($.inArray(ucb.id, activatedUcbs) > -1) ? false : true;
			}
		},

		_handleFilters: function(tabId) {
			var mod = this,
				$ctx = $(mod._ctx),
				$tabLinks = mod.$tabLinks,
				ucbThreshold = 0,
				$tabLink,
				$tabContainer;

			if (tabId) {
				$tabLink = $tabLinks.filter('[data-tab-ref=' + tabId + ']');
				$tabContainer = $('#' + tabId);
			}
			else { // first tab
				$tabLink = $tabLinks.eq(0);
				tabId = $tabLink.data('tab-ref');
				$tabContainer = $('#' + tabId);
			}

			var ucbs = parseInt($tabLink.find('.js-ucbs__tab-nr').text());

			if (ucbs <= ucbThreshold) {
				$('#' + tabId).find('.js-ucbs__controls').hide();
				$('#' + tabId).find('.js-ucbs__sum').hide();
			}
			else {
				$('#' + tabId).find('.js-ucbs__controls').show();
				$('#' + tabId).find('.js-ucbs__sum').show();
			}

			// show/hide "no ucb"-info
			if (ucbs === 0) {
				var tpl = T.Utils.Template.get($ctx.data('tpl-info')),
					render = tpl({
						msg: $ctx.data('msg-info-zero-ucbs')
					}),
					$render = $(render);

				$render.addClass('js-ucbs_no-ucb-info');

				if (!$tabContainer.find('.js-ucbs_no-ucb-info').length) {
					$tabContainer.append($render);
				}
			}
			else {
				if ($tabContainer.find('.js-ucbs_no-ucb-info').length) {
					$tabContainer.find('.js-ucbs_no-ucb-info').remove();
				}
			}
		},

		_trackUcbs: function(config) {
			config = config || {};

			var mod = this,
				$ctx = $(mod._ctx),
				$tab = $ctx.find('[data-tab-ref="' + mod.state.tabId + '"]'),
				$tabContainer = $('#' + mod.state.tabId),
				$ucbs = $('.m-ucb', $tabContainer),

				availableCount = $('#' + mod.CONST.TABIDS[0]).find('.m-ucb').length,
				activeCount = $('#' + mod.CONST.TABIDS[1]).find('.m-ucb').length,
				usedCount = $('#' + mod.CONST.TABIDS[2]).find('.m-ucb').length,
				totalCount = availableCount + usedCount,
				inactiveCount = availableCount - activeCount,
				impressions = [],
				totalSum = 0,
				$ucb, model;

			// collect sum
			for (var i = 0, len = mod.ucbs.length; i < len; i++) {
				totalSum += mod.ucbs[i].amount || 0;
			}

			// collect only visible ucbs
			$ucbs.each(function(i) {
				$ucb = $(this);
				model = mod._getModelByItemId($ucb.data('item-id'));

				impressions.push({
					name: model.titleText,
					id: '',
					position: i + 1,
					price: '',
					list: $tab.data('tracking-key') || '',
					brand: 'cumulus bon',
					category: model.amount + '.- Fr.',
					variant: 'gueltig_' + model.expirationDate
				});
			});

			mod._track({
				event: 'ucbBons',
				action: config.action || '',
				list: $tab.data('tracking-key') || '',
				sortSelected: $tabContainer.find('.js-ucbs__sort .is-active').data('tracking-key') || '',
				ucbsSum: totalCount + '',
				ucbsSumValue: 'Fr. ' + totalSum + '.-',
				ucbsActivated: activeCount + '',
				ucbsDeactivated: inactiveCount + '',
				ucbsUsed: usedCount + ''
			});

			mod._track({
				event: 'ucbImpression',
				ecommerce: {
					impressions: impressions
				}
			});
		}
	});
}(jQuery));
