/**
 * @author Lutz Möbius
 */

(function () {
  'use strict';

  /**
        @description
        Shows hides $('.ui-js-show-mobile') or $('.ui-js-show-desktop') depending on device.

     */

  ui.SocialMediaDeviceComponentView = ui.ComponentView.extend({
    name: 'ui.SocialMediaDeviceComponentView',

    initialize: function () {
      this.$SocialMediaDeviceMobile = $('.ui-js-show-mobile');
      this.$SocialMediaDeviceDesktop = $('.ui-js-show-desktop');
    },

    showElementByDevice: function () {
      if (/Mobi/.test(navigator.userAgent)) {
        this.$SocialMediaDeviceDesktop.addClass('hidden');
      } else {
        this.$SocialMediaDeviceMobile.addClass('hidden');
      }
    },

    render: function () {
      this.showElementByDevice();

      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'SocialMediaDeviceComponent',
    View: ui.SocialMediaDeviceComponentView,
    selector: 'body',
  });

  $(ui.bootstrapSocialMediaDeviceComponent());
}).call(this);
