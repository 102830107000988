/**
 * @author Nils von Rymon-Lipinski
 * @description TODO description
 */

(function () {
  'use strict';

  ui.FreeHtmlHotspotsComponentView = ui.ComponentView.extend({
    name: 'ui.FreeHtmlHotspotsComponentView',

    hotSpotBox: '.hotspot-box',
    hotspotSelector: '.ui-js-hotspot',
    hotspotContentSelector: '.ui-js-hotspot-content',
    hotspotBtnSel: '.ui-js-hotspot-btn',
    hotspotIndex: 0,
    contentCopy: false,

    $hotspots: null,
    $hotspotContents: null,
    $hotspotBtns: null,

    events: {
      'focusin .ui-js-hotspot': 'toggleHotspot',
      'focusout .ui-js-hotspot': 'toggleHotspot',
      'mouseenter .ui-js-hotspot': 'toggleHotspot',
      'mouseleave .ui-js-hotspot': 'toggleHotspot',
      'click .ui-js-hotspot-close': 'closeHotspot',
    },

    initialize: function (options) {
      this.$hotspots = $(this.hotspotSelector, this.$el);
      this.$hotspotContents = $(this.hotspotContentSelector, this.$el);
      this.$hotspotBtns = $(this.hotspotBtnSel, this.$hotspots);

      if (!this.$hotspotBtns.length) {
        console.warn('No HotspotBtns!');
        return false;
      }

      this.setHotspotPosition(this.$hotspots);
      this.$lastBtn = $(this.$hotspotBtns[this.hotspotIndex]);

      var isMobileCheck = this.isMobileCheck.bind(this);
      ui.on('bootstrap.activemediaquery.changed', isMobileCheck);

      this.isMobileCheck();

      this.$images = $(this.hotSpotBox, this.$el).find(
        '[src*="/author"], [data-srcset*="/author"], [srcset*="/author"]'
      );
      this.adjustImagePaths(this.$images);
    },

    adjustImagePaths: function ($images) {
      $.each($images, function () {
        var path = $(this).attr('src') || $(this).attr('data-srcset') || $(this).attr('srcset');

        if (location.pathname.indexOf('/author') === -1 && $(this).attr('src')) {
          $(this).attr('src', path.replace(/author/g, '..'));
        }

        if (location.pathname.indexOf('/author') === -1 && $(this).attr('data-srcset')) {
          $(this).attr('data-srcset', path.replace(/author/g, '..'));
        }

        if (location.pathname.indexOf('/author') === -1 && $(this).attr('srcset')) {
          $(this).attr('srcset', path.replace(/author/g, '..'));
        }
      });
    },

    initTabletAndDesktop: function () {
      if (!this.contentCopy) {
        this.copyHotspotContent();
        this.contentCopy = true;
      }
      this.toggleNextHotspotHint();
    },

    deactivateTabletAndDesktop: function () {
      this.deactivateHotspotHint();
      this.$hotspots.removeClass('is-active');
    },

    isMobileCheck: function () {
      var viewport = ui.Bootstrap ? ui.Bootstrap.activeView : '';
      // Determine device category on each viewport change
      if (/xs|ms/.test(viewport)) {
        this.deactivateTabletAndDesktop();
      } else {
        this.initTabletAndDesktop();
      }
    },

    setHotspotPosition: function ($hotspots) {
      $hotspots.each(function (idx, hotspot) {
        var $hotspot = $(hotspot);
        var posData = $hotspot.data('pos');
        $hotspot.css({
          left: posData.x,
          top: posData.y,
        });
      });
    },

    deactivateHotspotHint: function () {
      this.$hotspotBtns.off(ui.consts.ON_TRANSITION_END);
      this.$hotspotBtns.removeClass('is-hint');
    },

    toggleNextHotspotHint: function () {
      var self = this;
      self.$lastBtn.one(ui.consts.ON_TRANSITION_END, function () {
        self.$lastBtn.removeClass('is-hint');
        self.$lastBtn.one(ui.consts.ON_TRANSITION_END, function () {
          self.hotspotIndex++;
          if (self.hotspotIndex > self.$hotspotBtns.length - 1) {
            self.hotspotIndex = 0;
          }
          self.$lastBtn = $(self.$hotspotBtns[self.hotspotIndex]);
          self.toggleNextHotspotHint();
        });
      });
      self.$lastBtn.addClass('is-hint');
    },

    toggleHotspot: function (e) {
      var self = this;
      switch (e.type) {
        case 'mouseenter':
        case 'focusin':
          self.setHotspotActive($(e.currentTarget));
          break;
        case 'mouseleave':
        case 'focusout':
          self.setHotspotInactive($(e.currentTarget));
          break;
      }
    },

    setHotspotActive: function ($target) {
      var mobile = /(ms|xs)/.test(ui.Bootstrap.activeView);
      this.deactivateHotspotHint();
      $target.addClass('is-active');

      if (mobile) {
        var indexBtn = this.$hotspots.index($target);
        $(this.$hotspotContents[indexBtn]).addClass('is-active');
      }
    },

    setHotspotInactive: function ($target) {
      this.toggleNextHotspotHint();
      $target.removeClass('is-active');
    },

    copyHotspotContent: function () {
      for (var idx = 0; idx < this.$hotspotBtns.length; idx++) {
        $(this.$hotspotBtns[idx]).after($(this.$hotspotContents[idx]).html());
      }
    },

    closeHotspot: function () {
      $(this.$hotspotContents).removeClass('is-active'); // for mobile
    },

    render: function () {
      return this;
    },

    closeView: function () {
      this.unbind();
      this.remove();
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FreeHtmlHotspotsComponent',
    View: ui.FreeHtmlHotspotsComponentView,
    selector: '.ui-js-free-html-hotspots',
    reinitialize: true,
  });

  $(ui.bootstrapFreeHtmlHotspotsComponent());
}).call(this);
