(function () {
  'use strict';

  ui.NavTabsFiltersComponentView = ui.ComponentView.extend({
    name: 'ui.NavTabsFiltersComponentView',

    // Checks for History support, uses window.location.hash if replaceState is not supported
    state: !!(window.history && window.history.replaceState) || false,

    events: {
      'click .ui-js-filter-btn': 'onItemClick',
    },

    initialize: function () {
      ui.on(ui.GlobalEvents.TAB_FILTER_TRACKING, this.trackFilter.bind(this));
    },

    onItemClick: function (e) {
      e.preventDefault();

      const $target = $(e.currentTarget);

      if ($target.hasClass('is-active') || $target.hasClass('is-disabled')) return;

      this.setHashInUrl($target);

      ui.trigger(ui.GlobalEvents.TAB_FILTER_CHANGE, $target);
    },

    setHashInUrl: function ($target) {
      const hashArray = window.location.hash ? window.location.hash.split('--') : null;
      const targetName = $target.attr('name') || $target.data('name');
      const targetValue = $target.val() || $target.data('value');

      let newHash = '';

      if (!hashArray) return;

      // if there are 2 or more
      if (hashArray.length > 1) {
        newHash = hashArray.reduce((str, hashPart) => {
          // first hashPart(hashPart with #)
          let replaceHash = hashPart;

          // already existing hashPart
          if (hashPart.indexOf(targetName) === -1 && hashPart.indexOf('#') === -1) {
            replaceHash = `--${hashPart}`;
          }

          // replace hashParts that already exists
          if (hashPart.indexOf(targetName) !== -1) {
            replaceHash = `--${targetName}-${targetValue}`;
          }

          return (str += replaceHash);
        }, '');

        // add new hashParts that not exist at the moment
        if (newHash.indexOf(targetName) === -1) newHash += `--${targetName}-${targetValue}`;
      }

      // if there is one filter => [new] is added
      if (hashArray.length === 1) {
        newHash = hashArray[0] + `--${targetName}-${targetValue}`;
      }

      if (newHash) {
        if (this.state) {
          window.history.replaceState({}, '', newHash);
        } else {
          window.location.hash = newHash;
        }
      }
    },

    trackFilter: function ($container) {
      if (!$container) return;

      const activeFilters = $container[0].querySelectorAll('.ui-js-filter-btn');

      Array.from(activeFilters).reduce((arr, filter) => {
        const filterName = filter.name || filter.dataset.name;
        const hasFilterAlready =
          arr.findIndex((item) => item.name === filterName || item.dataset.name === filterName) !==
          -1;

        if (!hasFilterAlready) {
          const activeSibling = $container[0].querySelector(
            `[data-name="${filterName}"].ui-js-filter-btn.is-active, [name="${filterName}"].ui-js-filter-btn:checked`
          );
          const isDefault =
            !activeSibling && (!filter.classList.contains('is-active') || !filter.checked);

          // if it's an active filter track it directly
          // if it's not an active filter track the active filter with the same name or use the default
          let currentFilter = filter;
          currentFilter =
            (!filter.classList.contains('is-active') || !filter.checked) && activeSibling
              ? activeSibling
              : filter;

          arr.push(currentFilter);
          this.pushToDataLayer($(currentFilter), isDefault);
        }

        return arr;
      }, []);
    },

    pushToDataLayer: function ($target, isDefault = false) {
      if (!window.dataLayer || !$target || $target.length === 0) return;

      const { data } = ui.getElementSetup({
        el: $target.closest('[data-setup]'),
      });

      if (!data) return;

      let dataObj = data;
      const targetValue = isDefault ? 'default' : $target.data('value') || $target.val();

      if (targetValue) {
        dataObj =
          data.event === 'sortierung'
            ? {
                ...data,
                sortBy: targetValue,
              }
            : {
                ...data,
                filterSelected: `filter ${targetValue}`,
              };
      }

      window.dataLayer.push(dataObj);

      if (window.logTracking) {
        console.info('added dataLayer data:', JSON.stringify(dataObj));
        console.info('Tracking Obj', dataObj);
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'NavTabsFiltersComponent',
    View: ui.NavTabsFiltersComponentView,
    selector: '.ui-js-nav-tabfilters',
  });

  $(ui.bootstrapNavTabsFiltersComponent());
}).call(this);
