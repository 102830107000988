/**
 * @author Nils von Rymon-Lipinski (IBM IX/Aperto GmbH)
 */

(function () {
  'use strict';

  /**
   * add description here
   */

  ui.Quiz = ui.ComponentView.extend({
    name: 'ui.Quiz',
    activeClass: 'is-active',
    fadeOutClass: 'is-fade-out',
    fadeInClass: 'is-fade-in',
    flexClass: 'is-display-flex',
    fadeOutTime: 400,
    fadeInTime: 400,
    correct: 0,
    answerBtnSel: '[data-js-item="ui-js-answer"]',
    sectionSel: '[data-js-item="quiz-section"]',
    answerCounterSel: '[data-js-item="answer-counter"]',
    answerSel: '[data-js-item="ui-js-answer"]',
    answersSel: '[data-js-item="answers"]',
    dataAnswerTextSel: '[data-answer-text]',
    quizItemsSel: '[data-js-item="quiz-items"]',
    answerCorrectWrongSel: '[data-js-item="answer-correct-wrong"]',
    answerTitleSel: '[data-js-item="answer-title"]',
    answerTextSel: '[data-js-item="answer-text"]',
    questionSel: '[data-js-item="question"]',
    nextQuestionSel: '[data-js-item="ui-js-next-question"]',
    overlaySel: '[data-js-item="ui-js-overlay"]',
    overlayContentSel: '[data-js-item="ui-js-overlay-content"]',
    scoringSel: '[data-js-item="scoring"]',
    stepsCorrectSel: '[data-js-item="steps-correct"]',
    cookieNamePrefix: 'quiz-',
    desktop: /md|lg/,
    margin_md: 13,
    margin_lg: 15,
    desktopPadding: 30,

    events: {
      'click [data-js-item="ui-js-answer"]': 'handleAnswerClick',
      'click [data-js-item="ui-js-cancel-quiz"]': 'handleCancelClick',
      'click [data-js-item="ui-js-overlay"]': 'handleOverlayClick',
      'click [data-js-item="ui-js-quiz-reset"]': 'handleResetClick',
      'click [data-js-item="ui-js-next-question"]': 'handleNextQuestionClick',
    },

    initialize: function (options) {
      this.$bodyHtml = $('html,body');
      this.step = 0;
      this.$quizItems = this.$el.find(this.quizItemsSel);
      this.$sections = this.$el.find(this.sectionSel);
      this.setQuizItemsHeight(0);
      this.$answers = this.$el.find(this.answerSel);
      this.steps = this.$sections.length > 1 ? this.$sections.length - 1 : this.$sections.length;
      this.$overlay = this.$el.find(this.overlaySel);
      this.$answerCorrectWrong = this.$overlay.find(this.answerCorrectWrongSel);
      this.$answerTitle = this.$overlay.find(this.answerTitleSel);
      this.$answerText = this.$overlay.find(this.answerTextSel);
      this.$overlayContent = this.$overlay.find(this.overlayContentSel);
      this.$scoring = this.$el.find(this.scoringSel);
      this.$nextQuestion = this.$el.find(this.nextQuestionSel);
      this.$stepsCorrect = this.$el.find(this.stepsCorrectSel);
      this.cookie =
        $.docCookies(this.cookieNamePrefix + '' + (this.setup.id || 'example')) || false;
      this.trackingData =
        this.setup &&
        this.setup.data &&
        this.setup.data.event &&
        this.setup.data.event === 'form-tools' &&
        this.setup.data;

      if (this.$scoring.length > 0) {
        this.scoringObject = JSON.parse(this.$scoring[0].dataset.jsOptions);
      }

      if (this.steps < 2) {
        this.$el.addClass('without-pagination');
      }

      if (this.cookie !== false) {
        this.correct = parseInt(this.cookie);
        this.updateResults();
        $(this.$sections[this.steps]).addClass(this.activeClass);
        $(this.$sections[this.steps]).addClass(this.fadeInClass);
        this.step = this.steps;
        this.setQuizItemsHeight(this.step);
      } else {
        $(this.$sections[0]).addClass(this.activeClass);
        $(this.$sections[0]).addClass(this.fadeInClass);
      }
      this.setNextQuestionText();
      this.bindEvents();
    },

    bindEvents: function () {
      $(window).on(
        'bootstrap.activemediaquery.changed resize ',
        _.debounce(() => this.setQuizItemsHeight(this.step), 250)
      );
    },

    scrollToTopOfElement: function () {
      const toTopOfElement = this.$el.offset().top - 30;
      this.$bodyHtml.animate({ scrollTop: toTopOfElement }, this.fadeOutTime);
    },

    updateResults: function () {
      if (this.$scoring.length > 0 && this.$stepsCorrect.length > 0) {
        const result =
          (this.correct / this.steps) * 100 > 100 ? 100 : (this.correct / this.steps) * 100;
        const resultText = this.scoringObject.filter((scoring) => result <= scoring.score);
        this.$scoring.text(resultText[0].text);
        this.$stepsCorrect.text(this.correct + '/' + this.steps);
      }
    },

    setQuizItemsHeight: function (index) {
      if (this.$quizItems.length > 0) {
        this.$quizItems.css('height', this.getHeight(index) + 'px');
      }
    },

    getHeight: function (index) {
      if (!this.$sections[index]) {
        console.log('error', this.$sections, index);
      }
      this.$sections[index].style.display = 'block';
      const $isHorizontal = $(this.$sections[index]).find('.ui-js-horizontal');
      const hasAnswerWrapper = $isHorizontal.length && $isHorizontal.find('.answer-wrapper').length;
      if (this.desktop.test(ui.Bootstrap.activeView) && $isHorizontal.length && !hasAnswerWrapper) {
        const margin = ui.Bootstrap.activeView === 'md' ? this.margin_md : this.margin_lg;
        $isHorizontal.removeClass('is-horizontal');
        const width = $isHorizontal[0].clientWidth;
        const $answers = $(this.$sections[index]).find('.answer');
        if ($answers.length && $answers[0].children.length === 1) {
          $answers.removeAttr('style');
          const widthArray = [];
          $answers.each((idx, answer) => {
            widthArray.push(answer.firstElementChild.clientWidth);
          });
          if (widthArray.length) {
            const MAX = Math.max(...widthArray);
            const itemWidth = MAX + this.desktopPadding + margin;
            let PLACE = Math.trunc((width + margin) / itemWidth);
            if (PLACE > widthArray.length) {
              PLACE = widthArray.length;
            }
            if (PLACE > 1) {
              $answers.width('calc((100% / ' + PLACE + ') - ' + margin + 'px)');

              $isHorizontal.addClass('is-horizontal');
            }
          }
        }
      } else if (!this.desktop.test(ui.Bootstrap.activeView) && $isHorizontal.length) {
        if ($isHorizontal.hasClass('is-horizontal')) {
          const $answers = $(this.$sections[index]).find('.answer');
          $answers.removeAttr('style');
        }
      }
      const height = this.$sections[index].clientHeight;
      this.$sections[index].removeAttribute('style');
      return height;
    },

    resetQuiz: function () {
      this.correct = 0;
      this.updateResults();
      $.docCookies(this.cookieNamePrefix + '' + this.setup.id, '');
      this.fadeInAndOutTimeline(true);
      bodyScrollLock.clearAllBodyScrollLocks();
      this.scrollToTopOfElement();
    },

    handleOverlayClick: function (event) {
      if (
        $(event.target).hasClass('quiz-overlay-content') ||
        $(event.target).parents(this.overlayContentSel).length
      ) {
        return false;
      }
      this.resetQuiz();
    },

    handleResetClick: function (event) {
      this.resetQuiz();
    },

    handleCancelClick: function (event) {
      this.resetQuiz();
    },

    handleAnswerClick: function (event) {
      let $target = $(event.currentTarget);
      let correct;
      let answerText;
      if (event.currentTarget.dataset && event.currentTarget.dataset.answerText) {
        this.correct++;
        correct = true;
        answerText = event.currentTarget.dataset.answerText;
      } else {
        $target = $target.parents(this.answersSel).find(this.dataAnswerTextSel);
        answerText = $target[0].dataset.answerText;
        correct = false;
      }
      const answerTitleFull = $target.find(this.answerTextSel).text();
      const answerTitle =
        $target.find(this.answerCounterSel).length > 0
          ? $target.find(this.answerCounterSel).text()
          : answerTitleFull;
      this.pushToDatalayer('click-answer', correct, answerTitleFull);
      this.setNextQuestionText(true);
      this.showOverlay(correct, answerTitle, answerText, answerTitleFull);
    },

    showOverlay: function (correct, answerTitle, answerText, answerTitleFull) {
      bodyScrollLock.disableBodyScroll(this.$overlayContent[0]);
      this.$answerCorrectWrong.text(
        correct
          ? this.$answerCorrectWrong[0].dataset.correct
          : this.$answerCorrectWrong[0].dataset.wrong
      );
      this.$answerTitle.text(
        this.$answerTitle[0].dataset.answerPlaceholder.replace('{x}', answerTitle)
      );
      this.$answerText.text(answerText);
      this.$overlay.addClass(this.flexClass);
      this.$overlay.addClass(this.fadeInClass);
      this.pushToDatalayer('view-question-result', correct, answerTitleFull);
    },

    fadeInAndOutTimeline: function (reset) {
      if (reset) {
        this.$overlay.removeClass(this.fadeInClass);
        if (this.step) {
          this.setQuizItemsHeight(0);
          $(this.$sections[0]).addClass(this.activeClass);
          $(this.$sections[0]).addClass(this.fadeInClass);
          $(this.$sections[this.step]).addClass(this.fadeOutClass);
        }
        setTimeout(() => {
          this.$overlay.removeClass(this.flexClass);
          if (this.step) {
            $(this.$sections[this.step]).removeClass(this.activeClass);
            $(this.$sections[this.step]).removeClass(this.fadeInClass);
            $(this.$sections[this.step]).removeClass(this.fadeOutClass);
          }
          this.step = 0;
        }, this.fadeOutTime);
      } else {
        this.step++;
        this.$overlay.removeClass(this.fadeInClass);
        this.setQuizItemsHeight(this.step);
        $(this.$sections[this.step]).addClass(this.activeClass);
        $(this.$sections[this.step]).addClass(this.fadeInClass);
        $(this.$sections[this.step - 1]).addClass(this.fadeOutClass);
        setTimeout(() => {
          this.$overlay.removeClass(this.flexClass);
          $(this.$sections[this.step - 1]).removeClass(this.activeClass);
          $(this.$sections[this.step - 1]).removeClass(this.fadeInClass);
          $(this.$sections[this.step - 1]).removeClass(this.fadeOutClass);
        }, this.fadeOutTime);
      }
    },

    setNextQuestionText(bool) {
      if (!this.$nextQuestion.length) {
        return;
      }

      if ((this.steps > 1 && this.step === this.steps) || (bool && this.step === this.steps - 1)) {
        this.$nextQuestion.text(this.$nextQuestion[0].dataset.nextResult);
      } else if (this.$nextQuestion.text() !== this.$nextQuestion[0].dataset.nextQuestion) {
        this.$nextQuestion.text(this.$nextQuestion[0].dataset.nextQuestion);
      }
    },

    handleNextQuestionClick: function (event) {
      this.fadeInAndOutTimeline();
      this.pushToDatalayer('view-question');
      bodyScrollLock.clearAllBodyScrollLocks();
      this.scrollToTopOfElement();
      this.updateResults();
      if (this.step === this.steps) {
        this.pushToDatalayer('view-quiz-result');
        $.docCookies(this.cookieNamePrefix + '' + this.setup.id, this.correct, {
          expires: 365,
          secure: true,
          sameSite: 'Lax',
        });
      }
    },

    pushToDatalayer: function (switchCase, correct, answerTitleFull) {
      if (this.trackingData) {
        switch (switchCase) {
          case 'view-question':
            this.trackingData.formAction = 'view-question';
            this.trackingData.formQuestion = $(this.$sections[this.step])
              .find(this.questionSel)
              .text()
              .trim();
            this.trackingData.formAnswer = '';
            this.trackingData.formResult = '';
            this.trackingData.formStep = 'step' + (this.step + 1);
            break;
          case 'click-answer':
            this.trackingData.formAction = 'click-answer';
            this.trackingData.formQuestion = $(this.$sections[this.step])
              .find(this.questionSel)
              .text()
              .trim();
            this.trackingData.formAnswer = answerTitleFull;
            this.trackingData.formResult = correct ? 'success' : 'failure';
            this.trackingData.formStep = 'step' + (this.step + 1);
            break;
          case 'view-question-result':
            this.trackingData.formAction = 'view-question-result';
            this.trackingData.formQuestion = $(this.$sections[this.step])
              .find(this.questionSel)
              .text()
              .trim();
            this.trackingData.formAnswer = answerTitleFull;
            this.trackingData.formResult = correct ? 'success' : 'failure';
            this.trackingData.formStep = 'step' + (this.step + 1);
            break;
          case 'view-quiz-result':
            this.trackingData.formAction = 'view-quiz-result';
            this.trackingData.formTopic = this.trackingData.formType + 'result';
            this.trackingData.formQuestion = $(this.$sections[this.step])
              .find(this.questionSel)
              .text()
              .trim();
            this.trackingData.formAnswer = '';
            this.trackingData.formResult = '';
            this.trackingData.formStep = 'results';
            break;
        }
        if (window.dataLayer) {
          window.dataLayer.push(this.trackingData);
          if (window.logTracking) {
            console.info('added dataLayer data:', JSON.stringify(this.trackingData));
            console.info('Tracking Obj', this.trackingData);
          }
        }
      }
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'QuizComponent',
    View: ui.Quiz,
    selector: '.ui-js-quiz',
    reinitialize: false,
  });

  $(ui.bootstrapQuizComponent());
}).call(this);
