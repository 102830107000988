/**
 * @author Chuck Norris
 *
 */

(function () {
  'use strict';

  ui.AsyncButtonListenerAmountComponentView = ui.ComponentView.extend({
    name: 'ui.AsyncButtonListenerAmountComponentView',

    initialize: function () {
      ui.on(ui.GlobalEvents.ASYNC_BTN_STATE, this.asyncBtnStateListener.bind(this));
    },

    asyncBtnStateListener: function (state) {
      const amountString = this.$el.text().trim();
      let currentAmount = amountString.match(/\d/g).join('');
      currentAmount = currentAmount ? Number(currentAmount) : null;

      let amount = state.isActive ? currentAmount + 1 : currentAmount - 1;
      if (amount < 0) amount = 0;

      const textAmount = amountString.replace(currentAmount, amount);
      this.$el.text(textAmount);
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'AsyncButtonListenerAmountComponent',
    View: ui.AsyncButtonListenerAmountComponentView,
    selector: '.ui-js-async-button-listener-amount',
    reinitialize: true,
  });

  $(ui.bootstrapAsyncButtonListenerAmountComponent());
}).call(this);
