/**
 * @author: Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Opens social media services in a popup window.
   *
   * Data-setup for ahref icon-social:
   * @params {Number} [width] - popup window width
   * @params {Number} [height] - popup window height
   */
  ui.SocialMediaHandlerComponentView = ui.ComponentView.extend({
    name: 'ui.SocialMediaHandlerComponentView',

    // Object with reference to (popup) windows
    windowReference: {},

    options: {
      width: 500,
      height: 400,
      toolbar: 0,
      menubar: 0,
      location: 0,
      status: 1,
      scrollbars: 1,
      resizable: 1,
      left: 0,
      top: 0,
    },

    events: {
      'click .icon-social:not(.mail)': 'clickEvent',
    },

    render: function () {
      return this;
    },

    /**
     * Stringify object to suit window.open needs
     * @param {Object} obj - object with options
     * @returns {string} obj as string i.e. a=b,c=d
     */
    stringify: function (obj) {
      var string = '';
      for (var name in obj) {
        string += name + '=' + obj[name] + ',';
      }
      return string.replace(/,$/, '');
    },

    /**
     * Focuses old window (popup) if reference found, opens a new popup window otherwise.
     * @param {Event} e - click event
     * @returns {Boolean} false if popup window
     */
    clickEvent: function (e) {
      var $target = $(e.target);
      var options = this.options;
      var href = $target.attr('href');
      var setup = ui.getElementSetup({ el: e.target });

      for (var prop in setup) {
        if (prop in this.options) {
          // Extend this options with data-setup options of the same name
          options[prop] = setup[prop];
        }
      }

      options = this.stringify(options || this.options);

      if (setup.data && setup.data.socialNetwork && href) {
        var networkName = setup.data.socialNetwork;
        if (!this.windowReference[networkName] || this.windowReference[networkName].closed) {
          e.preventDefault();
          this.windowReference[networkName] = window.open(href, networkName, options);
        } else if (this.windowReference[networkName]) {
          e.preventDefault();
          this.windowReference[networkName].focus();
        }
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'SocialMediaHandlerComponent',
    View: ui.SocialMediaHandlerComponentView,
    selector: '.ui-js-social-media-handler',
    reinitialize: true,
  });

  $(ui.bootstrapSocialMediaHandlerComponent());
}).call(this);
