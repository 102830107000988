/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Represents Bootstrap dropdown, adds methods for rendering and state storage
   */
  ui.DropdownComponentView = ui.ComponentView.extend({
    name: 'ui.DropdownComponentView',

    dropDownName: undefined,
    markupState: undefined,
    activeState: undefined,
    filterType: 'dropdown',

    defaults: {
      activeChangeEvent: 'dropdown:change',
    },

    events: {
      'click .ui-js-filter-btn': 'onItemClick',
    },

    initialize: function () {
      this.dropDownName = this.$('.ui-js-filter-btn').data('name');
      this.markupState = this.$('.ui-js-filter-btn.is-active').data('value');
      this.activeState = this.getCurrentState();
      this.$dropdownToggle = this.$('.dropdown-toggle');

      var changeEventName = 'change:' + this.dropDownName;

      // Install model listener for data-name, render view on changes
      this.model.on(
        changeEventName,
        function (model) {
          this.activeState = this.getCurrentState();
          this.render();
        },
        this
      );
    },

    /**
     * Returns current active value of the dropdown, determined either by model or markup
     * @returns {String} value of current active state
     */
    getCurrentState: function () {
      // Checks if the model's key 'dropDownName' contains a value
      var modelState = this.model.get(this.dropDownName);

      if (modelState !== undefined) {
        return modelState;
      } else {
        return this.markupState;
      }
    },

    /**
     * Sets active classes on passed element and removes them elsewhere, labels toggle-btn
     * @param {Object} $itemEl - the jQuery element that holds the filter with name and value
     * @param {String} [title] - the dropdown-toggle-title that should be set
     */
    labelDropdown: function ($itemEl, title) {
      this.$('.ui-js-filter-btn.is-active').removeClass('is-active');
      $itemEl.addClass('is-active');

      // Replace button's text (nodeType 3), keep other tags f.e. spans with icons, add EOL for spacing
      if (title) {
        this.$dropdownToggle.contents().filter(function () {
          return this.nodeType === 3;
        })[0].nodeValue = title + '\n';
      }
    },

    /**
     * Reports change event to parent view, passes input parameters
     * @param {Event} e - click event
     */
    onItemClick: function (e) {
      e.preventDefault();
      if ($(e.target).hasClass('disabled')) {
        return;
      }
      var $eventElement = this.$(e.currentTarget);
      var value = $eventElement.data('value');

      this.$el.trigger(this.setup.activeChangeEvent, {
        type: this.filterType,
        name: this.dropDownName,
        value,
      });
    },

    // Populate this view's dropdown items with current state
    render: function () {
      if (this.activeState !== undefined) {
        var $element = this.$(
          '.ui-js-filter-btn[data-name="' +
            this.dropDownName +
            '"][data-value="' +
            this.activeState +
            '"]'
        );
        var label = $element.data('label');
        this.labelDropdown($element, label);
      }

      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'DropdownComponent',
    View: ui.DropdownComponentView,
    reinitialize: false,
  });
}).call(this);
