/**
 * @author Nils von Rymon-Lipinski
 */

(function () {
  'use strict';

  ui.LoadMoreComponentView = ui.ComponentView.extend({
    name: 'ui.LoadMoreComponentView',

    loadMoreClassSel: '.ui-js-load_more',
    spinnerClassSel: '.ui-js-spinner',
    isHiddenClass: 'is-hidden',

    events: {
      'click .ui-js-load_more': 'loadMore',
    },

    initialize: function (options) {
      this.$button = $(this.loadMoreClassSel, this.$el);
      this.$spinner = $(this.spinnerClassSel, this.$el);

      if (options.loadMoreResultId) {
        this.$results = $('[data-load-more-result-id=' + options.loadMoreResultId + ']', this.$el);
      } else {
        console.warn(
          'No Result Node declared! ... add a loadMoreResultId parameter to data-setup!'
        );
        return false;
      }
      if (options.ajaxUrl) {
        this.ajaxUrl = options.ajaxUrl;
      } else {
        console.warn('No ajaxUrl declared! ... add a ajaxUrl parameter to data-setup!');
        return false;
      }
      if (options.page) {
        this.page = options.page;
      } else {
        console.warn('No page declared! ... add a page parameter to data-setup!');
        return false;
      }
      if (options.offset) {
        this.offset = options.offset;
      } else {
        console.warn('No offset declared! ... add a offset parameter to data-setup!');
        return false;
      }
    },

    loadMore: function (e) {
      e.preventDefault();
      this.$spinner.removeClass(this.isHiddenClass);
      this.getNextItems();
    },

    getNextItems: function () {
      var self = this;

      $.ajax({
        type: 'GET',
        dataType: 'html',
        data: { page: this.page, offset: this.offset },
        url: this.ajaxUrl,
      })
        .done(function (html) {
          self.$results.append(html);
          self.$spinner.addClass(self.isHiddenClass);
          self.incrementPageIndex();
          if (self.$results.find('.ui-js-last-item').length) {
            self.$button.addClass(self.isHiddenClass);
          }
        })
        .fail(function (msg) {
          console.log('load-more Error', msg);
        });
    },

    incrementPageIndex: function () {
      this.page++;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'LoadMoreComponent',
    View: ui.LoadMoreComponentView,
    selector: '.ui-js-load-more',
    reinitialize: false,
  });

  $(ui.bootstrapLoadMoreComponent());
}).call(this);
