(function () {
  function Dev() {}

  Dev.prototype.getParams = window.location.search;

  Dev.prototype.hasParam = function (name) {
    return this.getParams.toLowerCase().indexOf(name) !== -1;
  };

  Dev.prototype.init = function () {
    if (this.hasParam('gridhelper')) {
      this.gridHelper = true;
    }

    if (this.hasParam('breakpointhelper')) {
      this.breakpointHelper = true;
    }

    if (this.hasParam('trackinghelper')) {
      this.trackingHelper = true;
    }

    if (this.hasParam('loginhelper')) {
      this.loginHelper = true;
    }

    if (this.hasParam('msrchelper')) {
      this.msrcHelper = true;
    }

    this.startDevModes();
  };

  Dev.prototype.startDevModes = function () {
    if (this.gridHelper) this.startGridHelper();
    if (this.breakpointHelper) this.startBreakpointHelper();
    if (this.trackingHelper) window.logTracking = true;
    if (this.loginHelper && localStorage.getItem('logLogin') !== 'true')
      localStorage.setItem('logLogin', 'true');
    if (this.msrcHelper && localStorage.getItem('logMsrcWidgets') !== 'true')
      localStorage.setItem('logMsrcWidgets', 'true');
  };

  Dev.prototype.startGridHelper = function () {
    var gridId = 'grid-helper';
    var gridColumnCount = 12;
    var gridColumnTmpl =
      '<div class="col-xs-1" style="height: 100%;">' +
      '<div style="height: 100%; background: #FF0000; opacity: 0.1; ">' +
      '</div>' +
      '</div>';
    var gridColumns = '';
    var i;

    for (i = 0; i < gridColumnCount; i = i + 1) {
      gridColumns = gridColumns + gridColumnTmpl;
    }

    if (!document.getElementById(gridId)) {
      $(function () {
        $('body').append(
          '<div id="' +
            gridId +
            '" ' +
            'style="position: fixed; ' +
            'width: 100%; ' +
            'height: 100%; ' +
            'top: 0; ' +
            'left: 0; ' +
            'z-index: 99999; ' +
            'pointer-events: none; ' +
            'background-repeat: repeat-y; ' +
            'background-attachment: scroll; ' +
            'background-position: center top;"> ' +
            '<div style="position: relative; height: 100%;" class="container">' +
            '<div class="row" style="height: 100%;">' +
            gridColumns +
            '</div>' +
            '</div>' +
            '</div>'
        );
      });
    }

    $(document).keypress(function () {
      $('#' + gridId).toggle();
    });
  };

  Dev.prototype.startBreakpointHelper = function () {
    let mqProvider = document.querySelector('.ui-mediaquery-js-provider');

    if (!mqProvider) {
      mqProvider = document.createElement('div');
      mqProvider.classList.add('ui-mediaquery-js-provider');

      document.body.appendChild(mqProvider);
    }

    mqProvider.textContent = 'bootstrap: ';
    mqProvider.classList.add('is-visible');
  };

  var dev = new Dev();
  dev.init();
})();
