(function () {
  'use strict';

  ui.helpers = ui.helpers || {};

  ui.helpers.unserializeGetParams = function (p) {
    if (!p) {
      return {};
    }

    var ret = {};
    var seg = p.replace(/^\?/, '').split('&');
    var len = seg.length;
    var i = 0;
    var s;
    for (; i < len; i++) {
      if (!seg[i]) {
        continue;
      }
      s = seg[i].split('=');
      ret[s[0]] = s[1];
    }

    return ret;
  };
}).call(this);
