/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Delegates click on background div or p to the first A tag found starting from
   * surrounding parent container (in xs and ms viewports only). Tested in c208.
   */
  ui.ClickExtensionComponentView = ui.ComponentView.extend({
    name: 'ui.ClickExtensionComponentView',

    isMobile: false,

    events: {
      click: 'delegateClick',
    },

    initialize: function () {
      this.viewportListener();

      var viewportListener = this.viewportListener.bind(this);
      ui.on('bootstrap.activemediaquery.changed', viewportListener);
    },

    render: function () {
      return this;
    },

    /**
     * Checks target tagName for P and DIV, delegates click in this case
     * @param {Event} e - click event
     */
    delegateClick: function (e) {
      if (this.isMobile) {
        var $target = $(e.target);

        if ($target.length && /(P|DIV)/.test($target[0].tagName)) {
          var $link = $target.parents('.container').find('a');
          if ($link.length) {
            $link[0].click();
          }
        }
      }
    },

    viewportListener: function () {
      this.isMobile = /(ms|xs)/.test(ui.Bootstrap.activeView);
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ClickExtensionComponent',
    View: ui.ClickExtensionComponentView,
    selector: '.ui-js-click-extension',
  });

  $(ui.bootstrapClickExtensionComponent());
}).call(this);
