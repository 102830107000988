/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Creates an endless (teaser) carousel that can be synced with a preview stage.
   * Supports touch gestures and provides basic accessibility. Only starts with >= 3 slides.
   *
   * Data-setup
   * @param {Number} [step] - amount of steps when used with left/right controls
   * @param {String} [syncCarouselId] - unique id that should match the carousel data-setup's syncIconPrevId
   * @param {Boolean} [autoplay] - slides automatically if true
   * @param {Number} [interval] - amount of seconds for automatically slide
   * @param {Boolean} [sliderBoundaryHidesControls] - if controls should hide if on beginning or end
   */
  ui.PreviewTeaserSliderComponentView = ui.ComponentView.extend({
    name: 'ui.PreviewTeaserSliderComponentView',

    // Transition timeout (mostly for IE9 compatibility)
    TRANSITION_TIMEOUT: 700,
    // Transition-end string for CSS3 transition
    ON_TRANSITION_END: 'transitionend',

    $carouselTrack: null,
    $items: null,
    $clonesL: null,
    $clonesR: null,

    IS_PREV_PREV: 'is-prev-prev',
    IS_PREV: 'is-prev',
    IS_CURRENT: 'is-current',
    IS_NEXT: 'is-next',
    IS_NEXT_NEXT: 'is-next-next',
    IS_NEXT_NEXT_NEXT: 'is-next-next-next',

    // Current pointer position
    position: 0,
    // Zero offset, start position offset considering clones
    zeroOffset: 0,
    // Outer dimension of a slide in current viewport
    outerWidth: 0,
    // Amount of total slides
    amount: 0,
    // Viewports for specific behavior
    isMobile: false,
    isTablet: false,
    isDesktop: true,
    // Variable for interval
    autoplayIVL: null,
    // Whether a slide wasn't tabbed
    TABdebut: true,
    // Whether the slider is currently in movement
    isRotating: false,

    // Defines slide limits in non-loop mode
    maxOffset: 0,
    maxPosition: 0,
    visibleWidth: 0,

    // Swipe gesture variables
    startTransition: '',
    startOffsetX: 0,
    touchPointX: 0,
    touchPointY: 0,
    distanceX: 0,
    distanceY: 0,
    isMoving: false,
    animationClass: 'is-animated',
    slowAnimationClass: 'is-animated-slow',
    flyinAnimationClass: 'is-flyin-animation',

    // Threshold for snapping next slide while swiping (0.75 = 75% visible)
    roundUpLimit: 0.75,
    // Time in ms that the carousel needs to snap into grid
    snapInDuration: 200,
    // Adds a slide to total swipe distance for fast swipes (lower is higher)
    accelerationFactor: 0.4,
    touchStartTime: 0,

    defaults: {
      step: 1,
      loop: true,
      autoplay: false,
      interval: 5,
      dynamicClones: {
        xs: true,
        ms: true,
        sm: true,
        md: true,
        lg: true,
      },
    },

    events: {
      'click .js-slider-item': 'itemSelected',
      'click .left.carousel-control': 'rotateLeft',
      'click .right.carousel-control': 'rotateRight',
      'touchstart .carousel-track': 'touchEvent',
      'touchmove .carousel-track': 'touchEvent',
      'touchend .carousel-track': 'touchEvent',
      keyup: 'keyupEvent',
    },

    initialize: function () {
      var self = this;

      if (!ui.browser.supportsTouch()) {
        this.$el.addClass('no-touch');
      }
      this.$carouselTrack = this.$('.carousel-track');
      this.$leftButton = this.$('.left.carousel-control');
      this.$rightButton = this.$('.right.carousel-control');
      this.$items = this.$('.js-slider-item');
      this.amount = this.$items.length;
      this.$carouselVisibleMask = this.$('.carousel-inner');
      this.maskWidth = this.$carouselVisibleMask.width();
      this.fullWidth = 0;
      this.menuList = !!(this.setup && this.setup.type && this.setup.type === 'menu-list');
      this.brandList = this.setup && this.setup.type && this.setup.type === 'brand-list';
      this.allowMobil = this.setup && this.setup.type && this.setup.type === 'allow-mobil';
      this.dynamicSteps = this.setup && this.setup.dynamicSteps ? this.setup.dynamicSteps : false;
      this.dynamicClones =
        this.setup && this.setup.dynamicClones
          ? this.setup.dynamicClones
          : this.defaults.dynamicClones;
      this.subscribeIds = this.setup && this.setup.type && this.setup.subscribeIds;
      this.$items.each(
        function (i, item) {
          this.fullWidth += $(item).outerWidth(true);
          if ($(item).hasClass('is-active')) {
            this.position = i;
          }
        }.bind(this)
      );

      var resizeFn = _.debounce(
        () => {
          if (!self.menuList && !self.brandList && !self.allowMobil) {
            return;
          }
          self.$carouselVisibleMask.attr('style', '');
          self.$el.css('marginLeft', '');
          const resizeTimeout = setTimeout(function () {
            clearTimeout(resizeTimeout);
            self.render();
          }, 150);
        },
        500,
        true
      );

      $(window).on('resize', resizeFn);

      if (this.menuList && this.brandList) {
        this.$selectedItem = this.$items.filter('.is-selected');
        this.selectedItemIndex =
          this.$selectedItem.length > 0 ? this.$items.index(this.$selectedItem) : 0;
      }

      // substract margin from left and right side, because this is out of bounds
      this.$outerWidthItem = $(this.$items.filter(':not(.is-selected)')[0]);
      this.fullWidth -= this.$outerWidthItem.outerWidth(true) - this.$outerWidthItem.width();

      this.outerWidth = this.$outerWidthItem.outerWidth(true);
      this.inviewClass = this.setup.inviewClass || null;
      this.animationClass = this.setup.step > 2 ? this.slowAnimationClass : this.animationClass;

      // Render and on every bootstrap mediaquery change (item width might have changed)
      var render = this.render.bind(this);
      ui.on('bootstrap.activemediaquery.changed', render);

      this.$el.on('previewTeaserSlider:jumpToSlide', function (event, slideIndex) {
        self.jumpToSlide(slideIndex);
      });

      ui.on(ui.GlobalEvents.FRAGMENT_PLACED, function (e) {
        if (self.$items.find($($(e)[0])).length > 0) {
          self.removeCloneSlides();
        }
      });

      if (!(this.maskWidth >= this.fullWidth) && this.setup.loop) {
        this.cloneSlides();
      }

      // Install auto-rotate if autoplay is true
      if (this.setup.autoplay && this.amount >= 3) {
        this.autoplayIVL = setInterval(function () {
          self.rotateRight(null, true);
        }, this.setup.interval * 1000);
      }

      this.handleControlsForSliderBoundaries();

      // If syncCarouselId provided, install handler for slide syncing with carousel
      if (this.setup.syncCarouselId) {
        ui.on(ui.GlobalEvents.CAROUSEL_SLID, function (data) {
          if (
            data &&
            data.carouselId === self.setup.syncCarouselId &&
            data.position !== undefined
          ) {
            var $slide = self.$items.eq(data.position);
            var index = $slide.data('index');
            if (index !== undefined) {
              self.position = index;
            }
          }
        });
      }
    },

    render: function () {
      var viewport = ui.Bootstrap ? ui.Bootstrap.activeView : '';
      var self = this;
      var totalOffset;
      this.$carouselVisibleMask.attr('style', '');

      // Determine device category on each viewport change
      this.isMobile = /xs|ms/.test(viewport);
      this.isTablet = /sm/.test(viewport);
      this.isDesktop = /md|lg/.test(viewport);
      this.$el.removeAttr('style');
      this.$items.first().removeAttr('style');
      this.brandListOffset = this.$el.offset().left;

      if ((this.isTablet || this.isMobile) && this.brandList) {
        var brandListWidth =
          this.brandListOffset > 8 ? window.screen.width - 8 : window.screen.width;
        this.brandListOffset =
          this.brandListOffset > 8 ? this.$el.offset().left - 4 : this.$el.offset().left;
        this.$items.first().css({
          marginLeft: this.brandListOffset + 'px',
        });

        this.$el.css({
          width: brandListWidth + 'px',
          marginLeft: -this.brandListOffset + 'px',
        });
      } else {
        this.brandListOffset = 0;
        this.$el.removeAttr('style');
        this.$items.first().removeAttr('style');
      }

      this.position = this.convertToAbsolute(this.position);
      if (this.$outerWidthItem && this.$outerWidthItem[0] !== undefined) {
        this.outerWidth =
          this.$outerWidthItem.outerWidth(true) -
          this.$outerWidthItem.outerWidth() +
          this.$outerWidthItem[0].getBoundingClientRect().width;
      } else {
        this.outerWidth = this.$outerWidthItem.outerHeight(true);
      }
      this.fullWidth = 0;
      this.snapRight = this.setup.snapRight || false;

      this.$items.each(
        function (i, item) {
          this.fullWidth += $(item).outerWidth(true);
        }.bind(this)
      );

      // substract margin from left and right side, because this is out of bounds
      this.fullWidth -= this.$outerWidthItem.outerWidth(true) - this.$outerWidthItem.width();
      this.maskWidth = this.$carouselVisibleMask.outerWidth();
      this.visibleWidth = this.$('.carousel-inner').outerWidth();
      this.maxItems = Math.trunc(this.maskWidth / this.outerWidth);

      if (!this.setup.loop && !this.menuList) {
        this.setNormalVariables();
      }

      if ((this.isDesktop || this.isTablet) && this.menuList) {
        if (this.isDesktop && this.brandList) {
          return;
        }
        this.setMenuListSliderSettings();
      }

      if (this.maskWidth >= this.fullWidth) {
        this.hideArrowNavigation();
      } else {
        this.showArrowNavigation();
      }

      if (this.allowMobil || this.brandList || this.isDesktop || this.isTablet) {
        if (this.brandList && this.isDesktop) {
          // Viewport is desktop and type brandlist, avoid sliding
          this.$carouselTrack.attr('style', '');
          return;
        }
        this.$carouselTrack.removeClass(this.animationClass);
        this.zeroOffset = this.outerWidth * this.$('.clone.clone-l').length;

        // The total offset onrender is el-width * position + start offset (zero offset)
        totalOffset = this.outerWidth * this.position + this.zeroOffset;

        this.setOffset(totalOffset);

        setTimeout(function () {
          // Re-enable animation after changing offset
          self.$carouselTrack.addClass(self.animationClass);
          self.$el.removeClass(self.flyinAnimationClass);
        }, 0);
      } else {
        // Viewport is <sm, no sliding defined here
        this.$carouselTrack.attr('style', '');
      }

      if (this.menuList) {
        this.switchMenuListCases();
      }

      this.handleControlsForSliderBoundaries();

      return this;
    },

    setNormalVariables: function () {
      this.visibleWidth = this.$('.carousel-inner').outerWidth();
      if (this.brandList && this.isTablet && this.brandListOffset) {
        this.visibleWidth -= this.brandListOffset;
      }
      this.maxPosition = this.amount - Math.ceil(this.visibleWidth / this.outerWidth);
      this.maxOffset = -Math.ceil(this.amount * this.outerWidth - this.visibleWidth);

      if (this.brandList) {
        this.maxPosition = this.amount - Math.trunc(this.maskWidth / this.outerWidth);
        this.maxOffset = -(this.amount * this.outerWidth + this.brandListOffset - this.maskWidth);
      }
    },

    switchMenuListCases: function () {
      if (this.isMobile) {
        this.$el.removeClass(this.setup.toggleClass);
        this.$carouselTrack.attr('style', '');
        this.$el.attr('style', '');
      } else if (
        (this.isDesktop || this.isTablet) &&
        this.outerWidth * (this.amount - 1) < this.maskWidth - this.outerWidth
      ) {
        this.$el.removeClass(this.setup.toggleClass);
        this.$carouselTrack.attr('style', '');
        this.$carouselVisibleMask.attr('style', '');
        this.$el.attr('style', '');
      } else if (this.isDesktop || this.isTablet) {
        this.$el.addClass(this.setup.toggleClass);
        if (this.$carouselVisibleMask.find('.ui-menu-list-item.is-back').length > 0) {
          if (($(this.$items[0]).outerWidth(true), this.$el.offset().left)) {
            var tempMarginLeft = $(this.$items[0]).outerWidth(true) + 22 - this.$el.offset().left;
            if (tempMarginLeft > 0) {
              this.$el.css(
                'marginLeft',
                $(this.$items[0]).outerWidth(true) + 22 - this.$el.offset().left + 'px'
              );
            } else {
              this.$el.css('marginLeft', '');
            }
          }
        }
        var $selectedItem = this.$items.filter('.is-selected');
        var selectedItemIndex = this.$items.index($selectedItem);
        this.jumpToSlide(selectedItemIndex);
        this.handleControlsForSliderBoundaries();
      }
    },

    setMenuListSliderSettings: function () {
      if (
        this.maxItems * this.outerWidth - 10 < this.maskWidth ||
        this.maxItems * this.outerWidth + 10 < this.maskWidth
      ) {
        if (this.$carouselVisibleMask.find('.ui-menu-list-item.is-back').length > 0) {
          if (document.body.clientWidth - this.maskWidth < this.outerWidth * 1.5) {
            this.maxPosition = this.amount - Math.trunc(this.maskWidth / this.outerWidth) + 1;
            this.maxItems = this.maxItems - 1;
            this.maskWidth = this.maxItems * this.outerWidth + 10;
            this.maxOffset = -(this.amount * this.outerWidth - this.maskWidth) - 10;
          } else {
            this.maxPosition = this.amount - Math.trunc(this.maskWidth / this.outerWidth);
            this.maskWidth = this.maxItems * this.outerWidth + 10;
            this.maxOffset = -(this.amount * this.outerWidth - this.maskWidth) - 10;
          }
        } else {
          this.maxPosition = this.amount - Math.trunc(this.maskWidth / this.outerWidth);
          this.maskWidth = this.maxItems * this.outerWidth + 10;
          this.maxOffset = -(this.amount * this.outerWidth - this.maskWidth) - 10;
        }
      }
    },

    debounce: function (func, wait, immediate) {
      var timeout;
      return function () {
        var context = this;
        var args = arguments;
        var later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },

    /**
     * Set selected SlideItem in Viewport, tested for setup.steps between 3 and 5
     * @param {Number} slideIndex - slideIndex
     */
    //
    jumpToSlide: function (slideIndex) {
      var itemsMiddle = Math.ceil(Math.floor(this.maskWidth / $(this.$items[0]).width()) / 2);
      var itemsLength = this.$items.length;
      var lastItem = itemsLength - itemsMiddle;
      var newPos = slideIndex + 1;
      var jumpStep = 0;

      if (newPos <= itemsMiddle) {
        newPos = itemsMiddle;
        jumpStep = newPos - (this.position + itemsMiddle);
      } else if (newPos >= lastItem) {
        jumpStep = this.maxPosition - this.position;
      } else {
        jumpStep = newPos - (this.position + itemsMiddle);
      }

      this.rotate(jumpStep, false, false, true);
    },

    hideArrowNavigation: function () {
      this.hideLeftArrow();
      this.hideRightArrow();
    },

    hideLeftArrow: function () {
      if (this.menuList || this.brandList) {
        this.$leftButton.addClass('is-hide');
      } else {
        this.$leftButton.css('display', 'none');
      }
    },

    hideRightArrow: function () {
      if (this.menuList || this.brandList) {
        this.$rightButton.addClass('is-hide');
      } else {
        this.$rightButton.css('display', 'none');
      }
    },

    showArrowNavigation: function () {
      this.showLeftArrow();
      this.showRightArrow();
    },

    showLeftArrow: function () {
      if (this.menuList || this.brandList) {
        this.$leftButton.removeClass('is-hide');
      } else {
        this.$leftButton.css('display', 'block');
      }
    },

    showRightArrow: function () {
      if (this.menuList || this.brandList) {
        this.$rightButton.removeClass('is-hide');
      } else {
        this.$rightButton.css('display', 'block');
      }
    },

    handleControlsForSliderBoundaries: function () {
      if (
        !this.setup.sliderBoundaryHidesControls ||
        this.setup.loop ||
        isNaN(this.position) ||
        isNaN(this.maxPosition)
      ) {
        return;
      }

      if (this.maxPosition > 0 && this.position === this.maxPosition) {
        this.hideRightArrow();
      } else if (this.position === 0) {
        this.hideLeftArrow();
      } else {
        this.showLeftArrow();
        this.showRightArrow();
      }

      if (this.snapRight && this.position === this.maxPosition && this.position > 0) {
        this.showLeftArrow();
      }

      if (this.snapRight && this.position < this.maxPosition) {
        this.showRightArrow();
      }
    },

    checkSize: function () {
      if (this.menuList) {
        if (
          this.position === this.maxPosition &&
          this.selectedItemIndex === this.$items.length - 1
        ) {
          this.maskWidth = this.$carouselVisibleMask
            .width(this.maxItems * this.outerWidth + 10 + 20 + 'px')
            .width();
        } else if (this.position === 0 && this.selectedItemIndex === 0) {
          this.maskWidth = this.$carouselVisibleMask
            .width(this.maxItems * this.outerWidth + 10 + 20 + 'px')
            .width();
        } else {
          this.maskWidth = this.$carouselVisibleMask
            .width(this.maxItems * this.outerWidth + 10 + 'px')
            .width();
        }
        if (
          this.$selectedItem &&
          this.selectedItemIndex - this.position >= this.maxItems - 1 &&
          this.position !== this.maxPosition
        ) {
          this.$selectedItem.addClass('is-minimized');
        } else if (this.$selectedItem) {
          this.$selectedItem.removeClass('is-minimized');
        }
      }
    },

    /**
     * Initializes rotation logic, determines position and rotation offset
     * @param {Number} steps - if positive, rotate left by amount of steps, otherwise right
     * @param {Boolean} forceRender - whether to call render without waiting for transitionEnd
     * @param {Boolean} isMirroringCarousel - whether this click is meant for another carousel (workaround)
     * for preview slider which has click on items which are mirrored to another carousel
     * --> if preview slider items/thumbs click elements are not enough to be a carousel case
     */
    rotate: function (steps, forceRender, isMirroringCarousel, snapRight) {
      if (snapRight) {
        this.snapRight = true;
      }

      var hitLeftBoundary =
        !this.setup.loop && !isNaN(this.position) && this.position === 0 && steps < 0;
      var hitRightBoundary =
        !this.setup.loop &&
        !isNaN(this.position) &&
        !isNaN(this.maxPosition) &&
        this.maxPosition > 0 &&
        this.position === this.maxPosition &&
        steps > 0;

      this.checkSize();

      if (
        !isMirroringCarousel &&
        ((this.isMobile && !this.brandList && !this.allowMobil) ||
          this.maskWidth >= this.fullWidth ||
          hitLeftBoundary ||
          hitRightBoundary)
      ) {
        // No slide functionality defined on mobile viewports
        return;
      }

      var currentOffset = this.outerWidth * this.position;
      var transitionOffset = this.outerWidth * steps;
      var totalOffset = transitionOffset + currentOffset + this.zeroOffset;
      var newPosition = this.position + steps;

      ui.trigger(
        ui.GlobalEvents.ICON_PREVIEW_TEASER_SLID,
        this.convertToAbsolute(newPosition),
        this.setup.syncCarouselId
      );

      this.position = newPosition;

      // if preview slider items/thumbs click elements are not enough to be a carousel case
      if (this.maskWidth >= this.fullWidth || hitLeftBoundary || hitRightBoundary) {
        this.$items.removeClass('is-active').eq(newPosition).addClass('is-active');
        return;
      }

      this.setOffset(totalOffset);
      this.handleControlsForSliderBoundaries();

      // Left (pos >= amount) or right limit (pos <= -amount - steps) was reached, render
      if (
        (this.position >= this.amount || this.position <= -(this.amount - 1) - steps) &&
        !this.menuList
      ) {
        this.position = this.position % this.amount;

        if (forceRender || !this.dynamicClones[ui.Bootstrap.activeView]) {
          this.render();
        } else {
          var render = this.render.bind(this);
          this.callAfterTransition(render, true, 'transform');
        }
      }
    },

    /**
     * Applies offset, sets width and marks current slide as active, results in animation
     * @param {Number} offset - left offset in pixels
     */
    setOffset: function (offset) {
      if (this.snapRight) {
        if (this.position > 0) {
          if (offset - -this.maxOffset > -$(this.$items[0]).width()) {
            offset = -this.maxOffset;
            this.position = this.maxPosition;
          }
        }
        if (this.position === 0) {
          this.hideLeftArrow();
        }
      }

      this.checkSize();

      // translateX: total offset, width: total track width * 3 (add left/right clones)
      if (this.brandList && !this.isDesktop) {
        this.$carouselTrack.addClass(this.animationClass);
      }

      this.$carouselTrack.attr('style', '').css({
        width: this.amount * this.outerWidth * 3,
        transform: 'translateX(' + -offset + 'px)',
      });

      // Add 'is-active' class to l/r clones and items
      this.$items.removeClass('is-active').eq(this.position).addClass('is-active');
      this.updatePrevNextItems(
        this.$items.index(this.$items.filter('.is-active')),
        this.setup.loop,
        this.$items.length
      );

      // Add 'is-active' class to l/r clones and items if existent
      if (this.$clonesL && this.$clonesR && this.$clonesL.length) {
        this.$clonesL.removeClass('is-active').eq(this.position).addClass('is-active');
        this.$clonesR.removeClass('is-active').eq(this.position).addClass('is-active');
      }
    },

    updatePrevNextItems: function (position, loop, itemsLength) {
      position = position < 0 ? 0 : position;
      var prevprev =
        position === 0 ? itemsLength - 2 : position === 1 ? itemsLength - 2 : position - 2;
      var prev = position === 0 ? itemsLength - 1 : position - 1;
      var next = position === itemsLength - 1 ? 0 : position + 1;
      var nextnext =
        position === itemsLength - 1 ? 1 : position === itemsLength - 2 ? 0 : position + 2;
      var nextnextnext =
        position === itemsLength - 1
          ? 2
          : position === itemsLength - 2
          ? 1
          : position === itemsLength - 3
          ? 0
          : position + 3;

      this.$items.removeClass(this.IS_PREV_PREV).eq(prevprev).addClass(this.IS_PREV_PREV);
      this.$items.removeClass(this.IS_PREV).eq(prev).addClass(this.IS_PREV);
      this.$items.removeClass(this.IS_CURRENT).eq(position).addClass(this.IS_CURRENT);
      this.$items.removeClass(this.IS_NEXT).eq(next).addClass(this.IS_NEXT);
      this.$items.removeClass(this.IS_NEXT_NEXT).eq(nextnext).addClass(this.IS_NEXT_NEXT);
      this.$items
        .removeClass(this.IS_NEXT_NEXT_NEXT)
        .eq(nextnextnext)
        .addClass(this.IS_NEXT_NEXT_NEXT);
    },

    removeCloneSlides: function () {
      this.$clonesL.remove();
      this.$clonesR.remove();
      if (!(this.maskWidth >= this.fullWidth) && this.setup.loop) {
        this.cloneSlides();
      }
    },

    /**
     * Clones slides to both left and right sides to create an endless carousel
     */
    cloneSlides: function () {
      // Create deep clones (maximal 6 to each side)
      this.$clonesL = this.$items.filter(':gt(-6)').clone(true, true).addClass('clone clone-l');
      this.$clonesR = this.$items.filter(':lt(6)').clone(true, true).addClass('clone clone-r');

      // Remove tabable content in clones and append them to the left and right
      this.$clonesL.add(this.$clonesR).find('a, :input').attr('tabindex', -1);
      this.$carouselTrack.prepend(this.$clonesL).append(this.$clonesR);
    },

    /**
     * Reacts on item selection (click), calculates amount of steps to selected slide
     * @param {Event} e - click event
     */
    itemSelected: function (e) {
      e = this.syncEvent(e, '.js-slider-item');
      var $target = $(e.currentTarget);
      var index = $target.data('index');

      if (index !== undefined) {
        var distance;
        var absolutePosition = this.convertToAbsolute(this.position);

        e.preventDefault();

        switch (true) {
          case index > absolutePosition:
            // Desired slide is right of current slide
            distance = Math.abs(absolutePosition - index);
            this.triggerTrackingEvent('right', index);
            break;
          case index < absolutePosition:
            // Desired slide is left of current slide
            distance = this.amount - absolutePosition + index;
            this.triggerTrackingEvent('left', index);
            break;
          default:
            // Same position, do nothing
            break;
        }

        if (distance) {
          this.rotate(distance, false, true);
        } else if (this.maskWidth >= this.fullWidth) {
          // if preview slider items/thumbs click elements are not enough to be a carousel case
          this.rotate(0, false, true);
        }
      }
    },

    syncEvent: function (e, targetSel) {
      var isSyncing = false;
      if (this.subscribeIds && e.syncEvent) {
        e = e.syncEvent;
        isSyncing = true;
      }
      if (this.subscribeIds && this.subscribeIds.length > 0 && !isSyncing) {
        if (this.subscribeIds.length === 1) {
          var $subscriber = $('#' + this.subscribeIds[0]);
          var $subscriberTarget = targetSel === null ? $subscriber : $subscriber.find(targetSel);
          $subscriberTarget.trigger({
            type: e.type,
            syncEvent: e,
          });
        }
      }
      return e;
    },

    /**
     * Performs touch recognition and applies translateX on carousel track
     * @param e
     */
    touchEvent: function (e) {
      e = this.syncEvent(e, '.carousel-track');
      var event = null;
      if (
        this.$items.length === this.setup.step &&
        this.setup.step === this.dynamicSteps[ui.Bootstrap.activeView]
      ) {
        return false;
      }

      if (e.originalEvent && e.originalEvent.changedTouches && this.amount >= 3) {
        if (this.brandList && this.isDesktop) {
          return;
        }
        if (this.brandList || this.allowMobil || !this.isMobile) {
          event = e.originalEvent.changedTouches[0];
        } else {
          return;
        }
      } else {
        return;
      }

      switch (e.type) {
        case 'touchstart':
          this.touchPointX = event.pageX;
          this.touchPointY = event.pageY;
          this.startOffsetX = this.outerWidth * this.position + this.zeroOffset;
          this.touchStartTime = new Date().getTime();
          this.startTransition = this.$carouselTrack.attr('style');
          clearInterval(this.autoplayIVL);
          break;

        case 'touchmove':
          if (this.touchPointX) {
            // Only translate the track if a touchstart has been recognized before
            this.distanceX = this.touchPointX - event.pageX;
            this.distanceY = this.touchPointY - event.pageY;

            if (Math.abs(this.distanceX) > Math.abs(this.distanceY)) {
              var offset = -this.distanceX - this.startOffsetX;
              e.preventDefault();

              // For non-loop sliders, prevent movement beyond bounds
              if (this.maxOffset && (offset > 20 || offset + 20 < this.maxOffset)) {
                break;
              }

              this.$carouselTrack.css({
                transform: 'translateX(' + offset + 'px)',
                transitionDuration: '0ms',
              });
              this.isMoving = true;
            } else {
              // Cancel swipe if distance y > distance x
              this.touchPointX = 0;
            }
          }
          break;

        case 'touchend':
          if (this.isMoving) {
            var ratio = this.distanceX / this.outerWidth;
            var distanceInSteps = this.round(ratio);
            var time = new Date().getTime() - this.touchStartTime;
            var speed = time / Math.abs(this.distanceX);

            if (distanceInSteps !== 0) {
              if (speed < this.accelerationFactor) {
                // The swipe was fast, add an item to total distance
                distanceInSteps = distanceInSteps + 1 * Math.sign(distanceInSteps);
              }

              // Standard case, the carousel is endless (loop)
              var tmpOffset = -distanceInSteps * this.outerWidth - this.startOffsetX;

              // Following else-if is for non-loop sliders
              if (this.maxOffset && tmpOffset > 20) {
                // Offset is beyond left bound, reset it
                tmpOffset = 0;
                distanceInSteps = -this.position;
              } else if (this.maxOffset && tmpOffset < this.maxOffset) {
                // Offset is beyond right bound, limit it
                tmpOffset = this.maxOffset;
                distanceInSteps = Math.abs(this.position - this.maxPosition);
              }

              // There is a distance, reset style attribute and apply temporary translate
              this.$carouselTrack.attr('style', this.startTransition).css({
                transitionDuration: this.snapInDuration + 'ms',
                transform: 'translateX(' + tmpOffset + 'px)',
              });
              // Set parameters for rotate to touch-distance and forceRender true
              var rotate = this.rotate.bind(this, distanceInSteps, true);

              this.callAfterTransition(rotate, false, 'transform');
            } else {
              // Carousel did not travel far enough, reset to initial transition state
              this.$carouselTrack.attr('style', this.startTransition);

              if (
                !this.dynamicClones[ui.Bootstrap.activeView] &&
                (ratio === Infinity || ratio === -Infinity)
              ) {
                var moveSteps =
                  ratio === -Infinity
                    ? -this.dynamicSteps[ui.Bootstrap.activeView]
                    : this.dynamicSteps[ui.Bootstrap.activeView];
                this.rotate(moveSteps, true);
              }
            }

            this.touchPointX = 0;
            this.touchPointY = 0;
            this.isMoving = false;
          }
          break;
      }
    },

    /**
     * Installs both a transitionEnd listener and a backwards-compatible timeout. Executes callback
     * after the transition property has been captured
     * @param {Function} callback - the function to be executed after the transition
     * @param {Boolean} animate - whether to keep animation while executing the callback
     * @param {String} propertyName - propertyName, i.e. 'left', 'transition'
     */
    callAfterTransition: function (callback, animate, propertyName) {
      var self = this;
      // Setting flag prevents sliding while rendering
      this.isRotating = true;
      // Transition timeout for IE9 support
      var transitionEnd = setTimeout(function () {
        callback();
        self.isRotating = false;
        self.$carouselTrack.off(self.ON_TRANSITION_END);
      }, this.TRANSITION_TIMEOUT);

      this.$carouselTrack.on(this.ON_TRANSITION_END, function (e) {
        // Only listen to property name
        if (e.originalEvent && e.originalEvent.propertyName !== propertyName) {
          return;
        }
        // Only listen on carousel-track
        if (e.target.className && !/carousel-track/gi.test(e.target.className)) {
          return;
        }
        if (!animate) {
          self.$carouselTrack.removeClass(self.animationClass);
        }
        self.$carouselTrack.off(self.ON_TRANSITION_END);
        clearTimeout(transitionEnd);

        // Call the callback function
        callback();

        self.isRotating = false;
        if (!animate) {
          setTimeout(function () {
            // Re-enable animation after changing offset (invisibly)
            self.$carouselTrack.addClass(self.animationClass);
          }, 0);
        }
      });
    },

    /**
     * Accessibility features: left/right-arrow (keyboard) navigation
     * @param {Event} e - key event
     */
    keyupEvent: function (e) {
      e = this.syncEvent(e, null);
      switch (e.keyCode) {
        // Right arrow
        case 39:
          this.rotate(1);
          this.gainFocus(this.position);
          this.triggerTrackingEvent('right');
          break;
        // Left arrow
        case 37:
          this.rotate(-1);
          this.gainFocus(this.position);
          this.triggerTrackingEvent('left');
          break;
        // TAB
        case 9:
          if (this.TABdebut && !e.shiftKey) {
            // The first item was tabbed, reset position to zero
            this.position = 0;
            this.render();
            this.TABdebut = false;
          }
      }
      clearInterval(this.autoplayIVL);
    },

    /**
     * Accessibility feature for giving focus to item via position number
     * @param {Number} position
     */
    gainFocus: function (position) {
      var $item = this.$items.eq(position).find('a');
      if ($item.length) {
        $item.focus();
      }
    },

    rotateLeft: function (e) {
      e = this.syncEvent(e, '.left.carousel-control');
      var steps;
      if (this.maxPosition && this.position - this.setup.step < 0) {
        // Calculate step amount for rotation for non-loop mode
        steps = -this.position;
      } else {
        // And for loop mode unless mobile
        steps = this.isTablet && this.setup.step > 3 ? -this.setup.step + 1 : -this.setup.step;
      }

      if (this.dynamicSteps !== false) {
        steps = -this.dynamicSteps[ui.Bootstrap.activeView];
      }

      if (this.isRotating) {
        e.preventDefault();
        return;
      }
      if (e) {
        e.preventDefault();
        clearInterval(this.autoplayIVL);
        this.triggerTrackingEvent('left');
      }
      this.rotate(steps, false);
      this.TABdebut = true;
    },

    /**
     * @param {Event} e
     * @param {Boolean} isAutoPlay - true if triggered by autoplay
     */
    rotateRight: function (e, isAutoPlay) {
      e = this.syncEvent(e, '.right.carousel-control');
      var steps;

      if (this.isRotating) {
        e.preventDefault();
        return;
      }
      if (isAutoPlay && !this.$el.hasClass(this.inviewClass)) {
        return;
      }

      // Calculate step amount for rotation for loop and non-loop modes
      if (this.maxPosition && this.position + this.setup.step > this.maxPosition) {
        steps = Math.abs(this.position - this.maxPosition);
      } else {
        steps = this.isTablet && this.setup.step > 3 ? this.setup.step - 1 : this.setup.step;
      }

      if (this.dynamicSteps !== false) {
        steps = this.dynamicSteps[ui.Bootstrap.activeView];
      }

      if (e) {
        e.preventDefault();
        clearInterval(this.autoplayIVL);
        this.triggerTrackingEvent('right');
      }

      if (isAutoPlay) {
        this.$el.trigger('carousel:slid-auto', {
          direction: 'right',
          currentTarget: this.el,
        });
      }

      this.rotate(steps, false);
      this.TABdebut = true;
    },

    /**
     * Make event available for tracking.js lib to hook in
     * @param {String} direction - rotation direction ('left', 'right')
     */
    triggerTrackingEvent: function (direction, index) {
      this.$el.trigger('carousel:slid-manually', {
        direction,
        currentTarget: this.el,
        nextIndex: index,
      });
    },

    /**
     * Converts slider position in positive absolute number
     * @param {Number} position - slider position
     * @returns {Number} absolute position, i. e. -2 of total 5 becomes 3
     */
    convertToAbsolute: function (position) {
      return position >= 0 ? position % this.amount : (this.amount + position) % this.amount;
    },

    /**
     * Custom round function, uses bitwise OR (|)
     * @param {Number} n - float
     * @returns {Number} - rounded up if roundUpLimit reached
     */
    round: function (n) {
      if (n > 0) return (n + this.roundUpLimit) | 0;
      else {
        n = -n;
        return -(n + this.roundUpLimit) | 0;
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'PreviewTeaserSliderComponent',
    View: ui.PreviewTeaserSliderComponentView,
    selector: '.ui-js-bk-12-iconpreview-cr, .ui-js-preview-teaser, .ui-js-productcategory-slider',
  });

  $(ui.bootstrapPreviewTeaserSliderComponent());
}).call(this);
