/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Upgrades Bootstrap's tab navigation to support deep-linking based on anchors.
   * If an anchor is recognized, the corresponding tabs's body is shown.
   */
  ui.NavTabComponentView = ui.ComponentView.extend({
    name: 'ui.NavTabComponentView',

    // Checks for History support, uses window.location.hash if replaceState is not supported
    state: !!(window.history && window.history.replaceState) || false,

    events: {
      'click a[data-toggle="tab"]': 'clickOnTab',
    },

    initialize: function () {
      this.isAjaxContentTab = this.$el.hasClass('js-ajax-tab-content');
      this.$tabItems = this.$el.find('.nav-item');
      this.trackFiltersAfterCall = false;
      ui.on(ui.GlobalEvents.TAB_FILTER_CHANGE, this.tabFilterLoadAjax.bind(this));
      ui.on(ui.GlobalEvents.TAB_CHANGE, this.triggerTab.bind(this));
      ui.on(ui.GlobalEvents.ASYNC_BTN_STATE, ({ $el }) => this.tabFilterLoadAjax($el));
    },

    render: function () {
      let $target = this.$('a[href="' + window.location.hash + '"]');

      // handle a direct target
      if ($target.length > 0) {
        // Show tab via Bootstrap JavaScript API
        $target.tab('show');
        this.pushToDataLayer($target.parent());

        if (this.isAjaxContentTab) {
          this.trackFiltersAfterCall = true;
          this.setHashInUrl($target);
          this.tabLoadAjax($target);
        }
      }

      // if there isn't a target then check if there is a hash
      if (this.isAjaxContentTab && $target.length === 0) {
        this.trackFiltersAfterCall = true;

        if (window.location.hash === '') {
          $target = this.$tabItems.first().children('a');

          $target.tab('show');
          this.pushToDataLayer($target.parent());
          this.setHashInUrl($target);
          this.tabLoadAjax($target);
        } else {
          this.initFromHash();
        }
      }

      return this;
    },

    initFromHash: function () {
      const hashArray = window.location.hash ? window.location.hash.split('--') : null;

      if (!hashArray) return;

      const $targetPanel = $(hashArray[0]);
      const $target = this.$('a[href="' + hashArray[0] + '"]');

      if ($target.length === 0) return;

      // Show tab via Bootstrap JavaScript API
      $target.tab('show');
      this.pushToDataLayer($target.parent());
      this.tabFilterLoadAjax($targetPanel);
    },

    // Trigger deeplinking and ajax call
    clickOnTab: function (e) {
      e.preventDefault();

      const $target = $(e.currentTarget);

      // only update og:title for guide component
      if (this.$el.closest('[data-js-item="ui-js-guide-nav"]').length > 0) {
        $("[property='og:title']").attr('content', $target.text().trim());
      }

      if ($target.closest('.nav-item').hasClass('active')) return;

      this.setHashInUrl($target);

      if (this.isAjaxContentTab) {
        this.trackFiltersAfterCall = true;
        this.tabLoadAjax($target);
      }
    },

    triggerTab: function (hash) {
      const $target = this.$el.find(`[href="${hash}"]`);

      if ($target) $target.click();
    },

    // Executed when user clicks on a nav-tab element, sets history or location.hash attribute
    setHashInUrl: function ($target) {
      const $a = $target.closest('a');
      const hash = $a[0] && $a[0].hash;

      if (hash) {
        if (this.state) {
          window.history.replaceState({}, '', hash);
        } else {
          window.location.hash = hash;
        }
      }
    },

    createUrl: function (ajaxUrl = '') {
      const hashArray = window.location.hash ? window.location.hash.split('--') : null;
      let url = ajaxUrl;

      // if there are 2 or more
      if (hashArray.length > 1) {
        url = hashArray.reduce((str, hashPart) => {
          // first hashPart(hashPart with #)
          let replaceHash = hashPart;

          // category is managed over the url in setup
          if (hashPart && hashPart.indexOf('#') !== -1) return str;

          // add filtering & sorting to url
          const filter = hashPart.split('-');

          if (filter.length > 1) {
            replaceHash =
              str.indexOf('?') === -1 ? `?${filter[0]}=${filter[1]}` : `&${filter[0]}=${filter[1]}`;
          }

          return (str += replaceHash);
        }, url);
      }

      return url;
    },

    pushToDataLayer: function ($activeTab) {
      if (!window.dataLayer) return;

      const activeTabTrackingData = ui.getElementSetup({
        el: $activeTab,
      }).data;
      window.dataLayer.push(activeTabTrackingData);

      if (window.logTracking) {
        console.info('added dataLayer data:', JSON.stringify(activeTabTrackingData));
        console.info('Tracking Obj', activeTabTrackingData);
      }
    },

    tabFilterLoadAjax: function ($target) {
      const $currentTabPanel = $target.closest('.tab-pane');
      const id = $currentTabPanel.attr('id');
      const $currentTab = this.$el.find(`[href="#${id}"]`);

      const $ajaxLoaderContainer = $currentTabPanel.find('.ui-js-tab-content-loader');

      const setup = ui.getElementSetup({
        el: $currentTab.closest('.nav-item'),
      });

      const url = this.createUrl(setup.ajaxUrl);

      this.ajaxRequest({
        url,
        $container: $ajaxLoaderContainer,
        $currentTab,
        scrollIntoView: false,
      });
    },

    tabLoadAjax: function ($target) {
      const $tabPanel = $target.closest('.nav-item');
      const setup = ui.getElementSetup({ el: $tabPanel });
      const $ajaxLoaderContainer = $($target.attr('href')).find('.ui-js-tab-content-loader');

      this.ajaxRequest({
        url: setup.ajaxUrl,
        $container: $ajaxLoaderContainer,
        $currentTab: $target,
        scrollIntoView: true,
      });
    },

    ajaxRequest({ url, $container, $currentTab, scrollIntoView }) {
      if (!$container || !url || !$currentTab) return;
      $.ajax({
        type: 'GET',
        dataType: 'html',
        url,
      })
        .done((data) => {
          // remove events before removing DOM
          ui.bootstrapper.defuse({
            context: $container.get(0),
          });

          $container.html(data);
          this.setAmountNumbers($container);

          if (this.trackFiltersAfterCall) {
            ui.trigger(ui.GlobalEvents.TAB_FILTER_TRACKING, $($currentTab.attr('href')));
            this.trackFiltersAfterCall = false;
          }

          ui.bootstrapper.reinitialize({
            context: $container.get(0),
          });

          if (scrollIntoView) this.scrollTabIntoView($currentTab.parent());
        })
        .fail((error) => console.log(error));
    },

    setAmountNumbers: function ($container) {
      const teaserNumbers = $container.find('[data-teaser-numbers]').data('teaser-numbers');

      if (!teaserNumbers) return;

      Object.keys(teaserNumbers).forEach((id) => {
        const $amount = this.$el.find(`[href="#${id}"] .ui-js-teasers-amount`);
        $amount.text(`(${teaserNumbers[id].toString()})`);
      });
    },

    scrollTabIntoView: function ($activeTab) {
      const windowWidth = $(window).width();
      const offsetActiveTab = $activeTab.offset().left;
      const offsetActiveTabRelativeToList = offsetActiveTab - this.$el.offset().left;
      const tabWidth = $activeTab.width();
      const menuScrollPosition = this.$el.scrollLeft();

      const isMobile = /xs|ms/.test(ui.Bootstrap.activeView);
      const offsetTop = isMobile ? 150 : 210;

      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $activeTab.offset().top - offsetTop,
          },
          250
        );

      if (offsetActiveTab < 0) {
        const isFirst = $activeTab.is(':first-of-type');
        const spacingLeft = isFirst ? 20 : 10;
        this.$el.scrollLeft(menuScrollPosition + offsetActiveTabRelativeToList - spacingLeft);
      } else if (offsetActiveTabRelativeToList + tabWidth > windowWidth) {
        const isLast = $activeTab.is(':last-of-type');
        const spacingRight = isLast ? 20 : 10;
        this.$el.scrollLeft(
          tabWidth + spacingRight + menuScrollPosition - (windowWidth - offsetActiveTab)
        );
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'NavTabComponent',
    View: ui.NavTabComponentView,
    selector: '.ui-js-nav-tab-listener',
  });

  $(ui.bootstrapNavTabComponent());
}).call(this);
