/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Represents Checkbox, contains methods for rendering and state storage
   */
  ui.CheckboxComponentView = ui.ComponentView.extend({
    name: 'ui.CheckboxComponentView',

    checkboxName: undefined,
    activeState: undefined,
    filterType: 'checkbox',

    defaults: {
      activeChangeEvent: 'checkbox:change',
    },

    events: {
      click: 'onItemClick',
    },

    initialize: function () {
      this.checkboxName = this.$el.attr('name');
      this.activeState = this.getCurrentState();

      var changeEventName = 'change:' + this.checkboxName;

      // Install model listener for data-name, render view on changes
      this.model.on(
        changeEventName,
        function (model) {
          this.activeState = this.getCurrentState();
          this.render();
        },
        this
      );
    },

    /**
     * Returns current active value of the checkbox, if the key is present in the model
     * @returns {String | Boolean} value of current active state, false if undefined (unchecked)
     */
    getCurrentState: function () {
      var modelState = this.model.get(this.checkboxName);

      if (modelState !== undefined) {
        return modelState;
      } else {
        return false;
      }
    },

    /**
     * Reports change event to parent view, passes input parameters
     * @param {Event} e - click event
     */
    onItemClick: function (e) {
      e.preventDefault();
      var value = e.currentTarget.value;

      this.$el.addClass('is-loading');

      this.$el.trigger(this.setup.activeChangeEvent, {
        type: this.filterType,
        name: this.checkboxName,
        value,
      });
    },

    // Set this view's checkbox current state (checked, unchecked)
    render: function () {
      var isChecked = !!this.model.attributes[this.$el.attr('name')];
      this.$el.prop('checked', isChecked);
      this.$el.removeClass('is-loading');

      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'CheckboxComponent',
    View: ui.CheckboxComponentView,
    reinitialize: false,
  });
}).call(this);
