/**
 * @author Sang-In Haetzel
 * @description handles geneeric filter flyout, show/hide flyout, reset filter
 */

(function () {
  'use strict';

  ui.FilterFlyoutComponentView = ui.ComponentView.extend({
    name: 'ui.FilterFlyoutComponentView',

    activeClass: 'active',
    inactiveClass: 'inactive',
    isMobileClass: 'is-mobile',
    isOpenMobileNavClass: 'is-open-mobile-nav',
    noScrollingClass: 'no-scrolling',
    isBackClass: 'is-back',

    $filterElement: null,
    $filterFlyout: null,
    $filterTagsWrapper: null,
    $tagItems: null,

    isMobile: false,

    events: {
      'click .ui-js-generic-item': 'toggleFlyout',
      'click .ui-js-close-flyout': 'hideFlyout',
      'click .ui-js-filter-tag-item': 'hideTagItem',
      'click .ui-js-reset': 'hideAllTagItems',
      'click .ui-js-show-filters': 'showNavFilterIsMobile',
      'click .ui-js-close-level': 'hideNavFilterIsMobile',
      'click .ui-js-paragraph-headline': 'backNavigation',
      'click .ui-js-generic-filter__show-result': 'hideNavFilterIsMobile',
      'checkbox:change': 'onCheckboxChange',
      'delete:after:search': 'hideAllTagItems',
    },

    initialize: function () {
      this.$body = $(document.body);

      this.$tabs = this.$('.tab-pane');
      this.$navTabs = this.$('.ui-js-generic-item');

      this.$filterElement = this.$('[data-filter-level="0"].ui-js-filter-level');
      this.$filterFlyout = this.$('[data-filter-level="1"].ui-js-filter-level');
      this.$filterResults = this.$('.ui-js-filter-result');
      this.$filterTagsWrapper = this.$('.ui-js-filter-tags-wrapper');

      this.$genericFilterWrapper = this.$('.ui-js-generic-filter__wrap');
      this.$genericFilterCanvas = this.$('.ui-generic-filter-canvas');
      this.$filterHeadline = this.$('.ui-js-paragraph-headline');
      this.$filterHeadlineWrapper = this.$('.ui-js-filter-headline');
      this.$filterResultsBtn = this.$('.ui-js-generic-filter__show-result');

      // context: $filterTagsWrapper
      this.$tagItems = $('.ui-js-filter-tag-item', this.$filterTagsWrapper);
      this.$tagRadioItems = $('.ui-js-checkbox-single', this.$filterElement);

      // shows tags onLoad
      this.URLParamModel = ui.requests.getURLParamModel();
      this.showTagItemsOnLoad();

      // mobile methods
      this.isMobile = this.checkIsMobile();

      this.toggleMobileNavView();

      ui.on('bootstrap.activemediaquery.changed', this.viewportListener.bind(this));
    },

    showTagItemsOnLoad: function () {
      var that = this;
      var checkedElements = this.URLParamModel.attributes || {};

      $.each(checkedElements, function (index, value) {
        that.onCheckedShowTagItem(index);
        // that.disableCheckboxesOnLoad(index);
      });
    },

    disableCheckboxesOnLoad: function (identifier) {
      var $checkBox = $('#' + identifier, this.$filterElement);

      $checkBox && this.disableCheckboxes($checkBox);
    },

    onCheckboxChange: function (event) {
      var $checkBox = $(event.target);
      var checkBoxIdentifier = $checkBox.attr('name');

      if ($checkBox.is(':checked')) {
        this.onCheckedShowTagItem(checkBoxIdentifier);
        // this.disableCheckboxes($checkBox);
      } else {
        this.onCheckedHideTagItem(checkBoxIdentifier);
        // this.enableCheckboxes($checkBox);
      }
    },

    disableCheckboxes: function ($checkBox) {
      if (this.isInArray($checkBox) > -1) {
        $.each(this.$tagRadioItems, function (index, item) {
          if (!$(item).is($checkBox)) {
            $(item).attr('disabled', '');
          }
        });
      }
    },

    enableCheckboxes: function ($checkBox, reset) {
      if (reset || this.isInArray($checkBox) > -1) {
        $.each(this.$tagRadioItems, function (index, item) {
          $(this).removeAttr('disabled');
        });
      }
    },

    toggleNavFilterIsMobile: function () {
      if (this.$genericFilterWrapper.hasClass(this.activeClass)) {
        this.hideNavFilterIsMobile();
      } else {
        this.showNavFilterIsMobile();
      }
    },

    backNavigation: function () {
      if (this.$filterHeadline.hasClass(this.isBackClass)) {
        this.hideFlyout();
        this.showNavFilterIsMobile();
      }
    },

    showNavFilterIsMobile: function () {
      this.$genericFilterCanvas.addClass(this.activeClass);
      this.$filterElement.addClass(this.activeClass);
      this.$filterElement.css({
        height:
          'calc(100% - ' +
          (this.$filterHeadlineWrapper.outerHeight(true) +
            this.$filterResultsBtn.outerHeight(true)) +
          'px)',
      });
      this.$el.addClass(this.isOpenMobileNavClass);
      this.$body.addClass(this.noScrollingClass);
    },

    hideNavFilterIsMobile: function () {
      this.$genericFilterCanvas.removeClass(this.activeClass);
      this.$filterElement.css({
        height: 'auto',
      });
      this.$el.removeClass(this.isOpenMobileNavClass);
      this.$body.removeClass(this.noScrollingClass);
      this.hideFlyout();
    },

    isInArray: function ($checkBox) {
      return $.inArray($checkBox[0], this.$tagRadioItems);
    },

    onCheckedShowTagItem: function (identifier) {
      var that = this;

      $.each(this.$tagItems, function (index, item) {
        if ($(this).data('option-name') === identifier) {
          that.showFilterResult();
          $(this).addClass(that.activeClass);
          return false;
        }
      });
    },

    onCheckedHideTagItem: function (identifier) {
      var that = this;

      $.each(this.$tagItems, function (index, item) {
        if ($(this).data('option-name') === identifier) {
          $(this).removeClass(that.activeClass);
        }
      });

      if (!this.hasActiveTagsCheck()) {
        this.hideFilterResult();
      }
    },

    toggleFlyout: function (event) {
      if ($(event.currentTarget).hasClass(this.activeClass)) {
        this.hideFlyout();
      } else {
        this.showFlyout();
      }
    },

    showFlyout: function () {
      this.$filterFlyout.removeClass(this.inactiveClass);
      this.$filterFlyout.addClass(this.activeClass);

      this.$filterElement.removeClass(this.activeClass);
      this.$filterElement.addClass(this.inactiveClass);
      this.$filterHeadline.addClass(this.isBackClass);
    },

    hideFlyout: function () {
      var that = this;

      this.$filterFlyout.addClass(this.inactiveClass);

      this.$filterElement.addClass(this.activeClass);
      this.$filterElement.removeClass(this.inactiveClass);
      this.$filterHeadline.removeClass(this.isBackClass);

      if (!this.isMobile) {
        this.$filterFlyout.removeClass(this.activeClass);
      }

      setTimeout(function () {
        that.$tabs.removeClass(that.activeClass);
        that.$navTabs.removeClass(that.activeClass);
      }, 0);
    },

    hideTagItem: function (event) {
      $(event.currentTarget).removeClass(this.activeClass);

      if (!this.hasActiveTagsCheck()) {
        this.hideFilterResult();
      }
    },

    hideAllTagItems: function () {
      this.$tagItems.removeClass(this.activeClass);
      // this.enableCheckboxes(true, true);
      this.hideFilterResult();
    },

    showFilterResult: function () {
      this.$filterResults.addClass(this.activeClass);
    },

    hideFilterResult: function () {
      this.$filterResults.removeClass(this.activeClass);
    },

    hasActiveTagsCheck: function () {
      var that = this;
      var hasActvieTags = false;

      $.each(this.$tagItems, function (index, item) {
        if ($(this).hasClass(that.activeClass)) {
          hasActvieTags = true;
          return false;
        }
      });

      return hasActvieTags;
    },

    toggleMobileNavView: function () {
      if (this.isMobile) {
        this.$el.addClass(this.isMobileClass);
        this.hideFlyout();
      } else {
        this.$el.removeClass(this.isMobileClass);
        this.hideNavFilterIsMobile();
      }
    },

    viewportListener: function () {
      // check for isMobile value changed
      if (this.isMobile === this.checkIsMobile()) {
        return;
      }

      this.isMobile = this.checkIsMobile();
      this.toggleMobileNavView();
    },

    checkIsMobile: function () {
      return /(ms|xs)/.test(ui.Bootstrap.activeView);
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FilterFlyoutComponent',
    View: ui.FilterFlyoutComponentView,
    selector: '.ui-js-filter-flyout',
    reinitialize: true,
  });

  $(ui.bootstrapFilterFlyoutComponent());
}).call(this);
