/**
 *
 * @author Nils von Rymon-Lipinski
 *
 */
(function () {
  'use strict';

  ui.ModalAjaxComponentView = ui.ComponentView.extend({
    name: 'ui.ModalAjaxComponentView',

    events: {},

    initialize: function (options) {
      this.content = this.$el.find('.modal-content');
      this.$ctas = $('.ui-js-modal-ajax-link[data-toggle="#' + this.el.id + '"]');
      this.scrollBackPos = false;
      this.bindEvents();
    },

    bindEvents: function () {
      if (this.$ctas.length > 0) {
        this.$ctas.on('click', this.onOpenModalBtnClick.bind(this));
        if (this.$ctas.hasClass('o-swap-intro__btn')) {
          this.$ctas.addClass('is-active');
        }
      }
    },

    onOpenModalBtnClick: function (e) {
      e.preventDefault();
      if (e.currentTarget.dataset.jumpBack) {
        this.scrollBackPos = $('html').scrollTop();
        this.$el.on('hidden.bs.modal', this.scrollBack.bind(this));
      }
      this.requestContent(e.currentTarget.href);
    },

    scrollBack: function (e) {
      $('html').scrollTop(this.scrollBackPos);
      this.$el.off('hidden.bs.modal');
    },

    requestContent: function (url) {
      var self = this;
      $.ajax({
        type: 'GET',
        dataType: 'html',
        url,
      })
        .done(function (html) {
          self.content.html(html);
          $(self.el).modal('show');
        })
        .fail(function (jqxhr, textStatus, error) {
          console.error('ERROR AJAX', textStatus, error);
        });
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ModalAjaxComponent',
    View: ui.ModalAjaxComponentView,
    selector: '.ui-js-modal-ajax',
    reinitialize: false,
  });

  $(ui.bootstrapModalAjaxComponent());
}).call(this);
