/**
 * @author Sang-In Haetzel
 */

(function () {
  'use strict';

  /**
     @description
     set today marker to opening-hours-view
     */

  ui.OpeningHoursComponentView = ui.ComponentView.extend({
    name: 'ui.OpeningHoursComponentView',
    date: null,
    today: null,
    notes: null,

    initialize: function (options) {
      this.date = new Date();

      // this.date.getDay() means: sunday = 0, monday = 1, tuesday = 2, wednesday = 3, ... , saturday = 6
      // => transform to array-index, which starts with monday = 0 , tuesday = 1, ... , sunday = 6
      if (this.date.getDay() > 0) {
        this.today = this.date.getDay() - 1;
      } else {
        this.today = 6;
      }

      this.notes = this.$el.find('.ui-js-note');
      this.setTodayMarker();
    },

    setTodayMarker: function () {
      // check getDay function passed and notes are available
      if (this.notes.length && this.notes[this.today]) {
        $(this.notes[this.today]).removeClass('hidden');
      }
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'OpeningHoursComponent',
    View: ui.OpeningHoursComponentView,
    selector: '.ui-js-opening-hours',
  });

  $(ui.bootstrapOpeningHoursComponent('.ui-js-opening-hours'));
}).call(this);
