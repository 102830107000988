/**
 * @author Henri Podolski
 */
(function () {
  'use strict';

  /**
   *
   *
   */
  ui.TabControlExtendedComponentView = ui.ComponentView.extend({
    name: 'ui.TabControlExtendedComponentView',

    events: {},

    initialize: function () {
      var self = this;
      this.$tabsAlternative = this.$('.js-tabs-overflow-control');
      this.$tabsAlternativeHeadings = this.$tabsAlternative.find('.panel-heading');
      this.$tabsAlternativeAnchors = this.$tabsAlternativeHeadings.find('a');
      this.$tabsWrapper = this.$('.js-tabs-fit-control');
      this.$tabsMask = this.$('.js-tabs-mask');
      this.$tabsElements = this.$tabsWrapper.find('.js-tabs-items');
      this.$tabsAnchors = this.$tabsElements.find('a');
      this.$window = $(window);
      this.selectedIndex = this.$el.data('tabindex');

      var hash = window.location.hash;
      if (
        this.selectedIndex !== undefined &&
        this.isValidHash(hash) &&
        $(hash, this.$el).length === 0
      ) {
        this.openTab($(this.$tabsAnchors[this.selectedIndex - 1]).attr('href'));
        this.openAccordionItem($(this.$tabsAlternativeHeadings[this.selectedIndex - 1]).attr('id'));
      }

      this.cacheHrefs();

      this.fits = true;
      this.fitsBefore = false;

      this.checkAndRender = this.checkAndRender.bind(this);
      this.externalOpenAccordionItem = this.externalOpenAccordionItem.bind(this);
      this.externalOpenTab = this.externalOpenTab.bind(this);

      if (this.$tabsElements.length) {
        this.checkAndRender(true);
        this.$window.on('resize', this.onResize.bind(this));
      }

      this.rating = $('.mui-js-ratings .mui-js-rating-loaded');
      $('.mui-js-ratings').on('rating-received:' + this.rating.data('id'), function (evt, rating) {
        self.checkAndRender(true);
      });
    },

    /**
     * tabs anchors are set to accordion anchors if accordion is displayed,
     * therefor cache those for later use
     */
    cacheHrefs: function () {
      this.tabAnchorHrefs = [];

      this.$tabsAnchors.each(
        function (i, anchor) {
          this.tabAnchorHrefs.push($(anchor).attr('href'));
        }.bind(this)
      );

      this.accordionAnchorHrefs = [];

      this.$tabsAlternativeHeadings.each(
        function (i, anchor) {
          this.accordionAnchorHrefs.push('#' + $(anchor).attr('id'));
        }.bind(this)
      );
    },

    onResize: function () {
      clearTimeout(this.waitExecute);
      this.waitExecute = setTimeout(this.checkAndRender, 100);
    },

    checkAndRender: function (force) {
      this.checkTabsFit();
      this.render(force);
    },

    checkTabsFit: function () {
      var parentWidth;
      var childsWidth = 0;

      this.$tabsWrapper.css({
        position: 'absolute',
        visibility: 'visible',
        display: 'block',
      });

      parentWidth = this.$tabsMask.width();

      this.$tabsElements.each(function () {
        childsWidth += $(this).find('a').outerWidth(true);
      });

      this.$tabsWrapper.get(0).style.removeProperty('position');
      this.$tabsWrapper.get(0).style.removeProperty('visibility');
      this.$tabsWrapper.get(0).style.removeProperty('display');
      this.fits = childsWidth <= parentWidth;
    },

    /**
     * tab control is default layout
     */
    renderDefault: function () {
      this.$tabsWrapper.removeAttr('hidden');
      this.$tabsWrapper.trigger('change.tab.view');
      this.$tabsAlternative.attr('hidden', true);
      this.toggleGlobalAnchorIds(false);
    },

    /**
     * when the layout changes from tab control or accordion the
     * anchors must change on a global level
     * @param {boolean} isAlternative toggle from one kind of tabs to the others
     */
    toggleGlobalAnchorIds: function (isAlternative) {
      this.tabAnchorHrefs.forEach(
        function (tabAnchorHref, i) {
          // search for globals and make replacements,
          // exclude component anchors
          if (isAlternative) {
            this.switchTabToAccordionAnchors(tabAnchorHref, i);
          } else {
            this.switchAccordionToTabAnchors(tabAnchorHref, i);
          }
        }.bind(this)
      );
    },

    switchTabToAccordionAnchors: function (tabAnchorHref, i) {
      var $tabAnchorHref = $('a[href="' + tabAnchorHref + '"]');

      $tabAnchorHref.not(this.$tabsAnchors).attr('href', this.accordionAnchorHrefs[i]);

      var $accordionAnchorHref = $('a[href="' + this.accordionAnchorHrefs[i] + '"]');

      $accordionAnchorHref
        .not(this.$tabsAlternativeAnchors)
        .off('click', this.externalOpenAccordionItem)
        .off('click', this.externalOpenTab)
        .on('click', this.externalOpenAccordionItem);

      if (tabAnchorHref === location.hash) {
        location.hash = this.accordionAnchorHrefs[i];
        this.openAccordionItem(this.accordionAnchorHrefs[i]);
      }
    },

    switchAccordionToTabAnchors: function (tabAnchorHref, i) {
      var $accordionAnchorHref = $('a[href="' + this.accordionAnchorHrefs[i] + '"]');

      $accordionAnchorHref.not(this.$tabsAlternativeAnchors).attr('href', tabAnchorHref);

      var $tabAnchorHref = $('a[href="' + tabAnchorHref + '"]');

      $tabAnchorHref
        .not(this.$tabsAnchors)
        .off('click', this.externalOpenAccordionItem)
        .off('click', this.externalOpenTab)
        .on('click', this.externalOpenTab);

      if (
        this.accordionAnchorHrefs[i] === location.hash ||
        $(this.$tabsAlternativeHeadings[i]).find('a').first().attr('href') === location.hash
      ) {
        location.hash = tabAnchorHref;
        this.openTab(tabAnchorHref);
      }
    },

    externalOpenTab: function (evt) {
      var $eventElement = $(evt.currentTarget);

      this.openTab($eventElement.attr('href'));
    },

    openTab: function (href) {
      var $tabControlExtendedTab = this.$('[href=' + href + ']');

      $tabControlExtendedTab.tab('show');
    },

    externalOpenAccordionItem: function (evt) {
      var $eventElement = $(evt.currentTarget);
      this.openAccordionItem($eventElement.attr('href'));
    },

    openAccordionItem: function (href) {
      var $tabControlExtendedAccordionItem =
        href.indexOf('#') === -1 ? this.$('#' + href) : this.$(href);

      $tabControlExtendedAccordionItem
        .next()
        .collapse('show')
        .closest('.panel-group')
        .find('.panel-collapse.in')
        .not($tabControlExtendedAccordionItem.next())
        .collapse('hide');
    },

    renderAlternative: function () {
      this.$tabsAlternative.removeAttr('hidden');
      this.$tabsAlternative.trigger('change.tab.view');
      this.$tabsWrapper.attr('hidden', true);
      this.toggleGlobalAnchorIds(true);
    },

    render: function (force) {
      if (force || this.fits !== this.fitsBefore) {
        if (!this.fits) {
          this.renderAlternative();
        } else {
          this.renderDefault();
        }

        this.fitsBefore = this.fits;
      }

      return this;
    },

    isValidHash: function (hash) {
      return hash !== undefined && hash.length > 0 && hash.indexOf('=') === -1;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'TabControlExtendedComponent',
    View: ui.TabControlExtendedComponentView,
    selector: '.ui-js-tab-control-extended',
  });

  $(ui.bootstrapTabControlExtendedComponent());
}).call(this);
