/**
 * @author Henri Podolski
 * @author Nils von Rymon-Lipinski
 */

(function () {
  'use strict';

  ui.TabSliderHangingCardsComponentView = ui.ComponentView.extend({
    name: 'ui.TabSliderHangingCardsComponentView',

    events: {
      'click .js-milestone-link': 'onMilestoneLinkClick',
      'click .js-milestone': 'onMilestoneClick',
      // 'carousel:slid-auto .ui-js-carousel.top-slider': 'onSliderAutoplay',
      'keypress .js-milestone': 'onMilestoneKeypress',
      // 'dragStart .js-active-pill': 'onDragStart',
      // 'dragEnd .js-active-pill': 'onDragEnd'
    },

    defaults: {
      step: 1,
      loop: true,
      autoplay: false,
      pxThreshold: 40,
    },

    initialize: function () {
      this.setMilestoneStates = this.setMilestoneStates.bind(this);
      this.onWindowResize = this.onWindowResize.bind(this);

      this.index = 0;
      this.milestonePositions = [];

      this.$milestones = this.$('.js-milestone');
      this.$dragPill = this.$('.js-active-pill');
      this.$progressbar = this.$('.js-progress-indicator');
      this.$topSlider = this.$('.ui-js-carousel.top-slider');
      this.$topSliderItems = this.$topSlider.find('.item');
      this.isInit = true;
      this.$tabslider = this.$('.tab-slider');

      this.hideUnneededThings();

      this.$tabsliderInner = this.$tabslider.find('.tab-slider-inner');
      this.$firstSliderContainer = this.$topSlider.find('.container:first');
      this.marginLeft = this.$firstSliderContainer[0].offsetLeft;

      this.$tabsliderInner.css({
        left: this.marginLeft + 'px',
      });
      this.indexOffset = Math.floor(this.$el.width() / this.$milestones.first().width() / 2);

      this.$milestones.find('.milestone').attr('tabindex', 0);

      this.$dragPill.draggabilly({
        axis: 'x',
        containment: '.tab-slider-inner',
      });

      this.setMilestoneStates();

      this.activate(this.index);

      $(window).on('resize', this.onWindowResize);

      this.checkIfHashSet();
    },

    // TODO : clean it in next Sprints ( current Sprint 53)
    hideUnneededThings: function () {
      this.$milestones.not(':first').addClass('is-hidden');
      this.$dragPill.addClass('is-hidden');
    },

    checkIfHashSet: function () {
      var self = this;
      if (
        this.$milestones &&
        this.$milestones.length > 0 &&
        window.location.hash &&
        window.location.hash.charAt(1) !== '?'
      ) {
        var $targetMilestone = this.$milestones.filter(function (idx, elm) {
          return window.location.hash === '#' + elm.id;
        });
        if ($targetMilestone.length) {
          $('html, body').animate({ scrollTop: self.$el.offset().top }, 500, function () {
            this.isInit = true;
            $targetMilestone.click();
          });
        }
      }
    },

    /**
     * toggle the location url hash
     */
    toggleUrlHash: function ($targetElement) {
      var locationNew = $targetElement
        ? '#' + $targetElement.attr('id')
        : location.href.replace(location.hash, '');

      if (history.pushState) {
        history.pushState(null, null, locationNew);
      } else {
        location.href = locationNew;
      }
    },

    onWindowResize: function () {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }

      this.resizeTimeout = setTimeout(
        function () {
          this.setMilestoneStates();
        }.bind(this),
        1
      );
    },

    setMilestoneStates: function () {
      // clear previous cached milestones
      this.milestonePositions = [];

      this.$milestones.each(
        function (i, el) {
          var $milestoneElement = $(el);
          var $milestoneTarget = $milestoneElement.find('.milestone');

          this.cacheMilestonePosition($milestoneTarget, i);

          if ($milestoneElement.hasClass('is-active')) {
            this.index = i;
            this.$currentMilestone = $milestoneElement;
          }
        }.bind(this)
      );
    },

    cacheMilestonePosition: function ($milestoneTarget) {
      var leftprogressBarPosition = this.$progressbar.offset().left;
      var leftMilestonePosition = $milestoneTarget.offset().left;

      this.milestonePositions.push({
        left: leftMilestonePosition - leftprogressBarPosition,
      });
    },

    getIndexByLeftPosition: function (direction, leftPos) {
      var index = this.index;

      if (!direction || isNaN(leftPos)) {
        return index;
      }

      var closest = this.milestonePositions.reduce(function (previousMilestone, currentMilestone) {
        if (!previousMilestone) {
          return currentMilestone;
        }

        return Math.abs(currentMilestone.left - leftPos) <
          Math.abs(previousMilestone.left - leftPos)
          ? currentMilestone
          : previousMilestone;
      });

      index = this.milestonePositions.indexOf(closest);

      return index;
    },

    deactivateAutoplay: function () {
      var $topCarouselApiEl = this.$topSlider.prop('View').$carousel;
      $topCarouselApiEl.carousel('pause');
    },

    onSliderAutoplay: function (evt, data) {
      this.activate(data.position, true);
    },

    onMilestoneKeypress: function (evt) {
      if (evt.keyCode === 13) {
        var $eventElement = $(evt.currentTarget);
        this.deactivateAutoplay();
        this.activate($eventElement.data('index'));
      }
    },

    onDragStart: function (evt) {
      this.startLeftPosition = parseInt(this.$dragPill.css('left'), 10);
      clearTimeout(this.draggingClassDelay);
      this.$progressbar.addClass('is-dragging');
    },

    onDragEnd: function (evt) {
      var thresholdMin = this.startLeftPosition - this.setup.pxThreshold;
      var thresholdMax = this.startLeftPosition + this.setup.pxThreshold;
      // default is startLeftPosition
      var endLeftPosition = this.startLeftPosition;
      // default is start index
      var direction = null;

      this.draggingClassDelay = setTimeout(
        function () {
          endLeftPosition = parseInt(this.$dragPill.css('left'), 10);
          this.$progressbar.removeClass('is-dragging');

          if (endLeftPosition < thresholdMin) {
            direction = 'left';
          } else if (endLeftPosition > thresholdMax) {
            direction = 'right';
          }

          this.activate(this.getIndexByLeftPosition(direction, endLeftPosition));
        }.bind(this),
        100
      );
    },

    onMilestoneLinkClick: function (evt) {
      evt.preventDefault();

      var $currentTarget = $(evt.currentTarget);
      var $milestoneTarget = this.$milestones.filter($currentTarget.attr('href'));

      if ($milestoneTarget.length) {
        $milestoneTarget.click();
      } else {
        console.log('No Milestone Target found.', $currentTarget.attr('href'));
      }
    },

    onMilestoneClick: function (evt) {
      var $eventElement = $(evt.currentTarget);
      this.deactivateAutoplay();
      this.activate($eventElement.data('index'));
    },

    activate: function (index, isAutoplay, $slider) {
      if (index !== this.index) {
        this.index = index;

        this.$progressbar.removeClass('is-dragging');

        this.$previousMilestone = this.$currentMilestone;
        this.$previousMilestone.removeClass('is-active');
        this.$previousMilestone.find('.milestone').attr('tabindex', 0);
        this.$currentMilestone = this.$milestones.eq(this.index);
        this.$currentMilestone.addClass('is-active');
        this.$currentMilestone.find('.milestone').attr('tabindex', -1);

        this.toggleUrlHash(this.$currentMilestone);

        this.forceCarouselSync(isAutoplay, $slider);
      }

      // this.position(this.milestonePositions[index].left);
    },

    forceCarouselSync: function (isAutoplay, $slider) {
      var topCarousel = this.$topSlider.prop('View');
      var self = this;
      var $activeSliderItem = this.$topSliderItems.filter('.active');

      if (!isAutoplay && !topCarousel.isMoving && $slider !== topCarousel.$carousel) {
        if (this.isInit) {
          $activeSliderItem.addClass('animate-out');
        } else {
          this.isInit = true;
        }

        setTimeout(function () {
          topCarousel.$carousel.carousel(self.index);
          setTimeout(function () {
            $activeSliderItem.removeClass('animate-out');
          }, 600);
        }, 400);
      }
    },

    position: function (pos) {
      this.$dragPill.css('left', pos + 'px');
      var newPos = 0;

      if (this.index !== 0) {
        if (this.index < this.milestonePositions.length - 1 - this.indexOffset) {
          newPos = this.milestonePositions[0].left - this.milestonePositions[this.index - 1].left;
        } else if (this.milestonePositions.length - 2 - this.indexOffset > -1) {
          newPos =
            this.milestonePositions[0].left -
            this.milestonePositions[this.milestonePositions.length - 2 - this.indexOffset].left;
        }

        this.$tabsliderInner.delay(400).animate(
          {
            left: this.marginLeft + newPos + 'px',
          },
          300
        );
      } else if (this.index === 0) {
        this.$tabsliderInner.delay(400).animate(
          {
            left: this.marginLeft + 'px',
          },
          300
        );
      }

      var $textNode = this.$currentMilestone.find('.milestone-headline');
      if (this.$temporaryMilestoneHeadline && $textNode.length) {
        this.$temporaryMilestoneHeadline.text($textNode.text());
      }
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'TabSliderHangingCardsComponent',
    View: ui.TabSliderHangingCardsComponentView,
    selector: '.ui-js-tab-slider-hanging-cards',
  });

  $(ui.bootstrapTabSliderHangingCardsComponent());
}).call(this);
