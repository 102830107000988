/**
 * @author nils.vonrymon-lipinski@aperto.com (Nils von Rymon-Lipinski)
 */
(function () {
  'use strict';

  ui.GeoP13nComponentView = ui.ComponentView.extend({
    name: 'ui.GeoP13nComponentView',

    initialize: function (options) {
      if ($.docCookies('g360-geo') === undefined) {
        this.getGeoData();
      }
    },

    getGeoData: function () {
      if (mui && mui.helpers && mui.helpers.GeoTargeting) {
        var geoTargeting = mui.helpers.GeoTargeting;
        geoTargeting.geoTarget('mgb_places_www_migros', this.success, this.error);
      } else {
        console.warn('mui.helpers.GeoTargeting not found...');
      }
    },

    success: function (result) {
      if (result.zip && result.cooperative) {
        $.docCookies('g360-geo', result.zip + '|' + result.cooperative, {
          expires: 0.02,
          path: '/',
          secure: true,
        });
        if ($('body.ui-js-geo-p13n-reload').length) {
          console.info('Do geo location reload ...');
          window.location.reload();
        } else {
          ui.trigger('geoP13n');
        }
      }
    },

    error: function (error) {
      console.warn('error ->', error);
      $.docCookies('g360-geo', '|', { expires: 0.02, path: '/', secure: true });
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'GeoP13nComponent',
    View: ui.GeoP13nComponentView,
    selector: '.ui-js-geo-p13n',
    reinitialize: false,
  });

  $(ui.bootstrapGeoP13nComponent());
}).call(this);
