/**
 * @author Henri Podolski
 */

(function () {
  'use strict';

  /**
   *
   * Data-setup
   * @param {Object}  limit - holds different configurations for views, that limit the display of items
   * @param {Number}  limit.[ui.Bootstrap.activeView] - e.g. xs, sm and so on @see _js.scss, limit of items displayed
   *                  at the same time, when limits are applied
   */

  ui.CollapseListComponentView = ui.ComponentView.extend({
    name: 'ui.CollapseListComponentView',

    defaults: {
      hiddenClass: 'hidden',
    },

    events: {
      'click .js-collapse-btn': 'onCollapseBtnClick',
    },

    isCollapsed: true,

    initialize: function () {
      this.$collapseBtnContainer = this.$('.js-collapse-btn-container');
      this.$collapseBtn = this.$('.js-collapse-btn');
      this.$collapseItems = this.$('.js-item');

      this.initialButtonValue = this.$collapseBtn.text(); // show more
      this.altButtonValue = this.$collapseBtn.data('toggleText'); // show less

      // on bootstrap mediaquery change
      ui.on('bootstrap.activemediaquery.changed', this.onMediaQueryChange.bind(this));
    },

    /**
     * toggles between show all and show limited on click
     */
    onCollapseBtnClick: function () {
      if (this.isCollapsed) {
        this.showAll();
        this.isCollapsed = false;
      } else {
        this.render();
        this.isCollapsed = true;
      }

      this.toggleControlButtonText();
    },

    /**
     * conditions could have changed for count of display items
     * --> call render
     */
    onMediaQueryChange: function () {
      this.render();
    },

    /**
     * show all items
     */
    showAll: function () {
      // addClass height 0 - .is-collapsed
      // .width()
      // removeClass .is-collapsed

      this.$collapseItems.removeClass(this.setup.hiddenClass);
      this.isCollapsed = false;
    },

    /**
     * shows count items, hides others
     * @param {Number} countVisibleItems
     */
    applyDisplayItemsLimit: function (countVisibleItems) {
      // show "show more" - button if setup condition are met
      if (countVisibleItems < this.$collapseItems.length) {
        this.showControlButton();
        this.isCollapsed = true;
      }

      this.$collapseItems
        .removeClass(this.setup.hiddenClass)
        .filter(':gt(' + (countVisibleItems - 1) + ')')
        .addClass(this.setup.hiddenClass);
    },

    /**
     * uses data-toggle on $collapseBtn to switch between more and less items text on the button
     */
    toggleControlButtonText: function () {
      var hasMore =
        this.isCollapsed && this.setup.limit[ui.Bootstrap.activeView] < this.$collapseItems.length;

      this.$collapseBtn.text(hasMore ? this.initialButtonValue : this.altButtonValue);
    },

    /**
     *
     */
    showControlButton: function () {
      this.$collapseBtnContainer.removeClass(this.setup.hiddenClass);
    },

    /**
     *
     */
    hideControlButton: function () {
      this.$collapseBtnContainer.addClass(this.setup.hiddenClass);
    },

    /**
     * no limit of displayed item, therefore show all and
     * hide controls
     */
    noLimits: function () {
      this.showAll();
      this.hideControlButton();
    },

    /**
     * look up if there any limits for the active responsive view or not and react to those cases
     * @returns {ui.CollapseListComponentView}
     */
    render: function () {
      if (this.setup.limit && !isNaN(this.setup.limit[ui.Bootstrap.activeView])) {
        this.applyDisplayItemsLimit(this.setup.limit[ui.Bootstrap.activeView]);
      } else {
        this.noLimits();
      }

      this.toggleControlButtonText();

      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'CollapseListComponent',
    View: ui.CollapseListComponentView,
    selector: '.ui-js-collapse-list',
  });

  $(ui.bootstrapCollapseListComponent());
}).call(this);
