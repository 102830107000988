/**
 * @author: Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Provides receipt-list functionality like checkbox handling and modal dialogs
   */
  ui.ReceiptListComponentView = ui.ComponentView.extend({
    name: 'ui.ReceiptListComponentView',

    events: {
      'click .ui-js-toggle-modal': 'loadIFrame',
      'click .ui-js-table input[type="checkbox"]': 'checkboxClicked',
      'click .ui-js-filter-link': 'transferFocus',
      'submit .ui-js-controls form': 'disableDownloadBtns',
      // Modal dialog events
      'hidden.bs.modal': 'closeIframe',
    },

    $IFrame: null,
    $checkboxes: null,
    $downloadPanel: null,

    // Datepicker reference
    $datepicker: null,

    // If true, a click on a receipt link will start the PDF download instead opening the modal
    downloadPDFMode: false,

    initialize: function () {
      this.$datepicker = this.$('.ui-js-datepicker');
      var self = this;

      if (/ms|xs/.test(ui.Bootstrap.activeView)) {
        this.downloadPDFMode = true;
      }

      // Delegate view events and render after each successful ajax call
      ui.on(ui.GlobalEvents.FRAGMENT_PLACED, function () {
        self.delegateEvents();
        self.render();
      });
    },

    render: function () {
      this.$IFrame = this.$('.modal iframe');
      this.$checkboxes = this.$('.ui-js-table input[type="checkbox"]');
      this.$downloadPanel = this.$('.ui-js-controls');

      var inputs = this.$downloadPanel.find('input[name^="checkbox"]');
      var $ajaxDateFrom = this.$('[name="ui-js-ajax-date-from"]');
      var $ajaxDateUntil = this.$('[name="ui-js-ajax-date-until"]');
      var $isEmpty = this.$('[name="ui-js-ajax-is-empty"]');
      var $downloadBtn = this.$('.ui-js-download-csv');
      var valueFrom = $ajaxDateFrom.val();
      var valueUntil = $ajaxDateUntil.val();
      var isEmpty = $isEmpty.val();

      if (inputs.length) {
        // Reset all checkboxes on render
        inputs.val('');
      }

      if (valueFrom && valueUntil) {
        // Preset datepicker with ajax values from hidden fields from response (fragment)
        this.$datepicker.trigger('datpicker:update', {
          dates: [valueFrom, valueUntil],
        });
      }

      // Toggle download button it the result contains an input with the empty flag
      if (isEmpty === 'true') {
        $downloadBtn.attr('disabled', 'disabled');
      } else {
        $downloadBtn.removeAttr('disabled');
      }

      setTimeout(function () {
        // Focus previously pressed element (accessibility)
        if (ui && ui.activeElement && typeof ui.activeElement === 'string') {
          var $focus = $(ui.activeElement);
          if ($focus.length) {
            $focus.first().focus();
            delete ui.activeElement;
          }
        }
      }, 0);

      return this;
    },

    /**
     * Removes iframe src attribute and body when modal closes
     */
    closeIframe: function () {
      this.$IFrame.removeAttr('src');
      if (this.$IFrame[0].contentWindow.document) {
        this.$IFrame[0].contentWindow.document.body.innerHTML = '';
      }
    },

    /**
     * Triggers Bootstrap API and opens modal dialog with href as iframe src
     * @param {Event} e - click event
     */
    loadIFrame: function (e) {
      if (this.downloadPDFMode) {
        return;
      }

      e.preventDefault();
      var $targetElement = $(e.currentTarget);
      if (this.$IFrame && $targetElement) {
        var url = $targetElement.data('modal-src');
        this.$IFrame.attr('src', url);
        this.$('.ui-js-modal-details').modal('show');
      }
    },

    disableDownloadBtns: function () {
      this.$downloadPanel.find('input[type="submit"]').attr('disabled', 'disabled');
    },

    /**
     * Accessibility feature: transfers focus to clicked link in new ajax fragment
     * @param e
     */
    transferFocus: function (e) {
      if (
        ui &&
        e.currentTarget.dataset &&
        e.currentTarget.dataset.name &&
        e.currentTarget.dataset.value
      ) {
        ui.activeElement =
          '.listlink[data-name="' +
          e.currentTarget.dataset.name +
          '"]' +
          '[data-value="' +
          e.currentTarget.dataset.value +
          '"]';
      }
    },

    /**
     * Click handler on checkboxes, assigns values to download buttons for POST
     * @param {Event} e - click event
     */
    checkboxClicked: function (e) {
      var name = $(e.currentTarget).attr('name');
      var checked = e.currentTarget.checked;
      var self = this;

      this.$downloadPanel.find('form').each(function () {
        this.isSubmitted = false;
        self.$downloadPanel.find('input[type="submit"]').removeAttr('disabled');
      });

      if (checked) {
        this.$downloadPanel.find('input[type="submit"]').removeAttr('disabled');
      } else if (!checked && name === 'checkbox-all') {
        this.$downloadPanel.find('input[type="submit"]').attr('disabled', '');
      } else {
        if (!this.$checkboxes.is(':not([name="checkbox-all"]):checked')) {
          // Disable buttons if no checked checkbox can be found
          this.$downloadPanel.find('input[type="submit"]').attr('disabled', '');
        }
      }

      // All checkboxes were selected
      if (name === 'checkbox-all') {
        this.$checkboxes.each(function () {
          // Apply state of all-checkbox to specific checkbox and assign their values
          this.checked = checked;
          if (this !== e.currentTarget) {
            var input = self.$downloadPanel.find('input[name="' + this.name + '"]');
            var value = $(this).attr('value');
            input.attr('value', checked ? value : '');
          }
        });
        // One checkbox was selected
      } else {
        var inputs = this.$downloadPanel.find('input[name="' + name + '"]');
        var value = $(e.currentTarget).attr('value');

        if (inputs.length && value) {
          inputs.attr('value', checked ? value : '');
        }
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ReceiptListComponent',
    View: ui.ReceiptListComponentView,
    selector: '.ui-js-receipt-list',
  });

  $(ui.bootstrapReceiptListComponent());
}).call(this);
