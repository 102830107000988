(function () {
  'use strict';

  /**
   * used for replacing the logo with a breadcrumb for the main menu
   */
  ui.MainMenuMobileBreadcrumb = ui.ComponentView.extend({
    name: 'ui.MainMenuMobileBreadcrumb',

    events: {
      'click .js-main-menu-mobile-breadcrumb': 'onBreadcrumbClick',
    },

    isActive: false,

    isMenuOpen: false,

    /**
     * @member {RegExp} whether the functionality is active or not in the current view
     */
    activateViews: /(xs|ms)/,

    lastbreadcrumbModel: {},

    HIDDEN_CLASS: 'hidden',

    initialize: function (options) {
      this.$breadcrumb = this.$('.js-main-menu-mobile-breadcrumb');

      this.toggleActivation();
      ui.on('bootstrap.activemediaquery.changed', this.toggleActivation.bind(this));
      ui.on(ui.GlobalEvents.MAIN_MENU_OPEN, this.onMainMenuOpen.bind(this));
      ui.on(ui.GlobalEvents.MAIN_MENU_CLOSE, this.onMainMenuClose.bind(this));
      ui.on(
        ui.GlobalEvents.MAIN_MENU_BREADCRUMB_CHANGE,
        this.onMainMenuBreadcrumbChange.bind(this)
      );
    },

    onBreadcrumbClick: function (evt) {
      evt.preventDefault();
      ui.trigger(ui.GlobalEvents.MAIN_MENU_GOTO, evt.currentTarget.href);
    },

    onMainMenuOpen: function () {
      this.isMenuOpen = true;
      this.toggleActivation();
    },

    onMainMenuClose: function () {
      this.isMenuOpen = false;
      this.toggleActivation();
    },

    onMainMenuBreadcrumbChange: function (data) {
      var level = data && parseInt(data.level, 10);

      if (level > -1 && data && data.uuid && data.title) {
        if (this.isActive) {
          this.$el.removeClass(this.HIDDEN_CLASS);
        }
        this.$breadcrumb.attr('href', '#menu/' + data.uuid);
        this.$breadcrumb.text(data.title);

        // save last model, because it is used when activated without model change
        this.lastbreadcrumbModel = data;
      } else {
        this.$el.addClass(this.HIDDEN_CLASS);
        this.$breadcrumb.attr('href', '#');
        this.$breadcrumb.text('');
      }
    },

    toggleActivation: function () {
      var isActivateView = this.activateViews.test(ui.Bootstrap.activeView);

      if (isActivateView && this.isMenuOpen) {
        this.activate();
      } else {
        // set timeout because resize may fire later one, in this class
        setTimeout(this.deactivate.bind(this), 1);
      }
    },

    activate: function () {
      if (!this.isActive) {
        this.isActive = true;
        this.onMainMenuBreadcrumbChange(this.lastbreadcrumbModel);
      }
    },

    deactivate: function () {
      if (this.isActive) {
        this.$el.addClass(this.HIDDEN_CLASS);
        this.lastbreadcrumbModel = {};
        this.isActive = false;
      }
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'MainMenuMobileBreadcrumbComponent',
    View: ui.MainMenuMobileBreadcrumb,
    selector: '.ui-js-main-menu-breadcrumb',
  });

  $(ui.bootstrapMainMenuMobileBreadcrumbComponent());
}).call(this);
