/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Ajax pagination, uses get parameters and browser history for a restful implementation.
   * If combined with item-filter, set pagination's popState to false.
   *
   * Data-Setup:
   * @param {String} resultNodeId - id of node where response will be appended
   * @param {String} ajaxUrl - relative URL for ajax request
   * @param {Boolean} [popState] - if to install popstate listener
   * @param {Boolean} [scrollTop] - whether to scroll to primary pagination on successful request
   */
  ui.PaginationComponentView = ui.ComponentView.extend({
    name: 'ui.PaginationComponentView',

    ajaxPageUrl: '',
    popState: false,

    fadeInCallback: null,
    // Whether url-parameters are separated by custom character (~)
    customSubmMeth: false,
    scrollTop: false,
    // If pushState is supported
    historySupport: false,
    // If to apply a Backbone View to the DOM element from the Ajax call via setElement
    replaceViewOnRender: false,
    // Object that holds the data for the ajax-request (urlparams)
    queryObj: {},
    // Whether there are two paginations for one result node, the second one is the isSecondary
    isSecondary: false,
    // jQuery result node where the ajax result should be inserted
    $resultNode: null,
    $body: null,

    // Install event listener on both pager and sort-filter
    events: {
      'click .ui-js-page': 'onItemClick',
      'click .ui-js-filter-btn': 'onItemClick',
      'click .ui-item-filter .dropdown-toggle': 'transferDropdownFocus',
      'click a.ui-js-page': 'transferPageFocus',
    },

    initialize: function (options) {
      this.ajaxPageUrl = options.ajaxUrl;
      this.popState = options.popState;
      this.customSubmMeth = options.customSubmitMethod;
      this.scrollTop = options.scrollTop;
      this.$resultNode = options.resultNodeId && $('#' + options.resultNodeId);
      this.historySupport = !!window.history.pushState || false;
      this.isSecondary = this.$el.is('.ui-js-secondary');
      this.fadeInCallback = this.fadeInCB.bind(this);
      this.$body = $('html,body');

      this.model = ui.requests.getURLParamModel();

      // && !this.isSecondary
      if (this.popState && this.historySupport) {
        this.installPopState();
      }

      // Install model listener, render view on changes
      this.model.on(
        'change destroy',
        function (model) {
          this.render();
        },
        this
      );
    },

    /**
     * Installs a popstate-listener on window, retrieves parameters either from state or location on popstate
     */
    installPopState: function () {
      var self = this;

      $(window).on('popstate', function (e) {
        var callback;
        var state = window.history.state;
        // If history state is available and contains params object
        if (state && state.params) {
          self.queryObj = state.params;
          // Else if location has search params or custom params
        } else if (
          window.location &&
          (window.location.search || ui.requests.locationHasCustomParams(window.location))
        ) {
          self.queryObj = ui.requests.parseLocationParameters(window.location);
          // Else reset the query
        } else {
          self.queryObj = {};
        }

        if (JSON.stringify(self.model.attributes) === JSON.stringify(self.queryObj)) {
          return;
        } else if (
          self.model.attributes.p &&
          self.queryObj.p &&
          self.model.attributes.p.toString() === self.queryObj.p.toString()
        ) {
          return;
        }

        // Define a scroll callback for after the request to return to the last scrollTop position
        callback = function () {
          if (state && state.scrollTop && !e.target.location.hash) {
            document.body.scrollTop = state.scrollTop;
          }
          self.fadeInCB.apply(self);
        };
        self.$resultNode.addClass('loading');
        self.$resultNode.find('.fade-in').attr('aria-busy', true);
        // Request page based on params from history without pushing the history
        self.requestPage(true, callback);
      });
    },

    /**
     * Render replaces the view's el with the updated ui-js-pagination node found in the resultNode when called
     * repeatedly from outside, f.e. by the item-filter view.
     */
    render: function () {
      // https://jira.aperto.de/browse/MGRSR-5714
      // leads to reload, change to reinitialize for ajax
      // if (this.replaceViewOnRender) {
      //     var $pagination = this.$resultNode.find(
      //         '.ui-js-pagination' + (this.isSecondary ? '.ui-js-secondary' : ':not(.ui-js-secondary)'
      //     ));
      //     if ($pagination.length) {
      //         // Sets new $el and moves the view's delegated events from the old element to the new one
      //         this.setElement($pagination);
      //         this.focusElement();
      //     }
      // }
      // this.replaceViewOnRender = true;

      // Reset query after render
      this.queryObj = {};

      return this;
    },

    /**
     * If browser supports pushState, prevent page-reload and handle request with Ajax
     * * @param {Event} e - click event on page link
     */
    onItemClick: function (e) {
      e.preventDefault();
      var $target = $(e.currentTarget);

      if ($target.data('name') && $target.data('value') !== undefined) {
        // Update value of clicked element's key in queryObj
        this.queryObj[$target.data('name')] = $target.data('value');
        this.$resultNode.addClass('loading');
        this.$resultNode.find('.fade-in').attr('aria-busy', true);
        this.requestPage(false, this.fadeInCallback);
      }
    },

    /**
     * Triggers ajax request and calls render() after request was successfully executed.
     * @param {Boolean} isPopState - if true, new entry will be pushed to browser history
     * @param {function} successCb - success callback that will be executed after the request
     */
    requestPage: function (isPopState, successCb) {
      var self = this;
      ui.requests.ajaxRequestPage({
        url: this.ajaxPageUrl,
        query: this.queryObj,
        $el: this.$resultNode,
        customSubmitMethod: this.customSubmMeth,
        isPopState,
        successCb,
        failCb: function () {
          console.log('request failed', self.ajaxPageUrl);
        },
      });
    },

    /**
     * Callback function to be executed after a successful Ajax load
     */
    fadeInCB: function () {
      var self = this;
      // Defer for animation purpose
      setTimeout(function () {
        self.$resultNode.removeClass('loading');
        self.$resultNode.find('.fade-in').attr('aria-busy', false);
        if (self.scrollTop) {
          var $firstPagination = $('.ui-js-pagination:first');
          if ($firstPagination.length) {
            var scrollY = $firstPagination.offset().top;
            self.$body.animate({ scrollTop: scrollY }, 200);
          }
        }
      }, 0);
    },

    /**
     * Accessibility feature
     * Saves last focused item selector into temporary variable ui.activeElement
     */
    transferPageFocus: function () {
      if (ui) {
        ui.activeElement = (this.isSecondary ? '.ui-js-secondary ' : '') + '.page.is-active';
      }
    },

    /**
     * Accessibility feature
     * Saves last focused item selector into temporary variable ui.activeElement
     * @param {Event} e - click event
     */
    transferDropdownFocus: function (e) {
      if (ui && e.currentTarget.id) {
        ui.activeElement = (this.isSecondary ? '.ui-js-secondary ' : '') + '#' + e.currentTarget.id;
      }
    },

    /**
     * Accessibility feature: if ui.activeElement contains a selector, find and focus the element,
     * add a tabindex if necessary (in case the active page is a span)
     */
    focusElement: function () {
      // Defer to work in frontend prototype
      setTimeout(function () {
        if (ui && ui.activeElement && typeof ui.activeElement === 'string') {
          var $focus = $(ui.activeElement);
          if ($focus.length) {
            if (!$focus.is(':input, a')) {
              // Found element is unable to receive focus, add tabindex
              $focus.attr('tabindex', 0);
            }
            $focus.first().focus();
            delete ui.activeElement;
          }
        }
      }, 0);
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'PaginationComponent',
    View: ui.PaginationComponentView,
    selector: '.ui-js-pagination',
    reinitialize: true,
  });

  $(ui.bootstrapPaginationComponent());
}).call(this);
