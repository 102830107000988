/**
 * @author Fabio Tedesco
 * Sticky Element, shown on scroll up and hidden on scroll down
 */

(function () {
  'use strict';

  ui.ContentTabsComponentView = ui.ComponentView.extend({
    name: 'ui.ContentTabsComponentView',
    state: !!(window.history && window.history.replaceState) || false,

    initialize: function (options) {
      this.fixedClass = 'fixed-item';
      this.hideClass = 'hide-item';
      this.activeClass = 'active';
      this.bodyEl = document.body;
      this.htmlEl = document.documentElement;
      this.ticking = false;
      this.fadeOutTime = 250;
      this.urlHash = window.location.hash || false;

      this.initialSetup();

      if (this.item && this.contentArea) {
        this.bindEvents();
        // this.createObserver();
      }
    },

    render: function () {
      var $target = this.$('a[href="' + window.location.hash + '"]');
      var that = this;

      if ($target.length && this.urlHash !== false) {
        // Show tab via Bootstrap JavaScript API
        $target.tab('show');
        setTimeout(function () {
          $('html,body').animate({ scrollTop: $target.offset().top }, that.fadeOutTime);
        }, 50);
      }
      // return this;
    },

    initialSetup: function () {
      // this.prevPos = 0.0;
      this.isMobile = /(ms|xs)/.test(ui.Bootstrap.activeView);
      this.item = this.$el.find('.ui-js-item');
      this.contentArea = this.$el.find('.ui-js-content');
      this.scrollPosition = 0;
      this.topOfElement = this.$el.offset().top - 30;
      this.contentAreaTop = this.contentArea ? $(this.contentArea).offset().top : 0;
      // this.contentAreaBottom = this.contentArea ? this.contentAreaTop + this.contentArea.height() : 0;
      this.itemLeft = this.item ? this.item[0].getBoundingClientRect().left + 'px' : null;
      this.itemWidth = this.contentArea
        ? this.contentArea[0].getBoundingClientRect().width + 'px'
        : null;
      this.btn = this.$el.find('.ui-js-btn');
      this.btnWrapper = this.$el.find('.ui-js-btn-wrapper');
      // this.counter = 0;
    },

    bindEvents: function () {
      $(window).on('scroll', this.handleScroll.bind(this));
      ui.on('bootstrap.activemediaquery.changed', this.handleResize.bind(this));
      $(window).on('resize', this.handleResize.bind(this));
      if (this.btn && this.btnWrapper) {
        $(this.btn).on('click', this.handleBtnClick.bind(this));
      }
    },

    handleScroll: function () {
      this.requestTick();
    },

    requestTick: function () {
      if (!this.ticking) {
        requestAnimationFrame(this.triggerScrollDown.bind(this));
      }
      this.ticking = true;
    },

    triggerScrollDown: function () {
      this.ticking = false;
      this.bodyTopPosition = this.bodyEl.scrollTop || this.htmlEl.scrollTop;
      this.isInView = this.bodyTopPosition >= this.contentAreaTop;
      this.isScrollingDown = this.bodyTopPosition > this.scrollPosition;
      var atTopAndScrollingUp =
        this.bodyTopPosition - this.contentAreaTop <= 15 &&
        this.bodyTopPosition - this.contentAreaTop > 0;
      if (this.isInView && this.isMobile) {
        if (this.isScrollingDown) {
          this.hideItem();
        } else {
          this.fixAndShowItem();
          if (atTopAndScrollingUp) {
            this.hideItem();
          }
        }
      } else {
        this.unfixItem();
        this.showItem();
        $(window).off('scroll', this.handleScroll.bind(this));
      }

      this.scrollPosition = this.bodyTopPosition;
    },

    handleBtnClick: function (e) {
      if (e.currentTarget.hasAttribute('href')) {
        this.setHashInUrl(e.currentTarget.getAttribute('href'));
      }

      if (this.isMobile) {
        if ($(e.currentTarget).closest(this.btnWrapper).hasClass(this.activeClass)) {
          return false;
        } else {
          $('html,body').animate({ scrollTop: this.topOfElement }, this.fadeOutTime);
        }
      }
    },

    setHashInUrl: function (hash) {
      if (this.state) {
        window.history.replaceState({}, '', hash);
      } else {
        window.location.hash = hash;
      }
    },

    handleResize: function () {
      this.initialSetup();

      if (!this.isMobile) {
        this.unfixItem();
        this.showItem();
      } else {
        // this.createObserver();
        if ($(this.item.hasClass(this.fixedClass))) {
          this.item[0].style.left = this.itemLeft;
          this.item[0].style.width = this.itemWidth;
        }
      }
    },

    /* createObserver: function () {
			var observer;
			//var root = $('body').find(".ui-js-observer-parent")[0];
			var options = {
				root: null,
				rootMargin: "0px",
				threshold: this.buildThresholdList()
			};
			observer = new IntersectionObserver(this.handleIntersect.bind(this), options);
			observer.observe(this.$el[0]);
		},

		buildThresholdList: function () {
			var thresholds = [];
			var numSteps = 20;
			var ratio;

			for (var i = 1.0; i <= numSteps; i++) {
				ratio = i / numSteps;
				thresholds.push(ratio);
			}

			thresholds.push(0);
			return thresholds;
		},

		handleIntersect: function (entries, observer) {
			var that = this;

			entries.forEach(function (entry) {
				if (that.isMobile) {
					console.log("ENTRY", entry);
					/!*var isIntersected = entry.intersectionRect.top === 0;
					var innerIntersectPosition = entry.boundingClientRect.top;
					var scrolledDown = innerIntersectPosition < that.prevPos;*!/
					console.log("entry.intersectionRect.top", entry.intersectionRect.top);
					console.log("entry.boundingClientRect.top", entry.boundingClientRect.top);
					console.log("entry.intersectionRatio", entry.intersectionRatio);
					console.log("that.prevPos", that.prevPos);

					if (entry.intersectionRect.top === 0) {
						console.log("IS INTERSECTED");
						//if (scrolledDown) {
						if (entry.boundingClientRect.top < that.prevPos) {
							console.log("scrolled DOWN");
							if (entry.boundingClientRect.top < -40) {
								that.hideItem();
							}
						} else {
							console.log("scrolled UP");
							that.fixAndShowItem();
						}
					} else {
						that.unfixItem();
					}

					that.prevPos = entry.boundingClientRect.top;
				}

			});
		}, */

    unfixItem: function () {
      $(this.item).removeClass(this.fixedClass);
      if (!this.isMobile) {
        this.item[0].style.left = 'auto';
        this.item[0].style.width = 'auto';
      }
    },

    fixAndShowItem: function () {
      this.fixItem();
      this.showItem();
    },

    showItem: function () {
      $(this.item).removeClass(this.hideClass);
    },

    fixItem: function () {
      this.item[0].style.left = this.itemLeft;
      this.item[0].style.width = this.itemWidth;
      $(this.item).addClass(this.fixedClass);
    },

    hideItem: function () {
      $(this.item).addClass(this.hideClass);
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ContentTabsComponent',
    View: ui.ContentTabsComponentView,
    selector: '.ui-js-c296-content-tabs',
    reinitialize: false,
  });

  $(ui.bootstrapContentTabsComponent());
}).call(this);
