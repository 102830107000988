(function (window, $) {
  'use strict';

  /**
   * jQuery document cookie manager
   * @param  {String} key      read / write (depends on val) cookie name
   * @param  {String} val      value of cookie (key)
   * @param  {Object} options  Object with optional parameters like 'expires', 'path', etc.
   * @return {Object / String} Returns an object (JSON) if no attributes where given; the value of cookie - key
   *
   * expires is set as days as Integer, unless <1 (0.0055 ~ 4min)
   */
  $.docCookies = function (key, val, options) {
    var encode = encodeURIComponent;
    var decode = decodeURIComponent;
    var cookies;
    var n;
    var tmp;
    var cache = {};
    var days;

    if (val === undefined) {
      // all about reading cookies
      cookies = document.cookie.split(/;\s*/) || [];
      for (n = cookies.length; n--; ) {
        tmp = cookies[n].split('=');
        if (tmp[0]) cache[decode(tmp.shift())] = decode(tmp.join('=')); // there might be '='s in the value...
      }

      if (!key) return cache;
      // return Json for easy access to all cookies
      else return cache[key]; // easy access to cookies from here
    } else {
      // write/delete cookie
      options = options || {};

      if (val === '' || options.expires < 0) {
        // prepare deleteing the cookie
        options.expires = -1;
        // options.path = options.domain = options.secure = undefined; // to make shure the cookie gets deleted...
      }

      if (options.expires !== undefined) {
        // prepare date if any
        days = new Date();
        if (options.expires < 1) {
          days.setTime(days.getTime() + options.expires * 60 * 60 * 24 * 1000); // 60s * 60min * 24h
        } else {
          days.setDate(days.getDate() + options.expires);
        }
      }

      document.cookie =
        encode(key) +
        '=' +
        encode(val) +
        (days ? '; expires=' + days.toUTCString() : '') +
        (options.path ? '; path=' + options.path : '') +
        (options.domain ? '; domain=' + options.domain : '') +
        (options.secure ? '; secure' : '') +
        (options.sameSite ? '; sameSite=' + options.sameSite : '');
    }
  };
})(window, jQuery);
