/**
 *
 */
(function () {
  'use strict';

  ui.ModalGallery = ui.ComponentView.extend({
    name: 'ui.ModalGallery',

    modalGalleryIdIdentifier: 'mgId',

    events: {
      'show.bs.modal': 'slideGallery',
      'shown.bs.modal': 'setScrollPos',
      'hide.bs.modal': 'closeGallery',
      'hidden.bs.modal': 'setScrollPos',
    },

    initialize: function (options) {
      this.carousel = this.$el.find('.ui-js-carousel');
      this.carouselInner = this.carousel.find('.carousel-inner');
      this.items = this.carouselInner.find('.item');
      this.id = '#' + this.$el.attr('id');
      this.isHistory = this.$el.parents('.ui-js-history').length;
      this.isGridGallery = this.$el.hasClass('is-grid-gallery');
      if (!this.isHistory) {
        this.carousel.attr('data-seturl', this.id);
      }
      this.checkIfHashSet();
      this.callFirstItem = false;
      this.scrollPosition = false;
    },

    /**
     * check if anchor hash is set for overlay and trigger the link
     */
    checkIfHashSet: function () {
      var idRegEx = new RegExp('(^' + this.id + '$)|((^' + this.id + ')(?=:))');
      var mgIdRegEx = /(?::mgId=)(\d+)/;
      var idMatch = location.hash.match(idRegEx);
      var mgIdMatch = location.hash.match(mgIdRegEx);

      if (idMatch !== null && idMatch.length) {
        this.$el.modal('show');

        // if (mgIdMatch !== null && mgIdMatch.length === 2) {
        this.rotateToSlide(parseInt(mgIdMatch[1] - 1));
        // }
      }
    },

    setScrollPos: function (e) {
      if (this.isHistory && this.scrollPosition) {
        $('html,body').scrollTop(this.scrollPosition);
      }

      if (this.isGridGallery && this.scrollPosition) {
        if ($('html,body').scrollTop() !== this.scrollPosition) {
          $('html,body').scrollTop(this.scrollPosition);
        }
      }

      if (e.type === 'shown') {
        ui.trigger(ui.GlobalEvents.MODAL_GALLERY_OPEN, {
          cid: this.cid,
        });
      }

      if (this.callFirstItem) {
        ui.trigger(ui.GlobalEvents.MODAL_GALLERY_CAROUSEL_FIRST);
      }
    },

    slideGallery: function (e) {
      // if (e.relatedTarget === undefined) {
      //     return;
      // }
      if (this.isHistory) {
        this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        $('body').addClass('is-history-modal');
      }

      if (this.isGridGallery) {
        this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        $('body').addClass('ui-grid-gallery-modal');
      }

      var $button = $(e.relatedTarget);
      var index = $button.data('modal-slide-to');

      if (index === 0) {
        this.callFirstItem = true;
      }

      this.rotateToSlide(index);
    },

    rotateToSlide: function (index) {
      if (this.carousel.length && this.carousel.get(0).View) {
        // this.carousel.get(0).View.recalcItems();
        // this.carousel.get(0).View.rotate(parseInt(index), true);
        var $relatedTarget = $(this.items[index]);
        this.items.removeClass('active');
        $relatedTarget.addClass('active');
        this.carousel.get(0).View.index = index;
        this.carousel.get(0).View.reinitVars();
        if (this.checkIfAjaxItem($relatedTarget)) {
          if (!$relatedTarget.hasClass('is-loaded')) {
            this.requestItem($relatedTarget, $relatedTarget.attr('data-ajax-url'));
          }

          if (this.checkIfAjaxItem($relatedTarget.prev())) {
            if (!$relatedTarget.prev().hasClass('is-loaded')) {
              this.requestItem($relatedTarget.prev(), $relatedTarget.prev().attr('data-ajax-url'));
            }
          }

          if (this.checkIfAjaxItem($relatedTarget.next())) {
            if (!$relatedTarget.next().hasClass('is-loaded')) {
              this.requestItem($relatedTarget.next(), $relatedTarget.next().attr('data-ajax-url'));
            }
          }
        }
      }
    },

    checkIfAjaxItem: function ($elm) {
      return $elm.attr('data-ajax-url') !== undefined && $elm.attr('data-ajax-url') !== '';
    },

    requestItem: function ($target, ajaxReqUrl) {
      $.ajax({
        type: 'GET',
        dataType: 'html',
        url: ajaxReqUrl,
      })
        .done(function (data) {
          $target.html(data);
          $target.addClass('is-loaded');

          ui.trigger(ui.GlobalEvents.FRAGMENT_PLACED, $target);
          ui.trigger(ui.GlobalEvents.CAROUSEL_AJAX_ITEM, $target);
          ui.reinitbootstrapVendorWidgets($target);
          ui.bootstrapper.reinitialize({
            context: $target.get(0),
          });
        })
        .fail(function (error) {
          console.log(error);
        });
    },

    closeGallery: function () {
      this.items.find('iframe').each(function (idx, elm) {
        $(elm)[0].contentWindow.postMessage(
          '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
          '*'
        );
      });

      if (!this.isHistory) {
        var noHashURL = window.location.href.replace(/#.*$/, '');
        window.history.replaceState('', document.title, noHashURL);
      } else {
        $('body').removeClass('is-history-modal ui-grid-gallery-modal');
      }
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ModalGallery',
    View: ui.ModalGallery,
    selector: '.ui-js-modal-gallery',
    reinitialize: true,
  });

  $(ui.bootstrapModalGallery());
}).call(this);
