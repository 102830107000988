(function () {
  'use strict';

  ui.EllipsisTextComponentView = ui.ComponentView.extend({
    name: 'ui.EllipsisTextComponentView',

    events: {
      'click .ui-js-dot-more': 'showMoreText',
      'click .ui-js-dot-less': 'showLessText',
    },

    initialize: function (options) {
      if (options.moreButton) {
        this.initMoreButton(options);
      } else {
        this.$el.dotdotdot({
          watch: this.el,
        });
      }
    },

    initMoreButton: function () {
      this.$el.dotdotdot({
        watch: this.el,
        after: '.ui-js-dot-more',
        callback: function (isTruncated, orgContent) {
          if (isTruncated) {
            $(this).addClass('is-truncated');
          }
        },
      });
    },

    showMoreText: function (e) {
      var $target = $(e.currentTarget);
      var parent = $target.closest(this.$el);

      e.preventDefault();
      parent.trigger('destroy').removeClass('is-truncated').addClass('isnt-truncated');
    },

    showLessText: function (e) {
      var $target = $(e.currentTarget);
      var parent = $target.closest(this.$el);

      e.preventDefault();
      parent.removeClass('isnt-truncated');
      this.initMoreButton(parent); // Re-initialize ellipsis
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'EllipsisTextComponent',
    View: ui.EllipsisTextComponentView,
    selector: '.ui-js-ellipsis',
  });

  $(ui.bootstrapEllipsisTextComponent());
}).call(this);
