(function () {
  /**
   * Sticky element
   * with requestAnimationFrame for smooth adding the class while scrolling
   */

  'use strict';

  ui.StickyElementComponentView = ui.ComponentView.extend({
    name: 'ui.StickyElementComponentView',

    initialize: function (options) {
      this.isMobile = /(ms|xs)/.test(ui.Bootstrap.activeView);
      this.ticking = false;
      this.$sticky = $('.ui-js-sticky');

      if (!this.isMobile) {
        $(window).scroll(this.onScroll.bind(this));

        this.update();
      }
    },

    update: function () {
      this.ticking = false;
      if (window.pageYOffset > this.$sticky.offset().top) {
        this.$sticky.addClass('is-sticky');
      } else {
        this.$sticky.removeClass('is-sticky');
      }
    },

    onScroll: function () {
      this.requestTick();
    },

    requestTick: function () {
      if (!this.ticking) {
        requestAnimationFrame(this.update.bind(this));
      }
      this.ticking = true;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'StickyElementComponent',
    View: ui.StickyElementComponentView,
    selector: '.ui-js-sticky',
  });

  $(ui.bootstrapStickyElementComponent());
}).call(this);
