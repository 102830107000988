/**
 * Created by henri.podolski on 19.10.2015.
 */
(function () {
  'use strict';

  /**
   * Mediator which handles automatic closing of all header components when another one opens
   *
   */
  ui.HeaderStateManager = ui.ComponentClass.extend({
    name: 'ui.HeaderStateManager',

    initialize: function (options) {
      // object which holds the close methods
      this.allCloseMethods = {
        loginpopover: this.closeLoginPopover,
        preferredstorepopover: this.closePreferredStorePopover,
        searchbar: this.closeSearchbar,
        searchwidget: this.closeSearchWidget,
        searchflyout: this.closeSearchflyout,
        profile: this.closeProfile,
        mainmenu: this.closeMainMenu,
        safetyteaser: this.closeSafetyTeaser,
        genericflyout: this.closeGenericFlyout,
      };

      ui.on(ui.GlobalEvents.MAIN_MENU_OPEN, this.mainMenuOpens.bind(this));
      ui.on(ui.GlobalEvents.SEARCHBAR_OPEN, this.searchbarOpens.bind(this));
      ui.on(ui.GlobalEvents.SEARCHWIDGET_OPEN, this.searchWidgetOpens.bind(this));
      ui.on(ui.GlobalEvents.PROFILE_OPEN, this.profileOpens.bind(this));
      ui.on(ui.GlobalEvents.SEARCHFLYOUT_OPEN, this.searchflyoutOpens.bind(this));
      ui.on(ui.GlobalEvents.LOGIN_POPOVER_OPEN, this.loginPopoverOpens.bind(this));
      ui.on(ui.GlobalEvents.SAFETY_TEASER_OPEN, this.safetyTeaserOpens.bind(this));
      ui.on(ui.GlobalEvents.GENERIC_HEADER_FLYOUT_OPEN, this.genericFlyoutOpens.bind(this));
    },

    /**
     * iterates through this.allCloseMethod and does not trigger
     * the close method identified by idString
     * @param {idString} - included in this.allCloseMethod is the
     */
    closeAllBut: function (idString) {
      var closeMethods = this.allCloseMethods;

      idString = idString.toLowerCase();

      if (!closeMethods[idString]) {
        console.error('Cannot find ' + idString + ' in method map.');
      } else {
        for (var method in closeMethods) {
          if (
            method !== idString &&
            Object.prototype.hasOwnProperty.call(closeMethods, method) &&
            typeof closeMethods[method] === 'function'
          ) {
            closeMethods[method]();
          }
        }
        ui.trigger(ui.GlobalEvents.HEADERSTATEMANAGER_FINISHED, {
          el: idString,
        });
      }
    },
    genericFlyoutOpens: function () {
      this.closeAllBut('genericFlyout');
    },
    loginPopoverOpens: function () {
      this.closeAllBut('loginPopover');
    },
    safetyTeaserOpens: function () {
      this.closeAllBut('safetyteaser');
    },
    searchbarOpens: function () {
      this.closeAllBut('searchbar');
    },
    searchWidgetOpens: function () {
      this.closeAllBut('searchwidget');
    },
    searchflyoutOpens: function () {
      this.closeAllBut('searchflyout');
    },
    profileOpens: function () {
      this.closeAllBut('profile');
    },
    mainMenuOpens: function () {
      this.closeAllBut('mainMenu');
      ui.trigger(ui.GlobalEvents.Z_INDEX_ACTOR_OPEN);
    },
    closeMainMenu: function () {
      ui.trigger(ui.GlobalEvents.MAIN_MENU_CLOSE);
    },

    closeSearchbar: function () {
      ui.trigger(ui.GlobalEvents.SEARCHBAR_CLOSE);
    },

    closeSearchWidget: function () {
      ui.trigger(ui.GlobalEvents.SEARCHWIDGET_CLOSE);
    },

    closeSearchflyout: function () {
      ui.trigger(ui.GlobalEvents.SEARCHFLYOUT_CLOSE);
    },

    closeProfile: function () {
      ui.trigger(ui.GlobalEvents.PROFILE_CLOSE);
    },

    closeGenericFlyout: function () {
      ui.trigger(ui.GlobalEvents.GENERIC_HEADER_FLYOUT_MENU_CLOSE);
    },

    closeLoginPopover: function () {
      ui.trigger(ui.GlobalEvents.LOGIN_POPOVER_CLOSE);
    },

    closeSafetyTeaser: function () {
      ui.trigger(ui.GlobalEvents.SAFETY_TEASER_CLOSE);
    },
    closePreferredStorePopover: function () {
      $('.mui-preferred-store').trigger('mui:preferred-store:hide');
    },
  });

  $(document).ready(function () {
    new ui.HeaderStateManager();
  });
}).call(this);
