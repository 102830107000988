/**
 * @author Jan Suwart, Henri Podolski
 */

(function () {
  'use strict';

  /**
   * Customized Bootstrap-Carousel with captions below items
   * @see http://getbootstrap.com/javascript/#carousel
   *
   * Data-setup
   * @param {Boolean} autoplay - whether the carousel starts automatically
   * @param {Boolean} loop - whether the carousel should cycle continuously, false by default
   * @param {String} syncIconPrevId - unique id that should match the icon-previewFswi
   *      data-setup's syncCarouselId
   */
  ui.CarouselComponentView = ui.ComponentView.extend({
    name: 'ui.CarouselComponentView',

    // Default cycle interval
    INTERVAL: 5500,
    // Alternative classes for theming
    ALT_CLASS: 'alt-colored',
    INV_CLASS: 'is-inverted',
    IS_PREV_PREV: 'is-prev-prev',
    IS_PREV: 'is-prev',
    IS_CURRENT: 'is-current',
    IS_NEXT: 'is-next',
    IS_NEXT_NEXT: 'is-next-next',
    modalGalleryIdIdentifier: 'mgId',

    $carousel: null,
    $controls: null,
    $prevButton: null,
    $nextButton: null,
    $carouselIndicators: null,
    $items: null,

    // If true, slider will act as carousel
    loop: false,
    // Current slide index (equals slider position)
    index: 0,

    initEqualHeightCaptions: false,
    // If part of icon-preview-teaser (l540) and in large viewports (different behaviour)
    isIconPreviewAndLarge: false,
    // Swipe gesture variables
    $currentItem: null,
    touchedIndex: 0,
    itemWidth: 0,
    startTransition: '',
    startOffsetX: 0,
    touchPointX: 0,
    touchPointY: 0,
    distanceX: 0,
    distanceY: 0,
    isMoving: false,
    // Threshold for snapping next slide while swiping (0.66 = 66% visible)
    roundUpLimit: 0.5,
    // Necessary speed to outplay roundUpLimit (kinetic energy), lower number is higher speed
    swipeSpeedThreshold: 2.2,
    // Time in ms that the carousel needs to snap into position
    snapInMs: 250,
    // Swipe start timestamp
    touchStartTime: 0,
    // Transition-end string for CSS3 transition
    ON_TRANSITION_END: 'transitionend',

    events: {
      'slid.bs.carousel': 'onSlidEvent',
      'click .carousel-indicator-item:not(.active)': 'dispatchTrackingEvent',
      'click .carousel-control': 'dispatchTrackingEvent',
      'touchstart .carousel-inner .item': 'touchEvent',
      'touchmove .carousel-inner .item': 'touchEvent',
      'touchend .carousel-inner .item': 'touchEvent',
      'slide.bs.carousel': 'onSlideStartEvent',
      'click .ui-js-fullscreen-btn-wrapper': 'toggleFullscreen',
    },

    initialize: function () {
      var mobile = /(ms|xs)/.test(ui.Bootstrap.activeView);
      this.loop = typeof this.setup.loop === 'boolean' ? this.setup.loop : this.loop;
      this.isStoryTellingGallery = $(this.$el).hasClass('is-story-telling');

      if (this.isStoryTellingGallery) {
        this.sliderName = this.setup.data.sliderName.length ? this.setup.data.sliderName : '';
        const isIPhone = this.iPhone();

        if (mobile || isIPhone) {
          this.$('.collapse').collapse('hide');
        }
      }

      this.INTERVAL = this.setup.interval ? this.setup.interval * 1000 : this.INTERVAL;
      this.inviewClass = this.setup.inviewClass || null;
      this.isIconPreviewAndLarge = !mobile && this.$el.hasClass('ui-js-bk-12-iconpreview-stage');
      this.$carousel = this.$('.carousel').length ? this.$('.carousel') : this.$el;
      this.$carouselItem = this.$('.carousel-indicator-item');
      this.$controls = this.$('.carousel-indicators');
      this.$inner = this.$('.carousel-inner');
      this.$buttonControls = this.$('.carousel-control');
      this.$prevButton = this.$('.left.carousel-control');
      this.$nextButton = this.$('.right.carousel-control');
      this.$carouselIndicators = this.$('.carousel-indicators [data-target]');
      this.$items = this.$('.item');
      this.$equalHeightCaptions = this.$('.js-xs-addition');
      this.$modalGallery = this.$el.parents('.ui-js-modal-gallery').length
        ? $(this.$el.parents('.ui-js-modal-gallery')[0])
        : false;
      this.$body = $('body');
      this.$pagination = this.$('.ui-js-carousel-pagination');
      if (this.$pagination.length) {
        this.$paginationCurrentIndex = this.$pagination.find('.ui-js-current-index');
        this.$paginationCurrentIndex.text('1');
        this.$paginationSize = this.$pagination.find('.ui-js-size');
        this.$paginationSize.text(this.$items.length);
      }
      $(this.$items[this.index + 1]).addClass(this.IS_NEXT);

      if (this.$modalGallery) {
        this.$items.attr('tabindex', '0');
        this.isIE = window.navigator.msPointerEnabled;
        this.isGridGallery = this.$modalGallery.hasClass('is-grid-gallery');
      }
      this.recalcItems();
      this.checkDisableBodyScroll();

      // flag for applying equal height to xs-additions in mobile view
      this.initEqualHeightCaptions = this.$equalHeightCaptions.length > 0;

      // on bootstrap mediaquery change
      ui.on('bootstrap.activemediaquery.changed', this.applyEqualCaptionHeights.bind(this));
      ui.on('bootstrap.activemediaquery.changed', this.recalcItems.bind(this));
      ui.on(ui.GlobalEvents.CAROUSEL_AJAX_ITEM, this.reinitVars.bind(this));
      ui.on(ui.GlobalEvents.MODAL_GALLERY_OPEN, this.reinitVars.bind(this));

      // If connected to icon-preview-teaser, register event listener for slide syncing
      if (this.setup.syncIconPrevId) {
        var rotate = this.rotate.bind(this);
        var self = this;
        ui.on(ui.GlobalEvents.ICON_PREVIEW_TEASER_SLID, function (position, iconPrevId) {
          if (iconPrevId === self.setup.syncIconPrevId) {
            rotate(position, false);
          }
        });
      }

      if (this.inviewClass) {
        this.$el.on('scrollhandler:inview-once', this.initCarouselApi.bind(this));
      }

      this.checkHash();
    },

    /**
     * Toggles the slider name on fullscreen/container mode
     */
    toggleSliderName: function (full) {
      const sliderNameIsThere = this.sliderName !== '';
      const sliderNameFullscreen = 'storytelling-gallery-fullscreen';
      if (sliderNameIsThere) {
        full
          ? (this.setup.data.sliderName = sliderNameFullscreen)
          : (this.setup.data.sliderName = this.sliderName);
      }
    },

    /**
     * Toggles the fullscreen Class is-full
     */
    toggleFullClass: function (el) {
      const caption = this.$items.filter('.active').find('.caption').length
        ? this.$items.filter('.active').find('.caption')
        : false;
      const hiddenOnPlayClass = 'is-hidden-on-play-video';
      if ($(el).hasClass('is-full')) {
        $(el).removeClass('is-full');
        this.toggleSliderName(false);
        this.scrollToTopOfElement();
        if (caption && $(caption).hasClass(hiddenOnPlayClass)) {
          $(caption).removeClass(hiddenOnPlayClass);
        }
      } else {
        $(el).addClass('is-full');
        this.toggleSliderName(true);
      }
    },

    /**
     * Toggles the Class is-iphone for the iphone *fullscreen* mode
     */
    toggleIphoneClass: function (el) {
      const caption = this.$items.filter('.active').find('.caption').length
        ? this.$items.filter('.active').find('.caption')
        : false;
      const hiddenOnPlayClass = 'is-hidden-on-play-video';
      if ($(el).hasClass('is-iphone')) {
        $(el).removeClass('is-iphone');
        $(el).removeClass('is-full');
        this.toggleSliderName(false);
        this.enableBodyScroll(el);
        this.scrollToTopOfElement();
        if (caption && $(caption).hasClass(hiddenOnPlayClass)) {
          $(caption).removeClass(hiddenOnPlayClass);
        }
      } else {
        $(el).addClass('is-iphone');
        $(el).addClass('is-full');
        this.toggleSliderName(true);
        const scrollY = window.scrollY;
        window.scrollTo(0, 0);
        window.scrollTo(0, scrollY);
        this.disableBodyScroll(el);
      }
    },

    checkDisableBodyScroll: function () {
      bodyScrollLock.clearAllBodyScrollLocks();
      this.$body.removeClass('body-is-fixed');
    },

    disableBodyScroll: function (element) {
      const isIPhone = this.iPhone();
      if (isIPhone) {
        bodyScrollLock.disableBodyScroll(element);
        this.$body.addClass('body-is-fixed');
      }
    },

    enableBodyScroll: function (element) {
      bodyScrollLock.enableBodyScroll(element);
      this.$body.removeClass('body-is-fixed');
    },

    /**
     * Handles the fullscreen change
     * Calls the toggleFullClass function
     */
    handleFullscreenChange: function (e) {
      const elem = e.target;
      this.toggleFullClass(elem);
    },

    iPhone: function () {
      return ['iPhone'].includes(navigator.platform);
    },

    toggleIphoneMode: function (el) {
      const isIPhone = this.iPhone();
      if (isIPhone) {
        this.toggleIphoneClass(el);
      }
    },

    /**
     * Toggle Fullscreen
     */
    toggleFullscreen: function () {
      const elem = this.$el[0];
      const notInFullScreenStatus =
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement;

      this.toggleIphoneMode(elem);

      if (notInFullScreenStatus) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }

      if (elem.onfullscreenchange === null) {
        elem.onfullscreenchange = this.handleFullscreenChange.bind(this);
      } else if (elem.onmsfullscreenchange === null) {
        // ms supported
        elem.onmsfullscreenchange = this.handleFullscreenChange.bind(this);
      } else if (elem.onwebkitfullscreenchange === null) {
        // webkit is supported
        elem.onwebkitfullscreenchange = this.handleFullscreenChange.bind(this);
      }
    },

    /**
     * reset & recalc vars for carousel with thumbs
     */
    recalcItems: function () {
      this.widthItems = $(this.$carouselItem[0]).outerWidth();
      this.$transFormItem = $(this.$carouselItem[0]);
      this.maxItems =
        Math.trunc(this.$controls.outerWidth() / $(this.$carouselItem[0]).outerWidth()) - 1;
      if (this.maxItems >= this.$carouselItem.length - 1) {
        this.$controls.addClass('is-center');
      } else {
        this.$controls.removeClass('is-center');
      }
    },

    reinitVars: function (cidData) {
      if (cidData) {
        if (cidData.cid && cidData.cid !== this.$modalGallery.get(0).View.cid) {
          return;
        }
      }

      this.$prevButton = this.$('.left.carousel-control');
      this.$nextButton = this.$('.right.carousel-control');
      this.$items = this.$('.item');

      this.recalcItems();
      this.render();
      this.updatePagination(this.index);
    },

    /**
     * Iterates through responsive xs-addition caption, visible in xs viewport and applies
     * the maximum height to all of them to prevent shrinking of the whole container.
     */
    applyEqualCaptionHeights: function () {
      var maxHeight = 0;
      var responsiveView = ui.Bootstrap.activeView;
      var shouldInit = responsiveView === 'xs' && this.initEqualHeightCaptions;

      // nothing to do in this case:
      if (!shouldInit) {
        return;
      }

      // calculate maxHeight of all xs-addition mobile captions
      this.$equalHeightCaptions.each(function () {
        var height = $(this).outerHeight();

        maxHeight = Math.max(height, maxHeight);
      });

      // and apply the maxHeight to all
      if (maxHeight > 0) {
        this.$equalHeightCaptions.height(maxHeight);
      }
    },

    onSlidEvent: function (e) {
      if (!this.inviewClass || this.$el.hasClass(this.inviewClass)) {
        var $stopVideos = this.$items.find('.tile-video video');
        if ($stopVideos.length) {
          $stopVideos.each(function (idx, elm) {
            elm.pause();
            elm.currentTime = 0;
          });
        }

        var mobile = /(ms|xs)/.test(ui.Bootstrap.activeView);
        var $activeSlideVideo = this.$items.filter('.active').find('.tile-video video');
        if ($activeSlideVideo.length && !mobile && !this.isStoryTellingGallery) {
          $activeSlideVideo[0].play();
        }
        this.renderControls(e);
        this.dispatchSlidEvent(e);
      } else {
        this.$carousel.carousel('pause');
      }

      this.updatePagination(this.index);
    },

    onSlideStartEvent: function (e) {
      if (this.isIE && this.isGridGallery) {
        this.$modalGallery
          .find('.modal-body')
          .css('direction', e.direction === 'right' ? 'ltr' : 'rtl');
      }

      // stop youtube videos
      var iFrameVids = this.$items.find('iframe');
      if (iFrameVids.length) {
        this.$items.find('iframe').each(function (idx, elm) {
          $(elm)[0].contentWindow.postMessage(
            '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
            '*'
          );
        });
      }

      var $relatedTarget = $(e.target).hasClass('item') ? $(e.target) : $(e.relatedTarget);

      if (this.checkIfAjaxItem($relatedTarget)) {
        if (!$relatedTarget.hasClass('is-loaded')) {
          this.requestItem($relatedTarget, $relatedTarget.attr('data-ajax-url'));
        }
      }

      if (this.checkIfAjaxItem($relatedTarget.prev())) {
        if (!$relatedTarget.prev().hasClass('is-loaded')) {
          this.requestItem($relatedTarget.prev(), $relatedTarget.prev().attr('data-ajax-url'));
        }
      }

      if (this.checkIfAjaxItem($relatedTarget.next())) {
        if (!$relatedTarget.next().hasClass('is-loaded')) {
          this.requestItem($relatedTarget.next(), $relatedTarget.next().attr('data-ajax-url'));
        }
      }
    },

    checkIfAjaxItem: function ($elm) {
      return $elm.attr('data-ajax-url') !== undefined && $elm.attr('data-ajax-url') !== '';
    },

    requestItem: function ($target, ajaxReqUrl) {
      $.ajax({
        type: 'GET',
        dataType: 'html',
        url: ajaxReqUrl,
      })
        .done(function (data) {
          $target.html(data);
          $target.addClass('is-loaded');

          ui.trigger(ui.GlobalEvents.FRAGMENT_PLACED, $target);
          ui.trigger(ui.GlobalEvents.CAROUSEL_AJAX_ITEM, $target);
          ui.reinitbootstrapVendorWidgets($target);
          ui.bootstrapper.reinitialize({
            context: $target.get(0),
          });
        })
        .fail(function (error) {
          console.log(error);
        });
    },

    updatePagination: function (position) {
      this.index = position;
      this.setHashInUrl(position);
      this.$items.removeClass(this.IS_PREV_PREV);
      this.$items.removeClass(this.IS_PREV);
      this.$items.removeClass(this.IS_CURRENT);
      this.$items.removeClass(this.IS_NEXT);
      this.$items.removeClass(this.IS_NEXT_NEXT);
      $(this.$items[position]).addClass(this.IS_CURRENT);
      if (position > 0) {
        $(this.$items[position - 1]).addClass(this.IS_PREV);
      }
      if (position === 1) {
        $(this.$items[position - 1]).addClass(this.IS_PREV_PREV);
      }
      if (position > 1) {
        $(this.$items[position - 2]).addClass(this.IS_PREV_PREV);
      }
      $(this.$items[position + 1]).addClass(this.IS_NEXT);
      $(this.$items[position + 1]).addClass(this.IS_NEXT_NEXT);
      $(this.$items[position + 2]).addClass(this.IS_NEXT_NEXT);
      if (this.$pagination.length) {
        this.$paginationCurrentIndex.html(position + 1);
      }
      $(this.$items[position]).focus();
    },

    /**
     * Rotates carousel to desired position
     * @param {Number} position - new position number
     * @param {Boolean} useApi - whether to animate via API or manipulates css classes directly
     */
    rotate: function (position, useApi) {
      var self = this;
      if (!isNaN(position) && position >= 0) {
        if (useApi) {
          this.$carousel.carousel(position);
        } else {
          var $newItem = this.$carousel.find('.item:nth-child(' + (position + 1) + ')');

          this.$items.css({ transition: 'none' });
          this.$carousel.find('.item.active').removeClass('active');
          $newItem.addClass('active');

          this.renderControls();
          this.renderIndicators();

          // Defer resetting css styles
          setTimeout(function () {
            self.$items.attr('style', '');
          }, 0);
          // Re-initialize scroll-handler containers (hidden carousel items have changed)
          ui.trigger(ui.GlobalEvents.CAROUSEL_SLID);
          // Mimic Bootstrap's slide event (necessary for accessibility)
          $newItem.trigger('slide.bs.carousel', $newItem);
        }
      }
      this.updatePagination(position);
    },

    initCarouselApi: function () {
      var cycleInterval = this.setup.autoplay ? this.INTERVAL : false;

      // Initialize carousel via API
      this.$carousel.carousel({
        wrap: this.loop,
        interval: cycleInterval,
      });
    },

    render: function () {
      var generatedClientId = 'carousel-component-' + this.cid;

      // Attach client-side generatedId to carousel and its controls
      this.$carousel.attr('id', generatedClientId);
      this.$carouselIndicators.attr('data-target', '#' + generatedClientId);
      this.$prevButton.attr('href', '#' + generatedClientId);
      this.$nextButton.attr('href', '#' + generatedClientId);

      this.applyEqualCaptionHeights();

      this.renderControls();

      this.initCarouselApi();

      return this;
    },

    /**
     * Sets 'is-inverted' or 'alt-colored' css class on controls (and preview items), if current slide contains
     * 'is-inverted'. Hides (left and right) control when a non-looped carousel slides to its left or right edge.
     */
    renderControls: function () {
      var $slideActive = this.$('.carousel-inner .item.active');

      // Toggles is-inverted and alt-colored if found in active slide
      this.$controls.toggleClass(this.INV_CLASS, $slideActive.hasClass(this.INV_CLASS));
      this.$controls.toggleClass(this.ALT_CLASS, $slideActive.hasClass(this.ALT_CLASS));
      this.$buttonControls.toggleClass(this.INV_CLASS, $slideActive.hasClass(this.INV_CLASS));

      if (!this.loop) {
        this.$prevButton.toggleClass('hidden', $slideActive.index() === 0);
        this.$nextButton.toggleClass('hidden', $slideActive.index() === this.$items.length - 1);
      } else {
        // show both buttons in loop mode
        this.$prevButton.removeClass('hidden');
        this.$nextButton.removeClass('hidden');
      }

      var viewport = ui.Bootstrap ? ui.Bootstrap.activeView : '';

      if (this.$el.hasClass('has-thumbnails') && !/xs|ms/.test(viewport)) {
        var activeIndex = this.$carouselItem.index(this.$carouselItem.filter('.active'));
        if (activeIndex >= this.maxItems) {
          if (activeIndex >= this.$carouselItem.length - 1) {
            this.$transFormItem.css(
              'marginLeft',
              -((activeIndex - this.maxItems) * this.widthItems) + 'px'
            );
          } else {
            this.$transFormItem.css(
              'marginLeft',
              -((activeIndex + 1 - this.maxItems) * this.widthItems) + 'px'
            );
          }
        } else if (activeIndex < this.maxItems) {
          this.$transFormItem.css('marginLeft', 0);
        }
        if (this.maxItems >= this.$carouselItem.length - 1) {
          this.$controls.addClass('is-center');
        } else {
          this.$controls.removeClass('is-center');
        }
      } else if (this.$el.hasClass('has-thumbnails') && /xs|ms/.test(viewport)) {
        this.$transFormItem.css('marginLeft', 0);
      }
    },

    /**
     * Sets 'active' classes on control indicators (if available)
     */
    renderIndicators: function () {
      var $slideActive = this.$('.carousel-inner .item.active');
      var indexActive = $slideActive.index();

      if (this.$carouselIndicators.length && this.$carouselIndicators[indexActive]) {
        this.$carouselIndicators.removeClass('active');
        this.$carouselIndicators.eq(indexActive).addClass('active');
      }
    },

    /**
     * Broadcasts slid event, necessary for scroll handler and icon-preview-teaser
     * @param {Event} e - Bootstrap's event
     */
    dispatchSlidEvent: function (e) {
      this.index = e.relatedTarget ? $(e.relatedTarget).index() : 0;

      if (this.setup.syncIconPrevId) {
        // Sync with icon-preview slide
        ui.trigger(ui.GlobalEvents.CAROUSEL_SLID, {
          event: e,
          position: this.index,
          carouselId: this.setup.syncIconPrevId,
        });
      } else {
        // Re-initialize scroll-handler containers (hidden carousel items have changed)
        ui.trigger(ui.GlobalEvents.CAROUSEL_SLID);
      }

      // isManualSlide is set in the user interaction method
      // dispatchTrackingEvent, if it is not called isManualSlide is false
      if (!this.isManualSlide) {
        // autoplay slides always right
        this.$el.trigger('carousel:slid-auto', {
          direction: 'right',
          position: this.index,
          currentTarget: this.el,
        });
      } else {
        this.isManualSlide = false;
        if (this.setup.syncIconPrevId || this.isStoryTellingGallery) {
          const $carouselView = $(e.currentTarget).prop('View');

          $(e.currentTarget).trigger('carousel:slid-manually', {
            direction: e.direction === 'right' ? 'left' : 'right',
            currentTarget: this.el,
            nextIndex: $carouselView.index,
          });
        }
      }
    },

    /**
     * set hash url for current carousel item if in gallery-modal mode, see modal-gallery.js
     */
    setHashInUrl: function (position) {
      if (position === undefined) {
        position = this.index;
      }
      this.setUrl = this.$el.attr('data-seturl') ? this.$el.attr('data-seturl') : false;
      if (this.setUrl && !this.$el.parents('.ui-js-history').length) {
        var newHash = position > -1 ? this.setUrl + ':mgId=' + parseInt(position + 1) : this.setUrl;
        location.hash = newHash;
      }
    },

    /**
     * Triggers custom event enriched with data for tracking.js lib
     * @param {Event} e - click or touch event
     * @param {Number} [swipeDirection] - swipe direction as Number -1, +1
     * @param {Number} [targetSlidePos] - optional target slider position (used for tab-slider syncing)
     */
    dispatchTrackingEvent: function (e, swipeDirection, targetSlidePos) {
      var $target = $(e.currentTarget);
      var targetPos = $target.length ? $target.index() : 0;
      var direction;

      if (swipeDirection && !isNaN(swipeDirection)) {
        // Direction is known from manual swipe gesture
        direction = swipeDirection < 0 ? 'left' : 'right';
      } else {
        // Determine slide direction either on class name or dom index
        switch (true) {
          case $target.is('.carousel-control.right'): {
            direction = 'right';
            break;
          }
          case $target.is('.carousel-control.left'): {
            direction = 'left';
            break;
          }
          case targetPos > this.index: {
            direction = 'right';
            break;
          }
          case targetPos < this.index: {
            direction = 'left';
            break;
          }
        }
      }

      this.isManualSlide = true;

      if (this.isStoryTellingGallery) {
        return;
      }
      this.$el.trigger('carousel:slid-manually', {
        targetPos: targetSlidePos,
        direction,
        currentTarget: this.el,
      });
    },

    /**
     * Translates touch move event into css transition
     * @param {Object} transform - configuration object containing css properties
     * @param {Object} transform.$item - jQuery object that will be transformed
     * @param {Number} transform.width - total object width in px
     * @param {Number} transform.offset - offset for translate transition in px
     * @param {Number} [transform.left] - left offset in px (for adjacent slide)
     * @param {Number} [transform.right] - right offset in px (for adjacent slide)
     * @param {Number} [transform.duration] - transition duration in ms if > 0
     */
    transformItemMove: function (transform) {
      if (!transform || !transform.$item || !transform.$item.length) {
        return;
      }
      if (transform.width) {
        transform.$item.css({
          display: 'block',
          position: 'absolute',
          transform: 'translate3d(' + (transform.offset || 0) + 'px, 0, 0)',
          transitionDuration: (transform.duration || 0) + 'ms',
          width: transform.width,
          left: transform.left || 'auto',
          right: transform.right || 'auto',
          top: this.$inner.css('paddingTop'),
        });
      } else {
        transform.$item.css({
          transform: 'translate3d(' + (transform.offset || 0) + 'px, 0, 0)',
          transitionDuration: (transform.duration || 0) + 'ms',
        });
      }
    },

    /**
     * Finalizes css transition on touch end, relays to transformItemMove with static values
     *
     * @param {Number} offset - offset for translate transition in px
     */
    transformItemEnd: function (offset) {
      this.transformItemMove({
        $item: this.$currentItem,
        duration: this.snapInMs,
        offset,
      });
      if (this.distanceX > 0) {
        this.transformItemMove({
          $item: this.$items.eq((this.touchedIndex + 1) % this.$items.length),
          duration: this.snapInMs,
          offset,
        });
      } else {
        this.transformItemMove({
          $item: this.$items.eq(this.touchedIndex - 1),
          duration: this.snapInMs,
          offset,
        });
      }
    },

    /**
     * Performs touch recognition and applies css translate on carousel slides
     * @param {Event} e - touch event, i.e. touchstart, touchmove, touchend
     */
    touchEvent: function (e) {
      var event = null;
      var self = this;

      if (e.originalEvent && e.originalEvent.changedTouches && !this.isIconPreviewAndLarge) {
        event = e.originalEvent.changedTouches[0];
      } else {
        return;
      }

      switch (e.type) {
        case 'touchstart': {
          this.$currentItem = $(e.currentTarget);
          this.touchedIndex = $(e.currentTarget).index();
          this.itemWidth = this.$items.filter('.active').outerWidth();
          this.touchPointX = event.pageX;
          this.touchPointY = event.pageY;
          this.touchStartTime = new Date().getTime();
          this.$carousel.carousel('pause');

          if (!this.isMoving) {
            this.$currentItem.addClass('is-touched');
          }

          this.isMoving = true;

          break;
        }
        case 'touchmove': {
          // Only translate the track if a touchstart has been recognized before
          if (this.touchPointX) {
            this.distanceX = this.touchPointX - event.pageX;
            this.distanceY = this.touchPointY - event.pageY;

            // Cancel swipe if distance y > distance x
            if (
              Math.abs(this.distanceX) < Math.abs(this.distanceY) &&
              !($(self.$el[0]).hasClass('is-full') || $(self.$el[0]).hasClass('is-iphone'))
            ) {
              this.touchPointX = 0;
              break;
            }
            // Stop swipe at slide's end (item width)
            if (this.distanceX >= this.itemWidth || -this.distanceX >= this.itemWidth) {
              break;
            }

            // Prevent scroll
            e.preventDefault();

            if (!this.setup.slideAllItems) {
              // Move the touched item equally to the touched x-distance
              this.transformItemMove({
                $item: this.$currentItem,
                offset: -this.distanceX - this.startOffsetX,
              });

              if (this.distanceX > 0) {
                // Swipe right, also move the next slide
                this.transformItemMove({
                  $item: this.$items.eq((this.touchedIndex + 1) % this.$items.length),
                  offset: -this.distanceX - this.startOffsetX,
                  width: this.itemWidth,
                  left: this.itemWidth,
                });
              } else {
                // Swipe left, also move the show previous slide
                this.transformItemMove({
                  $item: this.$items.eq(this.touchedIndex - 1),
                  offset: -this.distanceX - this.startOffsetX,
                  width: this.itemWidth,
                  right: this.itemWidth,
                });
              }
            }
          }
          break;
        }
        case 'touchend': {
          if (this.isMoving) {
            var distance;
            var ratio = this.distanceX / this.itemWidth;
            var time = new Date().getTime() - this.touchStartTime;
            var speed = time / Math.abs(this.distanceX);
            if (Math.abs(speed) < this.swipeSpeedThreshold) {
              // The high speed of the swipe outweighs minimum distance threshold
              distance = ratio > 0 ? Math.ceil(ratio) : Math.floor(ratio);
            } else {
              distance = this.round(ratio);
            }
            var direction = Math.sign(distance);
            var total = this.$items.length;
            var targetPos = direction === -1 ? this.touchedIndex - 1 : this.touchedIndex + 1;

            var targetSlide = targetPos >= 0 ? targetPos % total : (total + targetPos) % total;
            var tmpOffset = -this.itemWidth * direction;

            // Define rollback function for css styles
            var removeTmpStyles = function () {
              self.$currentItem.attr('style', '');
              self.$items.eq((self.touchedIndex + 1) % self.$items.length).attr('style', '');
              self.$items.eq(self.touchedIndex - 1).attr('style', '');
              self.$items.removeClass('is-touched');
            };

            if (distance !== 0) {
              if (this.setup.slideAllItems) {
                targetPos = direction === -1 ? this.index - 1 : this.index + 1;

                if (targetPos > -1 && targetPos < this.$items.length) {
                  this.rotate(targetPos, false);
                  this.updatePagination(targetPos);
                  this.dispatchTrackingEvent(e, direction, targetPos);

                  this.$el.trigger(ui.GlobalEvents.CAROUSEL_TOUCHEND, {
                    currentTarget: this.el,
                    targetPos,
                  });
                }
              } else {
                // Distance above threshold, apply translate and call rotate function
                this.transformItemEnd(tmpOffset);
                // Set parameters for rotate callback after transition ends
                var rotate = this.rotate.bind(this, targetSlide, false);
                this.updatePagination(targetSlide);
                this.callAfterTransition(removeTmpStyles, 'transform');
                this.callAfterTransition(rotate, 'transform');
                this.dispatchTrackingEvent(e, direction, targetSlide);

                this.$el.trigger(ui.GlobalEvents.CAROUSEL_TOUCHEND, {
                  currentTarget: this.el,
                  targetPos: targetSlide,
                });
              }
            } else {
              // Slide did not travel far enough, reset to initial state
              this.transformItemEnd(0);
              this.callAfterTransition(removeTmpStyles, 'transform');
            }

            if (this.distanceY < -150 && self.isStoryTellingGallery) {
              /* if user swipes down in story telling gallery - fullscreen should be closed */
              if ($(self.$el[0]).hasClass('is-full')) {
                self.toggleFullClass(self.$el[0]);
              }
              if ($(self.$el[0]).hasClass('is-iphone')) {
                self.toggleIphoneClass(self.$el[0]);
              }
            }

            this.touchPointX = 0;
            this.distanceX = 0;
            this.isMoving = false;
          }
          break;
        }
      }
    },

    scrollToTopOfElement: function () {
      const toTopOfElement = this.$el.offset().top - 30;
      $('html,body').animate({ scrollTop: toTopOfElement }, 250);
    },

    /**
     * Installs both a transitionEnd listener and a backwards-compatible timeout. Executes callback
     * after the transition property has been captured
     * @param {Function} callback - the function to be executed after the transition
     * @param {String} propertyName - propertyName, i.e. 'left', 'transition'
     */
    callAfterTransition: function (callback, propertyName) {
      var self = this;
      // Undelegate events to prevent sliding while rendering
      this.undelegateEvents();

      this.$currentItem.on(this.ON_TRANSITION_END, function (e) {
        // Only listen to property name
        if (e.originalEvent && e.originalEvent.propertyName !== propertyName) {
          return;
        }
        // Only listen on slided item
        if (e.target.className && !/item/gi.test(e.target.className)) {
          return;
        }

        self.$currentItem.off(self.ON_TRANSITION_END);

        // Call the callback function
        callback();
        self.delegateEvents();
      });
    },

    /**
     * Set anchor hash for carousel items
     */
    checkHash: function () {
      if (
        this.$carouselItem &&
        this.$carouselItem.length > 0 &&
        window.location.hash &&
        window.location.hash.charAt(1) !== '?'
      ) {
        var $targetItem = this.$carouselItem.filter(function (idx, elm) {
          return window.location.hash === '#' + elm.id;
        });

        var $targetIndex = $targetItem ? this.$carouselItem.index($targetItem) : null;

        if (this.setup.syncIconPrevId !== undefined && $targetIndex) {
          ui.trigger(
            ui.GlobalEvents.ICON_PREVIEW_TEASER_SLID,
            $targetIndex,
            this.setup.syncIconPrevId
          );
        } else if ($targetIndex) {
          this.rotate($targetIndex, false);
        }
      }
    },

    /**
     * Custom round function, uses bitwise OR (|)
     * @param {Number} n - float
     * @returns {Number} - rounded up if roundUpLimit reached
     */
    round: function (n) {
      if (n > 0) return (n + this.roundUpLimit) | 0;
      else {
        n = -n;
        return -(n + this.roundUpLimit) | 0;
      }
    },
    /**
     * Unbinds all event listeners and removes it's nodes. Called by ui.js
     */
    closeView: function () {
      this.unbind();
      this.remove();
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'CarouselComponent',
    View: ui.CarouselComponentView,
    selector: '.ui-js-carousel',
    reinitialize: true,
  });

  $(ui.bootstrapCarouselComponent());
}).call(this);
