/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Configurable parallax View for scroll-callback execution. Needs scroll-handler to work.
   *
   * data-setup
   * @params {String} paralaxEl - selector for child node that should be affected
   * @params {String} [direction] - transition direction (i.e. 'up' or 'down')
   * @params {String} [effect] - transition effect (i.e. 'headline', 'position' or 'rotate')
   * @params {Number} [topOffsetLimit] - upper offset limit in pixel, i.e. 0 (=0px)
   * @params {Number} [slowdown] - slowdown factor regarding scroll distance (i.e. 1 - 16)
   */
  ui.ParallaxComponentView = ui.ComponentView.extend({
    name: 'ui.ParallaxComponentView',

    // Correctional factor for offset (defined by direction parameter)
    correctFactor: 1,
    // Limit (offset top in px) where the effect should start
    limit: 0,
    // Original background-position values
    origBgPosX: 0,
    origBgPosY: 0,
    pxCallback: null,
    isMobile: false,
    $parallaxEl: null,
    $document: null,

    defaults: {
      direction: 'up',
      effect: 'headline',
      slowdown: 12,
    },

    initialize: function () {
      this.$document = $(document);
      this.$parallaxEl = this.setup.paralaxEl ? this.$(this.setup.paralaxEl) : this.$el;

      if (this.setup.direction && this.setup.direction !== this.defaults.direction) {
        this.correctFactor = 1;
      } else {
        this.correctFactor = -1;
      }
      this.limit =
        this.setup.topOffsetLimit !== undefined ? Number(this.setup.topOffsetLimit) : this.limit;

      if (!this.isMobile && this.$parallaxEl.length) {
        this.$parallaxEl = this.$parallaxEl.eq(0);

        // Install listener for inview-callbacks
        var parallaxCallback = this.paralaxCallback.bind(this);
        this.$el.on('scrollhandler:inview', parallaxCallback);

        // Install carousel listener (parallax might be in invisible container)
        var recalculate = this.calculate.bind(this);
        ui.on(ui.GlobalEvents.CAROUSEL_SLID, recalculate);
        ui.on('bootstrap.activemediaquery.changed', recalculate);

        this.calculate();

        // Switch for the desired effect (if available)
        switch (this.setup.effect) {
          case 'headline': {
            this.pxCallback = this.headlineTransition;
            break;
          }
          case 'position': {
            var regex = /(\d+)%\s(\d+)%$/;
            var bgPos = this.$parallaxEl.css('background-position');
            var bgPosX = bgPos.replace(regex, '$1');
            var bgPosY = bgPos.replace(regex, '$2');
            this.origBgPosX = parseFloat(bgPosX);
            this.origBgPosY = parseFloat(bgPosY);

            this.$parallaxEl.addClass('parallax-bg');
            this.pxCallback = this.backgroundPosition;
            break;
          }
          case 'rotate': {
            this.pxCallback = this.skewBackground;
            break;
          }
        }
      }
    },

    render: function () {
      this.$parallaxEl.toggleClass('parallax-transition', !this.isMobile);
      if (!this.isMobile) {
        this.pxCallback && this.pxCallback.call(this);
      }
    },

    /**
     * Used for headline transform
     */
    headlineTransition: function () {
      this.$parallaxEl.css({
        transform: 'translate3d(0, ' + this.offset + 'px, 0)',
      });
    },

    /**
     * Used for background image position change
     */
    backgroundPosition: function () {
      var posY = this.origBgPosY + this.offset;
      this.$parallaxEl.css({
        'background-position': this.origBgPosX + '% ' + posY + '%',
      });
    },

    /**
     * Experimental
     */
    skewBackground: function () {
      this.$parallaxEl.css({
        transform: 'perspective( 400px ) rotateX(' + this.offset + 'deg)',
      });
    },

    /**
     * Calculate the current offset depending on document scroll and possible limits.
     * Necessary for carousel items where stage in not yet in view.
     */
    calculate: function () {
      var offset;
      this.isMobile = /xs/.test(ui.Bootstrap ? ui.Bootstrap.activeView : '');
      // Only recalculate if the element is visible
      if (this.$parallaxEl.is(':visible')) {
        this.scroll = this.$document.scrollTop() - this.$parallaxEl.offset().top;
        if (this.limit !== 0 && this.scroll < this.limit) {
          offset = (this.limit / this.setup.slowdown) * this.correctFactor;
        } else {
          offset = (this.scroll / this.setup.slowdown) * this.correctFactor;
        }
        this.offset = Math.ceil(offset);
        this.render();
      }
    },

    /**
     * Applies parallax effect on each event call
     * @param {Event} event
     * @param {Object} container - container holding position information about the element
     */
    paralaxCallback: function (event, container) {
      // Apply parallax effect if container is inview and activeView is not mobile
      if (container.inViewport && !this.isMobile) {
        var containerTop = container.top;
        var containerScroll = this.$document.scrollTop() - containerTop;
        this.offset = Math.floor((containerScroll / this.setup.slowdown) * this.correctFactor);

        if (this.limit !== 0 && containerScroll < this.limit) {
          // The scroll limit was reached, prevent rendering
          return;
        }
        this.render();
      }
    },
    /**
     * Unbinds all event listeners and removes it's nodes. Called by ui.js
     */
    closeView: function () {
      this.unbind();
      this.remove();
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ParallaxComponent',
    View: ui.ParallaxComponentView,
    selector: '.ui-js-paralax',
    reinitialize: true,
  });

  $(ui.bootstrapParallaxComponent());
}).call(this);
