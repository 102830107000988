/* Light YouTube Embeds by @labnol */
/* Web: http://labnol.org/?p=27941 */
/* Extended by Nils von Rymon-Lipinski (Aperto GmbH - An IBM Company) */

(function () {
  'use strict';

  ui.YoutubeVideo = ui.ComponentView.extend({
    name: 'ui.YoutubeVideo',

    default: {
      // volume
      volume: '0', // 0 (default), 50, 75
      // autoplay
      autoplay: '0', // on = 1, off = 0
      // loop
      loop: '0', // on = 1, off = 0
      // start time playback
      start: null, // should be a positive integer
      // End playback
      end: null, // should be a positive integer
      // Controls
      controls: '1', // on = 1,2, off = 0 (2 should be better for iframe)
      // Subtitle
      cc_load_policy: '0', // on = 1, off = 0
      // Fullscreen
      fs: '1', // on = 1, off = 0
      // Keyboard control
      disablekb: '0', // on = 0, off = 1
      // Video Annotations
      iv_load_policy: '1', // on = 1, off = 3
      // Related videos
      rel: '1', // on = 1, off = 0
      // Show info
      showinfo: '1', // on = 1, off = 0
      // Display title - watch later - share
      ecver: '1', // on = 1, off = 2
      // Yt logo from bottom
      modestbranding: false, // logo on = false, logo off = true
    },

    initialize: function (options) {
      this.isInStorytellingGallery = this.$el.closest('.is-story-telling').length;
      this.useYtIframeApi = false;
      this.isYtApi = !!options.yt;
      this.iframeLoaded = false;
      this.addYtIframeApi(options.yt);
      this.bindEvents();
      this.setViewportVars();
      this.loadVideo();
    },

    addYtIframeApi: function (yt) {
      if (!yt) {
        return;
      }
      this.setYtParameters(yt);

      if ($('#ytiframeapi').length === 0) {
        var ytIframeApiScript = document.createElement('script');
        ytIframeApiScript.setAttribute('async', '');
        ytIframeApiScript.setAttribute('defer', '');
        ytIframeApiScript.setAttribute('id', 'ytiframeapi');
        ytIframeApiScript.setAttribute('src', '//www.youtube.com/iframe_api');
        document.querySelector('head').appendChild(ytIframeApiScript);
      }

      if (window.onYouTubeIframeAPIReady === undefined) {
        window.onYouTubeIframeAPIReady = function () {
          ui.trigger(ui.GlobalEvents.YT_API_READY);
        };
      }
    },

    setYtParameters: function (yt) {
      if (yt) {
        this.playerVars = {
          autoplay: yt.autoplay || this.default.autoplay,
          loop: yt.loop || this.default.loop,
          controls: yt.controls || this.default.controls,
          cc_load_policy: yt.cc_load_policy || this.default.cc_load_policy,
          fs: yt.fs || this.default.fs,
          disablekb: yt.disablekb || this.default.disablekb,
          iv_load_policy: yt.iv_load_policy || this.default.iv_load_policy,
          rel: yt.rel || this.default.rel,
          showinfo: yt.showinfo || this.default.showinfo,
          ecver: yt.ecver || this.default.ecver,
          modestbranding: yt.modestbranding || this.default.modestbranding,
        };

        if (yt.start) {
          this.playerVars.start = yt.start;
        }

        if (yt.end) {
          this.playerVars.end = yt.end;
        }

        if (this.playerVars.loop) {
          this.playerVars.playlist = this.el.dataset.id;
        }

        this.playerVars.playsinline = 1;
        this.playerVars.enablejsapi = 1;

        this.playerVarsJs = {
          volume: yt.volume || this.default.volume,
        };
      }
    },

    bindEvents: function () {
      var inViewCallback = this.inViewCallback.bind(this);
      var onYtAPIReadyCallback = this.onYtAPIReady.bind(this);
      this.$el.on('scrollhandler:inview-once', inViewCallback);
      ui.on(ui.GlobalEvents.YT_API_READY, onYtAPIReadyCallback);
    },

    unbindEvents: function () {
      this.$el.off('scrollhandler:inview-once');
    },

    setViewportVars: function () {
      this.isMobile = /xs|ms/.test(ui.Bootstrap.activeView);
      this.isTablet = /sm/.test(ui.Bootstrap.activeView);
    },

    onYtAPIReady: function () {
      if (this.isYtApi) {
        this.useYtIframeApi = true;
      }
    },

    inViewCallback: function (e) {
      this.setViewportVars();
      if (this.isMobile || this.isTablet) {
        this.labnolIframe();
      } else if (!this.isMobile && !this.isTablet && this.playerVars && this.playerVars.autoplay) {
        this.labnolIframe();
      }
    },

    loadVideo: function () {
      var div;
      var elm = this.el;

      div = document.createElement('div');
      div.setAttribute('data-id', elm.dataset.id);
      if (this.isYtApi) {
        this.ytPlayerId = this.generateUniqueYtId(0);
        div.setAttribute('id', this.ytPlayerId);
      }
      this.labnolThumb(div, elm.dataset.id);
      div.onclick = this.labnolIframe.bind(this);
      elm.appendChild(div);
    },

    generateUniqueYtId: function (uuid) {
      if (document.getElementById('yt-vid-' + uuid + '-' + this.el.dataset.id) === null) {
        return 'yt-vid-' + uuid + '-' + this.el.dataset.id;
      } else {
        return this.generateUniqueYtId(uuid + 1);
      }
    },

    labnolThumb: function (div, id) {
      var image = new Image();
      var playDiv = document.createElement('DIV');
      playDiv.className = 'play';
      if (this.isMobile) {
        image.src = 'https://i.ytimg.com/vi/' + id + '/sddefault.jpg';
      } else {
        image.src = 'https://i.ytimg.com/vi/' + id + '/maxresdefault.jpg';
      }
      div.appendChild(image);
      div.appendChild(playDiv);
    },

    labnolIframe: function () {
      if (this.isYtApi) {
        var self = this;
        this.player = new YT.Player(this.ytPlayerId, {
          videoId: self.el.dataset.id,
          playerVars: self.playerVars,
          events: {
            onReady: self.onPlayerReady.bind(self),
            onStateChange: self.onPlayerStateChange.bind(self),
          },
        });
      } else {
        var iframe = document.createElement('iframe');
        var parameters = '?enablejsapi=1&version=3&rel=0&iv_load_policy=3&autoplay=1';
        var embed = 'https://www.youtube.com/embed/' + this.el.dataset.id + parameters;

        iframe.setAttribute('src', embed);
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allowfullscreen', '1');
        this.$el.html(iframe);
      }
      this.iframeLoaded = true;
      this.unbindEvents();
    },

    onPlayerReady: function (event) {
      event.target.setVolume(parseInt(this.playerVarsJs.volume));
    },

    onPlayerStateChange: function (e) {
      const caption = this.$el.closest('.item.active').find('.caption').length
        ? this.$el.closest('.item.active').find('.caption')
        : false;
      if (this.isInStorytellingGallery && caption) {
        if (e.data === YT.PlayerState.PLAYING) {
          this.hideCaptionOnPlay(caption);
        } else if (e.data === YT.PlayerState.PAUSED) {
          this.showCaptionOnPauseOrEnd(caption);
        } else if (e.data === YT.PlayerState.ENDED) {
          this.player.stopVideo();
          this.showCaptionOnPauseOrEnd(caption);
        }
      }
    },

    hideCaptionOnPlay: function (caption) {
      $(caption).addClass('is-hidden-on-play-video');
      $(caption).removeClass('is-paused-video');
    },

    showCaptionOnPauseOrEnd: function (caption) {
      $(caption).removeClass('is-hidden-on-play-video');
      $(caption).addClass('is-paused-video');
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'YoutubeVideoComponent',
    View: ui.YoutubeVideo,
    selector: '.ui-js-youtube-player',
  });

  $(ui.bootstrapYoutubeVideoComponent());
}).call(this);
