/**
 * @author Henri Podolski, Peter Dematté
 * Sets data attribute to current bootstrap mediaquery short code
 * Depends on _js.scss where :after content is set vie media queries
 * @see CSS class .ui-mediaquery-js-provider
 * @dependson .ui-mediaquery-js-provider CSS setting
 */

$(function () {
    var $body = $('body');
    var mqProvider = $('.ui-mediaquery-js-provider');

    if (!mqProvider || mqProvider.length === 0) {
        $body.append(
            '<div class="ui-mediaquery-js-provider" aria-hidden="true"></div>'
        );
        mqProvider = $('.ui-mediaquery-js-provider');
    }

    mqProvider = mqProvider[0];

    provideMediaQuery();

    $(window).on("load resize orientationchange retry", _.throttle(() => provideMediaQuery(), 200, {leading: true}));

    function provideMediaQuery() {
        var previousView = (ui.Bootstrap && ui.Bootstrap.activeView) || '';
        var activeView = window.getComputedStyle(mqProvider, ':after');
        if (activeView) {
            activeView =
                activeView.getPropertyValue('content').replace(/"|'/g, '') ||
                'xs';
        } else {
            $(window).trigger('retry');
        }
        $body.attr('data-bootstrap-activemediaquery', activeView);

        if (previousView !== activeView) {
            ui.Bootstrap = ui.Bootstrap || {};
            ui.Bootstrap.previousView = ui.Bootstrap.activeView;
            ui.Bootstrap.activeView = activeView;
            ui.trigger('bootstrap.activemediaquery.changed', activeView);
        }
    }
});
