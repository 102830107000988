/**
 *
 * @author Bastian Runge
 *
 */
(function () {
  'use strict';

  ui.ModalDialogFullscreen = ui.ComponentView.extend({
    name: 'ui.ModalDialogFullscreen',

    events: {
      click: 'hideModal',
      'show.bs.modal': 'zIndexActor',
    },

    initialize: function (options) {
      this.$modalFullscreen = this.$el.find('.modal-fullscreen');
      this.$imageFullscreen = this.$el.find('.image-fullscreen');
    },

    hideModal: function () {
      if (this.$modalFullscreen.length > 0 || this.$imageFullscreen.length > 0) {
        this.$el.modal('hide');
      }
    },

    zIndexActor: function () {
      ui.trigger(ui.GlobalEvents.Z_INDEX_ACTOR_OPEN);
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ModalDialogFullscreenComponent',
    View: ui.ModalDialogFullscreen,
    selector: '.modal',
    reinitialize: false,
  });

  $(ui.bootstrapModalDialogFullscreenComponent());
}).call(this);
