// scroll into view
// scroll direction can be seen by inview-top.inview.bottom or inview-bottom.inview.top
/**
 * @author Peter Dematté
 * @modified by Nils von Rymon-Lipinski
 * Library to be used to add class names to elements that show in viewport
 * by scrolling, resizing or just by being rendered on the beginning of the page.
 *
 * Init by new IsInViewport({options}); options are:
 *    dataAttribute: what data attribute to look for (also keeps element options)
 *    className: the main className to be added to elements
 *    offsetTop and Bottom: offset (delay) on how far the elements has to inside the viewport to be triggered
 *    delay: the setTimeout time used for throtteling
 *
 * All elements can also hold options (set with attribute (data-inview="{options...}")):
 *    offsetTop and Bottom: see above (can be overwritten for each element...)
 *    className: see above...
 *    stayInView: lets the class name be resitible after first appearence
 *    callback: name of the callback on after render; callbacks must be attached to IsInViewport like:
 *              IsInViewport.paralaxCallback = function(container) {}; ... 'this' will be instance
 */

window.ui.iframe = false;
window.ui = window.ui || {};

// fix IE11 unspecified error by returning what the W3C spec says, about default value for elements
// not in the DOM
HTMLElement.prototype._getBoundingClientRect = HTMLElement.prototype.getBoundingClientRect;
HTMLElement.prototype.getBoundingClientRect = function () {
  try {
    return this._getBoundingClientRect();
  } catch (e) {
    return { x: 0, y: 0, width: 0, height: 0 };
  }
};

window.ui.IsInViewport = (function (window, undefined) {
  // 10.53, 3.75, 1.43 KB

  'use strict';

  var _document,
    _body,
    _rLimit = '(?:\\s+|\\b)',
    _animate =
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      function (cb) {
        cb();
      },
    functionTimer = 0,
    scrollTop = 0,
    scrollBottom = 0,
    scrollHeight = 0,
    IsInViewport = function (options) {
      this.options = {
        dataAttribute: 'data-inview',
        className: 'inview',
        offsetTop: 0,
        offsetBottom: 0,
        delay: 100,
        watchTimer: 100,
        requestAnimationFrame: true,
        // callback: {'some': function(){}, ...}
      };
      initMe(this, options || {});
    },
    initMe = function (that, options) {
      var timeoutCallback = function (e) {
          functionTimer =
            functionTimer ||
            window.setTimeout(
              // window.requestAnimationFrame(action),
              action,
              that.options.delay
            );
        },
        RAFCallback = function () {
          checkIfInViewport(that);
        },
        action = function (force) {
          var oldScrollTop = scrollTop;

          functionTimer = window.clearTimeout(functionTimer);
          setScrollData();
          that.speed = scrollTop - oldScrollTop;
          force && that.initContainers(true);
          that.scrollTop = scrollTop;
          that.scrollBottom = scrollBottom;
          if (force) {
            checkIfInViewport(that, force);
          } else {
            that.options.requestAnimationFrame ? _animate(RAFCallback) : checkIfInViewport(that);
          }
        };

      _document = window.document;
      _body = _document.body;

      that.callbacks = {};
      // that.container = [];
      // that.elements = [];

      for (var option in options) {
        if (option === 'callback') {
          that.addCallback(options[option]);
        } else {
          that.options[option] = options[option];
        }
      }

      window.addEventListener('scroll', timeoutCallback, false);
      window.addEventListener('resize', timeoutCallback, false);
      ui.on('scroll:iostouchmove', timeoutCallback, false);
      that.options.watchTimer &&
        window.setInterval(function () {
          if (_body.scrollHeight !== scrollHeight) {
            scrollHeight = _body.scrollHeight;
            _.throttle(() => action(true), that.options.watchTimer + 1);
          }
        }, that.options.watchTimer);

      action(true);
    },
    setScrollData = function (scrollPos) {
      function inIframe() {
        try {
          return window.self !== window.top;
        } catch (e) {
          return true;
        }
      }

      if (scrollPos === undefined && inIframe() && /iP(hone|od|ad)/.test(navigator.userAgent)) {
        return;
      }

      if (inIframe() && /iP(hone|od|ad)/.test(navigator.userAgent)) {
        scrollTop = scrollPos || _body.scrollTop || _document.documentElement.scrollTop;
        scrollBottom =
          scrollTop + (window.screen.availHeight || _document.documentElement.offsetHeight);
      } else {
        scrollTop = _body.scrollTop || _document.documentElement.scrollTop;
        scrollBottom = scrollTop + _document.documentElement.offsetHeight;
      }
    },
    checkIfInViewport = function (that, force) {
      var container = {},
        options = {},
        className = '',
        element = {},
        inViewport = false,
        topInViewport = false,
        middleInViewport = false,
        bottomInViewport = false,
        completelyInViewport = false,
        isOldStatus = false,
        gotClassName = false;

      for (var n = 0, m = that.container.length; n < m; n++) {
        container = that.container[n];
        element = container.element;
        options = container.options;
        gotClassName = false;

        topInViewport = container.deltaTop <= scrollBottom && container.deltaTop >= scrollTop;
        middleInViewport = container.deltaTop <= scrollTop && container.deltaBottom >= scrollBottom;
        bottomInViewport =
          container.deltaBottom <= scrollBottom && container.deltaBottom >= scrollTop;

        inViewport =
          (topInViewport || bottomInViewport || middleInViewport) && !container.isConcealed;
        completelyInViewport = topInViewport && bottomInViewport;

        isOldStatus =
          container.inViewport === inViewport &&
          container.topInViewport === topInViewport &&
          container.bottomInViewport === bottomInViewport &&
          container.completelyInViewport === completelyInViewport;

        if (force || (!inViewport && !isOldStatus)) {
          className = element.className.replace(
            new RegExp(
              _rLimit +
                options.className +
                '(?:-\\w+)' +
                (options.stayInView && container.wasInViewport ? '+' : '*'),
              'g'
            ),
            ''
          );
          gotClassName = true;
        }

        if (inViewport && !isOldStatus) {
          !gotClassName && (className = element.className);

          if (!container.inViewport) {
            className +=
              container.wasInViewport && options.stayInView
                ? ''
                : (className ? ' ' : '') + options.className;
            container.wasInViewport = true;
          }
          if (topInViewport && !container.topInViewport) {
            className += ' ' + options.classNameTop;
          } else if (!topInViewport && container.topInViewport) {
            className = className.replace(' ' + options.classNameTop, '');
          }
          if (bottomInViewport && !container.bottomInViewport) {
            className += ' ' + options.classNameBottom;
          } else if (!bottomInViewport && container.bottomInViewport) {
            className = className.replace(' ' + options.classNameBottom, '');
          }
          if (completelyInViewport && !container.completelyInViewport) {
            className += ' ' + options.classNameCompletely;
          } else if (!completelyInViewport && container.completelyInViewport) {
            className = className.replace(' ' + options.classNameCompletely, '');
          }
        }

        if (!isOldStatus) {
          container.inViewport = inViewport;
          container.topInViewport = topInViewport;
          container.bottomInViewport = bottomInViewport;
          container.completelyInViewport = completelyInViewport;

          element.className = className;
        }

        if ((inViewport || !isOldStatus) && options.callback && that.callbacks[options.callback]) {
          // Is always called once on load - can be called a second time when entering/leaving viewport
          that.callbacks[options.callback].call(that, container);
        }
      }
    };

  /**
   * Resets all container positions, to be called after heavy DOM manipulations
   * @param {Boolean} force
   */
  IsInViewport.prototype.reset = function (force) {
    var _force = typeof force === 'boolean' ? force : force !== undefined;
    this.initContainers(_force);
    checkIfInViewport(this, _force);
  };

  IsInViewport.prototype.iostouch = function (scrollPosY) {
    setScrollData(scrollPosY);
    ui.trigger('scroll:iostouchmove');
  };

  IsInViewport.prototype.initContainers = function (force, body) {
    var data = {},
      origin = {},
      height = 0,
      className = '',
      options = this.options,
      container = {};

    if (force) {
      this.container = [];
      this.elements = _body.querySelectorAll('[' + options.dataAttribute + ']');
    }

    for (var n = 0, m = this.elements.length; n < m; n++) {
      className = this.elements[n].className;

      data = JSON.parse(
        this.elements[n].getAttribute(options.dataAttribute).replace(/'/g, '"') || '{}'
      );
      data.offsetTop = data.offsetTop !== undefined ? +data.offsetTop : options.offsetTop;
      data.offsetBottom =
        data.offsetBottom !== undefined ? +data.offsetBottom : options.offsetBottom;
      data.className = data.className !== undefined ? data.className : options.className;
      data.stayInView = !!data.stayInView || false;
      data.initialClassName = data.initialClassName || false;
      data.classNameTop = data.className + '-top';
      data.classNameBottom = data.className + '-bottom';
      data.classNameCompletely = data.className + '-completely';

      origin = getOrigin(this.elements[n]);
      height = this.elements[n].offsetHeight;

      container = this.container[n] = this.container[n] || {};

      container.element = this.elements[n];
      container.options = data;
      container.top = origin.top;
      container.bottom = origin.top + height;
      container.deltaTop = origin.top - data.offsetTop;
      container.deltaBottom = origin.top + height + data.offsetBottom;
      container.left = origin.left;
      container.height = height;
      // Checks if container is hidden, element.offsetWidth === 0 && element.offsetHeight === 0
      container.isConcealed =
        this.elements[n].offsetWidth === 0 && this.elements[n].offsetHeight === 0;

      container.wasInViewport = new RegExp(_rLimit + options.className + _rLimit).test(className);

      if (data.initialClassName) {
        this.elements[n].className = className + (className ? ' ' : '') + data.initialClassName;
      }
    }
  };

  IsInViewport.prototype.addCallback = function (callbacks) {
    for (var n in callbacks) {
      this.callbacks[n] = callbacks[n];
    }
  };

  IsInViewport.prototype.removeCallback = function (callback) {
    delete this.callbacks[callback];
  };

  function getOrigin(elm) {
    var box = elm.getBoundingClientRect ? elm.getBoundingClientRect() : { top: 0, left: 0 },
      doc = elm && elm.ownerDocument,
      body = doc.body,
      win = doc.defaultView || doc.parentWindow || window,
      docElem = doc.documentElement || body.parentNode,
      clientTop = docElem.clientTop || body.clientTop || 0,
      clientLeft = docElem.clientLeft || body.clientLeft || 0;

    return {
      left: box.left + (win.pageXOffset || docElem.scrollLeft) - clientLeft,
      top: box.top + (win.pageYOffset || docElem.scrollTop) - clientTop,
    };
  }

  return IsInViewport;
})(window);

// let's go!!
window.addEventListener('load', function (e) {
  // Array holding containers that were at least once in viewport
  var inviewContainers = [];
  var options = {
    // dataAttribute: 'data-inview', // is default
    // className: 'inview', // is default
    // delay: 100, // is default
    // offsetTop: -20,
    // offsetBottom: -20,
    delay: 50,
    requestAnimationFrame: false,
    callback: {
      triggerAlways: triggerAlways,
      triggerOnce: triggerOnce,
    },
  };

  window.ui.isInViewport = new window.ui.IsInViewport(options);

  var resetCallback = ui.isInViewport.reset.bind(ui.isInViewport);
  var iosScollCallback = ui.isInViewport.iostouch.bind(ui.isInViewport);

  // Install global event listener for resetting the scroll handler
  // ui.on(ui.GlobalEvents.MODAL_GALLERY_OPEN, resetCallback);
  ui.on(ui.GlobalEvents.CAROUSEL_SLID, resetCallback);
  ui.on(ui.GlobalEvents.MODAL_GALLERY_CAROUSEL_FIRST, resetCallback);
  ui.on(ui.GlobalEvents.FILTER_CUSTOM_CONTENT, resetCallback);
  ui.on(ui.GlobalEvents.FRAGMENT_PLACED, resetCallback);
  ui.on(ui.GlobalEvents.MAIN_MENU_TEMPLATES_RENDER, resetCallback);
  ui.on(ui.GlobalEvents.IOS_MOBILE_TOUCH_TO_SCROLL, iosScollCallback);

  /**
   * Callback function that triggers event when container gets inview (repeatedly)
   * @param {Object} container - dom node that is inview
   */
  function triggerAlways(container) {
    // If container has not been inview yet, push it to inviewContainers and trigger an event
    if (container.inViewport && inviewContainers.indexOf(container.element) < 0) {
      inviewContainers.push(container.element);
      $(container.element).trigger('scrollhandler:inview-once', container);
    } else {
      $(container.element).trigger('scrollhandler:inview', container);
    }
  }

  /**
   * Callback function that triggers event when container gets inview first time
   * @param {Object} container - dom node that is inview
   */
  function triggerOnce(container) {
    // If container has not been inview yet, push it to inviewContainers and trigger an event
    function callback() {
      if (container.inViewport && inviewContainers.indexOf(container.element) < 0) {
        inviewContainers.push(container.element);
        $(container.element).trigger('scrollhandler:inview-once', container);
      }
    }

    if (container.options && container.options.waitUntil) {
      ui.on(container.options.waitUntil, function () {
        // pretend that those containers are in view!
        container.inViewport = true;

        ui.off(ui.GlobalEvents.MAIN_MENU_TEMPLATES_RENDER, resetCallback);
        ui.off(container.options.waitUntil, this);
        callback();
      });
    } else {
      callback();
    }
  }
});
