(function () {
  'use strict';

  ui.ClipboardComponentView = ui.ComponentView.extend({
    name: 'ui.ClipboardComponentView',

    events: {
      'click .ui-js-clipboard-btn': 'onCopyToClipboard',
    },

    initialize: function (options) {
      this.copyField = options.copyField ? this.el.querySelector(options.copyField) : null;
    },

    onCopyToClipboard: function (e) {
      e.preventDefault();

      if (!this.copyField) return;

      this.copyField.select();
      this.copyField.setSelectionRange(0, 99999);

      document.execCommand('copy');
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ClipboardComponent',
    View: ui.ClipboardComponentView,
    selector: '.ui-js-clipboard',
  });

  $(ui.bootstrapClipboardComponent());
}).call(this);
