/**
 * @author Peter Dematté
 *
 * Documentation inline
 */
(function () {
  'use strict';

  ui.FairTradeTracerView = ui.ComponentView.extend({
    name: 'ui.FairTradeTracerView',

    events: {
      submit: 'submit',
    },

    initialize: function (options) {
      // events 'validates:valid' and 'validates:invalid' is triggered in form-validation.js
      // with this.$el.on('...', 'form', ... we have an event delegation construct
      // and therefore don't need to take care of dynamic changes insied '.ui-js-fairtrade'
      this.$el.on('validates:valid validates:invalid', 'form', function (e, data) {
        // toggles submit button 'disabled' dependent on validation
        $(this).find('[type="submit"]')[data.isValid ? 'removeAttr' : 'attr']('disabled', '');
      });
    },

    submit: function (e) {
      var $inputs = $(e.target).find(':input');
      var value = $inputs.filter(':not(:hidden)').val();
      var url = $inputs.filter(':hidden').val().replace('{}', value);
      var newWin = $inputs.filter(':hidden').data('new-window');

      e.preventDefault();
      if (newWin === true) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FairTradeTracerView',
    View: ui.FairTradeTracerView,
    selector: '.ui-js-fairtrade',
    reinitialize: false,
  });

  $(ui.bootstrapFairTradeTracerView('.ui-js-fairtrade'));
}).call(this);
