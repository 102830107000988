/**
 * @author Nils von Rymon-Lipinski (Aperto AG - An IBM Company)
 * @description init reCaptcha Api and install global onSubmit Callback Function
 *
 * With the following test keys, you will always get No CAPTCHA and all verification requests will pass.
 *
 * V2 (domain aperto.de)
 * Site key: 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
 * Secret key: 6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe
 *
 * V3 (domain aperto.de)
 * Site key: 6Lc97MkZAAAAAPD5WckzzH4-U5D92M29KJl68h71
 * Secret key: 6Lc97MkZAAAAAFM1my0g0J0DfBkaNuO3gK09-Y_V
 */

(function () {
  'use strict';

  ui.InvisibleReCaptchaView = ui.ComponentView.extend({
    name: 'ui.InvisibleReCaptchaView',

    initialize: function (options) {
      var self = this;

      //   <script src="https://www.google.com/recaptcha/api.js" async defer></script>
      if ($('#invReCap').length === 0) {
        var reCaptchaScript = document.createElement('script');
        reCaptchaScript.setAttribute('async', '');
        reCaptchaScript.setAttribute('defer', '');
        reCaptchaScript.setAttribute('id', 'invReCap');
        reCaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
        document.querySelector('head').appendChild(reCaptchaScript);
      }

      window.onSubmit = function (token) {
        self.$el.submit();
      };
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'InvisibleReCaptcha',
    View: ui.InvisibleReCaptchaView,
    selector: '.ui-js-invisible-recaptcha',
    reinitialize: true,
  });

  $(ui.bootstrapInvisibleReCaptcha());
}).call(this);
