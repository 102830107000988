/**
 * @author Peter Dematté
 *
 * Depends on window.iu.validator
 */
(function () {
  'use strict';

  /**
   * handles error messages, broadcast valid-message, validates inputs based on events from data setup
   *
   * validator-methods:
   * !-- validate() – Validates the selected form. --! is done via $('form').valid() or on submit. No extra method.
   * valid(silent) – Checks whether the selected form or selected elements are valid. Silent doesn't mark as error
   *
   * Data-setup:
   * ✓ @params {Object}  [validationRules] - holds specific rules for validation
   * ✓ @params {Boolean} [validationRules.required] - Inputfield must not be empty
   * ✓ @params {Boolean} [validationRules.onfocusin] - removes errorboxes on focusin event, default: false
   * ✓ @params {Boolean} [validationRules.onkeyup] - validates on keyup event, default: false
   * ✓ @params {Boolean} [validationRules.onkeydown] - validates on keyup event, default: false
   * ✓ @params {Boolean} [validationRules.onchange] - validates on change event, default: true
   * ✓ @params {Boolean} [validationRules.onfocusout] - validates on focusout event, default: true
   * ✓ @params {Number}  [validationRules.minlength] - Makes the element require a given minimum length
   * ✓ @params {Number}  [validationRules.maxlength] - Makes the element require a given maxmimum length
   * ✓ @params {Boolean}  [validationRules.toUppercase] - Makes the element convert input to uppercase
   * ✓ @params {Number}  [validationRules.min] - Makes the element require a given minimum.
   * ✓ @params {Number}  [validationRules.max] - Makes the element require a given maximum
   * ✓ @params {Boolean} [validationRules.isEmail] - Makes the element require a valid email
   * @params {Boolean} [validationRules.dateISO] - Makes the element require an ISO date.
   * @params {Boolean} [validationRules.date] - Makes the element require a date.
   * ✓ @params {Boolean} [validationRules.fileSize] - Requires the element to check for fileSize
   * ✓ @params {Boolean} [validationRules.fileType] - Requires the element to check for fileTyp
   * ✓ @params {Boolean} [validationRules.alphanumeric] - Makes the element require a alphanumeric format
   * ✓ @params {Number}  [validationRules.integer] - Makes the element require an integer
   * ✓ @params {Boolean} [validationRules.lettersonly] - Makes the element require letters only
   * ✓ @params {Object}  [validationRules.passwordSufficient] - Makes the element require matching password criteria,
   *     i.e. {"pwSufficient": {"minlength": 6, "matchCriteria": 2} }
   * ✓ @params {Boolean} [validationRules.isDate] - Makes the element require date format for ISO and DIN-5008
   * ✓ @params {String}  [validationRules.equalTo] - Requires the element to be the same as another one,
   *     i.e. "#mySelector" or ".mySelector"
   * @params {Array}   [validationRules.rangelength] - Makes the element require a given char length. i.e. [2, 6]
   * ✓ @params {Boolean} [validationRules.url] - Makes the element require a valid url
   * @params {Boolean} [validationRules.digits] - Makes the element require digits only.
   * @params {Array}   [validationRules.range] - Makes the element require a given value range. i.e. [13, 23]
   * @params {Boolean} [validationRules.creditcard] - Makes the element require a credit card number.
   */

  ui.FormValidationView = ui.ComponentView.extend({
    name: 'ui.FormValidationView',

    events: {
      validate: 'action',
      change: 'action',
      reset: 'action',
      focusout: 'action',
      focusin: 'action',
      keyup: 'action',
      keydown: 'action',
      submit: 'action',
      // edge case listeners for date picker and autocomplete
      show: 'datePickerStatus',
      hide: 'datePickerStatus',
      'autocomplete:start': 'autoCompleteStatus',
      'autocomplete:selected': 'autoCompleteStatus',
    },

    initialize: function (options) {
      var form;
      this.$form = this.$el.find('form');
      this.$form.on('submit', this.submit.bind(this));

      form = this.$form.get(0);

      form.uiSubmitConditions = form.uiSubmitConditions || {};
      form.uiSubmitConditions.validating = 'progress';

      // for this.valid(), the over all validation
      this.isAllValid = false;
      this.errors = [];
      this.validateSilent = false; // only use in this.valid()

      // edge case suppression
      this.datePickerIsOpen = false;
      this.autoCompleteIsOpen = false;
      this.replacePrefixWithAutoFormat();

      if (this.$el.hasClass('ui-js-disabled-until-valid')) {
        this.setUpFormValidation();
        // Enable Submit button if there are no required fields
        this.tryToEnableSubmit(this.$el.find('[type="submit"]'));
      }
    },

    /**
     * Edge case function : if tel nr has a +41 prefix, they will replaced by 0
     * for swiss numbers.
     */
    replacePrefixWithAutoFormat: function () {
      var $replaceElms = this.$form.find('[type="tel"][value^="+41"]');
      $replaceElms.each(function (idx, elm) {
        var $item = $(elm).closest('.ui-js-form-item[data-setup]');
        var setup = $item[0] ? $item[0].getAttribute('data-setup') || '' : '';
        var rules = setup
          ? JSON.parse(setup.replace(/\\'/g, "'").replace(/'/g, '"')).validationRules
          : null;
        if (rules.autoFormat) {
          elm.value = elm.value.replace('+41', '0');
          elm.value = elm.value.replace('0 ', '0');
        }
      });
    },

    /**
     * Edge case function: if date-picker sends event 'show' we need to supress
     * validation. Therefore we need to know...
     * @param  {Object} e Event model
     */
    datePickerStatus: function (e) {
      this.datePickerIsOpen = e.type === 'show';
    },

    /**
     * Edge case function: if autocomplete sends event 'autocomplete:start' we need to supress
     * validation. Therefore we need to know...
     * @param  {Object} e Event model
     */
    autoCompleteStatus: function (e) {
      this.autoCompleteIsOpen = e.type === 'autocomplete:start';
      if (e.type === 'autocomplete:selected') {
        this.autoCompleteIsOpen = false;
        $(e.target).trigger('change');
      }
    },

    /**
     * Switch for events change, focusout, focusin, keyup, keydown. Hands over to validation
     * @param  {Object} e Event model
     */
    action: function (e) {
      var $element = $(e.target);
      var $item = $element.closest('.ui-js-form-item[data-setup]');
      var setup = $item[0] ? $item[0].getAttribute('data-setup') || '' : '';
      var rules = setup
        ? JSON.parse(setup.replace(/\\'/g, "'").replace(/'/g, '"')).validationRules
        : null;
      var autocomplete = setup
        ? JSON.parse(setup.replace(/\\'/g, "'").replace(/'/g, '"')).autocomplete
        : null;
      var type = e.type; // compresses better

      if (
        $element.is(':disabled') &&
        autocomplete &&
        autocomplete.autocompleteDependencies.length > 0
      ) {
        const $targetForm = $element.closest('.ui-js-form');
        const allDependenciesHaveAValue = autocomplete.autocompleteDependencies.reduce(
          (allHaveValue, item) => {
            allHaveValue = $targetForm.find(`[name="${item}"]`).val() > 0;
            return allHaveValue;
          },
          false
        );

        // also validate disabled inputs which dependencies have a value (because the input will be enabled shortly after)
        if (allDependenciesHaveAValue) {
          this.doValidate($item, $element, rules, true);
        }
      }

      if (type === 'reset') {
        this.removeErrorStatus($item, $element, rules);
      }

      if (type === 'focusin' && rules && rules.dynamicSelectionRules) {
        var ruleCallback = ui.validator.dynamicSelectionRules;
        ruleCallback($element[0], rules.dynamicSelectionRules, true);
      }

      if ((type === 'keydown' || type === 'keyup') && rules && rules.touppercase) {
        $element.val($element.val().toUpperCase());
      }

      if (
        type === 'keydown' &&
        rules &&
        (rules.integer || rules.number) &&
        rules.onkeydown &&
        ($element.attr('type') === 'tel' || rules.plz)
      ) {
        // fix for ff to convert the numpad keys right
        var keyCode = e.keyCode || e.which;
        var ctrlDown = e.ctrlKey || e.metaKey; // Mac support
        var shiftDown = e.shiftKey;
        var altDown = e.altKey;

        var specialKeys =
          (ctrlDown && keyCode === 65) || // ctrl + a
          (ctrlDown && keyCode === 67) || // ctrl + c
          (ctrlDown && keyCode === 86) || // ctrl + v
          (ctrlDown && keyCode === 88) || // ctrl + x
          keyCode === 46 || // delete
          keyCode === 37 || // left window key
          keyCode === 39 || // right window key
          keyCode === 13 || // enter
          keyCode === 9 || // tab
          keyCode === 8; // back key
        if (keyCode >= 96 && keyCode <= 105) {
          // Numpad keys
          keyCode -= 48;
        }

        var checkInteger = rules.integer && !/-?\d/.test(String.fromCharCode(keyCode));
        var checkNumber = rules.number && !/\d/.test(String.fromCharCode(keyCode));

        if (!specialKeys) {
          if (checkInteger || shiftDown || altDown) {
            e.preventDefault();
          } else if (checkNumber || shiftDown || altDown) {
            e.preventDefault();
          }
        }
      }

      if ($element.is(':hidden') && rules && rules.hiddenValidate) {
        this.doValidate($item, $element, rules, false);
      } else if (
        !(
          $element.is(':hidden') &&
          $element[0].type === 'file' &&
          rules &&
          rules.fileSize &&
          rules.fileType
        ) &&
        (!rules ||
          rules['on' + type] === false ||
          (type === 'keyup' && e.keyCode === 9) ||
          $element.is(':hidden, [readonly], [disabled]') ||
          $element.closest('button')[0])
      ) {
        // TAB key
      } else if (this.datePickerIsOpen || (this.autoCompleteIsOpen && type !== 'keyup')) {
        this.autoCompleteIsOpen = false;
        this.doValidate($item, $element, rules, true);
      } else if (rules.autoFormat && type === 'keyup' && rules.onkeyup) {
        var caretPos = event.target.selectionStart;
        var caretPosNew = false;
        var regEx = /\b(\d{1,3})(\d{1,3})?(\d{1,2})?(\d{1,2})?(\d{1,})*\b/;
        var replaceEx = '$1 $2 $3 $4 $5';
        var value = $element.val();
        var $placeHolderSpan = $element.next().hasClass('ui-js-placeholder-text')
          ? $element.next()
          : false;
        if ($placeHolderSpan.length > 0) {
          var pH = $placeHolderSpan.data('placeholder');
          var newPh = pH;
        }
        if (rules.autoFormat !== true && rules.autoFormatReplaceEx) {
          regEx = new RegExp(rules.autoFormat);
          replaceEx = rules.autoFormatReplaceEx;
        }

        const replaceArray = replaceEx.match(/(\$\d)/g);

        var res = value
          .replace(/\s/g, '')
          .replace(/\D/g, '')
          .trim()
          .replace(regEx, function (match) {
            var result = '';
            for (var i = 0; i < replaceArray.length; i++) {
              var prefix = '';

              if (arguments[i + 1] !== undefined) {
                if (i > 0) {
                  prefix = replaceEx.substring(
                    replaceEx.indexOf(replaceArray[i - 1]) + replaceArray[i - 1].length,
                    replaceEx.indexOf(replaceArray[i])
                  );
                }
                if (caretPos > result.length && caretPos <= result.length + prefix.length) {
                  if (event.key === 'Backspace') {
                    if (caretPos === result.length + prefix.length) {
                      caretPosNew = caretPos;
                    } else {
                      caretPosNew = result.length;
                    }
                  } else if (
                    event.key === 'Delete' ||
                    event.key === 'Del' ||
                    (event.key === 'd' && event.ctrlKey)
                  ) {
                    caretPosNew = caretPos;
                  } else if (event.key === 'ArrowRight' || event.key === 'Right') {
                    caretPosNew = result.length + prefix.length;
                  } else if (event.key === 'ArrowLeft' || event.key === 'Left') {
                    if (caretPos === result.length + prefix.length) {
                      caretPosNew = caretPos;
                    } else {
                      caretPosNew = result.length;
                    }
                  } else {
                    caretPosNew = result.length + prefix.length + 1;
                  }
                }
                result = result + prefix + arguments[i + 1];
              }
            }
            if ($placeHolderSpan.length > 0) {
              newPh = '<ins>' + pH.slice(0, result.length) + '</ins>' + pH.slice(result.length);
            }
            return result;
          });
        if ($placeHolderSpan.length > 0) {
          $placeHolderSpan[0].innerHTML = newPh;
        }
        $element.val(res);

        if (rules.checkMembershipPrefix && res.length > 1) {
          this.doValidate(
            $item,
            $element,
            { checkMembershipPrefix: rules.checkMembershipPrefix },
            this.validateSilent || rules.silent
          );
        }

        if (event.key === 'Backspace') {
          if (caretPosNew) {
            $element[0].setSelectionRange(caretPosNew, caretPosNew);
          } else {
            $element[0].setSelectionRange(caretPos, caretPos);
          }
        } else if (caretPosNew) {
          $element[0].setSelectionRange(caretPosNew, caretPosNew);
        } else if (caretPos < res.length) {
          $element[0].setSelectionRange(caretPos, caretPos);
        }
      } else if (type === 'keyup' && rules.onkeyup) {
        if (rules.plz) {
          if (
            !rules.minlength &&
            $element.attr('minlength') &&
            parseInt($element.attr('minlength')) > 0
          ) {
            rules.minlength = parseInt($element.attr('minlength'));
          }
          if (
            !rules.maxlength &&
            $element.attr('maxlength') &&
            parseInt($element.attr('maxlength')) > 0
          ) {
            rules.maxlength = parseInt($element.attr('maxlength'));
          }
          if (rules.minlength && $element.val().length >= rules.minlength) {
            this.doValidate($item, $element, rules, !rules.onkeyup);
          }
        } else {
          this.doValidate($item, $element, rules, !rules.onkeyup);
        }
      } else if (rules.partialDate && type === 'focusout') {
        if ($(e.currentTarget).find(e.relatedTarget).length === 0) {
          this.doValidate($item, $element, rules, this.validateSilent || rules.silent);
        }
      } else if (type === 'focusout' || type === 'change' || type === 'validate') {
        if (
          !rules.minlength &&
          $element.attr('minlength') &&
          parseInt($element.attr('minlength')) > 0
        ) {
          rules.minlength = parseInt($element.attr('minlength'));
        }
        if (
          !rules.maxlength &&
          $element.attr('maxlength') &&
          parseInt($element.attr('maxlength')) > 0
        ) {
          rules.maxlength = parseInt($element.attr('maxlength'));
        }
        this.doValidate($item, $element, rules, this.validateSilent || rules.silent);
      } else if (type === 'focusin' && rules.onfocusin) {
        this.removeErrorStatus($item, $element, rules);
      }
    },

    /**
     * Validation. Gets rules of fild, iterates through rouls and hands over
     * to ui.validator to validate. Calls error states functions and fires event
     * @param  {$Object} $item    Element containing data-setup (rules)
     * @param  {$Object} $element form item
     * @param  {Object}  rules    JSON with rules (from markup)
     * @param  {Boolean} silent   if true, error states functions aren't called
     */
    doValidate: function ($item, $element, rules, silent) {
      var isAllValid = true;
      var isValid = true;
      var ruleCallback;
      var errors = [];
      var checkRadio = false;
      var checkCheckBox = false;

      for (var rule in rules) {
        // iterate throug all rules and validate
        ruleCallback = ui.validator[rule];
        isValid = ruleCallback && ruleCallback($element[0], rules[rule]);
        checkRadio = $element.is(':radio') && rule === 'required';
        checkCheckBox = $element.is(':checkbox') && rule === 'required';

        if (!rules.required && $element.val() === '') {
          // is still valid; suppresses other rules
        } else if (
          (ruleCallback && !isValid) ||
          (checkRadio &&
            !this.$form.find('input[name=' + $element.attr('name') + ']').is(':checked')) ||
          (checkCheckBox && !$element.is(':checked'))
        ) {
          this.isAllValid = isAllValid = false;
          errors.push(rule);
          this.errors.push(rule);
        }
      }

      if (!isAllValid && !silent) {
        // could be done outside with 'validates:' event
        this.setErrorStatus($item, $element, rules, errors);
      } else if ((rules.onkeyup && rules.onfocusin) || (isAllValid && !silent)) {
        this.removeErrorStatus($item, $element, rules);
      }

      !silent &&
        $element.trigger('validates:' + (isAllValid ? 'valid' : 'invalid'), {
          $item,
          $element,
          rules,
          isValid: isAllValid,
          silent: !!silent,
          errors,
        });
    },

    /**
     * Sets element class and error-box class
     * could be done outside with 'validates:' event
     * @param  {$Object} $item    Element containing data-setup (rules)
     * @param  {$Object} $element form item
     * @param  {Object}  rules    JSON with rules (from markup)
     * @param  {Array} errors     Array containig strings with failed validation rules
     */
    setErrorStatus: function ($item, $element, rules, errors) {
      // if closest('.ui-js-form-group-items') exists, we talk about
      // a group validation where there is 1 error-box for all items
      var $groupItem = $item.closest('.ui-js-form-group-items');
      var $errorBox;

      $item[errors ? 'addClass' : 'removeClass']('invalid');
      $groupItem = $groupItem[0] ? $groupItem.eq(0) : $item;
      $errorBox = $groupItem.find('.error-box');
      // We need to remove BE error-boxes first
      $errorBox.addClass('hidden').eq(0)[errors ? 'removeClass' : 'addClass']('hidden');

      if ($groupItem.length === 1) {
        var formFields = $groupItem.find('.ui-js-form-item');
        for (var i = 0; i < formFields.length; i++) {
          if ($(formFields[i]).hasClass('invalid')) {
            if ($errorBox.length === 1) {
              $errorBox.removeClass('hidden');
            }
            break;
          }
        }
      }
    },

    /**
     * Same as this.setErrorStatus() but doesn't send error, therefore reverts
     * could be done outside with 'validates:' event
     * @param  {$Object} $item    Element containing data-setup (rules)
     * @param  {$Object} $element form item
     * @param  {Object}  rules    JSON with rules (from markup)
     */
    removeErrorStatus: function ($item, $element, rules) {
      this.setErrorStatus($item, $element, rules);
    },

    /**
     * Chatches submit and validates
     * @param  {Object} e Event Object
     */
    submit: function (e) {
      var isValid;
      e.preventDefault();

      isValid = this.valid();

      if (isValid || /noValidate/.test(window.location.search)) {
        var target = e.target;
        target.uiSubmitConditions.validating = 'done';
        ui.formSubmitHandler(target);
      } else if (window.grecaptcha) {
        window.grecaptcha.reset();
      }
    },

    /**
     * Iterates through all form items (or defined by elements) and triggers a 'change' so it will
     * be evaluated through this.action() -> this.doValidate()
     * @param  {Boolean} silent   If set, no inalid consequences are processed (see doValidate)
     * @param  {Array}   elements Array that holds one or more elements to be validated
     * @return {Boolean}          Return true if all form elements validate positively; otherwhise false
     */
    valid: function (silent, elements) {
      var form = elements || this.$form[0];

      this.isAllValid = true;
      this.errors = [];
      this.validateSilent = !!silent;

      for (var n = 0, m = form.length; n < m; n++) {
        $(form[n]).trigger('validate'); // check for data-setup is done in action()
      }

      this.$form.trigger('validation', {
        view: this,
        isValid: this.isAllValid,
        silent: this.validateSilent,
        errors: this.errors,
      });
      this.validateSilent = false;

      return this.isAllValid;
    },

    setUpFormValidation: function () {
      const $submitButton = this.$el.find('[type="submit"]');
      // we only add a class and do not disable tbe buton entirely to still
      // give the user the possibility to force a validation by clicking
      // the submit button. otherwise it would be bad UX since the user
      // has no idication why the button is disabled.
      $submitButton.addClass('disabled');

      Array.from(this.$form[0]).forEach((item) => {
        if (!item.type || item.type === 'hidden' || item.nodeName === 'FIELDSET') {
          return;
        }

        $(item).on('input', () => this.tryToEnableSubmit($submitButton));
      });

      // Bind insert and prefill events (can be asynchronous)
      ui.on(ui.GlobalEvents.FORM_TRY_TO_ENABLE, () => {
        this.tryToEnableSubmit($submitButton);
      });

      ui.on(ui.GlobalEvents.LOGIN_PREFILL_DONE, () => {
        this.tryToEnableSubmit($submitButton);
      });
    },

    tryToEnableSubmit: function ($submitButton) {
      $submitButton.toggleClass('disabled', !this.valid(true));
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FormValidationView',
    View: ui.FormValidationView,
    selector: '.ui-js-form',
  });

  $(ui.bootstrapFormValidationView('.ui-js-form'));

  // fakes jQuery validation plugin used before...
  jQuery.fn.valid = function (silent) {
    return $(this).closest('.ui-js-form').prop('View').valid(silent);
  };
}).call(this);
