/**
 *
 * @author Peter Dematté
 *
 */
(function () {
  'use strict';

  /**
   * Bootstrap popover feature boosted
   *
   * Data-setup:
   * @params {string} [popoverClasses] - optional, adds classes to popover
   * @params {string} [popoverContainerClasses] - optional, adds classes to popover container
   * @params {boolean} [documentClose] - optional, popover closes on click somewhere
   * @params {string} [destroyOnEvent] - optional, destroy popover on specific event -
   *  event registers in this events, with given value
   * @params {boolean} [autoClose] - optional, hide popover on global z-index-actor event
   * @params {boolean} [useToggle] - whether or not to use the toogle for visibility on click
   *  default is true
   * @params {boolean} [autoplay] - true means show on load
   */

  ui.PopOver = ui.ComponentView.extend({
    name: 'ui.PopOver',

    defaults: {
      popoverClasses: '',
      popoverContainerClasses: '',
    },

    isActive: false,

    events: {
      click: 'toggle',
      'show.bs.popover': 'onShow',
      'hide.bs.popover': 'onHide',
      'shown.bs.popover': 'onShown',
      'hidden.bs.popover': 'onHidden',
      'inserted.bs.popover': 'onInserted',
    },

    popoverConfig: {
      html: true,
    },

    initialize: function (options) {
      this.isActive = false;

      this.$bodyRef = $('body');

      this.ensurePopoverContainer();

      this.$el.popover(this.popoverConfig);

      this.$popover = this.$el.data('bs.popover').tip();

      this.createCloseBtn();

      if (this.setup.autoClose) {
        var self = this;
        // Listen to global Z_INDEX_ACTOR_OPEN event triggered by other actors that might conflict with its z-index
        ui.on(ui.GlobalEvents.Z_INDEX_ACTOR_OPEN, function () {
          if (self.isActive) {
            self.hide.call(self);
          }
        });
      }

      // register events for destroying popover on specific event
      if (this.setup.destroyOnEvent) {
        this.undelegateEvents();
        this.events[this.setup.destroyOnEvent] = this.destroyPopover.bind(this);
        this.delegateEvents();
      }

      this.$el.on('popover:show', this.show.bind(this));
      this.$el.on('popover:hide', this.hide.bind(this));
    },

    ensurePopoverContainer: function () {
      this.$popoverContainer = $('.ui-js-popover-container').last();

      if (this.$popoverContainer.length === 0) {
        this.$bodyRef.append(
          '<div class="ui-scope"><div class="ui-popover-container ui-js-popover-container"></div></div>'
        );
      }

      this.$popoverContainer = $('.ui-js-popover-container').last();

      this.popoverConfig.container = this.$popoverContainer;
    },

    shouldHide: function (evt) {
      var isPopover = $(evt.target).is(this.$popover);
      var isWithinPopover = $(evt.target).parents('.popover').first().is(this.$popover);
      var isNotPopoverClick = !isWithinPopover && !isPopover;

      if (isNotPopoverClick) {
        this.hide();
      }
    },

    toggle: function (evt) {
      var isNestedPopoverOpener =
        evt && $(evt.target).hasClass('ui-js-popover') && !$(evt.target).is(this.$el);
      var preventToggle = evt && this.setup.useToggle === false;

      if (isNestedPopoverOpener || preventToggle) {
        evt.preventDefault();
        return;
      }

      if (this.isActive) {
        this.hide();
      } else {
        this.show();
      }
    },

    destroyPopover: function () {
      this.$el.popover('destroy');
    },

    createCloseBtn: function () {
      this.$closeBtn = this.$popover.find('.close-btn');

      if (!this.$closeBtn.length) {
        this.$popover.prepend(
          '<button class="btn close-btn ui-js-popover" data-toggle="popover"><span class="sr-only">Schliessen</span></button>'
        );
        this.$closeBtn = this.$popover.find('.close-btn');
      }

      this.$popover.on('click', '.close-btn', this.onCloseClick.bind(this));
    },

    addAdditionalClasses: function () {
      if (this.setup.popoverClasses) {
        this.$popover.addClass(this.setup.popoverClasses);
      }

      if (this.setup.popoverContainerClasses) {
        this.$popoverContainer.addClass(this.setup.popoverContainerClasses);
      }
    },

    removeAdditionalClasses: function () {
      if (this.setup.popoverClasses) {
        this.$popover.removeClass(this.setup.popoverClasses);
      }

      if (this.setup.popoverContainerClasses) {
        this.$popoverContainer.removeClass(this.setup.popoverContainerClasses);
      }
    },

    undelegateEvents: function () {
      if (this.$closeBtn && this.$closeBtn.length) {
        this.$closeBtn.off('click', this.hide.bind(this));
      }

      if (this.setup.documentClose && this.shouldHideMethod) {
        $(document).off('click', this.shouldHideMethod);
      }

      return ui.ComponentView.prototype.undelegateEvents.apply(this, arguments);
    },

    onShow: function (evt) {
      this.addAdditionalClasses();

      this.$el.addClass('is-showing-popover');

      // hide all other open popovers
      var $otherPopovers = $('.popover').not(evt.target);
      if ($otherPopovers.length > 1) {
        $otherPopovers.popover('hide');
      }
    },

    onShown: function (evt) {
      this.isActive = true;

      if (this.setup.documentClose) {
        this.shouldHideMethod = this.shouldHide.bind(this);
        $(document).on('click', this.shouldHideMethod);
      }

      // expose popover open to document
      $(document).trigger('popover:show', {
        type: 'popover:show',
        currentTarget: evt.target,
      });
    },

    show: function () {
      this.$el.popover('show');
      this.isActive = true;
    },

    onInserted: function (evt) {},

    onHide: function (evt) {
      this.$el.removeClass('is-showing-popover');

      if (this.setup.documentClose && this.shouldHideMethod) {
        $(document).off('click', this.shouldHideMethod);
      }
    },

    onHidden: function () {
      this.removeAdditionalClasses();
      this.isActive = false;
    },

    hide: function () {
      this.$el.popover('hide');
      this.isActive = false;
    },

    onCloseClick: function (evt) {
      this.hide();
    },

    addPopoverClass: function (className, isContainerClass) {
      var key = isContainerClass ? 'popoverContainerClasses' : 'popoverClasses';

      if (this.setup[key]) {
        this.setup[key] += ' ' + className;
      } else {
        this.setup[key] = className;
      }
    },

    render: function () {
      var that = this;

      if (this.setup.autoplay === true) {
        window.setTimeout(function () {
          that.toggle();
        }, 1000);
      } else if (this.$el.data('content') && this.$el.data('content').indexOf('<img') !== -1) {
        // due to positioning errors with images this is executed twice
        // (show/hide or hide/show) fixes the error
        this.$el.popover('toggle').popover('toggle');
      }

      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'PopOverComponent',
    View: ui.PopOver,
    selector: '.ui-js-popover',
  });

  $(ui.bootstrapPopOverComponent());
}).call(this);
