/**
 * @author Jan Suwart
 * @author Nils von Rymon-Lipinski (Aperto GmbH - An IBM Company)
 */
(function () {
  'use strict';

  /**
   * Upgrades Bootstrap's collapse plugin to support deep-linking  based on anchors. If an anchor
   * is recognized, the corresponding accordion's body is shown. Anchors can be either ID of the accordion's
   * body (panel-collapse) or its heading (panel-heading)
   */
  ui.AccordionComponentView = ui.ComponentView.extend({
    name: 'ui.AccordionComponentView',

    // Checks for History support, uses window.location.hash if replaceState is not supported
    state: !!(window.history && window.history.replaceState) || false,

    events: {
      'click a.collapsed': 'setHashInUrl',
      'click a[data-toggle="collapse"]:not(".collapsed")': 'removeHashInUrl',
    },

    initialize: function (options) {
      this.$panels = this.$el.find('.panel');
      this.bindEvents();
    },

    bindEvents: function () {
      $(window).on('hashchange', this.render.bind(this));
    },

    // Enable collapsible elements corresponding to anchor if found in view
    render: function () {
      var $target;
      var $guideAccordion = this.$el.parents('.ui-js-guide');
      var $guideTabItem = $guideAccordion.find('.nav-item');
      var $guideTabAnchors = $guideAccordion.find('.nav-link');
      var $guideTabPanels = $guideAccordion.find('.tab-pane');

      // prevent it from looking for anchors like #cumulus/success
      // because those will lead to an error
      if (window.location.hash.match(/^#[a-zA-Z0-9-_]+$/)) {
        $target = this.$(window.location.hash);
      }

      if ($target && $target.length) {
        var $targetParentPanel = $target.parent().find('.panel-collapse');
        var $targetGetParent = $target.parent().find('[data-parent]');

        if ($guideAccordion.length) {
          this.$panels.removeClass('is-collapse');
          $guideTabItem.removeClass('active');
          $guideTabPanels.removeClass('in active');
        }

        if ($targetParentPanel.length) {
          var hasParent = $targetGetParent.length ? $targetGetParent.data('parent') : undefined;

          if (hasParent !== undefined) {
            var $parentGroup = $targetParentPanel.closest(hasParent);
            if ($parentGroup.length) {
              $targetParentPanel.collapse({
                // parent-option causes all collapsible elements under the specified parent to be closed when this item is shown
                parent: hasParent,
              });

              // On hash change make parent of accordion item tab active
              if ($guideAccordion.length) {
                var $currentPanel = $targetParentPanel.closest('.panel');
                var $currentTab = $targetParentPanel.closest('.tab-pane');
                var $currentTabId = $currentTab.attr('id');

                var currentTabAnchor = $guideTabAnchors.filter(function (idx, elm) {
                  return $(elm).attr('href') === '#' + $currentTabId;
                });

                var $activeTabItem = currentTabAnchor.closest('.nav-item');

                $currentPanel.addClass('is-collapse');
                $targetParentPanel.addClass('in active');
                $activeTabItem.addClass('active');
                $currentTab.addClass('in active');
              }
            }
          } else {
            $targetParentPanel.collapse('show');
          }
        }
      }
      return this;
    },

    // Executed when user clicks on a heading of a collapsed element, sets history or location.hash attribute
    setHashInUrl: function (e) {
      var $a = $(e.target).hasClass('panel-heading')
        ? $(e.target)
        : $(e.target).closest('.panel-heading');
      var hash = $a[0] && $a[0].id && '#' + $a[0].id;

      if (hash) {
        if (this.state) {
          window.history.replaceState({}, document.title, hash);
        } else {
          window.location.hash = hash;
        }
      }
    },

    removeHashInUrl: function (e) {
      var noHashURL = window.location.href.replace(/#.*$/, '');
      if (this.state) {
        window.history.replaceState({}, document.title, noHashURL);
      } else {
        window.location.hash = '';
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'AccordionComponent',
    View: ui.AccordionComponentView,
    selector: '.ui-js-accordion-listener',
  });

  $(ui.bootstrapAccordionComponent());
}).call(this);
