(function () {
  'use strict';

  ui.FormTextareaView = ui.ComponentView.extend({
    name: 'ui.FormTextareaView',

    events: {
      keyup: 'counter',
      paste: 'counter',
    },
    initialize: function () {
      this.$wrapper = this.$el.closest('[data-setup]');
      this.dataSetup = JSON.parse(
        (this.$wrapper[0].getAttribute('data-setup') || '{}')
          .replace(/\\'/g, "'")
          .replace(/'/g, '"')
      );
      this.$counter = this.$wrapper.find('.counter');
      this.validationRules = this.dataSetup.validationRules || {};
      this.$textarea = this.$el;
      this.renderCache = undefined;

      this.counter();
    },
    counter: function (e) {
      if (this.$counter) {
        this.currentLength = (this.$textarea.val() || '').length;
        this.remainingLength = this.validationRules.maxlength - this.currentLength;
        this.isValidLength =
          this.currentLength >= (+this.validationRules.minlength || 0) &&
          this.currentLength <= (+this.validationRules.maxlength || 2e10);

        this.render();

        if (e && e.type === 'paste') {
          // onafterpaste
          window.setTimeout(this.counter.bind(this), 0);
        }
      }
    },

    render: function () {
      this.$counter.text(this.remainingLength);
      if (this.isValidLength !== this.renderCache) {
        this.$counter.toggleClass('not-valid-length', !this.isValidLength);
        this.renderCache = this.isValidLength;
      }

      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FormTextareaView',
    View: ui.FormTextareaView,
    selector: '.ff-text textarea',
    reinitialize: false,
  });

  $(ui.bootstrapFormTextareaView('.ff-text textarea'));
}).call(this);
