/**
 * @author Peter Dematté
 *
 * This thing is very flexible and might therefore be a bit hard to understand.
 * So, in '.ui-js-formControl' it waits for some events like 'click', 'change', ...
 * If this happens, the function action looks for a .closest [data-setup] and looks
 * for the magic key 'actionrules', which is a JSON and instructions for further actions.
 * actionrules: [ {'foo': {}}, {'foo': {}}, {'bar': {}} ]
 * So, 'actionrules' cycles through all of its items ...
 * 'foo' is a set of JSON, so is 'bar' and if you have a function called fooRules and
 * barRules, those functions will be called with the arguments of the JSON and the event.
 * The fact that 'foo' exists more times makes it possible to realize an 'OR' together with
 * this.condition. (see togglePropertyRule as example)
 *
 * Inside the callbacks (fooRules, etc.) you're free to do whatever you need to do by
 * iterating through the JSON...
 * See togglePropertyRule and copyValuesRule as examples.
 */

(function () {
  'use strict';

  ui.FormControlComponentView = ui.ComponentView.extend({
    name: 'ui.FormControlComponentView',

    events: {
      click: 'action',
      focusout: 'action',
      change: 'action',
      mouseup: 'action',
      focus: 'action',
    },
    condition: false,

    initialize: function (options) {
      this.formItemClass = 'ui-js-form-item';

      this.$form = this.$('form');
    },

    action: function (e) {
      var container = $(e.target).closest('.' + this.formItemClass + '[data-setup]')[0];
      var rules = this.setup.actionrules
        ? { actionrules: this.setup.actionrules }
        : container &&
          JSON.parse(container.getAttribute('data-setup').replace(/\\'/g, "'").replace(/'/g, '"'));
      var actionrules = [];
      var rule = '';

      this.condition = false; // reset for OR

      if (
        rules &&
        rules.actionrules &&
        (!rules.actionrules.event || rules.actionrules.event === e.type)
      ) {
        for (var n = rules.actionrules.length; n--; ) {
          // Array makes 'OR' possible (multiple same rules)
          actionrules = rules.actionrules[n];
          for (rule in actionrules) {
            // say, there is an actionrule 'hide' that contains data...
            // the method this.hideRule will be called with arguments data
            this[rule + 'Rule'] && this[rule + 'Rule'](actionrules[rule], e);
          }
        }
      }
    },

    /**
     * togglePropertyRule expects a data model (actionrules.toggleProperty) like the following:
     * [{
     *     event: 'type of event to be triggered'
     *     elements: [
     *         {
     *             selector: 'selector of element to be modified',
     *             either...
     *             className: 'class name to be added / removed',
     *             or...
     *             attribute: 'someAttr',
     *             value: 'some attribute value',
     *             propertySync: true // also set property on element
     *
     *         }
     *     ],
     *     conditions: [
     *         {
     *             name: 'name of Form element',
     *             property: 'the property to be checked against value',
     *             value: 'well...',
     *         }
     *     ]
     * }, {
     *     So simply explained: if all the conditions (name of form element's property equals value)
     *     are fulfilled, then elements with selector's calss names get the className value, otherwise
     *     the className is taken away...
     *     if keywor attribute is set, instead of changing the className the attribute gets set
     *     if propertySync is set, also the property will be set.
     *      .... another rule of same name to realize 'OR'
     * }]
     */

    splitFieldRule: function (data, e) {
      if (!data.event || data.event === e.type) {
        const $input = $(e.target);
        const $targetField = $(`[id='${data.splittedField}']`);
        let inputValue, splitValue;

        if ($targetField) {
          inputValue = $input.val();
          // split house number from street name
          splitValue = inputValue.match(/\s[1-9][0-9]*[a-zA-Z]{0,3}((\.\d+)?)$/g);

          if (splitValue && splitValue[0]) {
            const streetNameWithoutNumber = inputValue.split(splitValue)[0];
            $input.val(streetNameWithoutNumber);
            // Reset currentValue in Autocomplete lib in case of autocomplete field
            if ($input.autocomplete()) {
              $input.autocomplete().currentValue = streetNameWithoutNumber;
            }
            $targetField.val(splitValue[0].trim());
          }
        }
      }
    },

    // TODO: make more general (overload) for more flexibility (class, proprty, attribute, ...)
    togglePropertyRule: function (data, e) {
      // rule function 'hide'
      var form = this.$form[0];
      var conditionCount = 0;
      var item;
      var checkedItem;
      var self = this;

      if (!data.event || data.event === e.type) {
        // extra noize filter
        if (!this.condition || data.conditions) {
          // we should maybe assume this...
          for (var n = data.conditions.length; n--; ) {
            item = data.conditions[n];
            checkedItem = item.name ? form[item.name] : document.getElementById(item.id);
            conditionCount += item.not
              ? checkedItem[item.property] !== item.not
                ? 1
                : 0
              : checkedItem[item.property] === item.value
              ? 1
              : 0;
          }
          this.condition = conditionCount === data.conditions.length;
          for (var m = data.elements.length; m--; ) {
            item = data.elements[m];
            if (item.attribute !== undefined) {
              $(item.selector).attr(item.attribute, this.condition ? item.value : '');
              item.propertySync &&
                $(item.selector).each(function (idx, elm) {
                  elm[item.attribute] = self.condition ? item.value : '';
                });
            } else {
              $(item.selector).toggleClass(item.className, this.condition);
            }
            if (data.fireEvent) {
              $(document).trigger(data.fireEvent + '.rule', [$(item.selector), this.condition]);
            }
          }
        }
      }
    },

    /**
     * Copies values from inputs with a name to inputs with name + postfix
     * For now... expects a data set like follows:
     * {"event": "click", "postfix": "-new"}
     */
    copyValuesRule: function (data, e) {
      var that = this;
      var $copyToElements;
      var tagName = '';
      var autocompleteIds = [];

      if (!data.event || data.event === e.type) {
        // extra noize filter
        data.initialPostfix = data.initialPostfix || '';
        $copyToElements = this.$('[name*="' + data.postfix + '"]');
        $copyToElements.each(function (idx, elm) {
          var copyFromElement = that.$(
            '[name="' + elm.name.replace(data.postfix, data.initialPostfix) + '"]'
          )[0];
          const $elm = $(elm);
          const copyFromSetup = $(copyFromElement).parent().data('setup');
          const copyToSetup = $elm.parent().data('setup');
          if (copyFromElement) {
            tagName = copyFromElement.tagName.toLowerCase();

            elm.removeAttribute('disabled');

            if (/(?:radio|check)/.test(copyFromElement.type)) {
              elm.checked = copyFromElement.checked;
            } else if (
              autocompleteIds.indexOf(elm.name.replace(data.postfix, data.initialPostfix)) > -1
            ) {
              // do nothing if the input is populated by an autocomplete source
            } else if (
              copyFromSetup &&
              copyFromSetup.toggleAutocompleteList &&
              copyToSetup &&
              copyToSetup.toggleAutocompleteList
            ) {
              // if the source and the target are connected to an autocomplete filed this case is true
              const $autocompleteTarget = that
                .$(`[name="${copyToSetup.toggleAutocompleteList}"]`)
                .parent();
              const $autocompleteSource = that.$(
                `[name="${copyFromSetup.toggleAutocompleteList}"]`
              );

              elm.value = copyFromElement.value;
              autocompleteIds.push(copyFromSetup.toggleAutocompleteList);
              $autocompleteTarget.data('prefillValue', $autocompleteSource.val());
              $elm.trigger('change');
            } else if (tagName === 'input') {
              elm.value = copyFromElement.value;
            } else if (tagName === 'select') {
              elm.value = copyFromElement.value;
            } // TODO: take care of more types...
          }
        });
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FormControlComponentView',
    View: ui.FormControlComponentView,
    selector: '.ui-js-formControl',
  });

  $(ui.bootstrapFormControlComponentView('.ui-js-formControl'));

  // event driven functions
  // togglePropertyRule might fire an event....
  // on submit all items get enabled again...
  $(document).on('hiddenArea.rule', function (e, $items, isHidden) {
    // $items.find(':input').attr('disabled', isHidden ? 'disabled' : '');
    $items.find(':input').each(function (idx, elm) {
      if (isHidden) {
        elm.setAttribute('disabled', '');
      } else {
        elm.removeAttribute('disabled');
      }
    });
  });
}).call(this);
