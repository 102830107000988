/**
 * @author Henri Podolski
 */

(function () {
  'use strict';

  /**
   * requests and loads fragments into components
   *
   * Data-setup:
   * @params {string} requestUrl - required, URL to request a fragment
   * @params {string} [requestEvent] - optional, the event must have been triggered to request the fragment, default is
   * domready
   * @params {string} [replaceElSelector] - optional, if set the replaceEl is replaced instead of the View.el
   * @params {string} [additionalParam] - optional, adds additional parameters to the request URL
   * @params {string} [replaceAnimation] - optional, shows an animation if content gets replaced by fragment (possible values: fade)
   */

  /**
   * holds data/response for a specific fragment request
   * requests data for a fragment
   */
  ui.FragmentLoaderModel = ui.ComponentModel.extend({
    url: function () {
      return this.get('url');
    },

    defaults: {
      requestEvent: 'domready',
      replaceAnimation: 'none',
    },

    initialize: function (options) {
      this.options = options || {};
    },

    /**
     * @override ui.ComponentModel.prototype.sync
     * @param {*} method
     * @param {*} model
     * @param {*} options
     * @return {object} xhr
     */
    sync: function (method, model, options) {
      var params;
      var paramsModel = this.get('paramsModel');

      options || (options = {});

      // Default JSON-request options.
      params = {
        type: 'GET',
        dataType: 'html',
        url: this.url(),
        contentType: 'text/html',
        data: paramsModel,
      };

      if (this.options.withCredentials) {
        params.xhrFields = {
          withCredentials: true,
        };

        console.info(params);
      }

      // Make the request, allowing the user to override any Ajax options.
      var xhr = (options.xhr = Backbone.ajax(ui.utils.extend(params, options)));
      model.trigger('request', model, xhr, options);
      return xhr;
    },

    /**
     * Catch all model set method which sets allowed values to the model
     * and processes defined values by key
     * @override ui.ComponentModel.prototype.set
     * @param {*} key
     * @param {*} [value]
     * @param {*} options
     * @returns {*}
     */
    set: function (key, value, options) {
      var attrs;
      var attr;

      // process and whitelisted attributes
      var revisedAttrs = {};
      var allowedDefaultKeys = [
        'fragmentHTMLString',
        'requestEvent',
        'replaceAnimation',
        'paramsModel',
      ];

      // Handle both `"key", value` and `{key: value}` -style arguments.
      if (typeof key === 'object') {
        attrs = key;
        options = value;
      } else {
        (attrs = {})[key] = value;
      }

      for (attr in attrs) {
        if (Object.prototype.hasOwnProperty.call(attrs, attr)) {
          value = attrs[attr];

          if (attr === 'requestUrl') {
            revisedAttrs.url = this._urlAttribute(value);
          } else if (allowedDefaultKeys.indexOf(attr) !== -1) {
            revisedAttrs[attr] = value;
          }
        }
      }

      return ui.ComponentModel.prototype.set.call(this, revisedAttrs, options);
    },

    _urlAttribute: function (value) {
      if (value.indexOf('?') !== -1) {
        var unserializedParams = ui.helpers.unserializeGetParams(
          value.substring(value.indexOf('?') + 1)
        );

        this.set('paramsModel', ui.utils.extend(this.get('paramsModel'), unserializedParams));
      }

      return value.split('?')[0];
    },
  });

  /**
   * just a mass storage/collection for FragmentLoaderModel's
   * @see ui.FragmentLoaderModel
   */
  ui.FragmentLoaderCollection = ui.ComponentCollection.extend({
    model: ui.FragmentLoaderModel,
  });

  /**
   * handles collection for all components which have interests in loading fragments
   * is responsible for delegating global events to the specific fragment load event
   * selector: '[data-setup*=requestUrl]'
   */
  ui.FragmentLoaderComponent = ui.ComponentView.extend({
    name: 'ui.FragmentLoaderComponent',

    initialize: function (options) {
      this.fragmentLoaderViews = this.$('[data-setup*=requestUrl]');
      this.collection = new ui.FragmentLoaderCollection();

      ui.on(ui.GlobalEvents.FRAGMENT_PLACED, this.onFragmentsPlaced.bind(this));
    },

    onFragmentsPlaced: function (placedFragment) {
      var newFragments = $('[data-setup*=uiFragmentLoader]', placedFragment);

      if (newFragments.length) {
        this.fragmentLoaderViews.add(newFragments);
        newFragments.each(this.addOne.bind(this));
      }
    },

    addAll: function () {
      this.fragmentLoaderViews.each(this.addOne.bind(this));
    },

    /**
     * creates a ui.FragmentLoaderModel and its corresponding ui.FragmentLoaderView
     * @see ui.FragmentLoaderComponent.addAll
     * @param {number} i - Iterator
     * @param {object} fragmentLoaderEl - DOM Element for view.el
     */
    addOne: function (i, fragmentLoaderEl) {
      var model = new ui.FragmentLoaderModel();
      var view = ui
        .bootstrapFragmentLoaderView(fragmentLoaderEl, this.el, {
          model,
        })
        .call(this);

      this.collection.add(model);

      // trigger domready event immediatly, because domready is already done
      // when this component is initialized
      if (model.get('requestEvent') === 'domready') {
        view.trigger(model.get('requestEvent'));
      }
    },

    render: function () {
      this.addAll();
      return this;
    },
  });

  /**
   * this view controller/mediator is responsible for one component which has
   * interest in loading fragments
   */
  ui.FragmentLoaderView = ui.ComponentView.extend({
    name: 'ui.FragmentLoaderView',

    ON_TRANSITION_END:
      'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',

    defaults: {
      cssTransitionClass: 'animation-transition-300ms',
    },

    initialize: function (options) {
      var modelSetup = this.setup && this.setup.withCredentials ? { withCredentials: true } : {};
      this.model.options.withCredentials = modelSetup.withCredentials;

      this.model.set(this.setup);

      if (!(options && options.ajaxCache)) {
        // set default value for instance not for prototype
        this.model.set('paramsModel', { ajax: 1 });
      }

      this.$fragmentContainer = this.$el;

      if (this.setup.replaceElSelector && this.$(this.setup.replaceElSelector).length > 0) {
        this.$fragmentContainer = this.$(this.setup.replaceElSelector);
      } else if (this.setup.replaceElSelector) {
        console.warn(
          this +
            ' is trying to include fragment on a non existing element, selector was ' +
            this.setup.replaceElSelector
        );
      }

      if (this.setup.requestEvent && !(this.setup.requestEvent instanceof Array)) {
        this.setup.requestEvent = [this.setup.requestEvent];
      }

      // register other events/global events here, e.g. on Login data-setup='{requestEvent: "login"}'
      if (this.setup.requestEvent) {
        this.setup.requestEvent.forEach(this.setupRequestEvents.bind(this));
      } else {
        // domready event/default event for fragment loading
        this.on('domready', this.requestFragment.bind(this));
      }
    },

    setupRequestEvents: function (requestEvent) {
      // the domready event is a special case which is handled like the
      // default event and therefore it is dedicated to ui.FragmentLoaderView
      // and not triggered on the global ui event space
      if (requestEvent === 'domready') {
        this.requestFragment();
      } else {
        // console.info(this + '.setupRequestEvents() waiting for ' + requestEvent);
        ui.on(
          requestEvent,
          this.requestFragment.bind(this, {
            requestEvent,
          })
        );
      }
    },

    /**
     *
     * @param {object} [data] - additional data to send with fragment request
     */
    requestFragment: function (params, data) {
      var paramsModel = this.model.get('paramsModel') || {};

      // maybe some API has loaded while FragmentLoader was
      // waiting for the event, therefore call again.
      this.model.set(this.setup);
      if (data && data.fragmentUrl) {
        this.model.set('requestUrl', data.fragmentUrl);
      }

      if (params && params.requestEvent) {
        paramsModel.requestEvent = params.requestEvent;
        this.model.set('paramsModel', paramsModel);
      }

      this.model.fetch({
        success: this.onFragmentLoaded.bind(this),
        error: this[this.setup.errorCallback]
          ? this[this.setup.errorCallback].bind(this)
          : this.onError.bind(this),
      });
    },

    onError: function () {
      console.error(
        'Fetching of fragment failed for View id ' +
          this.cid +
          ' with url ' +
          this.model.url() +
          '.'
      );
    },

    onFragmentLoaded: function (model, fragmentHTMLString) {
      var doNotAnimate = model.get('replaceAnimation') === 'none' || !ui.cssSupports('transition');

      if (fragmentHTMLString.trim() !== 'doNotRenderMe') {
        model.set('fragmentHTMLString', fragmentHTMLString);

        // remove events before removing DOM
        ui.bootstrapper.defuse({
          context: this.$fragmentContainer,
        });

        if (doNotAnimate) {
          this.placeFragment(model.get('fragmentHTMLString'));
        } else {
          this.animateReplacedOut(model.get('fragmentHTMLString'));
        }
      }
    },

    animateReplacedOut: function (fragment) {
      var $childs = this.$fragmentContainer.children();
      var cssClasses =
        this.setup.cssTransitionClass + ' ' + this.model.get('replaceAnimation') + '-out';

      if (this.model.get('replaceAnimation') !== 'none') {
        $childs
          .addClass(cssClasses)
          .on(this.ON_TRANSITION_END, this.animateFragmentIn.bind(this, fragment));
      }
    },

    placeFragment: function (fragment) {
      this.$fragmentContainer.html(fragment);

      ui.bootstrapper.reinitialize({
        context: this.$fragmentContainer.get(0),
      });

      ui.trigger(ui.GlobalEvents.FRAGMENT_PLACED, this.$fragmentContainer);
    },

    animateFragmentIn: function (fragment) {
      var $childs;
      var transitionClass = this.setup.cssTransitionClass;
      var animationInCssClass = this.model.get('replaceAnimation') + '-in';

      this.placeFragment(fragment);

      $childs = this.$fragmentContainer.children();

      $childs
        .addClass(transitionClass)
        .addClass(animationInCssClass)
        .on(this.ON_TRANSITION_END, this.resetAnimatedFragment.bind(this, fragment));
    },

    resetAnimatedFragment: function () {
      var $childs = this.$fragmentContainer.children();
      var animationInCssClass = this.model.get('replaceAnimation') + '-in';
      var transitionClass = this.setup.cssTransitionClass;

      $childs.removeClass(transitionClass).removeClass(animationInCssClass);
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FragmentLoaderView',
    View: ui.FragmentLoaderView,
    selector: '[data-setup*=uiFragmentLoader]',
    reinitialize: false, // is reinitializable, because of some constraints not with the same API @see onFragmentsPlaced
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FragmentLoaderComponent',
    View: ui.FragmentLoaderComponent,
    selector: document,
    reinitialize: false,
  });

  $(ui.bootstrapFragmentLoaderComponent());
}).call(this);
