/**
 * @author Lutz Möbius
 * @description Loads videos only in selected viewports
 */

(function () {
  'use strict';

  ui.VideoLoader = ui.ComponentView.extend({
    name: 'ui.VideoLoader',

    activateViews: '',

    initialize: function () {
      this.$vid = this.$el.find('video');
      this.isProductStage = this.$el.parents('.ui-c286-12plus-product-stage');
      if (this.isProductStage.length === 1) {
        this.isInit = true;
        this.$el.css('visibility', 'hidden');
        this.$el.css('opacity', '0');
      }

      this.videosource = this.$vid.data('src');
      if (this.$vid.data('srcset') !== undefined) {
        this.videosources = JSON.parse(JSON.stringify(this.$vid.data('srcset')));
        if (this.videosources) {
          this.videosource = this.videosources[ui.Bootstrap.activeView];
        }
      }

      this.viewports = this.$vid.data('viewport');
      this.autoplay = this.$vid.data('autoplay');
      this.preload = this.$vid.data('preload');

      if (this.viewports === 'md-lg') {
        this.activateViews = /(md|lg)/;
      } else if (this.viewports === 'sm-md-lg') {
        this.activateViews = /(sm|md|lg)/;
      } else {
        this.activateViews = /(xs|ms|sm|md|lg)/;
      }

      this.toggleActivation();

      ui.on('bootstrap.activemediaquery.changed', this.toggleActivation.bind(this));
      $('.modal.is-vuvh')
        .on('shown.bs.modal', this.pauseVideo.bind(this))
        .on('hide.bs.modal', this.playVideo.bind(this));
    },

    toggleActivation: function () {
      var _this = this;
      var isActivateView = false;

      if (ui && ui.Bootstrap) {
        isActivateView = this.activateViews.test(ui.Bootstrap.activeView);
      } else {
        return false;
      }

      if (isActivateView) {
        if (this.$vid.data('srcset') !== undefined) {
          this.videosources = JSON.parse(JSON.stringify(this.$vid.data('srcset')));
          if (this.videosources) {
            this.videosource = this.videosources[ui.Bootstrap.activeView];
          }
        }

        this.$vid.get(0).src = this.videosource;
        this.$vid.attr('autoplay', this.autoplay);
        this.$vid.attr('preload', this.preload);

        if (this.isProductStage.length === 1 && this.isInit) {
          this.$vid.get(0).pause();
          this.$el.css('visibility', 'visible');

          this.$vid.get(0).oncanplay = function () {
            var timer = setTimeout(function () {
              _this.$el.animate(
                {
                  opacity: '1',
                },
                500,
                'swing',
                function () {
                  _this.$vid.get(0).play();
                }
              );
              clearTimeout(timer);
            }, 50);
          };

          this.isInit = false;
        }
      } else {
        this.$vid.get(0).src = '';
        this.$vid.removeAttr('autoplay');
        this.$vid.attr('preload', 'none');
      }
    },

    pauseVideo: function () {
      this.$vid.get(0).src = '';
      this.$vid.get(0).src = this.videosource;
      this.$vid.get(0).pause();
    },

    playVideo: function () {
      var _this = this;
      setTimeout(function () {
        _this.$vid.get(0).play();
      }, 1000);
    },

    stopVideo: function () {
      this.$vid.get(0).src = '';
      this.$vid.removeAttr('autoplay');
      this.$vid.attr('preload', 'none');
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'VideoLoaderComponent',
    View: ui.VideoLoader,
    selector: '.ui-js-video-loader',
    reinitialize: false,
  });

  // $(window).on('load', ui.bootstrapVideoLoaderComponent());

  $(ui.bootstrapVideoLoaderComponent());
}).call(this);
