/**
 * @author Jan Suwart, Henri Podolski
 * @modified by Nils von Rymon-Lipinski
 *
 * Tracking lib
 *
 * The item itself or some parent must contain 'ui-js-data-layer-item' and a valid data-setup.
 * The setup's 'data' attribute holds the tracking data. The two data-setup attributes
 * 'onclick' and 'onview' with the values of 'true' will trigger either the click or inview
 * tracking. Both attributes set to false will deactivate the tracking for that element.
 */
(function ($, window) {
  'use strict';

  if (!$) {
    console.log('No jQuery available for tracking');
    return;
  }

  window.logTracking =
    localStorage.getItem('logTracking') === 'true' || window.logTracking || false;

  window.logTracking && console.log('Tracking Lib initialized...');

  var handlers = [];

  ui.tracking = {
    createOneTrackingHandler: function (event, strategy) {
      return {
        eventName: event.eventName,
        reduceSelector: event.reduceSelector,
        callback: strategy,
      };
    },

    createAllTrackingHandlers: function (eventsArray, strategy) {
      for (var i = 0, len = eventsArray.length; i < len; i++) {
        handlers.push(this.createOneTrackingHandler(eventsArray[i], strategy));
      }
      return handlers;
    },

    getTrackingHandlers: function () {
      return handlers;
    },

    registerEvent: function (config) {
      if (config.eventName === 'ready') {
        $(document).ready(function domReady($) {
          var $el = $(config.reduceSelector);

          if ($el && $el.length) {
            config.callback({
              type: config.eventName,
              currentTarget: $el.get(0),
            });
          }
        });
      } else {
        $(document).on(config.eventName, config.reduceSelector, config.callback);
      }
    },

    /**
     *
     * @param events
     * @param strategy
     */
    applyTrackingListener: function (events, strategy) {
      this.createAllTrackingHandlers(events, strategy);
      var config = this.getTrackingHandlers();

      for (var i = 0, len = config.length; i < len; i++) {
        this.registerEvent(config[i]);
      }
    },

    /**
     * Estimates quadrant (1/4, 2/4 etc.) in which the clicked teaser is positioned
     * @param {Object} target - target html node
     * @returns {Number} page quadrant of the element
     */
    estimatePageQuadrant: function (target) {
      var $target = $(target);
      var docHeight = $(document).height();
      var elTopOffset = $target.offset().top;
      var quarterHeight = docHeight / 4;
      var quadrant;

      switch (true) {
        case elTopOffset > quarterHeight * 3:
          quadrant = 4;
          break;
        case elTopOffset > quarterHeight * 2:
          quadrant = 3;
          break;
        case elTopOffset > quarterHeight:
          quadrant = 2;
          break;
        case elTopOffset < quarterHeight:
          quadrant = 1;
      }

      return quadrant;
    },

    /**
     * Searches for a node of a specific tagName among the node's ancestors (and itself).
     * @param {Object} element - element (html node)
     * @param {Array} tagArray - tag names that will be included in the search
     * @returns {Object | null} html node that matches one filter type
     */
    findAncestorByTagName: function (element, tagArray) {
      var $target = $(element);
      var selector = tagArray.join(', ');
      var $foundAncestor;

      // If the el itself has the tagName, return it
      if ($target.is(selector)) {
        return $target.get(0);
      }
      // Otherwise traverse it's ancestor with the specific nodeNames
      $foundAncestor = $target.parents(selector);

      if ($foundAncestor && $foundAncestor.length) {
        return $foundAncestor.get(0);
      } else {
        return null;
      }
    },

    /**
     * @param {Object} link - html node
     * @returns {String} href if link is an anchor
     */
    extractHref: function (link) {
      var $link = $(link);
      var href = null;

      if ($link.attr('href')) {
        href = $link.attr('href');
      }
      return href;
    },

    stripHTMLTags: function (str) {
      return str.replace(/<\/?[^>]+(>|$)/g, ' ').replace(/\s\s+/g, ' ');
    },
  };
})(jQuery, window);

/**
 * Definition of project specific tracking of events, tracking strategy and dataLayer handling.
 * @see https://developers.google.com/tag-manager/enhanced-ecommerce
 */
(function ($, window) {
  'use strict';

  window.dataLayer = window.dataLayer || [];

  ui.trackingStrategy = {
    finalCallback: null,

    TYPES: {
      promoclick: 'promotionClick',
      promoview: 'promoView',
      addtocart: 'addToCart',
    },

    TRACKING_SETUP_NAME: 'data',

    TRACKING_ATTRIBUTES: {
      currentPage: 'pageSelected',
      sortBy: 'sortBy',
      filterSelected: 'filterSelected',
      filterName: 'filterName',
      pageQuadrant: 'dimension136',
      sliderDirection: 'sliderMovement',
      sliderType: 'sliderType',
    },

    /**
     * includes a map of objects for saving limited trackings
     * @member elTrackingOfTypeLimit array[object]
     * object.el - dom element
     * object.type - tracking lib event type
     * object.count - number of trackings
     * object.limit - number of allowed trackings
     */
    elTrackingOfTypeLimit: [],

    /**
     * Strategy callback - recognize tracking pattern and choose right strategy.
     * Push data results to dataLayer.
     */
    init: function (e, customEvtData) {
      var data;
      var setup = this.getTrackingDataSetup(customEvtData || e);

      // Choose strategy for tracking depending on event type, setup and tag
      switch (true) {
        case this.isSafetyTeaserTracking(e, setup): {
          data = this.trackElementInview(e, setup);
          break;
        }
        case this.isModalTracking(e, setup): {
          data = this.trackModal(e, setup);
          break;
        }
        case this.isPopoverTracking(e, setup): {
          data = this.trackPopover(e, setup, customEvtData);
          break;
        }
        case this.isFormStepSubmitTracking(e, setup): {
          data = this.trackFormStepSubmit(e, setup);
          break;
        }
        case this.isFormStepChangeTracking(e, setup): {
          data = this.trackFormStepChange(e, setup);
          break;
        }
        case this.isFormSubmitTracking(e, setup): {
          data = this.trackFormSubmit(e, setup);
          break;
        }
        case this.isSliderTracking(e, setup): {
          data = this.trackSliderMovement(e, setup, customEvtData);
          break;
        }
        case this.isAutoSliderTracking(e, setup): {
          data = this.trackAutoSliderMovement(e, setup, customEvtData);
          break;
        }
        case this.isAddToCartClickTracking(e, setup): {
          data = this.trackAddToCartElementClick(e, setup, ['BUTTON', 'A']);
          break;
        }
        case this.isInviewTracking(e, setup): {
          data = this.trackElementInview(e, setup);
          break;
        }
        case this.isPromotionClickTracking(e, setup): {
          data = this.trackPromotionElementClick(e, setup, ['BUTTON', 'A']);
          break;
        }
        case this.isPaginationTracking(e, setup): {
          data = this.trackPaginationClick(e, setup);
          break;
        }
        case this.isSortFilterTracking(e, setup): {
          data = this.trackSortFilterClick(e, setup);
          break;
        }
        case this.isFilterTracking(e, setup): {
          data = this.trackFilterClick(e, setup);
          break;
        }
        case this.isClickTracking(e, setup): {
          data = this.trackElementClick(e, setup);
          break;
        }
        default: {
          if (window.logTracking) {
            console.log('do nothing', e, setup);
          }
        }
      }

      if (data && dataLayer && dataLayer instanceof Array) {
        dataLayer.push(data);
        if (window.logTracking) {
          console.info(
            'added dataLayer data:',
            JSON.stringify(data),
            'has callback:',
            !!data.eventCallback || !!this.finalCallback
          );
          console.info('Tracking Obj', data);
        }
      }

      // call things after tracking,
      // for example submit or location href
      // when e.preventDefault() is called
      if (this.finalCallback) {
        var cb = this.finalCallback;
        this.finalCallback = null;
        cb();
      }
    },

    // event trigger modal:show tracking recognition
    isSafetyTeaserTracking: function (e, setup) {
      return !!(
        e.type === 'safety-teaser:open' &&
        setup &&
        setup.data &&
        setup.data.promotions &&
        setup.data.promotions[0]
      );
    },

    // event trigger modal:show tracking recognition
    isModalTracking: function (e, setup) {
      return !!(e.type === 'modal:show' && setup && setup.data);
    },

    // event trigger popover:show tracking recognition
    isPopoverTracking: function (e, setup) {
      return !!(e.type === 'popover:show');
    },

    // event trigger form:stepchange tracking recognition
    isFormStepChangeTracking: function (e, setup) {
      return !!(e.type === 'form:stepchange' && setup);
    },

    // 2-step form submit tracking recognition
    isFormStepSubmitTracking: function (e, setup) {
      return !!(
        e.type === 'submit' && e.currentTarget.querySelector('.ui-js-data-layer-step-change')
      );
    },

    // Standard form submit tracking recognition
    isFormSubmitTracking: function (e, setup) {
      return !!(e.type === 'submit' && setup && setup.data && setup.data.event === 'formular');
    },

    // Recognize carousel slid events caused by user interaction (swipe/click etc.)
    isSliderTracking: function (e, setup) {
      return !!(e.type === 'carousel:slid-manually' && setup && setup.data && setup.onclick);
    },

    // Recognize carousel slid events caused by carousel autoplay
    isAutoSliderTracking: function (e, setup) {
      return !!(e.type === 'carousel:slid-auto' && setup && setup.data);
    },

    isInviewTrackingInMenu: function (e, setup) {
      return !!(e.type === 'scrollhandler:inview-once' && setup && setup.data && setup.onview);
    },

    // Promotion tracking recognition (data holds an attribute promotion with an array)
    isAddToCartClickTracking: function (e, setup) {
      return !!(this.isClickTracking(e, setup) && setup.data.add && setup.data.currencyCode);
    },

    // Inviewport (scroll) tracking recognition
    isInviewTracking: function (e, setup) {
      return !!(e.type === 'scrollhandler:inview-once' && setup && setup.data && setup.onview);
    },

    // Promotion tracking recognition (data holds an attribute promotion with an array)
    isPromotionClickTracking: function (e, setup) {
      return !!(
        this.isClickTracking(e, setup) &&
        setup.data.promotions &&
        setup.data.promotions[0]
      );
    },

    // Pagination tracking recognition
    isPaginationTracking: function (e, setup) {
      return !!(
        this.isClickTracking(e, setup) &&
        setup.data.event &&
        setup.data.event === 'pagination'
      );
    },

    // Sort filter (dropdown) tracking recognition
    isSortFilterTracking: function (e, setup) {
      return !!(
        this.isClickTracking(e, setup) &&
        setup.data.event &&
        setup.data.event === 'sortierung'
      );
    },

    // General filter (item-filter, checkbox etc.) tracking recognition
    isFilterTracking: function (e, setup) {
      return !!(
        this.isClickTracking(e, setup) &&
        setup.data.event &&
        setup.data.event === 'filterField'
      );
    },

    // Simple click tracking recognition
    isClickTracking: function (e, setup) {
      return !!(e.type === 'click' && setup && setup.data && setup.onclick);
    },

    /**
     * tracks popovers as virtual page impressions
     * fills virtualPageURL, virtualPageTitle if available
     * @param {Event} e - custom object, native event vicarious
     * @param {Object} setup - null
     * @param {Object} customEvtSetup - includes data passed by trigger
     */
    trackPopover: function (e, setup, customEvtSetup) {
      var eventEl = customEvtSetup && customEvtSetup.currentTarget;
      var data;
      var virtualPageTitle = '';

      if (!eventEl) {
        return data;
      }

      if (eventEl.title) {
        virtualPageTitle = eventEl.title;
      } else if (eventEl.dataset.originalTitle) {
        virtualPageTitle = eventEl.dataset.originalTitle;
      } else if (eventEl.dataset.content) {
        virtualPageTitle = eventEl.dataset.content;
      }

      data = {
        event: 'virtualPageview',
        virtualPageTitle: ui.tracking.stripHTMLTags(virtualPageTitle),
        virtualPageURL: window.location.pathname,
      };

      return data;
    },

    /**
     * Tracks if a modal has opened
     *
     * @param {Event} e - custom object, native event vicarious
     * @param {Object} setup - setup
     * @returns {Object} dataLayer object containing form step and form data.
     */
    trackModal: function (e, setup) {
      var data = setup.data;

      if (!data.virtualPageURL) {
        data.virtualPageURL = window.location.pathname;
      }

      return data;
    },

    /**
     * Tracks if a form with step changes is submitted
     *
     * @param {Event} e - custom object, native event vicarious
     * @param {Object} setup - setup
     * @returns {Object} dataLayer object containing form step and form data.
     */
    trackFormStepSubmit: function (e, setup) {
      var $el;
      var eventEl = e.currentTarget;
      var data;

      eventEl.uiSubmitConditions = eventEl.uiSubmitConditions || {};
      eventEl.uiSubmitConditions.tracking = 'progress';

      e.preventDefault();

      $el = $(eventEl).find('.ui-js-data-layer-step-change');

      if ($el.length) {
        data = this.getTrackingDataSetup({
          currentTarget: $el.last().get(0),
        });

        data = data.data;

        data.formSubmit = true;
        data.formularURL = window.location.pathname;
      }

      this.finalCallback = function () {
        if (eventEl.isSubmitted) {
          return;
        }

        eventEl.uiSubmitConditions.tracking = 'done';
        ui.formSubmitHandler(eventEl);
      };

      return data;
    },

    /**
     * Tracks if a form is submitted
     *
     * @param {Event} e - submit event
     * @param {Object} setup - setup
     * @returns {Object} dataLayer object containing form data.
     */
    trackFormSubmit: function (e, setup) {
      var form = e.currentTarget;
      var data;

      form.uiSubmitConditions = form.uiSubmitConditions || {};
      form.uiSubmitConditions.tracking = 'progress';

      e.preventDefault();

      data = setup.data;
      data.formSubmit = true;
      data.formularURL = window.location.pathname;

      this.finalCallback = function () {
        if (form.isSubmitted) {
          return;
        }

        form.uiSubmitConditions.tracking = 'done';
        ui.formSubmitHandler(form);
      };

      return data;
    },

    /**
     * Tracks if the form:stepchange event is triggered on document
     * and if form with steps is initialized
     * @example event triggering
     * $(document).trigger('form:stepchange', {
     *      type: 'form:stepchange',
     *      currentTarget: this.$blocks[this.activeBlockIndex]
     * });
     *
     * @param {Event} e - custom object, native event vicarious
     * @param {Object} setup - data-setup
     * @returns {Object} dataLayer object containing form step and form data.
     */
    trackFormStepChange: function (e, setup) {
      var data = setup.data;
      data.formSubmit = false;
      data.formularURL = window.location.pathname;
      return data;
    },

    /**
     * Tracking of slider movement based on Bootstrap carousel
     * @example event triggering
     * $(element).trigger('carousel:slid-manually', { direction: 'right', ...}));
     *
     * @param {Event} e - custom slid event (by Bootstrap)
     * @param {Object} setup - data-setup
     * @returns {Object} dataLayer object containing direction
     */
    trackSliderMovement: function (e, setup, customEvtData) {
      var carousel = e.target;
      var data = null;
      if (carousel) {
        data = setup.data;
        data[this.TRACKING_ATTRIBUTES.sliderDirection] =
          e.direction || (customEvtData && customEvtData.direction);
        data[this.TRACKING_ATTRIBUTES.sliderType] = 'manual';
        if (customEvtData && customEvtData.nextIndex !== '') {
          var items = $(carousel).find('[data-slider-target]:not(.clone)');
          if (
            items[customEvtData.nextIndex] &&
            items[customEvtData.nextIndex].dataset.sliderTarget
          ) {
            data.sliderTarget = items[customEvtData.nextIndex].dataset.sliderTarget;
          }
        }
      }
      if (data && data.promotions) {
        delete data.promotions;
      }

      return data;
    },

    trackAutoSliderMovement: function (e, setup, customEvtData) {
      var carousel = e.target;
      var data = null;
      var limitObject;

      if (carousel) {
        limitObject = this.elTrackingOfTypeLimit.filter(
          function (item) {
            return item.el === carousel && item.event === 'trackAutoSliderMovement';
          }.bind(this)
        );

        if (!limitObject.length) {
          limitObject = {
            el: carousel,
            event: 'trackAutoSliderMovement',
            count: 0,
            limit: 5,
          };

          this.elTrackingOfTypeLimit.push(limitObject);
        } else {
          limitObject = limitObject[0];
        }

        if (limitObject.count < limitObject.limit) {
          data = setup.data;
          data[this.TRACKING_ATTRIBUTES.sliderDirection] =
            e.direction || (customEvtData && customEvtData.direction);
          data[this.TRACKING_ATTRIBUTES.sliderType] = 'auto';
          limitObject.count++;
        }
      }

      if (data && data.promotions) {
        delete data.promotions;
      }

      return data;
    },

    /**
     * Tracking of elements that come into view and contain a promotions array
     * @example event triggering
     * $(container.element).trigger('scrollhandler:inview', container));
     *
     * @param {Event} e - custom inview event (by scroll-handler lib)
     * @param {Object} setup - data-setup
     * @returns {Object} dataLayer object containing position data etc.
     */
    trackElementInview: function (e, setup) {
      var customType = this.TYPES.promoview;
      var data = setup.data;

      if (setup.data.promotions) {
        this.addColumnPositionData(setup, e.target);
        this.addWindowPositionData(setup, e.target);

        if (setup.data.promotions[0] && setup.data.promotions[0].laufzeit) {
          this.calcDurationData(setup);
        }

        data = this.createCustomTrackingObj(setup.data, customType, 'promoView');
      }
      return data;
    },

    /**
     * Promotion click tracking (could be of any type, contains non-empty 'promotions' array)
     * @param {Event} e - click event
     * @param {Object} setup - data-setup
     * @param {Array} validTagArray - tagNames on which the click tracking is valid
     * @returns {Object} dataLayer object containing position data etc.
     */
    trackAddToCartElementClick: function (e, setup, validTagArray) {
      var data = null;
      var customType = this.TYPES.addtocart;
      var link = ui.tracking.findAncestorByTagName(e.target, validTagArray);
      if (link) {
        data = this.createCustomTrackingObj(setup.data, customType);
      }
      return data;
    },

    /**
     * Promotion click tracking (could be of any type, contains non-empty 'promotions' array)
     * @param {Event} e - click event
     * @param {Object} setup - data-setup
     * @param {Array} validTagArray - tagNames on which the click tracking is valid
     * @returns {Object} dataLayer object containing position data etc.
     */
    trackPromotionElementClick: function (e, setup, validTagArray) {
      var data = null;
      var customType = this.TYPES.promoclick;
      var link = ui.tracking.findAncestorByTagName(e.target, validTagArray);
      const $link = $(link);
      // Only proceed if the click was inside a relevant tag (validTagArray)

      if (link) {
        this.addColumnPositionData(setup, e.target);
        this.addWindowPositionData(setup, e.target);

        if (setup.data.promotions[0] && setup.data.promotions[0].laufzeit) {
          this.calcDurationData(setup);
        }

        data = this.createCustomTrackingObj(setup.data, customType, 'promoClick');

        var except = !(
          $link.hasClass('mui-js-login-button') ||
          $link.hasClass('ui-js-toggle-modal') ||
          $link.hasClass('ui-js-link-redirect') ||
          $link.hasClass('ui-js-no-tracking-callback')
        );

        if (except) {
          this.addEventCallback(link, data);
        }
      }
      return data;
    },

    /**
     * Pagination link tracking (child of ui-js-pagination)
     * @param {Event} e - click event
     * @param {Object} setup - data-setup
     * @returns {Object} dataLayer object containing value of clicked page link
     */
    trackPaginationClick: function (e, setup) {
      var page = e.target;
      if (page && page.dataset && page.dataset.value !== undefined) {
        // Add page index from link's data-value attribute
        setup.data[this.TRACKING_ATTRIBUTES.currentPage] = page.dataset.value;
        return setup.data;
      } else {
        return null;
      }
    },

    /**
     * Sort filter tracking (child of ui-item-filter)
     * @param {Event} e - click event
     * @param {Object} setup - data-setup
     * @returns {Object} dataLayer object containing value of clicked dropdown element
     */
    trackSortFilterClick: function (e, setup) {
      var sortEl = e.target;
      if (sortEl && sortEl.dataset && sortEl.dataset.value !== undefined) {
        // Add sort criteria from elements data-value attribute
        setup.data[this.TRACKING_ATTRIBUTES.sortBy] = sortEl.dataset.value;
        return setup.data;
      } else {
        return null;
      }
    },

    /**
     * General filter tracking (child of ui-item-filter)
     * @param {Event} e - click event
     * @param {Object} setup - data-setup
     * @returns {Object} dataLayer object containing value and text of clicked filter element
     */
    trackFilterClick: function (e, setup) {
      var input = ui.tracking.findAncestorByTagName(e.target, ['A', 'INPUT']);
      var nameValue;
      var label;

      // Custom input, take criteria from input's data-name/value pair and node text
      if (input && input.dataset && input.dataset.name && input.dataset.value !== undefined) {
        label = ui.tracking.stripHTMLTags(input.innerHTML);
        nameValue = input.dataset.name + ' ' + input.dataset.value;
        // Native input, take criteria from name/value pair and the label's node text
      } else if (input && input.value && input.name && input.value !== undefined) {
        var labelNode = ui.tracking.findAncestorByTagName(input, ['LABEL']);
        label = ui.tracking.stripHTMLTags(labelNode.innerHTML);
        nameValue = input.name + ' ' + input.value;
      }

      if (setup.data && setup.data.filterElementSum) {
        if (setup.el && $(setup.el).hasClass('ui-js-bon-item')) {
          const $bonItem = $(setup.el);
          const bonItems = $bonItem.closest('.ui-js-table').find('.ui-js-bon-item:checked');
          setup.data.filterElementSum = bonItems.length;
        }
      }

      if (nameValue && label) {
        if (!setup.data[this.TRACKING_ATTRIBUTES.filterSelected]) {
          // Only add filterSelected if not initially included
          setup.data[this.TRACKING_ATTRIBUTES.filterSelected] = nameValue;
        }

        if (!setup.data[this.TRACKING_ATTRIBUTES.filterName]) {
          setup.data[this.TRACKING_ATTRIBUTES.filterName] = label;
        }

        return setup.data;
      } else {
        return null;
      }
    },

    /**
     * Fallback strategy for trackable items without a characteristics or dynamic data
     * @param {Object} setup - data-setup
     * @returns {Object} dataLayer object
     */
    trackElementClick: function (e, setup) {
      var link = ui.tracking.findAncestorByTagName(e.target, ['A']);
      const $link = $(link);

      if (
        link &&
        !$link.hasClass('ui-js-toggle-modal') &&
        !$link.hasClass('ui-js-load_more') &&
        !$link.hasClass('ui-js-link-redirect') &&
        !$link.hasClass('ui-js-no-tracking-callback')
      ) {
        this.addEventCallback(link, setup.data);
      }

      return setup.data;
    },

    /**
     * Attaches callback for location change to object
     * Method mutates passed data object `obj`
     * @param {Object} obj - data-setup
     * @param {String} href - URL to call after delivering dataLayer
     */
    addEventCallback: function (link, obj) {
      var href = ui.tracking.extractHref(link);
      var $link = $(link);
      var blank = $link.attr('target') ? $link.attr('target').indexOf('blank') > 0 : false;

      if (href && !blank && href.indexOf('#') !== 0) {
        obj.eventCallback = function () {
          if (window.logTracking) {
            console.log('eventCallback', 'obj', obj);
          }
          document.location = href;
        };
      }
    },

    /**
     * Returns tracking setup of either currentTarget or target
     * @param {Event | Object} eventData - custom event data or click event
     * @returns {Object | null} data-setup that contains tracking data, null otherwise
     */
    getTrackingDataSetup: function (eventData) {
      var obj;

      if (eventData && eventData.inViewport && eventData.element) {
        // The tracking target is wrapped in an inview element
        obj = ui.getElementSetup({ el: eventData.element });
        if (obj && obj[this.TRACKING_SETUP_NAME]) {
          return obj;
        }
      } else {
        // The tracking target is either currentTarget or target depending on data-setup
        obj = ui.getElementSetup({ el: eventData.currentTarget });
        if (obj && obj[this.TRACKING_SETUP_NAME]) {
          return obj;
        }
        obj = ui.getElementSetup({ el: eventData.target });
        if (obj && obj[this.TRACKING_SETUP_NAME]) {
          return obj;
        }
      }
      return null;
    },

    /**
     * Enriches data with column index (grid position) if not contained yet
     * @param {Object} setup - data-setup
     * @param {Object} target - event's target html node
     * @returns {Object} promotion data enriched with position (if computable)
     */
    addColumnPositionData: function (setup, target) {
      var promotion = setup.data.promotions;
      var $target = $(target);

      // Only calculate position if not yet provided in as position in data-setup
      if (promotion && promotion.length && !promotion[0].position) {
        var rowAncestor = $target.parents('.row');
        var $nativeSliderList = $target.parents('.ui-js-native-slider-list');
        var $circleTeaserList = $target.parents('.ui-js-circle-teaser-list');
        var colContainers;

        if (rowAncestor.length) {
          rowAncestor =
            $(rowAncestor[0]).hasClass('ui-js-data-layer-item') && rowAncestor.length > 1
              ? $(rowAncestor[1])
              : rowAncestor.first();
          colContainers = $(rowAncestor).children('[class*="col-"]');
          if ($nativeSliderList.length > 0) {
            colContainers = $($nativeSliderList).children('[class*="col-"]');
          }
          if ($circleTeaserList.length > 0) {
            colContainers = $($circleTeaserList).children('.ui-js-circle-teaser-item');
          }
        } else {
          // do slider check, sliders don't have grid classes
          var slider = $target.parents('.ui-js-preview-teaser');
          if (slider.length) {
            // slider items without clones
            colContainers = slider.find('.js-slider-item').filter(':not(.clone)');
          }
        }

        if (colContainers === undefined) {
          promotion[0].position = '1/1';
          return setup;
        }
        var currentColContainer = colContainers.filter(function (index) {
          return $(this).find($target).length === 1;
        });
        var pos = colContainers.index(currentColContainer) + 1;
        if (pos > 0) {
          promotion[0].position = pos + '/' + colContainers.length;
        }
      }
      return setup;
    },

    /**
     * Enriches data with estimated vertical position of the element in the window
     * @param {Object} setup - data-setup
     * @param {Object} target - event's target html node
     * @returns {Object} promotion data enriched with position (if computable)
     */
    addWindowPositionData: function (setup, target) {
      if (setup.data && setup.data.promotions && setup.data.promotions[0]) {
        var quadrant = ui.tracking.estimatePageQuadrant(target) + '/' + 4;
        setup.data.promotions[0][this.TRACKING_ATTRIBUTES.pageQuadrant] = quadrant;
      }
      return setup;
    },

    calcDurationData: function (setup) {
      if (
        setup.data &&
        setup.data.promotions &&
        setup.data.promotions[0] &&
        setup.data.promotions[0].laufzeit
      ) {
        if (setup.data.laufzeit) {
          return setup;
        }

        var days = Math.round(
          (new Date() - new Date(setup.data.promotions[0].laufzeit)) / (1000 * 60 * 60 * 24)
        );

        if (days > 0) {
          setup.data.promotions[0].laufzeit = days.toString();
          setup.data.laufzeit = true;
          return setup;
        }
      }
      return setup;
    },

    /**
     * Creates custom promotion object for tracking
     * @param {Object} data - tag-manager object that contains measuring data
     * @param {String} eventType - tag-manager type
     * @param {String} ecommerceCategoryName - property name of inner ecommerce object
     * @returns {Object} - dataLayer object that can be pushed
     */
    createCustomTrackingObj: function (data, eventType, ecommerceCategoryName) {
      var obj = {
        event: eventType,
        ecommerce: {},
      };
      // Add custom category name if deviates from TYPE name
      if (ecommerceCategoryName) {
        obj.ecommerce[ecommerceCategoryName] = data;
      } else {
        obj.ecommerce = data;
      }
      return obj;
    },
  };
})(jQuery, window);

// Configure tracking and initialize it with a strategy
document.addEventListener('DOMContentLoaded', function () {
  var strategy = ui.trackingStrategy.init.bind(ui.trackingStrategy);

  ui.tracking.applyTrackingListener(
    [
      { eventName: 'click', reduceSelector: '.ui-js-data-layer-item' },
      { eventName: 'carousel:slid-manually' },
      { eventName: 'carousel:slid-auto' },
      { eventName: 'form:stepchange' },
      { eventName: 'popover:show' },
      { eventName: 'modal:show' },
      {
        eventName: 'safety-teaser:open',
        reduceSelector: '.ui-js-safety-teaser',
      },
      { eventName: 'submit', reduceSelector: 'form' },
      { eventName: 'scrollhandler:inview-once' },
    ],
    strategy
  );
});

// Init mtiTrackingInlineCode
(function () {
  var mtiTracking = document.createElement('script');
  var mtiTrackingInlineCode = document.createTextNode(
    "var MTIProjectId='7f896dd3-42da-4334-bad8-e3a52dce4741';"
  );
  mtiTracking.appendChild(mtiTrackingInlineCode);
  mtiTracking.type = 'text/javascript';
  mtiTracking.async = 'true';
  (
    document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]
  ).appendChild(mtiTracking);
})();

// Fire mtiTracking
window.addEventListener('load', function (event) {
  var evalString = new String(
    (function (p, a, c, k, e, r) {
      e = function (c) {
        return c.toString(a);
      };
      if (!''.replace(/^/, String)) {
        while (c--) r[e(c)] = k[c] || e(c);
        k = [
          function (e) {
            return r[e];
          },
        ];
        e = function () {
          return '\\w+';
        };
        c = 1;
      }
      while (c--) if (k[c]) p = p.replace(new RegExp('\\b' + e(c) + '\\b', 'g'), k[c]);
      return p;
    })(
      "4 7=g.b;4 2=3.p('r');2.9='a/5';2.c='d';2.e=('6:'==3.u.h?'6:':'i:')+'//j.k.l/t/1.5?m=n&o='+7;(3.8('q')[0]||3.8('s')[0]).f(2);",
      31,
      31,
      '||mtiTracking|document|var|css|https|projectId|getElementsByTagName|type|text|MTIProjectId|rel|stylesheet|href|appendChild|window|protocol|http|fast|fonts|net|apiType|css|projectid|createElement|head|link|body||location'.split(
        '|'
      ),
      0,
      {}
    )
  );
  eval(evalString.toString());
});
