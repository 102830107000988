/**
 * @author Viktoriia Khardina
 * Accordion with additional options
 */
(function () {
  'use strict';

  ui.AccordionControllerComponentView = ui.ComponentView.extend({
    name: 'ui.AccordionControllerComponentView',

    events: {},

    initialize: function (options) {
      this.isMobile = /(ms|xs)/.test(ui.Bootstrap.activeView);

      if (options.firstItemOpen && !this.isMobile) {
        var firstItem = this.$el.find('.panel-collapse').first();

        firstItem
          .removeData('bs.collapse')
          .collapse({ parent: false, toggle: false })
          .collapse('show')
          .removeData('bs.collapse');
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'AccordionControllerComponent',
    View: ui.AccordionControllerComponentView,
    selector: '.ui-js-accordion-controller',
  });

  $(ui.bootstrapAccordionControllerComponent());
}).call(this);
