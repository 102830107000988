/**
 * @author Nils von Rymon-Lipinski
 * @description TODO description
 */

(function () {
  'use strict';

  ui.HotspotsComponentView = ui.ComponentView.extend({
    name: 'ui.HotspotsComponentView',
    hotspotSelector: '.ui-js-hotspot',
    collapseItems: '.panel-collapse',
    ON_ANIMATION_END: 'webkitAnimationEnd oAnimationEnd msAnimationEnd animationend',
    ON_TRANSITION_END:
      'webkitTransitionEnd mozTransitionEnd oTransitionEnd msTransitionEnd transitionend',
    hotspotContentSelector: '.ui-js-hotspot-content',
    hotspotBtnSel: '.ui-js-hotspot-btn-open',
    hotspotBoxSel: '.ui-js-hotspot-box',
    scrollIcon: '.ui-js-scroll-icon',
    hotspotIndex: 0,
    activeClass: 'is-active',
    stopAnimationClass: 'is-stop-animation',
    mobilBodyClass: 'is-overlay-open',
    contentCopy: false,
    isMobil: false,
    $hotspots: null,
    $hotspotContents: null,
    $hotspotBtns: null,
    touchPointX: 0,
    touchPointY: 0,
    distanceX: 0,
    distanceY: 0,
    touchStartTime: 0,
    swipeSpeedThreshold: 2.2,
    isMoving: false,

    events: {
      'click .ui-js-hotspot-btn-open': 'openHotspot',
      'click .ui-js-hotspot-btn-close': 'closeHotspot',
      'touchstart .ui-js-hotspot-mobile-panel': 'touchEvent',
      'touchmove .ui-js-hotspot-mobile-panel': 'touchEvent',
      'touchend .ui-js-hotspot-mobile-panel': 'touchEvent',
    },

    initialize: function (options) {
      this.$hotspots = $(this.hotspotSelector, this.$el);
      this.$collapseItems = $(this.collapseItems, this.$el);
      this.$hotspotContents = $(this.hotspotContentSelector, this.$el);
      this.$hotspotBtns = $(this.hotspotBtnSel, this.$hotspots);
      this.$hotspotBox = $(this.hotspotBoxSel, this.$el);
      this.$scrollIcon = $(this.scrollIcon, this.$el);
      this.body = $('body');
      this.isAnimated = false;
      this.isComplete = false;
      this.isntInit = false;

      if (!this.$hotspotBtns.length) {
        console.warn('No HotspotBtns!');
        return false;
      }

      this.isMobil = /xs|ms/.test(ui.Bootstrap.activeView);

      this.$lastBtn = $(this.$hotspotBtns[this.hotspotIndex]);

      this.setHotspotContentPositions();

      this.setAriaAttributes();

      this.isMobilCheck();

      var isMobilCheck = this.isMobilCheck.bind(this);

      ui.on('bootstrap.activemediaquery.changed', isMobilCheck);
    },

    bindMobilEvents: function () {
      var self = this;
      this.$hotspotBox.on('scrollhandler:inview', function (e) {
        if (
          !this.isntInit &&
          $(e.currentTarget).hasClass('inview-completely') &&
          !self.isAnimated
        ) {
          self.isAnimated = true;
          var $scroller = self.$hotspotBox.find('.hotspot-box-scroller');
          self.$hotspotBox.addClass('is-animation');
          $scroller.addClass('is-animation').on(self.ON_ANIMATION_END, function (e) {
            if (e.originalEvent.animationName === 'animateHotspotBox') {
              self.setScrollPos();
            }
          });

          self.$hotspotBox.off('scrollhandler:inview');
        }
      });

      this.$hotspotBox.on('touchstart', function () {
        if (self.$hotspotBox.hasClass('is-animation')) {
          self.setScrollPos();
          self.$hotspotBox.off('touchstart');
        }
      });

      this.$hotspotBox.on('scroll', function () {
        if (self.$hotspotBox.get(0).scrollLeft > 0 && self.$hotspotBox.hasClass('is-animation')) {
          self.setScrollPos();
          self.$hotspotBox.off('scroll');
        }
      });
    },

    unbindMobilEvents: function () {
      this.$hotspotBox.off('scrollhandler:inview');
      this.$hotspotBox.off('touchstart');
      this.$hotspotBox.off('scroll');
    },

    setScrollPos: function () {
      if (!this.isComplete) {
        var $scroller = this.$hotspotBox.find('.hotspot-box-scroller');
        var xscroll = this.getXfromTransform($scroller);

        this.$scrollIcon.addClass('is-hide');
        this.$hotspotBox.removeClass('is-animation');
        $scroller.css('animation', '');
        this.$hotspotBox.get(0).scrollLeft = parseInt(xscroll * -1);
        this.isComplete = true;
      }
    },

    getXfromTransform: function ($element) {
      var matrix = $element
        .css('transform')
        .replace(/[^0-9\-.,]/g, '')
        .split(',');
      var x = matrix[12] || matrix[4];
      return x;
    },

    isMobilCheck: function () {
      this.isMobil = /xs|ms/.test(ui.Bootstrap.activeView);
      if (this.isMobil) {
        // this.body.addClass(this.mobilBodyClass);
        this.bindMobilEvents();
      } else {
        // this.body.removeClass(this.mobilBodyClass);
        this.setHotspotContentPositions();
        this.unbindMobilEvents();
      }
    },

    setAriaAttributes: function () {
      this.$hotspotBtns.attr('tabindex', 0);
      this.$hotspotBtns.attr('aria-expanded', false);
      this.$hotspotBtns.find('.ui-js-open-sr').attr('aria-hidden', false);
      this.$hotspotBtns.find('.ui-js-close-sr').attr('aria-hidden', true);

      this.$hotspotContents.attr('tabindex', -1);
      this.$hotspotContents.attr('aria-hidden', true);
    },

    setHotspotContentPositions: function () {
      var self = this;
      var elmHeight = this.$el.outerHeight(true);
      var elmWidth = this.$el.outerWidth(true);
      this.$hotspots.each(function (idx, elm) {
        var contentElm = self.$hotspotContents[idx];
        var $contentElm = $(contentElm);
        var marginButton = 48;
        var top = elm.offsetTop;
        var topWithMargin = elm.offsetTop - marginButton;
        var right = elmWidth - elm.offsetLeft;
        var rightWithMargin = elmWidth - elm.offsetLeft - marginButton;
        var bottom = elmHeight - elm.offsetTop;
        var bottomWithMargin = elmHeight - elm.offsetTop - marginButton;
        var left = elm.offsetLeft;
        var leftWithMargin = elm.offsetLeft - marginButton;
        var contentHeight = contentElm.offsetHeight;
        var contentWidth = contentElm.offsetWidth;
        var isTop = topWithMargin > contentHeight;
        var isRight = rightWithMargin > contentWidth;
        var isRightHalf = right / 2 > contentWidth / 2;
        var isBottom = bottomWithMargin > contentHeight;
        var isLeft = leftWithMargin > contentWidth;
        var isLeftHalf = left / 2 > contentWidth / 2;
        var isRightLeftHalf = isRightHalf && isLeftHalf;

        if (isTop && isRightLeftHalf && topWithMargin >= bottomWithMargin) {
          $contentElm.addClass('is-top');
        } else if (isBottom && isRightLeftHalf && bottomWithMargin >= topWithMargin) {
          $contentElm.addClass('is-bottom');
        } else if (isRight && isRight > isLeft) {
          $contentElm.addClass('is-right');
        } else if (isLeft && isLeft > isRight) {
          $contentElm.addClass('is-left');
        } else if (top >= bottom) {
          $contentElm.addClass('is-top');
        } else if (bottom >= top) {
          $contentElm.addClass('is-bottom');
        }
      });
    },

    openHotspot: function (e) {
      this.isntInit = true;
      var self = this;
      var $currentHotspot = $(e.target).closest(this.hotspotSelector);

      if (this.isMobil) {
        this.$hotspotContents.removeClass('is-swipe-down');
        this.setScrollPos();
        var $contentWrapper = $currentHotspot.find('.hotspot-content-wrapper');
        $contentWrapper.on('click', function (e) {
          if ($(e.target).hasClass('hotspot-content-wrapper')) {
            $contentWrapper.off('click');
            self.closeHotspot(e);
          }
        });
        // bodyScrollLock.disableBodyScroll($contentWrapper[0]);
        // bodyScrollLock.disableBodyScroll(this.$hotspotBox[0]);
      } else {
        var $body = $('body');
        $body.on('click', function (e) {
          var $target = $(e.target);
          var isActive = $currentHotspot.hasClass(self.activeClass);
          var isBtn = $target.hasClass('ui-js-hotspot-btn-open');
          var isContent = $target.hasClass('ui-js-hotspot-content');
          var hasParentContent = $target.parents('.hotspot-content-wrapper');

          if (isActive && !isBtn && !isContent && !hasParentContent.length) {
            $body.off('click');
            self.closeHotspot({
              target: $currentHotspot[0],
            });
          }
        });
      }

      if (this.setup.mobileAccordion && this.isMobil) {
        this.$collapseItems.on('shown.bs.collapse', function (e) {
          var $panel = $(this).closest('.panel');
          // bodyScrollLock.clearAllBodyScrollLocks();

          $('html,body').animate(
            {
              scrollTop: $panel.offset().top,
            },
            500
          );
        });
      } else {
        if ($currentHotspot.hasClass(this.activeClass)) {
          this.closeHotspot(e);
          if (this.isMobil) {
            this.body.removeClass(this.mobilBodyClass);
            // bodyScrollLock.clearAllBodyScrollLocks();
          }
        } else {
          this.$hotspots.removeClass(this.activeClass);
          this.setAriaAttributes();
          $currentHotspot.addClass(this.activeClass);
          $currentHotspot
            .find(this.hotspotBtnSel)
            .attr('tabindex', -1)
            .attr('aria-expanded', true)
            .focus();
          $currentHotspot
            .find(this.hotspotContentSelector)
            .attr('tabindex', 1)
            .attr('aria-hidden', false)
            .focus();
          $currentHotspot.find('.ui-js-open-sr').attr('aria-hidden', true);
          $currentHotspot.find('.ui-js-close-sr').attr('aria-hidden', false);
          if (this.isMobil) {
            this.body.addClass(this.mobilBodyClass);
          }
        }
      }
    },

    closeHotspot: function (e) {
      $(e.target).closest(this.hotspotSelector).removeClass(this.activeClass);

      if (this.isMobil) {
        this.body.removeClass(this.mobilBodyClass);
        this.$hotspotContents.removeClass('is-swipe-up');
        // bodyScrollLock.clearAllBodyScrollLocks();
        // $currentHotspot.find('.hotspot-content-wrapper').off('click');
        this.$el.find('.hotspot-content-wrapper').removeClass('is-open');
      }
      this.setAriaAttributes();
    },

    /**
     * Performs touch recognition and applies css translate on carousel slides
     * @param {Event} e - touch event, i.e. touchstart, touchmove, touchend
     */
    touchEvent: function (e) {
      var event = null;
      var self = this;

      if (e.originalEvent && e.originalEvent.changedTouches && !this.isIconPreviewAndLarge) {
        event = e.originalEvent.changedTouches[0];
      } else {
        return;
      }

      switch (e.type) {
        case 'touchstart': {
          this.currentTarget = $(e.target);
          this.hotspotContent = this.currentTarget.parent();
          this.touchPointX = event.pageX;
          this.touchPointY = event.pageY;
          this.touchStartTime = new Date().getTime();
          break;
        }
        case 'touchmove': {
          // Only translate the track if a touchstart has been recognized before
          if (this.touchPointY) {
            this.distanceX = this.touchPointX - event.pageX;
            this.distanceY = this.touchPointY - event.pageY;

            // Prevent scroll
            e.preventDefault();

            this.isMoving = true;
          }

          break;
        }
        case 'touchend': {
          if (this.isMoving) {
            if (this.distanceY > 20) {
              this.hotspotContent.addClass('is-swipe-up');
              this.hotspotContent.closest('.hotspot-content-wrapper')[0].classList.add('is-open');
              // bodyScrollLock.disableBodyScroll(this.hotspotContent[0]);
            } else {
              this.hotspotContent.removeClass('is-swipe-up');
            }

            if (this.distanceY < -20) {
              this.hotspotContent.one(self.ON_TRANSITION_END, function (event) {
                self.closeHotspot(e);
              });
              this.hotspotContent.addClass('is-swipe-down');
            }
          }

          this.distanceY = 0;
          this.isMoving = false;

          break;
        }
      }
    },

    render: function () {
      return this;
    },

    closeView: function () {
      this.unbind();
      this.remove();
    },

    /**
     * Custom round function, uses bitwise OR (|)
     * @param {Number} n - float
     * @returns {Number} - rounded up if roundUpLimit reached
     */
    round: function (n) {
      if (n > 0) return (n + this.roundUpLimit) | 0;
      else {
        n = -n;
        return -(n + this.roundUpLimit) | 0;
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'HotspotsComponent',
    View: ui.HotspotsComponentView,
    selector: '.ui-js-hotspots',
    reinitialize: true,
  });

  $(ui.bootstrapHotspotsComponent());
}).call(this);
