/**
 * @author Sang-In Haetzel, Peter DEmatté
 */

(function () {
  'use strict';

  /**
        @description
        get value for free-html-wrapper ratio "wdith-to-height" via data-ratio or data-hieght attribute.

        @params
        throttleTimer: object,
        throttleTimerIntervall: integer,
        data-ratio: string (e.g. 4/3, notice: string always conatins a slash),
        data-hieght: integer
     */

  ui.FreeHtmlRatioComponentView = ui.ComponentView.extend({
    name: 'ui.FreeHtmlRatioComponentView',

    initialize: function (elm, options) {},

    setRatioOnElements: function (value) {
      this.$el.each(function (index, element) {
        var $element = $(element);
        var height = +$element.attr('data-height');
        var ratio = $element.attr('data-ratio');
        var width;

        if (ratio) {
          ratio = ratio.split('/');
          width = $element.innerWidth();
          height = Math.round((width / ratio[0]) * ratio[1]);
        }

        $element.innerHeight(height || '');
      });
    },

    render: function () {
      var that = this;

      ui.on('bootstrap.activemediaquery.changed', function (value) {
        that.setRatioOnElements.call(that, value);
      });

      this.setRatioOnElements();
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FreeHtmlRatioComponent',
    View: ui.FreeHtmlRatioComponentView,
    selector: '.ui-js-c238-XX-html',
  });

  // ui.bootstrapFreeHtmlRatioComponent = function() {
  //    $('.ui-js-c238-XX-html').uiFreeHtmlRatioComponent();
  // };

  $(ui.bootstrapFreeHtmlRatioComponent('.ui-js-c238-XX-html'));
}).call(this);
