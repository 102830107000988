/**
 * @author Viktoriia Khardina
 * Handle tile behaviour
 */
(function () {
  'use strict';

  ui.TileAccordionComponentView = ui.ComponentView.extend({
    name: 'ui.TileAccordionComponentView',
    defaults: {
      isOpenClass: 'is-open',
    },

    allTiles: '.ui-c301-12-tile-accordion [data-js-item="ui-js-tile"]',
    tile: '[data-js-item="ui-js-tile"]',
    tileItem: '[data-js-item="ui-js-tile-item"]',
    tileDesc: '.tile-description',

    events: {
      'click [data-js-item="ui-js-tile-item"]': 'toggleTile',
      'click [data-js-item="tile-close"]': 'closeTile',
    },

    initialize: function () {
      this.$tileItem = this.$el.find(this.tileItem);
      this.$tiles = this.$el.find(this.tile);
      this.$tileDesc = this.$el.find(this.tileDesc);

      // fix for rendering at the beginning
      this.$tileDesc.addClass('is-overflow-hidden');
    },

    render: function () {
      if (!location.hash) return;

      const $target = this.$el.find(location.hash);

      if (!$target.hasClass(this.defaults.isOpenClass))
        $target.find(this.tileItem).trigger('click');
    },

    pushToDataLayer: function (action, setup) {
      if (!window.dataLayer || !action || !setup) return;

      const { data } = setup;

      if (!data || !data.event || data.event !== 'kachelAccordion' || !data.id) return;

      const dataLayerData = {
        event: data.event,
        kachelName: data.name || '',
        kachelCategory: data.category || '',
        kachelId: data.id,
        kachelAction: action,
      };

      window.dataLayer.push(dataLayerData);

      if (window.logTracking) {
        console.info('added dataLayer data:', JSON.stringify(dataLayerData));
        console.info('Tracking Obj', dataLayerData);
      }
    },

    toggleTile: function (event) {
      event.stopPropagation();
      const $target = $(event.currentTarget);
      const isMobile = /xs|ms/.test(ui.Bootstrap.activeView);
      const offset = isMobile ? 24 : 100;

      this.$currentTile = $target.closest('.tile');

      if (this.$currentTile.hasClass(this.defaults.isOpenClass)) return;

      // change Open TileAccordion
      if (this.currentlyOpenTilesAccordionId !== this.cid) this.setCurrentlyOpenTilesAccordion();

      // remove all open tiles in all components
      $(this.allTiles).removeClass(this.defaults.isOpenClass);
      this.pushToDataLayer('close', this.currentTileDataLayer);

      // fix for rendering at the beginning
      this.$tileDesc.removeClass('is-overflow-hidden');

      // set new active tile
      this.$currentTile.addClass(this.defaults.isOpenClass);
      this.currentTileDataLayer = ui.getElementSetup({
        el: this.$currentTile,
      });
      this.pushToDataLayer('open', this.currentTileDataLayer);

      setTimeout(() => {
        history.replaceState(null, null, `#${this.$currentTile.attr('id')}`);

        const currentScrollTop = this.$currentTile.offset().top - offset;
        if (this.currentScrollTop !== currentScrollTop) {
          this.currentScrollTop = currentScrollTop;

          $('html, body').stop().animate(
            {
              scrollTop: currentScrollTop,
            },
            250
          );
        }
      }, 400); // delay for the animation of infolay
    },

    setCurrentlyOpenTilesAccordion: function () {
      $(document).off(
        `click.tilesAccordion${this.currentlyOpenTilesAccordionId}`,
        this.closeTile.bind(this)
      );
      $(document).on(`click.tilesAccordion${this.cid}`, this.closeTile.bind(this));

      this.currentlyOpenTilesAccordionId = this.cid;
    },

    closeTile: function () {
      this.$currentTile.removeClass(this.defaults.isOpenClass);
      this.pushToDataLayer('close', this.currentTileDataLayer);

      this.currentTileDataLayer = null;

      history.replaceState(null, null, location.pathname);

      $(document).off(`click.tilesAccordion${this.cid}`);
      this.currentlyOpenTilesAccordionId = null;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'TileAccordionComponent',
    View: ui.TileAccordionComponentView,
    selector: '.ui-js-tile-accordion',
  });

  $(ui.bootstrapTileAccordionComponent());
}).call(this);
