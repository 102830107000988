/**
 * @author Henri Podolski
 */

(function () {
  'use strict';

  /**
   * Interactive associations map popover, hover effects and search form
   *
   * Data-setup
   */
  ui.AssociationMapView = ui.ComponentView.extend({
    name: 'ui.AssociationMapView',

    events: {},

    ON_TRANSITION_END:
      'webkitTransitionEnd mozTransitionEnd oTransitionEnd msTransitionEnd transitionend',

    /**
     * mapping created using the following tools:
     * http://www2.kulturprozent.ch/img/kontakt/map_ch.gif - for the name of association on the map
     * https://filialen.migros.ch/de/ - for finding cities in the assocation
     * https://aus-der-region.migros.ch/aus-der-region/de/ihre-region.html - for testing the API using cities
     */
    associationsMapping: {
      ge: {
        top: '63%',
        left: '2%',
        id: '2',
      },
      bs: {
        top: '8%',
        left: '33%',
        id: '3',
      },
      vd: {
        top: '45%',
        left: '11%',
        id: '4',
      },
      nf: {
        top: '23%',
        left: '21%',
        id: '5',
      },
      os: {
        top: '48%',
        left: '78%',
        id: '6',
      },
      aa: {
        top: '36%',
        left: '35%',
        id: '7',
      },
      zh: {
        top: '22%',
        left: '65%',
        id: '8',
      },
      ti: {
        top: '64%',
        left: '61%',
        id: '9',
      },
      vs: {
        top: '68%',
        left: '33%',
        id: '10',
      },
      lu: {
        top: '31%',
        left: '53%',
        id: '11',
      },
    },

    touchPointX: 0,
    touchPointY: 0,
    distanceX: 0,
    distanceY: 0,
    touchStartTime: 0,
    swipeSpeedThreshold: 2.2,
    isMoving: false,
    isSlideUp: false,
    associationEl: false,

    initialize: function () {
      this.mapWrapper = this.el.querySelector('.js-association-map');
      this.scrollArea = this.el.querySelector('.image-animation');
      this.$mapWrapper = $(this.mapWrapper);
      this.mapPopovers = Array.prototype.slice.call(
        this.mapWrapper.querySelectorAll('.js-map-popover')
      );
      this.mapSvgEmbed = this.mapWrapper.querySelector('.js-association-map-svg');
      this.isMobil = /xs|ms/.test(ui.Bootstrap.activeView);
      this.bindEvents();
    },

    bindEvents: function () {
      $(document).on('click', this.onDocumentClick.bind(this));
      if (this.isMobil) {
        document.addEventListener('touchstart', this.touchEvent.bind(this), {
          passive: false,
        });
        document.addEventListener('touchmove', this.touchEvent.bind(this), {
          passive: false,
        });
        document.addEventListener('touchend', this.touchEvent.bind(this), {
          passive: false,
        });
      }

      // call if it's ready, otherwise wait until loaded
      if (
        this.mapSvgEmbed.contentDocument &&
        this.mapSvgEmbed.contentDocument.querySelector('[id^="genossenschaft"]')
      ) {
        this.onMapLoaded();
      } else {
        this.mapSvgEmbed.addEventListener('load', this.onMapLoaded.bind(this), false);
      }
    },

    touchEvent: function (e) {
      var isMap = $(e.target).is(this.$mapWrapper);
      var isWithinMap = $(e.target).parents('.js-association-map').first().is(this.$mapWrapper);
      var $popover = $(e.target);
      var $popoverWithin = $(e.target).parents('.popover').first();
      var isPopOver = $popover.hasClass('popover');
      var isWithinPopOver = $popoverWithin.hasClass('popover');

      if (isWithinMap || isMap) {
        this.$mapWrapper.addClass('is-touched');
      }

      if (isPopOver || isWithinPopOver) {
        this.$mapWrapper.addClass('is-touched');

        var event = null;
        var self = this;

        if (e.changedTouches) {
          event = e.changedTouches[0];
        } else {
          return;
        }

        switch (e.type) {
          case 'touchstart': {
            this.currentTarget = $(e.target);
            this.touchPointX = event.pageX;
            this.touchPointY = event.pageY;
            this.touchStartTime = new Date().getTime();
            break;
          }
          case 'touchmove': {
            // Only translate the track if a touchstart has been recognized before
            if (this.touchPointY) {
              this.distanceX = this.touchPointX - event.pageX;
              this.distanceY = this.touchPointY - event.pageY;

              // Prevent scroll
              e.preventDefault();
              this.isMoving = true;
            }

            break;
          }
          case 'touchend': {
            if (this.isMoving) {
              var _this = this;
              if (this.distanceY > 20) {
                if (isPopOver) {
                  $popover.addClass('slide-up');
                  _this.isSlideUp = true;
                } else if (isWithinPopOver) {
                  $popoverWithin.addClass('slide-up');
                  _this.isSlideUp = true;
                }
              }
              if (this.distanceY < -20) {
                if (isPopOver) {
                  _this.isSlideUp = false;
                  if ($popover.hasClass('slide-up')) {
                    $popover.removeClass('slide-up');
                  } else {
                    $popover.removeClass('slide-up in');
                    $popover.one(self.ON_TRANSITION_END, function () {
                      _this.hideAllPopOvers(true);
                    });
                  }
                } else if (isWithinPopOver) {
                  _this.isSlideUp = false;
                  if ($popoverWithin.hasClass('slide-up')) {
                    $popoverWithin.removeClass('slide-up');
                  } else {
                    $popoverWithin.removeClass('slide-up in');
                    $popover.one(self.ON_TRANSITION_END, function () {
                      _this.hideAllPopOvers(true);
                    });
                  }
                }
              }
            }

            this.distanceY = 0;
            this.isMoving = false;

            break;
          }
        }
      }
    },

    /**
     * provides document close functionality
     * @param evt
     */
    onDocumentClick: function (evt) {
      var $target = $(evt.target);
      var isMap = $target.is(this.$mapWrapper);
      var isWithinMap = $target.parents('.js-association-map').first().is(this.$mapWrapper);
      var isNotMap = !isMap && !isWithinMap;
      var isPopOver = $target.hasClass('popover');
      var isWithinPopOver = $target.parents('.popover').first().hasClass('popover');
      var isPopOverCloseBtn = $target.hasClass('close-btn');

      if (isNotMap && !isPopOver && !isWithinPopOver) {
        this.hideAllAssociationAreas();
        this.hideAllPopOvers();
        this.isSlideUp = false;
      }

      if (isPopOverCloseBtn) {
        this.$openPopover.removeClass('is-active');
        this.$openPopover = false;
        this.isSlideUp = false;
      }
    },

    hideAllPopOvers: function (forceActive) {
      if (this.$openPopover && this.$openPopover.length) {
        this.$openPopover[0].View.$popover.removeClass('slide-up');
        this.$openPopover.trigger('popover:hide');
        this.$openPopover.removeClass('is-hover');
        this.$mapWrapper.find('.js-map-popover.is-hover').removeClass('is-hover');
        if (forceActive) {
          this.$openPopover.addClass('is-active');
        } else {
          this.$openPopover.removeClass('is-active');
        }
        this.$openPopover = false;
      }
    },

    onMapLoaded: function () {
      var _this = this;
      this.mapSvgDoc = this.mapSvgEmbed.contentDocument;
      this.mapAssociationNodes = Array.prototype.slice.call(
        this.mapSvgDoc.querySelectorAll('[id^="genossenschaft"]')
      );

      this.mapPopoversEach(function (popover) {
        var mapAreaShortcut = _this.$(popover).data('mapArea');
        var mapAreaUrl = _this.$(popover).data('areaUrl');
        if (mapAreaShortcut && _this.associationsMapping[mapAreaShortcut]) {
          // read
          var id = 'genossenschaft' + _this.associationsMapping[mapAreaShortcut].id;
          var associationAreaEl = _this.mapSvgDoc.querySelector('#' + id);

          // write
          _this.associationsMapping[mapAreaShortcut].popover = popover;
          _this.associationsMapping[mapAreaShortcut].url = mapAreaUrl;
          _this.associationsMapping[mapAreaShortcut].associationEl = associationAreaEl;
          associationAreaEl.associationObject = _this.associationsMapping[mapAreaShortcut];
          popover.associationObject = _this.associationsMapping[mapAreaShortcut];
          popover.style.top = _this.associationsMapping[mapAreaShortcut].top;
          popover.style.left = _this.associationsMapping[mapAreaShortcut].left;

          // add popover listener
          popover.addEventListener(
            'mouseover',
            _this.onAssociationAreaMouseOverViaPopOver.bind(_this),
            true
          );
          popover.addEventListener(
            'mouseout',
            _this.onAssociationAreaMouseOutViaPopOver.bind(_this),
            true
          );
          popover.addEventListener(
            'click',
            _this.onAssociationAreaMouseClickViaPopOver.bind(_this),
            false
          );

          // add svg listener
          associationAreaEl.addEventListener(
            'mouseover',
            _this.onAssociationAreaMouseOver.bind(_this),
            true
          );
          associationAreaEl.addEventListener(
            'mouseout',
            _this.onAssociationAreaMouseOut.bind(_this),
            true
          );
          associationAreaEl.addEventListener(
            'click',
            _this.onAssociationAreaMouseClick.bind(_this),
            false
          );
        }
      });
    },

    addHoverStatePopOver: function (associationEl) {
      this.$mapWrapper.addClass('is-touched');
      $(associationEl.parentNode.associationObject.popover).addClass('is-hover');
    },

    removeHoverStatePopOver: function (associationEl) {
      $(associationEl.parentNode.associationObject.popover).removeClass('is-hover');
    },

    setSlideUpClass: function ($popover) {
      if (this.isSlideUp) {
        $popover.addClass('slide-up');
      } else {
        $popover.removeClass('slide-up');
      }
    },

    activeAssociationArea: function (associationEl, isCurrent) {
      this.$mapWrapper.addClass('is-touched');
      associationEl.parentNode.setAttribute('fill-opacity', '0');
      associationEl.style.cursor = 'pointer';
      if (isCurrent) {
        this.associationEl = associationEl;
      }
    },

    checkScrollPosition: function (associationEl) {
      if (this.isMobil) {
        var boundingRect = associationEl.getBoundingClientRect();
        var currentScrollX = this.scrollArea.scrollLeft;

        if (currentScrollX > boundingRect.left) {
          this.scrollArea.scrollLeft = boundingRect.left - 40;
        } else if (boundingRect.left + boundingRect.width > screen.availWidth) {
          this.scrollArea.scrollLeft = boundingRect.left - 80;
        }
      }
    },

    onAssociationAreaMouseOver: function (evt) {
      this.activeAssociationArea(evt.target);
      this.addHoverStatePopOver(evt.target);
    },

    onAssociationAreaMouseOverViaPopOver: function (evt) {
      this.activeAssociationArea(
        evt.target.parentNode.associationObject.associationEl.firstElementChild
      );
    },

    onAssociationAreaMouseOut: function (evt) {
      var associationEl = evt.target;
      var parentEl = associationEl.parentNode;
      if (!this.associationEl || !associationEl.isEqualNode(this.associationEl)) {
        parentEl.setAttribute('fill-opacity', '1');
      }
      this.removeHoverStatePopOver(evt.target);
    },

    onAssociationAreaMouseOutViaPopOver: function (evt) {
      var associationEl = evt.target.parentNode.associationObject.associationEl.firstElementChild;
      var parentEl = evt.target.parentNode.associationObject.associationEl;
      if (!this.associationEl || !associationEl.isEqualNode(this.associationEl)) {
        parentEl.setAttribute('fill-opacity', '1');
      }
      this.removeHoverStatePopOver(associationEl);
    },

    onAssociationAreaMouseClick: function (evt) {
      this.showAssociationAreaWithPopOver(evt.target, false, true);
    },

    showAssociationAreaWithPopOver: function (target, forceActive, isCurrent) {
      this.$mapWrapper.addClass('is-touched');
      this.hideAllAssociationAreas();
      this.activeAssociationArea(target, isCurrent);
      this.$mapWrapper.find('.js-map-popover.is-active').removeClass('is-active');
      if (forceActive) {
        $(target.parentNode.associationObject.popover).addClass('is-active');
      } else {
        this.togglePopOver(true, target.parentNode.associationObject.popover);
      }
      this.checkScrollPosition(target);
    },

    onAssociationAreaMouseClickViaPopOver: function (evt) {
      this.hideAllAssociationAreas();
      this.$openPopover = $(evt.target.parentNode.associationObject.popover);
      this.togglePopOver();
      this.setSlideUpClass(this.$openPopover[0].View.$popover);
      this.$mapWrapper.find('.js-map-popover.is-active').removeClass('is-active');
      this.$openPopover.addClass('is-active');
      this.activeAssociationArea(
        evt.target.parentNode.associationObject.associationEl.firstElementChild,
        true
      );
      this.checkScrollPosition(
        evt.target.parentNode.associationObject.associationEl.firstElementChild
      );
    },

    hideAllAssociationAreas: function () {
      this.associationAreasEach(function (area) {
        area.setAttribute('fill-opacity', '1');
      });
    },

    togglePopOver: function (force, target) {
      if (this.$openPopover && target && this.$openPopover[0].isEqualNode(target)) {
        if (this.$openPopover[0].View.isActive) {
          this.$openPopover.removeClass('is-active').trigger('popover:hide');
        } else if (!this.$openPopover[0].View.isActive) {
          this.$openPopover.trigger('popover:show').addClass('is-active');
        }
      } else if (force && target) {
        if (this.$openPopover) {
          this.$openPopover.removeClass('is-active').trigger('popover:hide');
        }
        this.$openPopover = $(target);
        this.$openPopover.trigger('popover:show').addClass('is-active');
        this.setSlideUpClass(this.$openPopover[0].View.$popover);
      }
    },

    associationAreasEach: function (callback) {
      if (this.mapAssociationNodes !== undefined) {
        this.mapAssociationNodes.forEach(callback);
      }
    },

    mapPopoversEach: function (callback) {
      this.mapPopovers.forEach(callback);
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'AssociationMapComponent',
    View: ui.AssociationMapView,
    selector: '.ui-js-association-map',
    reinitialize: true,
  });

  /* globals ui: true */
  $(ui.bootstrapAssociationMapComponent());
}).call(this);
