(function () {
  'use strict';

  ui.NavTabsTriggerComponentView = ui.ComponentView.extend({
    name: 'ui.NavTabsTriggerComponentView',

    initialize: function () {
      this.el.addEventListener('click', this.onItemClick.bind(this));
    },

    onItemClick: function (e) {
      e.preventDefault();

      const hash = e.currentTarget.hash;

      if (hash) ui.trigger(ui.GlobalEvents.TAB_CHANGE, hash);
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'NavTabsTriggerComponent',
    View: ui.NavTabsTriggerComponentView,
    selector: '.ui-js-nav-tabs-trigger',
  });

  $(ui.bootstrapNavTabsTriggerComponent());
}).call(this);
