/**
 * @author Maria Schneider
 * Chart with Chart.js
 *
 * Data-Setup:
 * @param {String} theme - different color schema that is used for the themes (default: migros.ch)
 * @param {String} chartType - different chart type - bar (default) || horizontalBar || doughnut
 * @param {String} title - title that is rendered on top of the chart
 * @param {String} ariaLabel - aria-label text for accessibility
 * @param {String} chartBtnTitle - title text for btn chart
 * @param {String} tableBtnTitle - title text for btn table
 */

(function () {
  'use strict';

  ui.Chart = ui.ComponentView.extend({
    name: 'ui.Chart',
    state: !!(window.history && window.history.replaceState) || false,

    events: {
      'click .ui-js-c213-chart-switchbtn': 'onSwitchChartTable',
    },

    initialize: function (options) {
      // Defaults
      this.chartTypes = ['bar', 'horizontalBar', 'doughnut'];

      this.themes = {
        default: [
          '#ff6600',
          '#ffa564',
          '#dd4509',
          '#fe8e41',
          '#ffd3b4',
          '#fd4800',
          '#cfcac7',
          '#888888',
          '#9f9690',
          '#3c3c3c',
          '#666666',
          '#333333',
        ],
        nachhaltigkeit: [
          '#37AA41',
          '#004735',
          '#E8F2E6',
          '#6EC175',
          '#BDE2C0',
          '#097E3B',
          '#082422',
          '#00642B',
          '#F26505',
          '#FFA564',
          '#F38433',
          '#D14B16',
        ],
      };

      this.options = {
        bar: {
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                  padding: 7,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                scaleLabel: {
                  display: true,
                  padding: 7,
                },
                gridLines: {
                  color: '#e1e1e1',
                  drawBorder: false,
                  tickMarkLength: 0,
                },
                ticks: {
                  labelOffset: 1,
                  padding: 7,
                },
              },
            ],
          },
        },
        horizontalBar: {
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                scaleLabel: {
                  display: true,
                  padding: 7,
                },
                gridLines: {
                  color: '#e1e1e1',
                  drawBorder: false,
                  tickMarkLength: 0,
                },
                ticks: {
                  labelOffset: 1,
                  padding: 7,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                  padding: 7,
                },
              },
            ],
          },
        },
        doughnut: {
          animation: {
            animateScale: true,
            animateRotate: true,
          },
        },
      };

      this.config = options.chart;

      if (!this.config) return;

      // init
      this.config.id = Math.round(Math.random() * 100000);

      this.initialSetup();
    },

    initialSetup: function () {
      this.chartType =
        this.config.type && this.chartTypes.indexOf(this.config.type) !== -1
          ? this.config.type
          : 'bar';

      this.setTable();
      this.createSwitcher();
      this.createCanvas();

      // hide table per default
      this.tableContainer.setAttribute('hidden', true);

      this.currentTheme =
        this.config.theme && Object.prototype.hasOwnProperty.call(this.themes, this.config.theme)
          ? this.themes[this.config.theme]
          : this.themes.default;
      this.data = this.chartType === 'doughnut' ? this.getDataDoughnut() : this.getDataBar();
    },

    setTable: function () {
      this.tableContainer = this.el.querySelector('.richtext .table-responsive');
      this.tableContainer.id = `tabpanel-table-${this.config.id}`;
      this.tableContainer.setAttribute('role', 'tabpanel');
      this.tableContainer.setAttribute('aria-labelledby', `tab-table-${this.config.id}`);
      $(this.tableContainer).addClass('ui-c213-table-container');
    },

    setSwitchActive: function (btn) {
      $(btn).addClass('active');
      btn.setAttribute('aria-selected', true);
      btn.removeAttribute('tabindex');
    },

    setSwitchDefault: function (btn) {
      btn.setAttribute('aria-selected', false);
      btn.setAttribute('tabindex', -1);
    },

    createSwitcher: function () {
      const switcher = document.createElement('div');
      $(switcher).addClass('ui-btn-group-switch btn-group btn-group-sm');
      switcher.setAttribute('role', 'tablist');
      const btns = ['chart', 'table'];

      btns.forEach((target) => {
        const btn = document.createElement('button');
        $(btn).addClass(
          `ui-js-c213-chart-switchbtn ui-js-c213-chart-switchbtn--${target} btn btn-outline-darkgrey`
        );

        btn.id = `tab-${target}-${this.config.id}`;
        btn.textContent = target;
        btn.setAttribute('role', 'tab');
        btn.setAttribute('aria-controls', `tabpanel-${target}-${this.config.id}`);
        if (this.config[`${target}BtnTitle`] || this.config[`${target}BtnTitle`] !== '')
          btn.title = this.config[`${target}BtnTitle`];

        this.setSwitchDefault(btn);

        if (target === 'chart') {
          this.setSwitchActive(btn);
        }

        switcher.appendChild(btn);
      });

      this.tableContainer.parentNode.insertBefore(switcher, this.tableContainer);
    },

    createCanvas: function () {
      this.canvasContainer = document.createElement('div');
      this.canvasContainer.id = `tabpanel-chart-${this.config.id}`;
      this.canvasContainer.setAttribute('role', 'tabpanel');
      this.canvasContainer.setAttribute('aria-labelledby', `tab-chart-${this.config.id}`);
      $(this.canvasContainer).addClass('ui-c213-chart-container');

      this.canvas = document.createElement('canvas');
      this.canvas.setAttribute('role', 'img');
      if (this.config.ariaLabel) this.canvas.setAttribute('aria-label', this.config.ariaLabel);

      this.canvasContainer.appendChild(this.canvas);
      this.tableContainer.parentNode.insertBefore(this.canvasContainer, this.tableContainer);
    },

    onSwitchChartTable: function (e) {
      const btn = e.target;
      const $btn = $(btn);

      if ($btn.hasClass('active')) return;

      let activeBtn = this.el.querySelector('.ui-js-c213-chart-switchbtn--table');

      if ($btn.hasClass('ui-js-c213-chart-switchbtn--table')) {
        this.canvasContainer.setAttribute('hidden', true);
        this.tableContainer.removeAttribute('hidden');
        activeBtn = this.el.querySelector('.ui-js-c213-chart-switchbtn--chart');
      } else {
        this.tableContainer.setAttribute('hidden', true);
        this.canvasContainer.removeAttribute('hidden');
      }

      this.setSwitchDefault(activeBtn);
      $(activeBtn).removeClass('active');

      this.setSwitchActive(btn);
    },

    getDataDoughnut: function () {
      const chartTable = this.el.querySelector('.ui-c213-chart-table .table');
      let labelIndex = null;

      const chartLabels = [];
      const chartData = [];
      const chartColors = [];

      Object.values(chartTable.querySelectorAll('tbody tr')).forEach((row, index) => {
        // labelIndex is used to check if a label for the column should be removed
        // only one index is used
        if (index === 0)
          labelIndex = Array.from(row.children).findIndex((item) => item.tagName === 'TH');
        chartLabels[index] =
          typeof labelIndex !== 'undefined' ? row.children[labelIndex].textContent : '';

        const data = Array.from(row.children).find((item) => item.tagName === 'TD');
        chartData[index] = data ? data.textContent : '';

        chartColors[index] = this.currentTheme[index % this.currentTheme.length];
      });

      const chartDatasetLabel = Object.values(
        chartTable.querySelectorAll('thead th, thead td strong')
      ).find((item, index) => item.textContent !== '' || index !== labelIndex);

      return {
        labels: chartLabels,
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColors,
            hoverBackgroundColor: chartColors,
            borderWidth: 4.5,
            hoverBorderWidth: 0,
            label: chartDatasetLabel ? chartDatasetLabel.textContent : '',
          },
        ],
      };
    },

    getDataBar: function () {
      let labelIndex = null;
      const chartTable = this.el.querySelector('.ui-c213-chart-table .table');

      const chartDataset = Object.values(chartTable.querySelectorAll('tbody tr')).reduce(
        (arr, row, index) => {
          // labelIndex is used to check if a label for the column should be removed
          // only one index is used
          if (index === 0)
            labelIndex = Array.from(row.children).findIndex((item) => item.tagName === 'TH');

          const data = Array.from(row.children).reduce((newData, item) => {
            if (item.tagName === 'TD') newData.push(item.textContent);

            return newData;
          }, []);

          arr.push({
            label: typeof labelIndex !== 'undefined' ? row.children[labelIndex].textContent : '',
            backgroundColor: this.currentTheme[index % this.currentTheme.length],
            hoverBackgroundColor: this.currentTheme[index % this.currentTheme.length],
            borderWidth: 0,
            data,
          });

          return arr;
        },
        []
      );

      const chartLabels = Object.values(
        chartTable.querySelectorAll('thead th, thead td strong')
      ).reduce((arr, item, index) => {
        if (item.textContent !== '' && index !== labelIndex) arr.push(item.textContent);

        return arr;
      }, []);

      return {
        labels: chartLabels,
        datasets: chartDataset,
      };
    },

    render: function () {
      if (!this.canvas) return;
      const ctx = this.canvas.getContext('2d');

      Chart.defaults.global.defaultFontColor = '#000';
      Chart.defaults.global.defaultFontFamily =
        '"Helvetica Now Micro Regular", Helvetica, sans-serif';
      Chart.defaults.global.tooltips.backgroundColor = '#333333';
      Chart.defaults.global.tooltips.cornerRadius = 1;
      Chart.defaults.global.tooltips.yPadding = 5;
      Chart.defaults.global.tooltips.titleFontSize = 14;
      Chart.defaults.global.tooltips.titleMarginBottom = 10;
      Chart.defaults.global.tooltips.titleSpacing = 6;
      Chart.defaults.global.tooltips.bodySpacing = 6;

      this.chart = new Chart(ctx, {
        type: this.chartType,
        data: this.data,
        options: Object.assign(
          this.options[this.chartType],
          {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: !!this.config.title,
              text: this.config.title,
              padding: 30,
              fontSize: 16,
            },
            legend: {
              position: 'bottom',
              align: 'start',
              labels: {
                boxWidth: 12,
                lineHeight: 1.5,
                padding: 15,
              },
            },
          },
          {}
        ),
      });
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ChartComponent',
    View: ui.Chart,
    selector: '.ui-js-c213-chart',
    reinitialize: false,
  });

  $(ui.bootstrapChartComponent());
}).call(this);
