/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Handles click events on button pills and toggles ARIA states. Compatible with
   * item-filter and pagination. Uses Bootstrap collapse-plugin. Reninitializes on each
   * AJAX call.
   */
  ui.EventCalendarComponentView = ui.ComponentView.extend({
    name: 'ui.EventCalendarComponentView',

    isOpened: false,

    $body: null,
    $panels: null,
    $headings: null,
    $pills: null,
    $filterBlock: null,

    $toggleFilterBtn: null,
    $toggleResultBtn: null,

    events: {
      'click .ui-js-show-filters': 'toggleFilterBlock',
      'click .ui-js-show-results': 'scrollToResults',
      'click .ui-js-open-pill': 'togglePanel',
      'click input.ui-js-input': 'transferCheckboxFocus',
      'click input.ui-js-checkbox-all': 'transferCheckboxFocus',
      'click input.ui-js-input-activate-select': 'transferCheckboxFocus',
      'click .ui-js-reset': 'transferResetBtnFocus',
      'click .ui-js-reset-by-id': 'transferResetBtnFocus',
      'click .ui-item-filter .dropdown-toggle': 'transferDropdownFocus',
    },

    initialize: function () {
      this.$body = $('html,body');
      this.$panels = this.$('.ui-panel-migros .panel-collapse');
      this.$headings = this.$('.ui-panel-migros .panel-heading');
      this.$pills = this.$('.ui-js-open-pill');
      this.$filterBlock = this.$('.ui-checkbox-block');
      this.$pillBlock = this.$('.ui-pill-block');
      this.$toggleFilterBtn = this.$('.ui-js-show-filters');
      this.$toggleResultBtn = this.$('.ui-pill-block .ui-js-show-results');
      this.isOpened = !this.$filterBlock.is('.hidden-xs');
      this.$mobilBlock = this.$('.ui-js-mobil-block');
    },

    render: function () {
      if (ui && ui.activeElement && typeof ui.activeElement === 'string') {
        this.focusElement();
      }
      return this;
    },

    /**
     * Either opens or closes the block (ui-checkbox-block) containing the checkbox filters
     */
    toggleFilterBlock: function (triggerIn) {
      if (this.isOpened) {
        // Close checkbox filters
        this.$filterBlock.addClass('hidden-xs hidden-ms');
        this.$filterBlock.removeClass('is-active-filter-block is-in');
        this.$toggleFilterBtn.removeClass('sr-only');
        this.$toggleResultBtn.addClass('sr-only');
        this.isOpened = false;
      } else {
        // Open checkbox filters
        this.$filterBlock.removeClass('hidden-xs hidden-ms');
        this.$filterBlock.addClass('is-active-filter-block');
        if (triggerIn && !triggerIn.type) {
          this.$filterBlock.addClass('is-in');
        }
        // this.$toggleFilterBtn.addClass('sr-only');
        this.$toggleResultBtn.removeClass('sr-only');
        this.isOpened = true;
      }
    },

    /**
     * Smooth scrolls to top edge ot pill-block, closes filter-block, collapses accordion
     * @param {Event} e - click event
     */
    scrollToResults: function (e) {
      var scrollY = this.$pillBlock.offset().top;

      e.preventDefault();

      if (this.isOpened) {
        this.toggleFilterBlock();
      }
      this.togglePanel();

      this.$('.collapse').collapse('hide');
      if (/(ms|xs)/.test(ui.Bootstrap.activeView)) {
        this.$body.animate({ scrollTop: scrollY }, 0);
      } else {
        this.$body.animate({ scrollTop: scrollY }, 300);
      }
    },

    /**
     * Toggles filter panel controlled by ui-pills. Sets ARIA states accordingly.
     * @param {Event} [e] - optional click event, closes the panel if not passed
     */
    togglePanel: function (e) {
      var $openedPanel = this.$panels.filter('.in');

      this.$panels.removeClass('in is-in').attr('aria-expanded', false);
      this.$headings.attr('aria-expanded', false);
      this.$pills.removeClass('is-active').attr('aria-expanded', false);

      if (e) {
        // If e exists, the toggle has been initialized manually
        var hash = e.target.hash;
        var $targetPill = $(e.target);
        var $targetPanel = $(hash);
        var $targetHeading = $('[href="' + hash + '"]');

        e.preventDefault();

        if ($targetPanel.length) {
          var isOpen = $targetPanel.is($openedPanel);

          if (!isOpen) {
            this.openPanel($targetPanel, $targetHeading, $targetPill);
          }
        }
      }
    },

    /**
     * Opens panel, sets ARIA attributes accordingly
     * @param {Object} $panel - target panel
     * @param {Object} $heading - target heading
     * @param {Object} $pill - corresponding target pill
     */
    openPanel: function ($panel, $heading, $pill) {
      if ($panel.length) {
        $panel.addClass('in is-in').attr('aria-expanded', true);
        if ($panel.closest('.ui-js-mobil-block')) {
          $panel.closest('.ui-js-mobil-block').addClass('is-active-filter-block ');
        }
      }
      if ($heading.length) $heading.attr('aria-expanded', true);
      if ($pill.length) $pill.addClass('is-active').attr('aria-expanded', true);
    },

    /**
     * Accessibility feature
     * Saves last focused item selector into temporary variable ui.activeElement
     * @param {Event} e - click event
     */
    transferCheckboxFocus: function (e) {
      var target = e.currentTarget;
      var selector =
        target.nodeName.toLowerCase() +
        '[name="' +
        target.name +
        '"]' +
        '[value="' +
        target.value +
        '"]';
      if (ui) {
        ui.activeElement = selector;
        this.saveMobilScrollPos(false);
      }
    },

    /**
     * Saves last focused item selector into temporary variable ui.activeElement
     * @param {Event} e - click event
     */
    transferDropdownFocus: function (e) {
      if (ui && e.currentTarget.id) {
        ui.activeElement = '#' + e.currentTarget.id;
        this.saveMobilScrollPos(false);
      }
    },

    /**
     * Saves last focused item reset-btn into temporary variable ui.activeElement
     * @param {Event} e - click event
     */
    transferResetBtnFocus: function (e) {
      var $target = $(e.currentTarget);
      if (ui && $target.attr('data-reset')) {
        ui.activeElement = '[data-reset="' + $target.attr('data-reset') + '"]';
        ui.activeElementIndex = this.$(ui.activeElement).index($(e.currentTarget));
        this.saveMobilScrollPos(true);
      }
    },

    saveMobilScrollPos: function (isResetBtn) {
      if (/(ms|xs)/.test(ui.Bootstrap.activeView)) {
        if (isResetBtn) {
          ui.activeElementResetBtn = true;
          ui.activeElementScrollPos = this.$mobilBlock.find('.panel-group').scrollTop();
        } else {
          ui.activeElementResetBtn = false;
          ui.activeElementScrollPos = $(ui.activeElement, this.$mobilBlock)
            .closest('.form-group > .row')
            .scrollTop();
        }
      }
    },

    setMobilScrollPos: function () {
      if (ui.activeElementResetBtn) {
        $('.ui-js-mobil-block .panel-group').scrollTop(ui.activeElementScrollPos);
      } else {
        $(ui.activeElement, this.$mobilBlock)
          .closest('.form-group > .row')
          .scrollTop(ui.activeElementScrollPos);
      }
    },

    /**
     * Open the panel (and mark the corresponding pill) that was focused last
     * (before the request). Focus the element for accessibility. Solves MGRSR-4067.
     */
    focusElement: function () {
      var activeElement = ui.activeElement;
      var activeElementIndex = ui.activeElementIndex;
      var $focus = this.$(activeElement);
      this.isOpened = false;

      if (/(ms|xs)/.test(ui.Bootstrap.activeView) && !$focus.length && activeElementIndex === 2) {
        this.toggleFilterBlock(true);
        this.setMobilScrollPos();
      }

      if ($focus.length) {
        var $targetPanel = this.$panels.has($focus);
        var $targetHeading = this.$headings.has($focus);
        var pillId = $targetPanel.attr('id');
        var $targetPill = $('.ui-js-open-pill[aria-controls="' + pillId + '"]');

        if (
          /(ms|xs)/.test(ui.Bootstrap.activeView) &&
          ($targetPanel.length > 0 || this.$mobilBlock.has($focus).length)
        ) {
          this.toggleFilterBlock(true);
        }

        if (this.$panels.filter('.in').length) {
          // Close previously opened panels in possible request
          this.togglePanel();
        }

        // Target focus element is not visible, panel needs to be opened
        this.openPanel($targetPanel, $targetHeading, $targetPill);

        if (!/(ms|xs)/.test(ui.Bootstrap.activeView) && $focus.length > 1) {
          $focus.last().focus();
        } else if (!/(ms|xs)/.test(ui.Bootstrap.activeView)) {
          $focus.first().focus();
        }

        if (/(ms|xs)/.test(ui.Bootstrap.activeView) && $targetPanel.length > 0) {
          this.setMobilScrollPos();
        }

        delete ui.activeElement;
        delete ui.activeElementIndex;
      }
    },

    /**
     * Unbinds all event listeners and removes it's nodes. Called by ui.js
     */
    closeView: function () {
      this.unbind();
      this.remove();
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'EventCalendarComponent',
    View: ui.EventCalendarComponentView,
    selector: '.ui-js-event-calendar',
    reinitialize: true,
  });

  $(ui.bootstrapEventCalendarComponent());
}).call(this);
