/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Calls Web-Api, listens to authentication event and inserts response (message)
   * as html into the child element.
   *
   * Data-setup:
   * @param {String} apiUrl - url of the api that will be called (i.e. 'https://test-web-api.migros.ch/widgets/mgo/notification/unreadcount')
   */
  ui.NotificationsComponentView = ui.ComponentView.extend({
    name: 'ui.NotificationsComponentView',

    $messageNode: null,
    messageString: '',

    initialize: function () {
      window.uiNotifications = window.uiNotifications || {};
      this.$messageNode = this.$('.ui-js-unread-mgs');
      this.isHeaderBar = this.$el.hasClass('is-js-headerbar');
      var testUrl = /.+?(?=\?)/.exec(this.setup.apiUrl);
      if (testUrl && testUrl.length > 0) {
        this.requestUrl = testUrl[0];
      } else {
        this.requestUrl = this.setup.apiUrl;
      }
      if (window.uiNotifications && window.uiNotifications[this.requestUrl] === true) {
        this.waiting = true;
        ui.on(this.requestUrl, this.setUnreadCount.bind(this));
      } else {
        this.waiting = false;
        window.uiNotifications[this.requestUrl] = true;
        ui.on(ui.GlobalEvents.LOGIN_AUTHENTICATED, this.requestNotification.bind(this));
      }
    },

    render: function () {
      if (this.messageString) {
        this.$messageNode.text(this.messageString.toString());
        this.$messageNode.removeClass('is-hidden');

        if (this.isHeaderBar) {
          this.$el.addClass('is-indicator');
          this.$messageNode.text(this.messageString.toString());
        }
      }
    },

    /**
     * Send Ajax request to provided url, render View in success case
     *
     * @example possible API response
     * response data: { unreadCount: 3 }
     */
    requestNotification: function (evt) {
      var self = this;
      $.ajax({
        url: this.setup.apiUrl,
        method: 'GET',
        xhrFields: {
          withCredentials: true,
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + evt.data.access_token);
        },
      })
        .done(function (data) {
          self.setUnreadCount(data);
          ui.trigger(self.requestUrl, data);
        })
        .fail(function (err) {
          console.log('err', err);
        });
    },

    setUnreadCount: function (data) {
      if (!data.unreadCount && this.isHeaderBar) {
        this.$el.removeClass('is-indicator');
      }
      if (data && typeof data === 'object' && data.unreadCount) {
        this.messageString = data.unreadCount;
        this.render();
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'NotificationsComponent',
    View: ui.NotificationsComponentView,
    selector: '.ui-js-notifications',
  });

  $(ui.bootstrapNotificationsComponent());
}).call(this);
