/**
 * @author nils.vonrymon-lipinski@aperto.com (Nils von Rymon-Lipinski)
 */
(function () {
  'use strict';

  ui.CampaignStageComponentView = ui.ComponentView.extend({
    name: 'ui.CampaignStageComponentView',

    initialize: function (options) {
      var $headerPanel = $('.ui-js-header-panel, .ui-js-103-12-navigational-header');

      this.$header = $headerPanel.length === 1 ? $headerPanel : false;
      this.bindEvents();
      this.checkViewport();
    },

    bindEvents: function () {
      ui.on('bootstrap.activemediaquery.changed', this.checkViewport.bind(this));
      $(window).on('resize', this.handleResize.bind(this));
    },

    handleResize: function () {
      this.checkViewport();
    },

    checkViewport: function () {
      if (
        this.setup.viewports &&
        this.setup.viewports[ui.Bootstrap.activeView] &&
        this.$header &&
        this.$header.length
      ) {
        this.setHeight();
      } else {
        this.$el.height('');
      }
    },

    setHeight: function () {
      const borderTopHeight = Number(this.$el.css('borderTopWidth').match(/\d+/g)[0] || 0);
      const borderBottomHeight = Number(this.$el.css('borderBottomWidth').match(/\d+/g)[0] || 0);

      this.$el.height(
        window.innerHeight - this.$header.get(0).clientHeight - borderTopHeight - borderBottomHeight
      );
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'CampaignStageComponent',
    View: ui.CampaignStageComponentView,
    selector: '.ui-js-campaign-stage',
    reinitialize: false,
  });

  $(ui.bootstrapCampaignStageComponent());
}).call(this);
