/**
 * @author Vika Khardina
 *
 */

(function () {
  /**
   * A-Z brands list
   */

  'use strict';

  ui.BrandListComponentView = ui.ComponentView.extend({
    name: 'ui.BrandListComponentView',

    events: {
      'click .ui-js-anchor-link': 'toggleBrands',
    },

    initialize: function (options) {
      this.$brandItems = this.$el.find('.ui-js-brand-item');
      this.$brandItemLinks = this.$el.find('.ui-js-anchor-link');
      this.$brandListSlider = this.$el.find('.ui-js-brand-anchors');
      this.counter = 0;
    },

    toggleBrands: function (e) {
      e.preventDefault();
      if (e.syncEvent === undefined) {
        var viewport = ui.Bootstrap ? ui.Bootstrap.activeView : '';
        var isDesktop = /md|lg/.test(viewport);
        var $target = $(e.currentTarget);
        var targetId = $target.find('a').attr('href');
        var $targetBrandItem = $(targetId);
        var syncTarget = this.$brandItemLinks.filter(function (idx, elm) {
          return $(elm).find('a').attr('href') === targetId;
        });

        this.$brandItems.removeClass('is-active');
        this.$brandItemLinks.removeClass('is-selected');
        $target.addClass('is-selected');
        $(syncTarget).addClass('is-selected');
        $targetBrandItem.addClass('is-active');

        var index = this.$brandItemLinks.index(this.$brandItemLinks.filter('.is-selected').first());
        if (!isDesktop) {
          this.$brandListSlider.trigger('previewTeaserSlider:jumpToSlide', index);
          $('html, body')
            .delay(500)
            .animate(
              {
                scrollTop: this.$brandListSlider.first().offset().top - 10,
              },
              500
            );
        }
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'BrandListComponent',
    View: ui.BrandListComponentView,
    selector: '.ui-js-brand-list',
    reinitialize: true,
  });

  $(ui.bootstrapBrandListComponent());
}).call(this);
