/**
 * @author Fabio Tedesco
 *
 */

(function () {
  'use strict';

  ui.FormMagazineOnlineComponentView = ui.ComponentView.extend({
    name: 'ui.FormMagazineOnlineComponentView',

    initialize: function (options) {
      this.$formMagazineLink = this.$el.find('[data-js-item="form-magazine-online-btn"]');
      this.$zipCodeEl = this.$el.find('[name="ref-zipCode"]');
      this.$yearSelectEl = this.$el.find('[name="ref-year"]');
      this.$zoneSelectEl = this.$el.find('[name="ref-zone"]');
      this.$hiddenInputIssueEl = this.$el.find('input[name="ref-issue"]');
      this.$issueSelectEl = this.$el.find('select[name="ref-issue"]');
      this.$formErrorBox = this.$el.find('[data-js-item="form-magazine-online-error"]');

      if (!this.$zipCodeEl || !this.$yearSelectEl || !this.$yearSelectEl || !this.$issueSelectEl) {
        return;
      }

      this.changeHiddenInputIdAndDisableIt(this.$hiddenInputIssueEl);
      this.errorOnZipCode(this.$zipCodeEl, 'remove');

      this.sendYearAndGetIssue(this.$yearSelectEl);

      this.$yearSelectEl.on(
        'change',
        function () {
          this.sendYearAndGetIssue(this.$yearSelectEl);
        }.bind(this)
      );

      this.$formMagazineLink.on(
        'click',
        function (e) {
          e.preventDefault();

          if (this.$zipCodeEl.length) {
            this.checkZipCodeAndGetLinkUrl(this.$zipCodeEl);
          } else if (this.$zoneSelectEl.length) {
            this.getLinkUrl();
          }
        }.bind(this)
      );
    },

    sendYearAndGetIssue: function ($el) {
      if (this.isAjaxItem($el)) {
        var ajaxUrl = $el.data('ajax-url').listIssueUrl;
        var ajaxParam = {
          year: this.$yearSelectEl.val(),
        };
        this.callAjax(ajaxUrl, ajaxParam, 'select');
      }
    },

    checkZipCodeAndGetLinkUrl: function ($el) {
      if (this.zipCodeValueIsValid($el)) {
        this.errorOnZipCode($el, 'remove');
      } else {
        this.errorOnZipCode($el, 'add');
        return;
      }

      this.getLinkUrl();
    },

    getLinkUrl: function () {
      if (this.isAjaxItem(this.$el)) {
        var ajaxUrl = this.$el.data('ajax-url').magazineUrl;
        var ajaxParam = this.$el.serialize();

        this.callAjax(ajaxUrl, ajaxParam, 'link');
      }
    },

    errorOnZipCode: function ($el, type) {
      if (type === 'remove') {
        $el.closest('.ui-js-form-item').find('.error-box').addClass('hidden');
      } else if (type === 'add') {
        $el.closest('.ui-js-form-item').find('.error-box').removeClass('hidden');
      }
    },

    changeHiddenInputIdAndDisableIt: function (inputfield) {
      if (inputfield && inputfield.length) {
        inputfield[0].id = inputfield[0].id + '-input';
        inputfield.attr('disabled', 'disabled');
      }
    },

    zipCodeValueIsValid: function ($el) {
      return !$el.closest('.ui-js-form-item')[0].classList.contains('invalid') && $el.val().length;
    },

    isAjaxItem: function ($elm) {
      return $elm.attr('data-ajax-url') !== undefined && $elm.attr('data-ajax-url') !== '';
    },

    callAjax: function (ajaxUrl, params, destination) {
      var isLink = destination === 'link';
      if (isLink) {
        var newWindow = window.open();
      }
      const _this = this;
      const options = {
        async: false,
        url: ajaxUrl,
        dataType: 'json',
        type: _this.$el.attr('method') || 'get',
        data: params,
      };
      $.ajax(options)
        .done(function (data) {
          if (isLink) {
            if (typeof data !== 'undefined' && data.url && data.url.length) {
              _this.setLinkTargetAndOpenNewTab(data.url, newWindow);
            }
          } else if (destination === 'select') {
            if (typeof data !== 'undefined' && data.length) {
              _this.resetOptionsAndSetNewOptions(data);
            }
          }
        })
        .fail(function () {
          console.error('no data is found for ajax request');

          if (isLink) {
            _this.setErrorMessageOnLink(newWindow);
          }
        });
    },

    setErrorMessageOnLink: function (newWindow) {
      this.$formErrorBox[0].classList.remove('is-hidden');
      newWindow.close();
    },

    setLinkTargetAndOpenNewTab: function (url, newWindow) {
      this.$formErrorBox[0].classList.add('is-hidden');
      this.$formMagazineLink.attr('href', url);

      newWindow.target = '_blank';
      newWindow.opener = null;
      newWindow.location = url;
    },

    resetOptionsAndSetNewOptions: function (newOptions) {
      this.$issueSelectEl[0].options.length = 0;
      var newOptArr = [];
      var optionsList = '';
      if (!Array.isArray(newOptions)) {
        newOptArr = [newOptions];
      } else if (Array.isArray(newOptions)) {
        newOptArr = newOptions;
      }
      newOptArr.forEach(function (item, idx) {
        optionsList += '<option value="' + item.value + '">' + item.value + '</option>';
      });
      this.$issueSelectEl.append(optionsList);
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FormMagazineOnlineComponent',
    View: ui.FormMagazineOnlineComponentView,
    selector: '[data-js-module="form-magazine-online"]',
  });
  $(ui.bootstrapFormMagazineOnlineComponent());
}).call(this);
