(function () {
  'use strict';

  /**
   * used in navigationalHeader and in functionalHeader to trigger a global event when clicking the
   * main-menu (navigationalHeader) or search-menu button (functionalHeader)
   */

  ui.HeaderNavigation = ui.ComponentView.extend({
    name: 'ui.HeaderNavigation',

    hasMainMenuOpenState: false,
    hasProfileOpenState: false,
    hasSearchbarOpenState: false,
    hasSearchWidgetOpenState: false,

    events: {
      'click .ui-js-profile': 'onProfileButtonClick',
      'click .js-search-button': 'onSearchWidgetButtonClick',
      'click .js-mainmenu-button': 'onMainMenuButtonClick',
      'click .js-collapsable-search-btn': 'onSearchbarButtonClick',
      'click .button': 'doTrack',
    },

    initialize: function (options) {
      this.$body = $('body');
      this.$searchBtn = this.$('.js-search-button');
      this.$searchBlock = $('.js-search-widget');
      this.$mainMenuBlock = $('.ui-js-main-menu');
      this.$footerAffix = $('.mui-js-footer-affix');
      this.$profileBlock = $('.ui-js-profile');
      this.mainMenuButton = this.$('.js-mainmenu-button');
      this.headerbarCollapseSearch = this.$('#headerbar-collapsable-search');
      this.desktopItems = this.$('.desk-item');
      this.list = $('.ui-js-header-navigation .list');

      ui.on(ui.GlobalEvents.HEADERSTATEMANAGER_FINISHED, this.checkDisableBodyScroll.bind(this));
      ui.on(ui.GlobalEvents.HEADER_FIX, this.fixPosition.bind(this));
      ui.on(ui.GlobalEvents.HEADER_UNFIX, this.unfixPosition.bind(this));
      ui.on(ui.GlobalEvents.SEARCHBAR_CLOSE, this.closeSearchBar.bind(this));
      ui.on(ui.GlobalEvents.SEARCHWIDGET_CLOSE, this.closeSearchWidget.bind(this));
      ui.on(ui.GlobalEvents.MAIN_MENU_CLOSE, this.mainMenuButtonOpenState.bind(this));
    },

    // PROFILE
    onProfileButtonClick: function (evt) {
      this[
        this.$el.find('.js-mainmenu-button').hasClass('is-open') ? 'openProfile' : 'closeProfile'
      ]();
    },

    openProfile: function () {
      this.hasProfileOpenState = true;
      ui.trigger(ui.GlobalEvents.PROFILE_OPEN);
    },

    closeProfile: function () {
      this.hasProfileOpenState = false;
    },

    checkDisableBodyScroll: function (data) {
      bodyScrollLock.clearAllBodyScrollLocks();
      this.$body.removeClass('body-is-fixed');

      switch (data.el) {
        case 'mainmenu':
          this.disableBodyScroll(this.$mainMenuBlock[0]);
          break;
      }
    },

    disableBodyScroll: function (element) {
      if (/(xs|ms)/.test(ui.Bootstrap.activeView)) {
        bodyScrollLock.disableBodyScroll(element);
        this.$body.addClass('body-is-fixed');
      }
    },

    enableBodyScroll: function (element) {
      bodyScrollLock.enableBodyScroll(element);
      this.$body.removeClass('body-is-fixed');
    },

    // MAIN MENU
    onMainMenuButtonClick: function (evt) {
      var evtTarget = $(evt.currentTarget);

      // toggle open/close
      this[this.hasMainMenuOpenState ? 'closeMainMenu' : 'openMainMenu'](evtTarget);
    },
    mainMenuButtonOpenState: function (buttonEl) {
      this.hasMainMenuOpenState = false;
      this.mainMenuButton.removeClass('is-open');
    },
    openMainMenu: function (buttonEl) {
      $(buttonEl).addClass('is-open');
      this.hasMainMenuOpenState = true;
      ui.trigger(ui.GlobalEvents.MAIN_MENU_OPEN);
    },
    closeMainMenu: function (buttonEl) {
      $(buttonEl).removeClass('is-open');
      this.hasMainMenuOpenState = false;
      ui.trigger(ui.GlobalEvents.MAIN_MENU_CLOSE);
      this.enableBodyScroll(this.$mainMenuBlock[0]);
    },

    // SEARCHBAR
    onSearchbarButtonClick: function (evt) {
      var evtTarget = $(evt.currentTarget);

      // toggle open/close
      this[this.hasSearchbarOpenState ? 'closeSearchBar' : 'openSearchBar'](evtTarget);
    },
    onSearchWidgetButtonClick: function (evt) {
      var evtTarget = $(evt.currentTarget);

      // toggle open/close
      this[this.hasSearchWidgetOpenState ? 'closeSearchWidget' : 'openSearchWidget'](evtTarget);
    },
    openSearchBar: function (buttonEl) {
      $(buttonEl).addClass('is-open');
      this.hasSearchbarOpenState = true;
      ui.trigger(ui.GlobalEvents.SEARCHBAR_OPEN);
    },
    closeSearchBar: function (buttonEl) {
      $(buttonEl).removeClass('is-open');

      if (this.headerbarCollapseSearch.length) {
        this.headerbarCollapseSearch.collapse('hide');
      }
      this.hasSearchbarOpenState = false;
    },

    openSearchWidget: function (buttonEl) {
      var _this = this;

      $(document).click(function (e) {
        if (
          _this.$searchBlock.hasClass('is-active') &&
          _this.hasSearchWidgetOpenState &&
          !$(e.target).hasClass('js-search-button') &&
          !$(e.target).hasClass('js-search-widget') &&
          !$(e.target).parents('.js-search-widget').length &&
          !$(e.target).parents('.js-search-button').length
        ) {
          ui.trigger(ui.GlobalEvents.SEARCHWIDGET_CLOSE);
        }
      });

      this.desktopItems.addClass('hide-item');
      this.list.addClass('hide-tablet');
      this.$searchBtn.addClass('is-open');
      this.$searchBlock.addClass('is-active');
      this.$searchBlock.find('input').focus();
      this.hasSearchWidgetOpenState = true;
      this.$footerAffix.addClass('is-search-open');
      ui.trigger(ui.GlobalEvents.SEARCHWIDGET_OPEN);
    },
    closeSearchWidget: function (buttonEl) {
      this.desktopItems.removeClass('hide-item');
      this.list.removeClass('hide-tablet');
      this.$searchBtn.removeClass('is-open');
      this.$searchBlock.removeClass('is-active');
      this.$footerAffix.removeClass('is-search-open');
      this.hasSearchWidgetOpenState = false;
    },

    // FIX/UNFIX POS
    fixPosition: function () {
      this.$el.addClass('is-fixed');
    },
    unfixPosition: function () {
      this.$el.removeClass('is-fixed');
    },

    doTrack: function (e) {
      var $target = $(e.currentTarget);
      var isCta = $target.hasClass('is-cta');
      var text = $target.text();
      var dataLayerPart = {};

      if (isCta || !text) {
        return;
      }

      dataLayerPart.event = 'topNavigation';
      dataLayerPart.navigationLevel1 = text;

      window.dataLayer.push(dataLayerPart);
    },
    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'HeaderNavigationComponent',
    View: ui.HeaderNavigation,
    selector: '.ui-js-header-panel',
  });

  $(ui.bootstrapHeaderNavigationComponent());
}).call(this);
