/**
 * @author Fabio Tedesco
 * @description toggles optional accordion content on play/pause while video is in carousel
 */

(function () {
  'use strict';

  ui.VideoCarousel = ui.ComponentView.extend({
    name: 'ui.VideoCarousel',

    events: {
      click: 'togglePlayPause',
    },

    initialize: function () {
      const _this = this;
      this.vid = this.$el.find('video').get(0);
      this.fallbackImg = this.$el.find('.fallback-img');
      this.isInStorytellingGallery = this.$el.closest('.is-story-telling').length;
      this.vid.addEventListener(
        'ended',
        function () {
          _this.toggleCaption('end');
          _this.$el.removeClass('play-hidden');
        },
        false
      );
    },

    hideFallbackImg: function () {
      if (this.fallbackImg) {
        this.fallbackImg.css('display', 'none');
      }
    },

    toggleCaption: function (state) {
      const caption = this.$el.closest('.item.active').find('.caption').length
        ? this.$el.closest('.item.active').find('.caption')
        : false;
      const canBeToggled = caption && this.isInStorytellingGallery;
      if (canBeToggled) {
        if (state === 'play') {
          this.hideCaptionOnPlay(caption);
        } else if (state === 'pause' || state === 'end') {
          this.showCaptionOnPauseOrEnd(caption);
        }
      }
    },

    hideCaptionOnPlay: function (caption) {
      $(caption).addClass('is-hidden-on-play-video');
    },

    showCaptionOnPauseOrEnd: function (caption) {
      $(caption).removeClass('is-hidden-on-play-video');
    },

    togglePlayPause: function () {
      if (this.vid.paused) {
        this.hideFallbackImg();
        this.$el.addClass('play-hidden');
        this.vid.play();
        this.toggleCaption('play');
      } else if (this.vid.played) {
        this.vid.pause();
        this.$el.removeClass('play-hidden');
        this.toggleCaption('pause');
      }
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'VideoCarouselComponent',
    View: ui.VideoCarousel,
    selector: '.ui-js-video-carousel',
  });

  $(ui.bootstrapVideoCarouselComponent());
}).call(this);
