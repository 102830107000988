/**
 * @author Fabio Tedesco
 *
 * Dynamic iframe height takes the height from the iframe content
 * once it's loaded and set that to the iframe wrapper
 */

(function () {
  'use strict';

  ui.IframeHeightView = ui.ComponentView.extend({
    name: 'ui.IframeHeightView',

    $IFrame: null,

    initialize: function (options) {
      this.$IFrame = this.$el;
      this.onLoadIFrame();
    },

    /**
     * On load gets the whole iframe height and pass it to the
     * setIFrameWrapperHeight function which will set the height
     * to the iframe wrapper
     *
     */
    onLoadIFrame: function () {
      var self = this;
      if (this.$IFrame && this.$IFrame.length) {
        this.$IFrame.on('load', function () {
          self.getIFrameHeight();
          $(window).on('resize', self.getIFrameHeight.bind(self));
        });
      }
    },

    getIFrameHeight: function () {
      if (
        this.$el &&
        this.$el.length &&
        this.$el[0].contentWindow.document.documentElement.offsetHeight
      ) {
        this.$el[0].style.visibility = 'hidden';
        this.setIFrameWrapperHeight(
          this.$el[0].contentWindow.document.documentElement.offsetHeight
        );
        this.$el[0].style.visibility = 'visible';
      }
    },

    /**
     * Set the iframe height to its wrapper identified with
     * the class .js-frame-wrapper
     */
    setIFrameWrapperHeight: function (iframeHeight) {
      var frameWrapper = this.$IFrame.closest('.js-frame-wrapper');
      if (frameWrapper && frameWrapper.length) {
        frameWrapper.css('height', iframeHeight + 'px');
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'IframeHeight',
    View: ui.IframeHeightView,
    selector: '.ui-js-iframe-height',
    reinitialize: true,
  });

  $(ui.bootstrapIframeHeight());
}).call(this);
