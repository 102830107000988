(function () {
  'use strict';

  ui.GlobalEvents = {
    ASYNC_BTN_STATE: 'asyncbutton:state:change',

    TAB_CHANGE: 'tab:change',
    TAB_FILTER_CHANGE: 'tabfilter:change',
    TAB_FILTER_TRACKING: 'tabfilter:tracking',

    MAIN_MENU_OPEN: 'mainmenu:open',
    MAIN_MENU_OPENED: 'mainmenu:opened',
    MAIN_MENU_TEMPLATES_RENDER: 'mainmenu:templatesrender',
    MAIN_MENU_TEMPLATES_RENDERED: 'mainmenu:templatesrendered',
    MAIN_MENU_CLOSE: 'mainmenu:close',
    MAIN_MENU_GOTO: 'mainmenu:goto',
    MAIN_MENU_BREADCRUMB_CHANGE: 'mainmenubreadcrumb:change',
    MAIN_MENU_TOGGLE_MEGA_MENU: 'mainmenu:togglemegamenu',
    MAIN_MENU_FORCE_CLOSE: 'mainmenu:forceclosemegamenu',

    GENERIC_HEADER_FLYOUT_OPEN: 'genericheaderflyout:open',
    GENERIC_HEADER_FLYOUT_MENU_OPEN: 'genericheaderflyoutmenu:open',
    GENERIC_HEADER_FLYOUT_OPENED: 'genericheaderflyout:opened',
    GENERIC_HEADER_FLYOUT_MENU_OPENED: 'genericheaderflyoutmenu:opened',
    GENERIC_HEADER_FLYOUT_CLOSE: 'genericheaderflyout:close',
    GENERIC_HEADER_FLYOUT_MENU_CLOSE: 'genericheaderflyoutmenu:close',
    GENERIC_HEADER_FLYOUT_CLOSED: 'genericheaderflyout:closed',

    PERSONALIZED_HEADERBAR_SAFETY_INFO: 'personalizedheaderbar:safetyinfo',

    LOGIN_POPOVER_OPEN: 'loginpopover:open',
    LOGIN_POPOVER_CLOSE: 'loginpopover:close',

    PROFILE_OPEN: 'profile:open',
    PROFILE_CLOSE: 'profile:close',

    SEARCHBAR_OPEN: 'searchbar:open',
    SEARCHBAR_CLOSE: 'searchbar:close',
    SEARCHWIDGET_OPEN: 'searchwidget:open',
    SEARCHWIDGET_CLOSE: 'searchwidget:close',
    SEARCHFLYOUT_OPEN: 'searchflyout:open',
    SEARCHFLYOUT_CLOSE: 'searchflyout:close',

    SAFETY_TEASER_HIDE: 'safetyteaser:hide',
    SAFETY_TEASER_CLOSE: 'safetyteaser:close',
    SAFETY_TEASER_OPEN: 'safetyteaser:open',

    HEADERSTATEMANAGER_FINISHED: 'headerstatemanager:finished',

    IOS_MOBILE_TOUCH_TO_SCROLL: 'scroll:ios-mobil:touch-to-scroll',

    HEADER_FIX: 'header:fix',
    HEADER_UNFIX: 'header:unfix',

    CAROUSEL_SLID: 'carousel:slid',
    CAROUSEL_AJAX_ITEM: 'carousel:ajax:item',
    ICON_PREVIEW_TEASER_SLID: 'iconpreviewteaser:slid',
    CAROUSEL_TOUCHEND: 'carousel:touchend',

    MODAL_GALLERY_OPEN: 'modal-gallery:open',
    MODAL_GALLERY_CAROUSEL_FIRST: 'modal-gallery:carousel:first',

    FILTER_CUSTOM_CONTENT: 'product-filter:custom-content',

    YT_API_READY: 'yt-player:yt-api-ready',

    FRAGMENT_PLACED: 'fragment:placed',

    EXPANDER_UPDATEVIEW: 'expander:updateview',
    EXPANDER_SCROLLBACK: 'expander:scrollback',

    ITEMS_CHECK_POSITION: 'historyItems:check:position',

    LOGIN_AUTHENTICATED: 'login:authenticated',
    LOGIN_ANONYMOUS: 'login:anonymous',
    LOGIN_ERROR: 'login:error',

    LOGIN_PREFILL: 'login:prefill',
    LOGIN_PREFILL_INIT: 'login:prefill-init',
    LOGIN_PREFILL_DONE: 'login:prefill-done',

    FORM_TRY_TO_ENABLE: 'form:try_to_enable',

    STORE_PREFERRED: 'store:preferred',

    LOGOUT_HASH_NOTIFICATION: 'hash:logout',

    Z_INDEX_ACTOR_OPEN: 'zindexactor:open',
  };
}).call(this);
