(function () {
  // Save a reference to the global object
  var root = this;

  root.ui = root.ui || {};

  ui.cssSupports = function (p) {
    var b = document.body || document.documentElement,
      s = b.style;

    if (typeof s[p] == 'string') {
      return true;
    }

    // Tests for vendor specific prop
    var v = ['Moz', 'webkit', 'Webkit', 'Khtml', 'O', 'ms'];
    p = p.charAt(0).toUpperCase() + p.substr(1);

    for (var i = 0; i < v.length; i++) {
      if (typeof s[v[i] + p] == 'string') {
        return true;
      }
    }

    return false;
  };

  /**
   * checks if a scrollbar takes the 16px width or not
   */
  ui.scrollbarTakesWidth = function () {
    return window.innerWidth > document.documentElement.clientWidth;
  };

  ui.scrollbarWidth = function () {
    return window.innerWidth - document.documentElement.clientWidth;
  };
}).call(this);
