/**
 * @author Lutz Möbius
 * @author Nils von Rymon-Lipinski
 *
 * - Dynamic addition new file input field
 */

(function () {
  'use strict';

  /**
   * Data-setup:
   * @params {number} [maxFileAdd] - specified the max file input fields which could add, if nothing set u can add infinite inputs
   */

  ui.FormFileAddComponentView = ui.ComponentView.extend({
    name: 'ui.FormFileAddComponentView',

    events: {
      'click .ui-js-file-add': 'addFileInput',
      'change .ui-js-file-row-wrap:first .ff-file [type="file"]': 'checkFileAddBtn',
      'file-reset .ui-js-file-added': 'removeFileInput',
      'file-added .ui-js-file-added': 'setCounterAndSetButtonStatus',
    },

    initialize: function (options) {
      this.$wrapFileRowWrap = this.$el.find('.ui-js-file-row-wrap');
      this.$firstFileInput = this.$wrapFileRowWrap.first().find('.ff-file [type="file"]');
      this.$addFileBtn = this.$el.find('.ui-js-file-add');
      this.counter = this.$wrapFileRowWrap.find('.ui-js-file-row').length;
      this.counterMax = options.maxFileAdd;
      this.inputMatrix = this.initInputMatrix(this.counterMax);
      this.firstFileInputWasFilled = false;
      this.checkFileAddBtn();
    },

    initInputMatrix: function (max) {
      var tempMatrix = [];
      for (var i = 0; i < this.counterMax; i++) {
        if (i < this.counter) {
          tempMatrix.push(false);
        } else {
          tempMatrix.push(true);
        }
      }
      return tempMatrix;
    },

    isTrue: function (bool) {
      return bool;
    },

    /**
     * toggle file add btn
     * @params {boolean} - showBool : true = show / false = hide
     */
    toggleFileAddBtn: function (showBool) {
      if (showBool) {
        this.$addFileBtn.css({ display: 'block' });
      } else {
        this.$addFileBtn.css({ display: 'none' });
      }
    },

    /**
     * increase/decrease counter & show/hide file add btn
     * @params {boolean} - addBool : true = increase counter / false = decrease counter
     */
    setCounterAndSetButtonStatus: function (event, addBool) {
      if (addBool) {
        this.counter++;
      } else {
        this.counter--;
      }
      this.checkFileAddBtn();
    },

    /**
     * check if file add btn should show/hide
     */
    checkFileAddBtn: function () {
      var firstFileInputButton = this.$firstFileInput.closest('.file-button-wrapper');
      if (
        this.$firstFileInput.val().length &&
        this.counterMax &&
        this.counterMax > this.counter &&
        this.counter >= 1
      ) {
        this.toggleFileAddBtn(true);
        if (this.firstFileInputWasFilled) {
          this.$firstFileInput.closest('.file-button-wrapper').show().next().next().show();
        }
        this.firstFileInputWasFilled = true;
      } else if (
        !this.$firstFileInput.val().length &&
        this.firstFileInputWasFilled &&
        this.counterMax &&
        this.counterMax > this.counter &&
        this.counter > 1
      ) {
        this.toggleFileAddBtn(true);
        firstFileInputButton.hide().next().next().hide();
      } else {
        this.toggleFileAddBtn(false);
        firstFileInputButton.show().next().next().show();
      }
    },

    /**
     * add new file input and initialize it
     * @params {obj} - current event
     */
    addFileInput: function (event) {
      event.preventDefault();
      var template;
      var $lastAddedFileInputWrapper = this.$wrapFileRowWrap.find('.ui-js-file-added');

      // take first input if empty
      if (!this.$firstFileInput.val().length) {
        this.$firstFileInput.click();
        return false;
      }

      // if last file input is not set, take it
      $lastAddedFileInputWrapper = $(
        $lastAddedFileInputWrapper[$lastAddedFileInputWrapper.length - 1]
      );
      if ($lastAddedFileInputWrapper.hasClass('is-hidden')) {
        $lastAddedFileInputWrapper.find('.ff-file [type="file"]').click();
        return false;
      }

      // compile template and append
      template = this.$wrapFileRowWrap.find('.file-inputs-template').first().html();
      var inputId = this.inputMatrix.findIndex(this.isTrue);
      template = template.replace(/%COUNT%/g, inputId + 1);
      this.inputMatrix[inputId] = false;
      template = template.replace(/ui-js-file-row/g, 'ui-js-file-row ui-js-file-added is-hidden');
      template = template.replace(/file-button-wrapper/g, 'file-button-wrapper is-hidden');
      this.$wrapFileRowWrap.append(template);

      // init new file input
      var $lastAddedFileInput = this.$wrapFileRowWrap.find('.ff-file [type="file"]');
      $lastAddedFileInput = $($lastAddedFileInput[$lastAddedFileInput.length - 1]);
      $lastAddedFileInput.attr('data-file-id', inputId + 1);

      // call bootstrapFormFieldUploadComponent with added file input
      $(ui.bootstrapFormFieldUploadComponent($lastAddedFileInput[0]));
    },

    /**
     * remove file input
     * @params {obj} - current event
     */
    removeFileInput: function (event) {
      this.inputMatrix[
        $(event.currentTarget).find('[data-file-id]').attr('data-file-id') - 1
      ] = true;
      $(event.target).unbind();
      $(event.currentTarget).remove();
      this.setCounterAndSetButtonStatus(null, false);
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FormFileAddComponent',
    View: ui.FormFileAddComponentView,
    selector: '.ui-js-file-add-wrap',
  });

  $(ui.bootstrapFormFileAddComponent());
}).call(this);
