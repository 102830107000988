/**
 *
 * @author Henri Podolski, Peter Dematté
 * - Main menu with sliding submenu's mapped by #menu/uuid anchors and data-menu-uuid
 * - Uses the bootstrap carousel and is restricted to slide to the next or the previous slide
 * - Menu slides which should not be displayed within the current slide are hidden, before going to the next
 */

(function () {
  'use strict';

  ui.MainMenuComponentView = ui.ComponentView.extend({
    name: 'ui.MainMenu',
    defaults: {},
    ON_TRANSITION_END:
      'webkitTransitionEnd mozTransitionEnd oTransitionEnd msTransitionEnd transitionend',
    topOffset: 0,
    isOpen: false,
    setup: {},
    visibleView: null,
    bodyNode: null,
    phoneExtrasInitialized: false,
    events: {
      'click .js-close': 'onCloseClick',
      'click a[href^="#menu/"]:not(.js-main-menu-mobile-breadcrumb)': 'onSubMenuItemClick',
      'click a[href="#"]': function (evt) {
        evt.preventDefault();
      },
      'click .menu-link': 'doTrackMainNavigation',
      'click .ui-t354-03-logo': 'doTrackOutNavigation',
    },

    initialize: function (options) {
      var that = this;

      this.$overlayBackground = this.$('.js-background');
      // get the rest of the menu slides...
      this.menuOptions = $('nav[data-setup]', this.$el).attr('data-setup'); // requestUrl
      this.menuRequestUrl = this.menuOptions
        ? JSON.parse(this.menuOptions.replace(/'/g, '"')).requestURL
        : '';
      if (this.menuRequestUrl) {
        $.ajax({
          url: this.menuRequestUrl,
        })
          .done(function (response, e) {
            that.renderMenuLayers(response, e);
            that.init2();
          })
          .fail(function () {
            console.log('request failed', this.menuRequestUrl);
          });
      } else {
        this.init2();
      }
    },

    init2: function () {
      this.windowRef = $(window);
      this.bodyNode = $('body');
      this.headNode = $('head');
      this.name = this.setup.name || this.name;
      this.breadCrumb = [];
      this.configure();
      this.responsiveSetup();
      this.scrollbarTakesWidth = ui.scrollbarTakesWidth();
      this.scrollbarWidth = ui.scrollbarWidth();

      // highlight active
      this.hightlightActivePageItem();

      // this.createFixJigglingClasses();

      this.startPageGroupItems = this.activeSlide.find('.group-item');

      this.windowRef.on('orientationchange', this.checkPhoneExtrasInit.bind(this));
      this.windowRef.on('resize', this.checkPhoneExtrasInit.bind(this));

      var elmHeight = this.$el.length ? parseInt(this.$el.height()) : 0;
      var headerNavigationHeight = this.headerNavigation.length
        ? parseInt(this.headerNavigation.height())
        : 0;

      this.$el
        .addClass('is-not-visible')
        .css({ top: -(elmHeight + headerNavigationHeight) + 'px' });

      // tracking
      window.dataLayer = window.dataLayer || [];
    },

    renderMenuLayers: function (response, e) {
      $(response).insertAfter('[data-menu-level="1"]', this.$el);
    },

    calculateMainMenuHeights: function () {
      var headerPanelHeight;

      if (ui.Bootstrap.activeView === 'xs' || ui.Bootstrap.activeView === 'ms') {
        headerPanelHeight = 0;
      } else {
        var safetyTeaserHeight = this.safetyTeaser.length
          ? parseInt(this.safetyTeaser.height())
          : 0;
        var pageBreadcrumbHeight =
          this.pageBreadcrumb.length && this.pageBreadcrumb.css('display') !== 'none'
            ? parseInt(this.pageBreadcrumb.height()) - 3
            : 0;
        headerPanelHeight = this.headerPanel.height() - pageBreadcrumbHeight - safetyTeaserHeight;
      }

      // set offset to the header height, because header gets fixed position
      this.$el
        .css({
          'margin-top': headerPanelHeight + 'px',
          height: '100%',
        })
        .css('height', '-=' + headerPanelHeight + 'px'); // mimic css calc(100% - headerPanelHeight)
    },

    /**
     * prepares menu and body for intro animation and fixed menu layout
     */
    prepareBodyAndMenuScrollLayout: function () {
      var that = this;

      if (!ui.cssSupports('transition')) {
        // add fixed class for fixed menu
        // for browsers that dont support css transitions
        // that.bodyNode.addClass('body-is-fixed');

        // nothing else to do here, because css transitions are not
        // supported
        return;
      } else {
        // hide scrollbar of menu as long as the animation run
        this.$el.addClass('is-scrollbar-hidden');
      }

      function doScrollbarPreparation() {
        if (!that.isOpen) {
          return;
        }

        // add fixed class for fixed menu
        // that.bodyNode.addClass('body-is-fixed');

        if (that.scrollbarTakesWidth) {
          that.bodyNode.addClass('body-has-width-taking-scrollbar');
        }

        // show scrollbar of menu after animation end
        that.$el.removeClass('is-scrollbar-hidden');
      }

      if (this.activeSlide === this.slides[0] && ui.cssSupports('transition')) {
        // the body class body-is-fixed is applied after the animation, because if you init before
        // the whole page does react responsive to the missing 16px of the body scrollbar
        // this is the last element which is animated (ATTENTION: strongly coupled to CSS implementation)
        // after animation the scrollbar of the body is hidden, which causes a reflow
        // of the whole page
        this.startPageGroupItems.eq(2).one(this.ON_TRANSITION_END, doScrollbarPreparation);
      } else {
        doScrollbarPreparation();
      }
    },

    open: function () {
      this.isOpen = true;
      document.body.scrollIntoView(true);

      this.prepareBodyAndMenuScrollLayout();

      // hide shadow by decreasing the prototype
      // this.headerNavigation.addClass('has-menu-index');

      this.renderTemplate(this.activeSlide);

      this.$el
        .addClass('is-menu-item-animate is-visible')
        .removeClass('is-not-visible')
        .css({ top: '0px' })
        .scrollTop(0);

      this.pageBreadcrumb.removeClass('is-visible');
      this.pageBreadcrumb.addClass('is-not-visible');

      this.showOverlay();

      ui.trigger(ui.GlobalEvents.MAIN_MENU_OPENED);

      // slide could get higher because of rendered templates
      if (this.currentId) {
        setTimeout(
          function () {
            this.mirrorSlideHeight(this.currentId);
          }.bind(this),
          300
        );
      }
    },

    close: function () {
      var that = this;

      ui.trigger(ui.GlobalEvents.HEADER_UNFIX);
      this.bodyNode.removeClass('body-is-fixed body-has-width-taking-scrollbar');

      // hide shadow by decreasing the prototype
      // this.headerNavigation.removeClass('has-menu-index');
      var elmHeight = this.$el.length ? parseInt(this.$el.height()) : 0;
      var headerNavigationHeight = this.headerNavigation.length
        ? parseInt(this.headerNavigation.height())
        : 0;

      this.$el
        .removeClass('is-menu-item-animate is-visible')
        .addClass('is-not-visible')
        .css({ top: -(elmHeight + headerNavigationHeight) + 'px' });

      // flush menu state when main menu is hidden (500ms)
      // could not be bound to transition, because IE11 and Safari
      // fires event after 3000ms on slower machines
      setTimeout(function () {
        that.hightlightActivePageItem();
      }, 600);

      if (!ui.cssSupports('transition')) {
        this.hightlightActivePageItem();
      }

      this.pageBreadcrumb.removeClass('is-not-visible');
      this.pageBreadcrumb.addClass('is-visible');
      this.hideOverlay();
      this.isOpen = false;
    },

    onCloseClick: function () {
      ui.trigger(ui.GlobalEvents.MAIN_MENU_CLOSE);
      this.hasMainMenuOpenState = false;
      this.close();
    },

    onSubMenuItemClick: function (evt) {
      evt.preventDefault();

      var eventElement = this.$(evt.target).closest('a');
      var eventTargetHref = eventElement.attr('href');
      var isPhoneButtonOnDesktop =
        !this.phoneExtrasInitialized && eventElement.hasClass('ui-js-phone-menu-special-link');

      if (isPhoneButtonOnDesktop) {
        // nothing to do when on desktop
        return;
      }

      if (eventTargetHref && eventTargetHref.indexOf('menu') !== -1) {
        this.showMenuItem(eventTargetHref);
        // ui.trigger(ui.GlobalEvents.SUB_MENU_ITEM_CLICK, targetSplit[1]);
      }
    },

    doTrackMainNavigation: function (e) {
      var $target = $(e.currentTarget);
      var $slide = $target.closest('[data-menu-level]');
      var menuLevel = $slide.data('menu-level');
      var level = this.getLevelFromMenuLevel(menuLevel);
      var relationCategory = $target.data('relation-category');
      var dataLayerPart = this.setDataLayerPart($target, level, relationCategory, 'mainNavigation');

      if (typeof dataLayerPart !== 'undefined') {
        window.dataLayer.push(dataLayerPart);
      }
    },

    getLevelFromMenuLevel: function (level) {
      return level > 1 ? level : 1; // menu-level 1 is for mobile, this is why both 0 and 1 return 1
    },

    setDataLayerPart: function ($target, level, relationCategory, event) {
      var dataLayerPart = {};
      var mainLevel = 1;

      dataLayerPart.event = event;

      if (relationCategory && level === mainLevel) {
        dataLayerPart['navigationLevel' + level] = $target
          .closest('.menu-group')
          .find('.headline')
          .text();
        dataLayerPart['navigationLevel' + ++level] = $target.text();
        return dataLayerPart;
      }

      if (relationCategory || (!relationCategory && level === mainLevel)) {
        dataLayerPart['navigationLevel' + level] = $target.text();
        return dataLayerPart;
      }
    },

    doTrackOutNavigation: function (e) {
      var $target = $(e.currentTarget);
      var dataLayerPart = {};
      var text = $target.find('img').attr('alt');

      if (!text) {
        return;
      }

      dataLayerPart.event = 'outNavigation';
      dataLayerPart.navigationLevel1 = text;

      window.dataLayer.push(dataLayerPart);
    },

    hideOverlay: function () {
      this.$overlayBackground.removeClass('is-show');
      this.$el.removeClass('is-show');
    },

    showOverlay: function () {
      this.$overlayBackground.addClass('is-show');
      this.$el.addClass('is-show');
    },

    /**
     * Extract menu target slide
     * @param  {String} menuRoute data-menu-uuid of a slide
     * @return {Mixed<String> or <falsy>} extracted uuid taken from data-menu-uuid
     */
    uuidFromMenuRoute: function (menuRoute) {
      var targetSplit = menuRoute.split('#');
      var route = targetSplit && targetSplit[1];
      var routeSplit = route && route.split('/');

      return routeSplit[0] && routeSplit[1] && routeSplit[0] === 'menu' && routeSplit[1];
    },

    /**
     * Searches and returns jQuery object of a menu item according to a uuid
     * @param  {String} data-menu-uuid of a slide
     * @return {jQuery object} found menu item
     */
    getMenuItem: function (uuid) {
      var $menuItem;

      if (typeof uuid === 'string' && uuid.indexOf('menu') !== -1) {
        uuid = this.uuidFromMenuRoute(uuid);
      }

      $menuItem = this.slides.find('[data-menu-uuid="' + uuid + '"]');

      return !uuid || !$menuItem.length
        ? console.warn(this + '.showMenuItem(' + uuid + ') no menu item found!') || $()
        : $menuItem;
    },

    /**
     * Writes new mode data for current menu state
     * Be aware that there is a helper slide for mobile...
     * @param  {jQueryObject} new menu item
     * @param  [{String}] optional index (depth) of new slide
     * @param  [{String}] optional index (depth) of current slide
     * @return {Object} the view model
     */
    updateModel: function ($newMenuItem, newMenuSlideIndex, currentMenuSlideIndex) {
      var mobileModel, $item;

      const menuSlideIndex =
        newMenuSlideIndex || +$newMenuItem.closest('[data-menu-level]').data('menu-level');
      currentMenuSlideIndex = currentMenuSlideIndex || +this.activeSlide.data('menu-level') || 0;
      // update model
      this.lastActiveMenuItem = this.activeMenuItem;
      this.activeMenuItem = $item = $newMenuItem;

      if (menuSlideIndex > currentMenuSlideIndex || menuSlideIndex) {
        // fill model reverse, beginning from new item
        this.breadCrumb.splice(1);
        this.reverseUpdateModel($item, menuSlideIndex);
        mobileModel = this.breadCrumb[this.breadCrumb.length - 2];
      }

      ui.trigger(ui.GlobalEvents.MAIN_MENU_BREADCRUMB_CHANGE, mobileModel);

      return this;
    },

    /**
     * recursion for all menu levels to add a new menu item
     * @param  {jQueryObject} new menu item
     * @param  {Integer} index (depth) of new slide
     */
    reverseUpdateModel: function ($item, i) {
      if (i !== 0) {
        var menuUuid = $item.data('backurl') ? $item.data('backurl') : $item.data('menu-uuid');

        if (i - 1 === 1 && /xs|sm/.test(ui.Bootstrap.activeView)) {
          if ($item.data('backurlmobil')) {
            menuUuid = $item.data('backurlmobil');
          }
        }
        var $reverseItem = this.getMenuItem(menuUuid);
        this.reverseUpdateModel($reverseItem, i - 1);
        return this.pushNewItemToBreadCumbModel($item, i);
      }
    },

    /**
     * push new menu item as new object to breadcumb array
     * @param  {jQueryObject} new menu item
     * @param  {Integer} index (depth) of new slide
     */
    pushNewItemToBreadCumbModel: function ($item, i) {
      var exisitingLevelElements = this.breadCrumb.filter(function (breadCrumbObj) {
        return breadCrumbObj.level === i;
      });
      if (!exisitingLevelElements.length && $item.length && $item.data('title')) {
        this.breadCrumb.push({
          title: $item.data('title'),
          uuid: $item.data('menu-uuid'),
          backurl: $item.data('backurl'),
          level: i,
          isMobileHelperSlide: $item.hasClass('js-normal-group'),
        });
      }
    },

    showMenuItem: function (uuid) {
      const $newMenuItem = this.getMenuItem(
        typeof uuid === 'string' || !this.breadCrumb[uuid] ? uuid : this.breadCrumb[uuid].uuid
      );
      const currentMenuSlideIndex = +this.activeSlide.data('menu-level') || 0;

      this.currentId = uuid;

      if (this.isOpen) {
        this.renderTemplate($newMenuItem);
      }

      // hide menu item that are not relevant to the current slide change
      // therefore we'll always see the relevant item when sliding
      // 1 forward or 1 backward
      if (this.lastActiveMenuItem) {
        this.lastActiveMenuItem.addClass('menu-item-pass-invisible');
      }
      $newMenuItem.removeClass('menu-item-pass-invisible');

      const newMenuSlideIndex = +$newMenuItem.closest('[data-menu-level]').data('menu-level');

      this.mirrorSlideHeight(uuid);

      this.updateModel($newMenuItem, newMenuSlideIndex, currentMenuSlideIndex);

      this.renderBreadcrumb($newMenuItem);
      this.slider.carousel(newMenuSlideIndex);
      $('.js-mobile-navigation').toggleClass('hidden', newMenuSlideIndex !== 0);
      this.searchBlock.toggleClass('is-toplevel', newMenuSlideIndex === 0);
    },

    mirrorSlideHeight: function (uuid) {
      var $newMenuItem = this.getMenuItem(
        typeof uuid === 'string' || !this.breadCrumb[uuid] ? uuid : this.breadCrumb[uuid].uuid
      );
      var breadcrumbHeight = !/xs|ms|sm/.test(ui.Bootstrap.activeView) ? 70 : 0;
      var property = !/xs|ms|sm/.test(ui.Bootstrap.activeView) ? 'max-height' : 'height';
      var cssObj = {};
      cssObj[property] = $newMenuItem.closest('.item').outerHeight() + breadcrumbHeight + 'px';

      this.$('.carousel').css(cssObj);
    },

    renderTemplate: function ($menuItem) {
      var $templates = $menuItem.find('.js-menu-group-template');

      // replace outer with inner
      $templates.each(function () {
        $(this).replaceWith($(this).html());
      });

      if (!this.hasRenderedOnce) {
        // in order to have to have the DOM rendered and options for
        // tracking parsed, set this to the end of the callstack
        // and trigger only once for a session

        this.hasRenderedOnce = true;

        setTimeout(function () {
          ui.trigger(ui.GlobalEvents.MAIN_MENU_TEMPLATES_RENDER);
        }, 1);

        setTimeout(function () {
          ui.trigger(ui.GlobalEvents.MAIN_MENU_TEMPLATES_RENDERED);
        }, 200);
      }
    },

    /**
     * checks out the current page data-menu-category, if it finds a menu item with the
     * same id, this item gets active highlight
     * @return {void}
     */
    hightlightActivePageItem: function () {
      var body = $(document.body);
      var menuCategory = body.data('menuCategory');
      var menuGroupCategory = body.data('menuGroupCategory');
      var activeMenuLink;
      var activeMenuBlock;
      var submenuUid;
      var predecessorUid;
      var predecessorMenuBlock;

      if (menuGroupCategory) {
        // highlight active group
        this.$('[data-group-category="' + menuGroupCategory + '"]').addClass('is-active');
      }

      if (menuCategory) {
        // highlight active item
        this.$('a[data-relation-category="' + menuCategory + '"]').addClass('is-active');
      }

      if (!this.phoneExtrasInitialized) {
        this.$('.ui-js-phone-menu-link.is-active').removeClass('is-active');
      }

      // check for highlighted point
      activeMenuBlock = this.$('[data-menu-uuid].is-active').first();

      if (!activeMenuBlock.length > 0) {
        if (/xs|ms/.test(ui.Bootstrap.activeView)) {
          activeMenuLink = this.$('.js-helper-inside .menu-link.is-active').first();
        } else {
          activeMenuLink = this.$('.menu-link.is-active').first();
        }
        activeMenuBlock = activeMenuLink.closest('[data-menu-uuid]');
      }

      submenuUid = activeMenuBlock.data('menu-uuid');

      // defaulting if there is no sub menu uid
      if (!submenuUid) {
        submenuUid = this.$('[data-menu-uuid]').first().data('menu-uuid');
      }

      // if menuGroupCategory (headline highlight) has highlight
      if (submenuUid && menuGroupCategory && activeMenuBlock.data('backurl')) {
        // check for back link and extract href for having predecessor uid
        predecessorUid = activeMenuBlock.data('backurl').replace('#menu/', '');
        // use href as a selector and select previous item
        predecessorMenuBlock = this.$el.find('[data-menu-uuid="' + predecessorUid + '"]');
        // check for link to submenuUid and highlight it using active class
        predecessorMenuBlock.find('.menu-link[href="#menu/' + submenuUid + '"]').addClass('active');
      }

      this.showMenuItem(submenuUid);
    },

    onMenuChangeStart: function (evt) {
      this.activeSlide = this.$('.item.active');

      if (this.isOpen) {
        // scroll back to top, before changing the menu item!
        document.body.scrollIntoView(true);
      }
    },

    onMenuChangeEnd: function (evt) {
      this.activeSlide = this.$('.item.active');
    },

    initializePhoneExtras: function () {
      var placeholderText =
        this.searchField.data('shortPlaceholder') || this.searchField.attr('placeholder');

      this.searchField.attr('placeholder', placeholderText);
      this.$('.ui-js-phone-menu-special-link').css({
        cursor: 'pointer',
      });
      this.phoneExtrasInitialized = true;
    },

    removePhoneExtras: function () {
      var placeholderText =
        this.searchField.data('longPlaceholder') || this.searchField.attr('placeholder');

      this.searchField.attr('placeholder', placeholderText);
      this.$('.ui-js-phone-menu-special-link').css({
        cursor: 'default',
      });

      this.phoneExtrasInitialized = false;
    },

    checkPhoneExtrasInit: function () {
      var isPhone = ui.Bootstrap.activeView === 'xs' || ui.Bootstrap.activeView === 'sm';

      if (isPhone && !this.phoneExtrasInitialized) {
        this.initializePhoneExtras();
      } else if (!isPhone) {
        this.removePhoneExtras();
      }
    },

    onBreakPointChange: function () {
      this.calculateMainMenuHeights();
      this.checkPhoneExtrasInit();
    },

    /**
     * sets up all functions that manipulate DOM elements
     * on data-bootstrap-activemediaquer change (bootstrap.activemediaquery.changed)
     * @return {void}
     */
    responsiveSetup: function () {
      var that = this;
      var elms = (this.responsiveElements = {}); // all elements relevant for responsiveness
      var activemediaquery = ui.Bootstrap.activeView; // $(document.body).data('bootstrap-activemediaquery');
      var invisibleClass = 'menu-item-pass-invisible';

      /* --------------------------------------------- */
      elms.$mobileHelperSlide = this.slides.eq(1).find('.js-helper-inside');
      elms.$elmsFromNormalGroup = this.slides.find('.js-normal-group');
      elms.$normalGroupFirstElement = elms.$elmsFromNormalGroup.eq(0);
      elms.$normalGroups = elms.$elmsFromNormalGroup.parent();
      elms.$tmpIcons = $();

      /**
       * puts 2nd and 3rd block of 'js-normal-group' group
       * into helper slide
       * @param  {Boolean} doIt sets or resets status
       * @return {void}
       */
      function shuffleNormalGroup(doIt) {
        if (doIt) {
          elms.$elmsFromNormalGroup.each(function (idx, elm) {
            var $elm = $(elm).clone(true, true);
            $elm.addClass(invisibleClass);
            var shuffleGroupHeadline = $elm.find('.menu-group > .headline').text();
            $elm.attr('data-menu-uuid', $(elm).attr('data-mobilemenu-uuid'));
            $elm.attr('data-title', shuffleGroupHeadline);
            $elm.removeClass('hidden-xs hidden-ms');
            elms.$mobileHelperSlide.append($elm);
          });
        }
      }

      /* --------------------------------------------- */

      function applyIconToggleHover(doIt) {
        function toggleIconOnMouseEvent(evt) {
          var $evtTarget = $(evt.target);
          var icon;
          var hoverIcon;
          var isOutEvent;
          var shouldHoverOnViewport = true;
          var shouldHover = $evtTarget.data('image-hover') && !$evtTarget.hasClass('js-no-hover');

          if (!/xs|ms/.test(ui.Bootstrap.activeView)) {
            shouldHoverOnViewport = !$evtTarget.hasClass('js-no-hover-above-ms');
          }

          if (/xs|ms/.test(ui.Bootstrap.activeView)) {
            shouldHoverOnViewport = false;
          }

          if (shouldHover && shouldHoverOnViewport) {
            icon = $evtTarget.data('image-before-hover');
            hoverIcon = $evtTarget.data('image-hover');
            isOutEvent = ['mouseout', 'touchend', 'pointerup'].indexOf(evt.type) > -1; // $(this).css('background-color') !== 'rgb(255, 255, 255)';

            if (!icon) {
              icon = $evtTarget.css('background-image').replace(/^url\((.*)\)$/, '$1');
              $evtTarget.data('image-before-hover', icon);
            }

            $evtTarget.css({
              'background-image': 'url(' + (isOutEvent ? icon : hoverIcon) + ')',
            });
          } else {
            elms.$dataImageHoverItems.each(function () {
              if ((icon = $(this).data('image-before-hover'))) {
                $(this).css({
                  'background-image': 'url(' + icon + ')',
                });
              }
            });
          }
        }

        // update elem before applying, because DOM may have changed
        // dont apply to some with specific classes
        elms.$dataImageHoverItems = that.$el
          .find('[data-menu-level="0"] [data-image-hover]')
          .filter(function () {
            return !$(this).hasClass('js-no-hover');
          });

        if (!/xs|ms/.test(ui.Bootstrap.activeView)) {
          elms.$dataImageHoverItems = elms.$dataImageHoverItems.filter(function () {
            return !$(this).hasClass('js-no-hover-above-ms');
          });
        }

        if (doIt) {
          that.$el.on('mouseover touchstart pointerdown', toggleIconOnMouseEvent);
          that.$el.on('mouseout touchend pointerup', toggleIconOnMouseEvent);
        } else {
          // cleanup
          that.$el.off('mouseover touchstart pointerdown', toggleIconOnMouseEvent);
          that.$el.off('mouseout touchend pointerup', toggleIconOnMouseEvent);
        }
      }

      /**
       * trigger all responsive functions in one go
       * nd set toggles to remember
       * @param  {String} size current size from ui.Bootstrap.activeView
       * @return {void}
       */
      function triggerResponsiveActors(size) {
        var isSmall = /ms|xs/.test(size);

        if (isSmall && !elms.isPhoneLayout) {
          shuffleNormalGroup(true);
          applyIconToggleHover(true);

          elms.isPhoneLayout = true;
        } else if (!isSmall && elms.isPhoneLayout) {
          // avoid hiding of helper menus
          if (
            that.lastActiveMenuItem &&
            that.lastActiveMenuItem.closest('.item').attr('data-menu-level') === '1'
          ) {
            that.lastActiveMenuItem = $();
          }
          shuffleNormalGroup(false);
          // movePersonalButton(false);
          applyIconToggleHover(false);

          elms.isPhoneLayout = false;

          that.slider.carousel(0);
          that.searchBlock.addClass('is-toplevel');
          that.breadCrumb = [that.breadCrumb[0]];
        }

        that.calculateMainMenuHeights();
      }

      /* --------------------------------------------- */

      triggerResponsiveActors(activemediaquery);
      ui.on('bootstrap.activemediaquery.changed', triggerResponsiveActors);

      applyIconToggleHover(false);
      applyIconToggleHover(true);
    },

    configure: function () {
      var activeSlide;

      // cache dom elements
      this.searchBlock = this.$('.js-search-block');
      this.searchField = this.$('.ui-js-search-field .js-input-search');
      this.phoneBackButton = this.$('.js-phone-search-back');
      this.slider = this.$('.js-main-menu-slides');
      this.slides = this.slider.find('.item');
      this.headerPanel = $('.ui-js-header-panel');
      this.safetyTeaser = $('.ui-js-safety-teaser');
      this.pageBreadcrumb = this.headerPanel.find('.ui-js-breadcrumb');
      this.headerBar = this.headerPanel.find('.ui-js-headerbar');
      this.headerNavigation = this.headerPanel.find('.ui-js-header-navigation');

      // activate a slide by defaulting if needed
      activeSlide = this.slider.find('.item.active');

      if (activeSlide.length === 0) {
        activeSlide = this.slider.find('.item').first().addClass('active');
      }

      this.activeSlide = activeSlide;

      // installing breadcrumb model, view and events
      this.breadCrumb = [
        {
          title: this.slides.eq(0).data('title'),
          uuid: this.slides.eq(0).find('[data-menu-uuid]').data('menu-uuid'),
          level: 0,
        },
      ];
      this.slider
        .eq(0)
        .prepend(
          '<div class="container menu-breadcrumb"><div class="col-xs-12 menu-breadcrumb-container js-menu-breadcrumb-container"></div></div>'
        );
      this.$breadCrumb = this.slider.eq(0).children().first();

      this.calculateMainMenuHeights();

      // init view setup
      this.slider.carousel({ interval: false });
      this.searchBlock.addClass('is-toplevel');

      // needs phone extras?
      this.checkPhoneExtrasInit();

      // global event bindings
      ui.on(ui.GlobalEvents.MAIN_MENU_OPEN, this.open.bind(this));
      ui.on(ui.GlobalEvents.MAIN_MENU_CLOSE, this.close.bind(this));
      ui.on(ui.GlobalEvents.MAIN_MENU_GOTO, this.showMenuItem.bind(this));

      // event bindings
      this.slider.on('slide.bs.carousel', this.onMenuChangeStart.bind(this));
      this.slider.on('slid.bs.carousel', this.onMenuChangeEnd.bind(this));

      ui.on('bootstrap.activemediaquery.changed', this.onBreakPointChange.bind(this));
    },

    renderBreadcrumb: function ($item) {
      var level = $item.closest('.item').data('menu-level');
      var html = [];
      var item;
      var isCurrent, isPrevious, isFirst, isSecond, isBetween;

      for (var n = 0, m = this.breadCrumb.length; n < m; n++) {
        item = this.breadCrumb[n];
        isCurrent = n === m - 1;
        isPrevious = m - 2 > -1 && n === m - 2;
        isFirst = n === 0;
        isSecond = n === 1 && /(lg|md)/.test(ui.Bootstrap.activeView);
        isBetween =
          (n === 2 && /(lg|md)/.test(ui.Bootstrap.activeView)) ||
          (n === 1 && !/(lg|md)/.test(ui.Bootstrap.activeView));

        if (isCurrent) {
          // is current item
          // do nothing
        } else if (isPrevious || isFirst || isSecond) {
          // the first two items or only the first when below 992px
          html.push(
            '<a href="#menu/' + item.uuid + '" class="breadcrumb-link">' + item.title + '</a>'
          );
        } else if (isBetween) {
          // all other level > 3 or > 2 at 992px are presented by ...
          html.push('<span class="breadcrumb-dots">...</span>');
        }
      }
      if (level) {
        this.$breadCrumb.find('.js-menu-breadcrumb-container').html(html.join(''));
      } else {
        this.$breadCrumb.find('.js-menu-breadcrumb-container').html('');
      }

      $item.closest('.item').prepend(this.$breadCrumb);
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'MainMenuComponent',
    View: ui.MainMenuComponentView,
    selector: '.ui-js-main-menu',
    reinitialize: false,
  });

  $(ui.bootstrapMainMenuComponent());
})();
