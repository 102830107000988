/**
 *
 */
(function () {
  'use strict';

  ui.VideoController = ui.ComponentView.extend({
    name: 'ui.VideoController',

    events: {
      'show.bs.modal .ui-js-modal-gallery': 'stopVideos',
      'hide.bs.modal .ui-js-modal-gallery': 'startVideos',
    },

    initialize: function (options) {
      this.$videos = this.$el.find('.tile-video video');
    },

    stopVideos: function () {
      if (/md|ms|xs/.test(ui.Bootstrap.activeView)) {
        return;
      }
      if (this.$videos.length) {
        this.$videos.each(function (idx, elm) {
          elm.pause();
          elm.currentTime = 0;
        });
      }
    },

    startVideos: function () {
      if (/md|ms|xs/.test(ui.Bootstrap.activeView)) {
        return;
      }
      if (this.$videos.length) {
        this.$videos.each(function (idx, elm) {
          elm.play();
        });
      }
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'VideoController',
    View: ui.VideoController,
    selector: '.ui-js-video-controller',
    reinitialize: true,
  });

  $(ui.bootstrapVideoController());
}).call(this);
