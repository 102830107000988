/**
 * @author: Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Handles Ajax-Requests for L-number, populates form and redirects to external site.
   * Depends on ui-js-forms and autocomplete
   *
   * UTZ tracer object has following structure:
   * { "utzDeclarations": [
   *     {
   *       "members":[
   *       "3-000-00",
   *       "CF00000"
   *     ],
   *     "url":"https://www.utzcertified.org/index.php?&members=3-000-00,CF00000&lang=de",
   *     "lot_number":"0000000"
   *   },
   *   { "members" ...}
   * ],
   * "name":"Boncampo Bohnen 250g" }
   */
  ui.UtzTracerComponentView = ui.ComponentView.extend({
    name: 'ui.UtzTracerComponentView',

    language: null,
    serviceUrl: null,

    tracerObj: [],
    tracerLanguage: null,

    $form: null,
    $submitBtn: null,
    $step2forms: null,
    $productName: null,
    $lNrSelect: null,

    events: {
      // 'click .autocomplete-suggestion': 'eanSelected',
      'change input': 'eanSelected', // a bit weak...
      'change .ui-js-l-numbers select': 'lNrSelected',
      'click .ui-js-submit': 'validateForm',
      keypress: 'avoidENTER',
    },

    initialize: function () {
      this.language = this.setup.language;
      this.serviceUrl = this.setup.serviceUrl;
      this.$form = this.$('form');
      this.$submitBtn = this.$('.ui-js-submit');
      this.$step2forms = this.$('.step-2');
      this.$productName = this.$('.ui-js-name');
      this.$lNrSelect = this.$('.ui-js-l-numbers select');
    },

    render: function () {
      // Remove options from previous selects
      this.$lNrSelect.find('option').remove('option:enabled');

      if (
        this.tracerObj &&
        this.tracerObj.name &&
        this.tracerObj.name.length &&
        this.tracerObj.utzDeclarations
      ) {
        var optionTags = [];

        // Show step-2 form
        this.$step2forms.removeClass('hidden').height();
        this.$step2forms.removeClass('height-0');

        // Insert element text into DOM
        this.$productName.text(this.tracerObj.name);

        // Iterate elements in utzDeclarations
        for (var prop in this.tracerObj.utzDeclarations) {
          var element = this.tracerObj.utzDeclarations[prop];
          var tag = this.createOptionTag(element.lot_number, element.url);
          optionTags.push(tag);
        }

        // Append created options to select
        this.$lNrSelect.append(optionTags);
      }
      return this;
    },

    /**
     * Prevents form from being submitted before select is shown...
     * @param  {Event}     e keypress event Object
     * @return {undefined}
     */
    avoidENTER: function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.target.blur();
      }
    },

    /**
     * @param {String} label - text
     * @param {String} value - select tag value
     * @returns {XMLList|*|jQuery} option tag node for select
     */
    createOptionTag: function (label, value) {
      return $('<option>', {
        value,
        text: label,
      });
    },

    /**
     * @param {String} responseString - ajax response string
     * @returns {Object} tracerObj - parsed response
     */
    parseResponse: function (responseString) {
      var tracerObj = [];
      try {
        tracerObj = JSON.parse(responseString);
      } catch (e) {
        console.warn('error parsing response');
      }
      return tracerObj;
    },

    /**
     * Retrieves ean number from autocomplete field and starts requests
     * @param {Event} e - change event
     */
    eanSelected: function (e) {
      var $target = $(e.target);
      if ($target.val() && $.isNumeric($target.val())) {
        this.sendRequest($target.val());
      }
    },

    /**
     * Retrieves l-numbers on option select
     * @param {Event} e - click event
     */
    lNrSelected: function (e) {
      var value = e.target.value;
      this.extendSubmitHref({
        url: value,
      });
    },

    /**
     * Extends href (submit button) with serialized parameters
     * @param {Object} params - parameters with redirect url
     */
    extendSubmitHref: function (params) {
      if (params.url) {
        this.$submitBtn.attr('href', params.url);
      }
    },

    /**
     * Executes Ajax request with ean number, proceeds to step-2 if successful
     * @param {Number} eanNumber - valid ean number to be send as data
     */
    sendRequest: function (eanNumber) {
      var self = this;
      $.ajax({
        type: 'GET',
        dataType: 'html',
        url: this.serviceUrl,
        data: {
          term: eanNumber,
          lang: self.language,
        },
      })
        .done(function (data) {
          self.tracerObj = self.parseResponse(data);
          self.render();
        })
        .fail(function (err) {
          console.warn('request error', err.responseText);
        });
    },

    /**
     * Validates form by triggering form validator, opens external service if valid
     * @param e - click event
     */
    validateForm: function (e) {
      if (typeof this.$form.valid === 'function' && !this.$form.valid()) {
        e.preventDefault();
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'UtzTracerComponent',
    View: ui.UtzTracerComponentView,
    selector: '.ui-js-utz-tracer',
  });

  $(ui.bootstrapUtzTracerComponent());
}).call(this);
