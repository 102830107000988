/**
 * @author Jan Suwart
 * Library for feature detection
 */
(function () {
  if (ui) {
    ui.browser = {
      /**
       * @param {String} agent - browser useragent
       * @returns {boolean} true if Internet Explorer version < 10 detected
       */
      isLtIE10: function (agent) {
        agent = agent.toLowerCase();

        var matchIE = /(msie) ([\w.]+)/.exec(agent) || [];
        var versionIE = matchIE[2];
        if (matchIE && versionIE && Number(versionIE) < 10) {
          return false;
        } else {
          return true;
        }
      },

      /**
       *
       * @param {String} agent - browser useragent
       * @returns {boolean} true if Safari (all versions) detected
       */
      isSafari: function (agent) {
        agent = agent.toLowerCase();
        return /safari/.test(agent) && !/chrome/.test(agent);
      },

      /**
       * @returns {boolean}
       * Returns true if browser supports touch events
       */
      supportsTouch: function () {
        return (
          true ==
          ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch))
        );
      },
    };
  }

  function addCustomEvents() {
    if (typeof window.CustomEvent === 'function') return false;

    function CustomEvent(event, params) {
      params = params || {
        bubbles: false,
        cancelable: false,
        detail: null,
      };
      var evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
    console.info('Polyfill CustomEvent is added!');
  }

  addCustomEvents();

  /**
   * Detects if High contrast mode is activated (the browser switches most likely in HC mode
   * if OS is set to HC mode, older FF were able to switch the browser only to HC mode)
   * This function sets the class 'hcm' on the <html> if hcm is on
   */
  function hcmDetection() {
    function inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }

    function isModalIframe(element, className) {
      return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
    }

    function hcmTest() {
      var objA = document.createElement('a'),
        strColor;
      objA.style.color = 'rgb(31, 41, 59)';
      document.documentElement.appendChild(objA);
      strColor = document.defaultView
        ? document.defaultView.getComputedStyle(objA, null).color
        : objA.currentStyle.color;
      strColor = strColor.replace(/ /g, '');
      document.documentElement.removeChild(objA);
      return strColor !== 'rgb(31,41,59)';
    }

    function setHCM() {
      var className = document.documentElement.className,
        testResult = hcmTest();
      document.documentElement.className = testResult
        ? className + (className ? ' ' : '') + 'hcm'
        : className.replace(/(?:\b|\s+)hcm\b/, '');
    }

    if (inIframe() && isModalIframe(document.body, 'ui-body-iframe')) {
      document.body.addEventListener('modalIframeLoaded', function (e) {
        setHCM();
      });
    } else {
      setHCM();
    }
  }

  /**
   * Detects if all areas of the 'jump to area navigation' are present...
   * if not, the link to the missing area will be taken out of this navigation
   */
  function navigationAreaDetection() {
    var skipNav = document.body.querySelector('.ui-skipnav'),
      links;

    if (skipNav) {
      links = skipNav.querySelectorAll('li a');

      for (var n = links.length; n--; ) {
        if (!document.body.querySelector(links[n].getAttribute('href'))) {
          links[n].parentNode.style.display = 'none'; // or remove node
        }
      }
    }
  }

  /* ------------- init detectors ------------- */
  function startDetectors(e) {
    if (!startDetectors.isStarted) {
      hcmDetection();
      navigationAreaDetection();
    }
    startDetectors.isStarted = true;
  }

  document.addEventListener('DOMContentLoaded', startDetectors);
  window.addEventListener('load', startDetectors);
})();

/**
 * jQuery extensions
 */
(function ($) {
  /**
   * Support for old libs (i. e. jquery.ba-postmessage.js) that access $.browser which was removed in jQuery 1.9
   */
  if (!$.browser) {
    $.browser = 'foo';
  }
})(jQuery);
