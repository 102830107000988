(function () {
  'use strict';

  // Initial Setup
  // -------------
  // Save a reference to the global object
  var root = this;

  root.ui = root.ui || {};

  root.ui = Exoskeleton.utils.extend({}, root.ui, root.Exoskeleton);

  ui.data = ui.data || {};

  ui.version = '0.40.4';

  var ComponentCollection = (ui.ComponentCollection = Exoskeleton.Collection.extend({
    name: 'ui.ComponentCollection',

    toString: function () {
      return this.name;
    },
  }));

  var ComponentModel = (ui.ComponentModel = Exoskeleton.Model.extend({
    name: 'ui.ComponentModel',

    toString: function () {
      return this.name;
    },
  }));

  var ComponentView = (ui.ComponentView = Exoskeleton.View.extend({
    name: 'ui.ComponentView',

    defaults: {},

    setup: {},

    constructor: function (options) {
      this.setup = Exoskeleton.utils.extend({}, this.defaults, options);

      this.name = this.setup.name || this.name;

      Exoskeleton.View.apply(this, arguments);
    },

    toString: function () {
      return this.name;
    },
  }));

  var ComponentClass = (ui.ComponentClass = function (options) {
    this.initialize.apply(this, arguments);
  });

  ui.utils.extend(ui.ComponentClass.prototype, {
    name: 'ui.ComponentClass',

    initialize: function () {},

    toString: function () {
      return this.name;
    },
  });

  ui.ComponentClass.extend = ui.extend;

  ui.formSubmitHandler = function formSubmitHandler(formEl) {
    var formSubmitHandlerInProgress = formEl.isSubmitted;
    var formSubmitConditions = formEl.uiSubmitConditions;

    if (formSubmitConditions && typeof formSubmitConditions === 'object') {
      for (var key in formSubmitConditions) {
        if (formSubmitConditions.hasOwnProperty(key) && formSubmitConditions[key] === 'progress') {
          formSubmitHandlerInProgress = true;
        }
        // if (formSubmitConditions.hasOwnProperty(key) && formSubmitConditions[key] === 'done') {
        //     formSubmitHandlerInProgress = false;
        // }
      }
    }

    if (!formSubmitHandlerInProgress) {
      var $formEl = $(formEl);
      if ($formEl.hasClass('ui-js-file-spinner')) {
        if (!$formEl.find('.ui-upload-spinner').hasClass('in')) {
          $formEl.find('.ui-js-file-upload-modal').click();
          $formEl.find('.ui-upload-spinner').addClass('in');
          $formEl.find('.ui-upload-spinner').css('display', 'block');

          setTimeout(function () {
            ui.deleteSavedFields();
            window.history.replaceState(null, null, window.location.href.split('?')[0]);
            formEl.submit();
            formEl.isSubmitted = true;
          }, 500);
        }
      } else {
        ui.deleteSavedFields();
        window.history.replaceState(null, null, window.location.href.split('?')[0]);
        formEl.submit();
        formEl.isSubmitted = true;
      }
    }
  };

  ui.deleteSavedFields = function () {
    var fields = window.deleteSavedFields;
    if (fields !== undefined) {
      for (var i = 0; i < fields.length; i++) {
        window.sessionStorage.removeItem(fields[i]);
      }
    }
  };

  ui.consts = ui.consts || {};

  function whichTransitionEvent() {
    var t,
      el = document.createElement('fakeelement');

    var transitions = {
      transition: 'transitionend',
      OTransition: 'oTransitionEnd',
      MozTransition: 'transitionend',
      WebkitTransition: 'webkitTransitionEnd',
    };

    for (t in transitions) {
      if (el.style[t] !== undefined) {
        return transitions[t];
      }
    }
  }

  ui.consts.ON_TRANSITION_END = whichTransitionEvent();

  var bootstraper = (ui.bootstrapper = {
    reinitialize: function (options) {
      var method;
      var context = options.context;
      var isBootstrapMethod;
      var isVendorMethod;
      var viewRef;

      for (method in ui) {
        isVendorMethod =
          ui.hasOwnProperty(method) &&
          typeof ui[method] === 'function' &&
          (typeof ui[method].reinitializable === 'undefined' ||
            ui[method].reinitializable === true) &&
          method.indexOf('bootstrapVendor') === 0;

        if (isVendorMethod && context) {
          viewRef = ui[method](context);
          continue;
        }

        isBootstrapMethod =
          !isVendorMethod &&
          ui.hasOwnProperty(method) &&
          typeof ui[method] === 'function' &&
          ui[method].reinitializable &&
          method.indexOf('bootstrap') === 0;

        if (isBootstrapMethod && context) {
          viewRef = ui[method]({ context: context });
          viewRef();
        }
      }
    },

    defuse: function (options) {
      var context = options.context;

      // remove all events
      var $components = $(context).find('[data-setup]');

      $components.each(function () {
        var $component = $(this);

        if (typeof options.customDefuse === 'function') {
          options.customDefuse.call(this, $component);
        }

        if (
          $component.prop('Component') &&
          typeof $component.prop('Component').undelegateEvents === 'function'
        ) {
          $component.prop('Component').undelegateEvents();
        }

        if (
          $component.prop('View') &&
          typeof $component.prop('View').undelegateEvents === 'function'
        ) {
          $component.prop('View').undelegateEvents();
        }

        // Executes close function to remove custom event listeners (if available)
        if ($component.prop('View') && typeof $component.prop('View').closeView === 'function') {
          $component.prop('View').closeView();
        }
      });
    },
  });

  /**
   * @scope this DOM node or other if options.el is set
   * @param options object
   */
  var getElementSetup = (ui.getElementSetup = function getElementSetup(options) {
    var dataSetup,
      setup,
      el = options.el || this;

    // get data-setup attribute
    dataSetup = $(el).data('setup');

    if (dataSetup && typeof dataSetup === 'string') {
      // if <div data-setup="{'show': true}"> is used, instead of <div data-setup='{"show": true}'>
      // convert to valid json string and parse to JSON
      dataSetup = dataSetup.replace(/\\'/g, "'").replace(/'/g, '"');
      try {
        dataSetup = JSON.parse(dataSetup);
      } catch (error) {
        console.error('ERROR >> getElementSetup', 'el', el);
        console.error('ERROR >> getElementSetup', 'dataSetup', dataSetup);
        console.error('ERROR >> getElementSetup', 'error :', error);
      }
    }

    // merge setup option
    setup = Exoskeleton.utils.extend({}, dataSetup, options || {});

    // create reference to this DOM node
    setup.el = el;
    return setup;
  });

  var ComponentFactory = (ui.ComponentFactory = {
    /**
     * @param options.pluginMethodName string
     * @param options.ElementView view class
     */
    createPlugin: function (options) {
      var self = this;
      var pluginMethodName = options.pluginMethodName;
      var ElementView = options.View;
      var defaultSelector = options.selector;
      var pluginPrefix = 'ui';
      var initPrefix = 'bootstrap';
      // if reinitialize is set to false
      // the component cannot be initialized by ui.bootstrapper.reinitialize
      var reinitialize = options.reinitialize !== false;

      if ($.fn[pluginPrefix + pluginMethodName]) {
        throw new Error('Plugin method ' + pluginPrefix + pluginMethodName + ' already exists.');
        return;
      }

      $.fn[pluginPrefix + pluginMethodName] = function (pluginSetup) {
        return this.each(function () {
          var setup = getElementSetup.call(this, pluginSetup);
          var $this = $(this);

          // make the view accessible on DOM element
          self.View = new ElementView(setup);
          self.View.render();

          // and on jQuery property Object
          $this.prop('View', self.View);
        });
      };

      if (ui[initPrefix + pluginMethodName]) {
        throw new Error('Initializer method ' + initPrefix + pluginMethodName + ' already exists.');
        return;
      }

      ui[initPrefix + pluginMethodName] = function (selector, context, options) {
        var obj;
        var isParamsObj = typeof selector === 'object' && !selector.jquery && !selector.style;

        // accepts a parameter object as the first argument too,
        // if is not selector string or jquery object or dom node
        if (isParamsObj) {
          obj = selector;
          selector = obj.selector;
          context = obj.context;
          options = obj.options;
        }

        selector = selector || defaultSelector;
        options = options || {};

        return function () {
          var $el = $(selector, context);

          return $el[pluginPrefix + pluginMethodName](options).prop('View');
        };
      };

      ui[initPrefix + pluginMethodName].reinitializable = reinitialize;
    },
  });
}).call(this);
