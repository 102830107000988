/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Represents Radio Button, contains methods for rendering and state storage
   */
  ui.RadioComponentView = ui.ComponentView.extend({
    name: 'ui.RadioComponentView',

    radioName: undefined,
    activeState: undefined,
    markupState: undefined,
    filterType: 'radio',

    $radios: null,

    defaults: {
      activeChangeEvent: 'radio:change',
    },

    events: {
      'click .ui-js-input[name][value][type="radio"]': 'onItemClick',
    },

    initialize: function () {
      this.$radios = this.$el.find('.ui-js-input[name][value][type="radio"]');
      this.radioName = this.$radios.attr('name');
      this.markupState = this.$radios.filter(':checked').attr('value');
      this.activeState = this.getCurrentState();

      var changeEventName = 'change:' + this.radioName;

      // Install model listener for data-name, render view on changes
      this.model.on(
        changeEventName,
        function (model) {
          this.activeState = this.getCurrentState();
          this.render();
        },
        this
      );
    },

    /**
     * Returns current active value of the radio, if the key is present in the model
     * @returns {String} value of current active state
     */
    getCurrentState: function () {
      var modelState = this.model.get(this.radioName);

      if (modelState !== undefined) {
        return modelState;
      } else {
        return this.markupState;
      }
    },

    /**
     * Reports change event to parent view, passes input parameters
     * @param {Event} e - click event
     */
    onItemClick: function (e) {
      e.preventDefault();
      var value = e.currentTarget.value;
      this.$el.trigger(this.setup.activeChangeEvent, {
        type: this.filterType,
        name: this.radioName,
        value,
      });
    },

    getElement: function () {
      if (this.activeState !== undefined) {
        var suffix =
          '[type="radio"][name="' + this.radioName + '"][value="' + this.activeState + '"]';
        var $input = this.$('.ui-js-input' + suffix);
        var $inputSelect = this.$('.ui-js-input-activate-select' + suffix);

        return $input.length ? $input : $inputSelect;
      }

      return this.$('.ui-js-input[type="radio"][value="0"]');
    },

    // Set this view's radio current state (checked)
    render: function () {
      var $element = this.getElement();

      var $mobileContainer = $('.ui-mobil-block');
      var isMobile = $mobileContainer.css('display') !== 'none';
      var isMobileElement = !!this.$el.parents('.ui-mobil-block').length;

      if ($element.length && isMobile === isMobileElement) {
        if ($element.hasClass('ui-js-input-activate-select')) {
          var $select = this.$el.find('select');
          var name = $select.attr('name');
          var activeName = this.model.attributes[name];
          var activeOption = $select
            .find('option')
            .toArray()
            .filter(function (option) {
              return option.value === activeName;
            })[0];
          $select.prop('disabled', false);
          activeOption && (activeOption.selected = true);
        }
        $element.prop('checked', true);
      }

      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'RadioComponent',
    View: ui.RadioComponentView,
  });
}).call(this);
