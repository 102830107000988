/**
 * @author nils.vonrymon-lipinski@aperto.com (Nils von Rymon-Lipinski)
 */
(function () {
  'use strict';

  ui.ParticipationComponentView = ui.ComponentView.extend({
    name: 'ui.ParticipationComponentView',

    initialize: function (options) {
      this.url = options.url;
      this.bindEvents();
    },

    bindEvents: function () {
      var self = this;
      ui.on(ui.GlobalEvents.LOGIN_AUTHENTICATED, function (user) {
        // TODO - Check delete in future - MGRSR-6708 - check for new and old guid=sub
        var guid = user.data.get('guid') || user.sub;
        if (guid !== '') {
          self.requestParticipation(guid);
        }
      });
    },

    requestParticipation: function (guid) {
      var self = this;
      $.getJSON(self.url + guid + '.json', null, self.success.bind(self));
    },

    success: function (data) {
      if (data && data.alreadyParticipated) {
        this.$el.addClass('is-checked');
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ParticipationComponent',
    View: ui.ParticipationComponentView,
    selector: '.ui-js-participation',
    reinitialize: false,
  });

  $(ui.bootstrapParticipationComponent());
}).call(this);
