/**
 * @author Peter Dematté
 */
(function () {
  'use strict';

  ui.NewsPaginationComponentView = ui.ComponentView.extend({
    name: 'ui.NewsPaginationComponentView',

    initialize: function (options) {
      var render = this.render.bind(this);

      // in window sizes 'sm' and up the links have to be leveled
      // by the top of the first paragraph of the first article
      this.$firstArticleParagraph = $('.ui-c213-08-copyimage p');
      this.$links = this.$('.page');

      ui.on('bootstrap.activemediaquery.changed', render);
    },

    render: function () {
      var offset = this.$firstArticleParagraph.offset() || { top: '' };
      var top = /(?:xs|ms)/.test(ui.Bootstrap.activeView) ? '' : offset.top;

      this.$links.css({ top });

      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'NewsPaginationComponent',
    View: ui.NewsPaginationComponentView,
    selector: '.ui-js-g111-pagination',
    reinitialize: false,
  });

  $(ui.bootstrapNewsPaginationComponent());
}).call(this);
