/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Represents Linklist filter, contains methods for rendering and state storage
   */
  ui.LinklistComponentView = ui.ComponentView.extend({
    name: 'ui.LinklistComponentView',

    linklistName: undefined,
    activeState: undefined,
    markupState: undefined,
    filterType: 'linklist',

    $linklist: null,

    defaults: {
      activeChangeEvent: 'linklist:change',
    },

    events: {
      'click .ui-js-filter-link[data-name][data-value]': 'onItemClick',
    },

    initialize: function () {
      this.$linklist = this.$el.find('.ui-js-filter-link[data-name][data-value]');
      this.linklistName = this.$linklist.data('name');
      this.markupState = this.$linklist.filter('.is-active').data('value');
      this.activeState = this.getCurrentState();

      var changeEventName = 'change:' + this.linklistName;

      // Install model listener for data-name, render view on changes
      this.model.on(
        changeEventName,
        function (model) {
          this.activeState = this.getCurrentState();
          this.render();
        },
        this
      );
    },

    /**
     * Returns current active value of the linklist, if the key is present in the model
     * @returns {String} value of current active state
     */
    getCurrentState: function () {
      var modelState = this.model.get(this.linklistName);

      if (modelState !== undefined) {
        return modelState;
      } else {
        return this.markupState;
      }
    },

    /**
     * Reports change event to parent view, passes input parameters
     * @param {Event} e - click event
     */
    onItemClick: function (e) {
      e.preventDefault();
      var $eventElement = this.$(e.currentTarget);
      var value = $eventElement.data('value');

      this.$el.trigger(this.setup.activeChangeEvent, {
        type: this.filterType,
        name: this.linklistName,
        value,
      });
    },

    // Set this view's linklist current state (is-active)
    render: function () {
      if (this.activeState !== undefined) {
        var $element = this.$(
          '.ui-js-filter-link[data-name="' +
            this.linklistName +
            '"][data-value="' +
            this.activeState +
            '"]'
        );
        this.$linklist.removeClass('is-active');
        $element.addClass('is-active');
      } else if (!this.markupState) {
        this.$linklist.removeClass('is-active');
      }

      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'LinklistComponent',
    View: ui.LinklistComponentView,
  });
}).call(this);
