/**
 * @author Peter Dematté
 *
 * Documentation inline
 * This module uses more than one logical process to do what it does...
 * See data-setup -> actionrules -> form-control.js for the disabling / enabling mechanism and
 * form-validation.js for event firing...
 */
(function () {
  'use strict';

  ui.DonatePointsView = ui.ComponentView.extend({
    name: 'ui.DonatePointsView',

    events: {
      'change :radio': 'action',
    },

    initialize: function (options) {
      this.$form = this.$('form');
      this.$radios = this.$form.find(':radio');
    },

    action: function (e) {
      // form-validation.js has the possibility to validate single items by
      // triggering 'validate' on them. As the two radio buttons are not a
      // group, they have to be re-validated on change to remove an 'invalid'
      // on both...
      var self = this;
      this.$radios.trigger('validate');

      // Defer the search for the disabled input (IE9-11 timing problem)
      setTimeout(function () {
        // we also need to remove errors on disabled inputs...
        self.$form
          .find(':input:disabled')
          .closest('.form-group')
          .removeClass('invalid')
          .find('.error-box')
          .addClass('hidden');
      }, 0);
    },

    render: function () {
      // this hides the second group error-box as it's double...
      this.$radios.closest('.form-group').find('.error-box').slice(1).hide();
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'DonatePointsView',
    View: ui.DonatePointsView,
    selector: '.ui-js-donate-cumulus-points',
  });

  $(ui.bootstrapDonatePointsView('.ui-js-donate-cumulus-points'));
}).call(this);
