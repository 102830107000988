/**
 * @author Henri Podolski
 */

(function () {
  'use strict';

  /**
   * this calculates the available space in a given view
   * and applies that space to an element
   * if another element should be visible, it takes care of that too by substracting dimension from
   * given element references
   *
   */

  ui.StageDimension = ui.ComponentView.extend({
    name: 'ui.StageDimension',

    /**
     * @member {boolean} whether the functionality is active or not
     */
    isActive: false,

    /**
     * @member {RegExp} whether the functionality is active or not in the current view
     */
    activateViews: /(sm|md|lg)/,

    initialize: function () {
      this.isActive = this.activateViews.test(ui.Bootstrap.activeView);
      this.$subtractContainer = $('.ui-js-header-panel');
      this.callMethod = this.useAvailableSpace.bind(this);

      this.toggleActivation();
      ui.on('bootstrap.activemediaquery.changed', this.toggleActivation.bind(this));
    },

    toggleActivation: function () {
      var isActivateView = this.activateViews.test(ui.Bootstrap.activeView);

      if (isActivateView) {
        this.activate();
      } else {
        // set timeout because resize may fire later one, in this class
        setTimeout(this.deactivate.bind(this), 1);
      }
    },

    activate: function () {
      if (!this.isActive) {
        $(window).on('resize', this.callMethod);
        this.isActive = true;
      }
    },

    deactivate: function () {
      if (this.isActive) {
        $(window).off('resize', this.callMethod);
        this.el.style.removeProperty('height');
        this.isActive = false;
      }
    },

    useAvailableSpace: function () {
      var dimensionValue = this.calculateAvailableSpace();

      // check if it does not have the same size like last call
      if (dimensionValue && dimensionValue !== this.dimensionValue) {
        this.dimensionValue = dimensionValue;
        this.$el.height(this.dimensionValue);
      }
    },

    calculateAvailableSpace: function () {
      var referenceDimension = window.innerHeight;
      var subtractHeight = this.$subtractContainer.height();
      var availableSpace = parseInt(referenceDimension - subtractHeight, 10);

      return availableSpace;
    },

    render: function () {
      if (this.isActive) {
        this.useAvailableSpace();
      }

      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'StageDimensionComponent',
    View: ui.StageDimension,
    selector: '.ui-js-stage-dimension',
    reinitialize: false,
  });

  // $(window).on('load', ui.bootstrapStageDimensionComponent());
  $(ui.bootstrapStageDimensionComponent());
}).call(this);
