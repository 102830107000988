/**
 * @author Viktoriia Khardina, Nils von Rymon-Lipinski
 *
 * Modal Remote Component
 * --------------------------------------------------------
 * Opens/Close Bootstrap Modal with IFrame
 * --------------------------------------------------------
 *
 * Modal IFrame Component (only active for IOS Mobile)
 * --------------------------------------------------------
 * Handle Touch Events and convert it to Scroll Events
 * for scroll-handler.js
 * --------------------------------------------------------
 */

(function () {
  'use strict';

  ui.ModalRemoteComponentView = ui.ComponentView.extend({
    name: 'ui.ModalRemoteComponentView',

    $IFrame: null,
    $frameBody: null,
    isActiveClass: 'is-active',
    isMovingOutClass: 'is-moving-out',
    isMovingInClass: 'is-moving-in',

    initialize: function (options) {
      this.$IFrame = this.$('iframe');
      this.$modalLinks = $('.ui-js-toggle-modal');
      this.lastSrc = this.$IFrame.attr('src');
      this.isOpen = false;
      if (!/iP(hone|od|ad)/.test(navigator.userAgent)) {
        this.$el.css('overflow-y', 'hidden');
      }
      this.bindEvents();
      this.checkIfHashSet();
    },

    bindEvents: function () {
      var loadIFrameHandler = this.loadIFrameAndShowIt.bind(this);
      this.$modalLinks.on('click', loadIFrameHandler);
    },

    /**
     * check if anchor hash is set for overlay and trigger the link
     */
    checkIfHashSet: function () {
      if (
        this.$modalLinks &&
        this.$modalLinks.length > 0 &&
        window.location.hash &&
        window.location.hash.charAt(1) !== '?'
      ) {
        var $targetModalLink = this.$modalLinks.filter(function (idx, elm) {
          return window.location.hash === '#' + elm.id;
        });
        if ($targetModalLink.length) {
          $targetModalLink.click();
        }
      }
    },

    /**
     * Check if url was already loaded, if loads new content into the iframe
     * @param {Event} e - click event
     */
    loadIFrameAndShowIt: function (e) {
      e.preventDefault();
      var $targetElement = $(e.currentTarget);
      var self = this;

      if ($targetElement.data('target') && self.$el.attr('id')) {
        if ($targetElement.data('target') !== '#' + self.$el.attr('id')) {
          return;
        }
      }

      if (self.$IFrame && $targetElement) {
        var url = $targetElement.data('modal-src');
        self.lastSrc = url;
        self.$IFrame.attr('src', url);
        self.$IFrame.on('load', function () {
          self.$el.modal('show');
          self.initEventsOnIframe($targetElement);
        });
      }
    },

    /**
     * Triggers Bootstrap API and opens modal dialog with href as iframe src
     * also init event listener on close button
     */
    initEventsOnIframe: function ($targetElement) {
      var self = this;
      this.isOpen = true;
      this.$frameBody = this.$IFrame.contents().find('body');
      var $content = this.$frameBody.find('.ui-c282-generic-overlay');
      var event = new CustomEvent('modalIframeLoaded');

      this.toggleUrlHash($targetElement);

      this.$frameBody.find('.ui-js-close-modal').one('click', function () {
        self.hideModal();
      });

      self.$el.one('shown.bs.modal', function () {
        self.$frameBody.get(0).dispatchEvent(event);
      });

      this.$frameBody.on('click', function (e) {
        var $target = $(e.target);
        if ($content.find($target).length === 0) {
          self.hideModal();
          self.$frameBody.off('click');
        }
      });

      /**
       * Handover bootstrap padding when modal open
       */
      setTimeout(function () {
        self.$el.css('padding', '0');
        self.$frameBody.css('padding', '0');
        $('body').css('padding', '0');
        self.$frameBody.addClass(self.isMovingInClass);
        self.$frameBody.find('.link[href], .btn[href]').attr('target', '_top');
      }, 150);

      if (/iP(hone|od|ad)/.test(navigator.userAgent)) {
        setTimeout(function () {
          self.$IFrame.height(self.$IFrame.contents().height());
        }, 2000);
      }
    },

    /**
     * toggle the location url hash
     */
    toggleUrlHash: function ($targetElement) {
      var locationNew =
        $targetElement && $targetElement.attr('id')
          ? '#' + $targetElement.attr('id')
          : location.href.replace(location.hash, '');

      if (history.pushState) {
        history.pushState(null, null, locationNew);
      } else {
        location.href = locationNew;
      }
    },

    /**
     * hide the modal over bs API
     */
    hideModal: function () {
      this.$frameBody.addClass(this.isMovingInClass);
      this.$el.modal('hide');
      this.isOpen = false;
      this.toggleUrlHash(false);
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ModalRemoteComponent',
    View: ui.ModalRemoteComponentView,
    selector: '.ui-js-modal-iframe',
    reinitialize: false,
  });

  $(ui.bootstrapModalRemoteComponent());

  ui.ModalIframeComponentView = ui.ComponentView.extend({
    name: 'ui.ModalIframeComponentView',

    events: {
      touchstart: 'onTouchStart',
      touchmove: 'onTouchMove',
    },

    initialize: function (options) {
      if (!/iP(hone|od|ad)/.test(navigator.userAgent)) {
        return;
      }
      this.touchStartY = 0;
      this.touchMoveY = 0;
      this.lastScrollY = 0;
      this.$body = this.$el;
    },

    /**
     * save touch start position
     */
    onTouchStart: function (e) {
      if (!/iP(hone|od|ad)/.test(navigator.userAgent)) {
        return;
      }

      this.touchStartY = e.originalEvent.touches[0].pageY;
    },

    /**
     * save touch move y position and calculate difference
     * for call a custom scroll event to scroll-handler.js
     */
    onTouchMove: function (e) {
      if (!/iP(hone|od|ad)/.test(navigator.userAgent)) {
        return;
      }

      this.touchMoveY = e.originalEvent.touches[0].pageY;

      if (Math.abs(this.touchStartY - this.touchMoveY) > 25) {
        this.lastScrollY += this.touchStartY - this.touchMoveY;
        var viewportDiff = this.$el.outerHeight(true) - window.screen.availHeight;

        if (this.lastScrollY < 0) {
          this.lastScrollY = 0;
        }

        if (this.lastScrollY > viewportDiff) {
          this.lastScrollY = viewportDiff;
        }
        window.ui.trigger(ui.GlobalEvents.IOS_MOBILE_TOUCH_TO_SCROLL, this.lastScrollY);
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'ModalIframeComponent',
    View: ui.ModalIframeComponentView,
    selector: '.ui-body-iframe',
    reinitialize: false,
  });

  $(ui.bootstrapModalIframeComponent());
}).call(this);
