(function () {
  /**
   * @todo refactor - hard to understand/remember!
   */

  /**
   * triggers global events which are mapped on events on system/browser level
   * - handles hashes in url's
   */
  ui.SystemEvents = ui.ComponentClass.extend({
    name: 'ui.SystemEvents',

    initialize: function (options) {
      this.urlHashMap = options.urlHash;
      this.urlGetMap = options.urlGet;

      this.triggerQueue = {
        immediate: [],
        domready: [],
      };

      this.triggerQueue[ui.GlobalEvents.LOGIN_AUTHENTICATED] = [];

      if (window.location.hash) {
        var checkHash = function (referenceVal, paramKey) {
          return (
            referenceVal.indexOf('#' + paramKey) !== -1 ||
            referenceVal.indexOf('/' + paramKey) !== -1
          );
        };

        this.mapEvents(this.urlHashMap, window.location.hash, checkHash);
      }

      if (window.location.search) {
        var checkGet = function (referenceVal, paramKey) {
          return Object.keys(referenceVal).indexOf(paramKey) !== -1;
        };

        this.mapEvents(
          this.urlGetMap,
          ui.helpers.unserializeGetParams(window.location.search),
          checkGet
        );
      }

      $(this.onDomReady.bind(this));
      ui.on(ui.GlobalEvents.LOGIN_AUTHENTICATED, this.onLoginAuthenticated.bind(this));
    },

    onLoginAuthenticated: function () {
      var triggersOnLoginAuthenticated =
        this.triggerQueue[ui.GlobalEvents.LOGIN_AUTHENTICATED] || [];

      /**
       * @param {object} item
       * @param {string} item.name name of the triggered event
       * @param {mixed} item.data data to give to the listener
       * @see ui.SystemEvents instance creation (new ui.SystemEvents(options))
       */
      triggersOnLoginAuthenticated.forEach(function (item) {
        // ensure that all other components are initialized
        // and trigger at the end of the stack
        setTimeout(function () {
          ui.trigger(item.name, {
            data: item.data,
            name: item.name,
          });
        }, 1);
      });
    },

    /**
     * triggers global events after dom is loaded
     */
    onDomReady: function () {
      var triggersOnDomReady = this.triggerQueue.domready;

      /**
       * @param {object} item
       * @param {string} item.name name of the triggered event
       * @param {mixed} item.data data to give to the listener
       * @see ui.SystemEvents instance creation (new ui.SystemEvents(options))
       */
      triggersOnDomReady.forEach(function (item) {
        // ensure that all other components are initialized
        // and trigger at the end of the stack
        setTimeout(function () {
          ui.trigger(item.name, {
            data: item.data,
            name: item.name,
          });
        }, 1);
      });
    },

    /**
     * maps parameter config for further execution
     */
    mapEvents: function (valMap, referenceVal, paramConditionMethod) {
      var i = 0;
      var len = valMap.length;
      var paramKey;
      var globalEventName;
      var triggerPoint;

      for (i; i < len; i++) {
        if (typeof valMap[i][0] !== 'string' || typeof valMap[i][1] !== 'string') {
          throw new Error('Parameter missing');
        }

        paramKey = valMap[i][0];
        globalEventName = valMap[i][1];
        triggerPoint = valMap[i][2] || 'domready';

        if (!this.triggerQueue[triggerPoint]) {
          this.triggerQueue[triggerPoint] = [];
        }

        if (paramConditionMethod(referenceVal, paramKey)) {
          this.triggerQueue[triggerPoint].push({
            name: globalEventName,
            data: valMap[i][3],
          });
        }
      }
    },
  });
}).call(this);
