/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Datepicker capable of range inputs and single date inputs, validation depends on form-validation.js
   *
   * @see https://github.com/eternicode/bootstrap-datepicker
   * @see http://bootstrap-datepicker.readthedocs.org/en/stable/options.html
   *
   * Data-setup
   * @param {String} [format] - displayed date format, i.e 'dd.mm.yyyy' or 'yyyy-mm-dd'
   * @param {String} [startDate] - minimum date that can be picked, default '01.01.2000'
   * @param {String} [endDate] - maximum date that can be picked, default '31.12.2099'
   * @param {Array} [initDates] - initial values with ISO dates, f.e. ['2015-08-15', '2015-08-20']
   * @param {Boolean} [todayBtn] - whether to display a today button
   * @param {String} [language] - localization, default 'de'
   */
  ui.DatePickerView = ui.ComponentView.extend({
    name: 'ui.DatePickerComponentView',

    events: {
      changeDate: 'dateChanged',
      'click .ui-js-picker-addon': 'openPicker',
      hide: 'toggleIsOpened',
      show: 'toggleIsOpened',
    },

    $datepickerRange: null,
    $datepickerSingle: null,
    $rangeInputs: null,
    $dateInput: null,
    $container: null,

    currentViewport: null,
    isOpened: false,
    isNative: false,

    initDatesArray: null,

    // Picker's default options
    defaults: {
      format: 'dd.mm.yyyy',
      startDate: new Date('01.01.1900'),
      endDate: new Date('31.12.2099'),
      todayBtn: 'linked',
      language: 'de',
      todayHighlight: true,
      forceParse: false,
      container: '#js-date-picker',
    },

    initialize: function () {
      this.$window = $(window);
      this.scrollContainer = $('html,body');

      this.currentViewport = ui.Bootstrap.activeView || 'unknown';
      this.isNative = /xs|ms|sm|md/.test(this.currentViewport) && ui.browser.supportsTouch();
      this.initDatesArray =
        this.setup.initDates && this.setup.initDates.length ? this.setup.initDates : null;

      this.$datepickerRange = this.$('.ui-js-input-daterange');
      this.$datepickerSingle = this.$('.ui-js-input-date');
      this.$rangeInputs = this.$('.ui-js-input-daterange input[type="text"]');
      this.$dateInput = this.$('.ui-js-input-date input[type="text"]');
      this.$container = $(this.defaults.container);

      this.initializeNativeHtmlPicker();
    },

    /**
     * Transforms input type to 'date' for native html 5 date support
     */
    initializeNativeHtmlPicker: function () {
      var $currentPicker = this.$dateInput.length ? this.$dateInput : this.$rangeInputs;

      if ($currentPicker && $currentPicker.length) {
        $currentPicker.attr('type', 'date');

        // Set initial dates via attribute (if available in data-setup)
        if (this.initDatesArray && $currentPicker.length === 1) {
          $currentPicker.val(this.setup.initDates[0]);
        } else if (
          this.initDatesArray &&
          this.initDatesArray.length > 1 &&
          $currentPicker.length > 1
        ) {
          $currentPicker.eq(0).val(this.initDatesArray[0]);
          $currentPicker.eq(1).val(this.initDatesArray[1]);
        }
      }
    },

    /**
     * Creates Date object if string can be parsed
     * @param {String} dateString - stringified date
     * @returns {Object} date
     */
    createDate: function (dateString) {
      if (!isNaN(Date.parse(dateString))) {
        return new Date(dateString);
      } else {
        return '';
      }
    },

    /**
     * Listens to picker event 'changeDate' and closes the picker
     * @param {Event} e - datepicker event
     */
    dateChanged: function (e) {
      $(e.target).datepicker('hide');
    },

    /**
     * Sets current picker status to isOpened if event was triggered
     * @param {Event} e - datepicker event
     */
    toggleIsOpened: function (e) {
      if (e.type === 'show') {
        this.isOpened = true;
      } else if (e.type === 'hide') {
        var self = this;
        // Picker needs some time to be removed, the timeout prevents close and reopen on button click (openPicker)
        setTimeout(function () {
          self.isOpened = false;
        }, 100);
      }
    },

    /**
     * Opens picker when user clicks on addon (picker symbol)
     * @param {Event} e - click
     */
    openPicker: function (e) {
      if (!this.isOpened) {
        var selector = this.isNative ? 'input[type="date"]' : 'input[type="text"]';
        var $currentPicker = $(e.target).closest('.ui-js-form-item').find(selector);
        if ($currentPicker.length) {
          if (!this.isNative) {
            $currentPicker.datepicker('show');
            $currentPicker.focus();
          } else {
            // Fallback to focus for Android devices
            $currentPicker.click().focus();
          }
        }
      }
    },

    /**
     * @returns {Array} current range as array in ISO representation, i. e. [2016-01-01, 2016-02-02]
     */
    getUTCRange: function () {
      var valFrom = new Date(this.$rangeInputs.eq(0).val());
      var valUntil = new Date(this.$rangeInputs.eq(1).val());
      if (valFrom && valUntil) {
        var dates = [this.toISODateString(valFrom), this.toISODateString(valUntil)];
      }
      return dates;
    },

    /**
     * @param {Date} date - date object
     * @returns {String} ISO date string without time, i.e. 2016-01-01
     */
    toISODateString: function (date) {
      return date.toISOString().split('T')[0];
    },

    /**
     * Sets dates via datepicker API or natively (if available)
     * @param {Array} datesArray - single date or range, i.e. [2015-01-01]
     */
    update: function (datesArray) {
      if (datesArray && datesArray.length) {
        if (this.$datepickerRange.length && !this.isNative) {
          this.$rangeInputs.eq(0).datepicker('setUTCDate', this.createDate(datesArray[0]));
          this.$rangeInputs.eq(1).datepicker('setUTCDate', this.createDate(datesArray[1]));
        } else if (this.$datepickerSingle.length && !this.isNative) {
          this.$dateInput.datepicker('setUTCDate', this.createDate(datesArray[0]));
        } else if (this.$datepickerRange.length && this.isNative) {
          this.$rangeInputs.eq(0).val(datesArray[0]);
          this.$rangeInputs.eq(1).val(datesArray[1]);
        } else if (this.$datepickerSingle.length && this.isNative) {
          this.$dateInput.val(datesArray[0]);
        }
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'DatePickerComponent',
    View: ui.DatePickerView,
    selector: '.ui-js-datepicker',
  });

  $(ui.bootstrapDatePickerComponent());
}).call(this);
