/**
 * @author Nils von Rymon-Lipinski (IBM IX/Aperto GmbH)
 */

(function () {
  'use strict';

  /**
   * add description here
   */

  ui.Voting = ui.ComponentView.extend({
    name: 'ui.Voting',
    preselectClass: 'is-preselected',
    showResultClass: 'show-result',
    fadeOutClass: 'is-fade-out',
    fadeInClass: 'is-fade-in',
    fadeOutTime: 300,
    fadeInTime: 300,
    showPreselectClass: 'show-preselect',
    toggleResultBtnSel: '[data-js-item="ui-js-voting-toggle"]',
    pillBtnSel: '[data-js-item="ui-js-voting-pill"]',
    resultWrapperSel: '[data-js-item="ui-js-voting-result"]',
    cookieNamePrefix: 'voting-',

    events: {
      'click [data-js-item="ui-js-voting-toggle"]': 'toggle',
      'click [data-js-item="ui-js-voting-pill"]': 'handlePillClick',
    },

    initialize: function (options) {
      this.$bodyHtml = $('html,body');
      this.$pillBtns = this.$el.find(this.pillBtnSel);
      this.isPreselected = this.$pillBtns.filter('.' + this.preselectClass).length;
      this.$resultWrapper = this.$el.find(this.resultWrapperSel);
      this.answer = $.docCookies('voting-' + this.setup.id) || false;

      this.trackingData =
        this.setup.data &&
        this.setup.data.event &&
        this.setup.data.event === 'voting' &&
        this.setup.data;

      if (this.answer) {
        this.requestResult(false);
        var $pill = this.$pillBtns.filter('[data-answer-uuid=' + this.answer + ']');
        $pill.addClass(this.preselectClass);
        this.$pillBtns.attr('disabled', true);
      }
    },

    toggle: function (bool) {
      var _this = this;
      this.$el.addClass(this.fadeOutClass);
      this.$el.removeClass(this.fadeInClass);
      var toTopOfElement = this.$el.offset().top - 30;
      if (bool !== true) {
        this.$bodyHtml.animate({ scrollTop: toTopOfElement }, this.fadeOutTime);
      }
      if (this.$el.hasClass(this.showResultClass)) {
        setTimeout(function () {
          _this.$el.removeClass(_this.showResultClass);
          _this.$el.addClass(_this.showPreselectClass);
          _this.$el.addClass(_this.fadeInClass);

          setTimeout(function () {
            _this.$el.removeClass(_this.fadeOutClass);

            setTimeout(function () {
              _this.$el.removeClass(_this.fadeInClass);
            }, 400);
          }, this.fadeInTime);
        }, this.fadeOutTime);

        this.pushToDatalayer('question');
      } else {
        setTimeout(function () {
          _this.$el.removeClass(_this.showPreselectClass);
          _this.$el.addClass(_this.showResultClass);
          _this.$el.addClass(_this.fadeInClass);

          setTimeout(function () {
            _this.$el.removeClass(_this.fadeOutClass);

            setTimeout(function () {
              _this.$el.removeClass(_this.fadeInClass);
            }, 400);
          }, this.fadeInTime);
        }, this.fadeOutTime);

        if (bool.target) {
          this.pushToDatalayer('result');
        }
      }
    },

    requestResult: function (answerId) {
      var _this = this;
      var ajaxOptions = {
        type: 'GET',
        dataType: 'html',
        url: this.setup.ajaxUrl,
      };

      if (answerId) {
        ajaxOptions.data = { answer: answerId };
      }

      $.ajax(ajaxOptions)
        .done(function (data) {
          _this.$resultWrapper.html(data);
          _this.$el.addClass(_this.showPreselectClass);
          _this.toggle(answerId === false);
        })
        .fail(function (error) {
          console.log(error);
        });
    },

    pushToDatalayer: function (switchCase) {
      if (this.trackingData) {
        this.trackingData.voteAction = 'click';
        this.trackingData.voteTitle = this.setup.data.voteTitle;
        this.trackingData.voteSelection = this.$pillBtns
          .filter('[data-answer-uuid=' + this.answer + ']')
          .text()
          .trim();
        switch (switchCase) {
          // back to question
          case 'answer':
            this.trackingData.voteTarget = 'answer';
            break;
          case 'question':
            this.trackingData.voteTarget = 'back to question';
            break;
          case 'result':
            this.trackingData.voteTarget = 'back to result';
            break;
        }
        if (window.dataLayer) {
          window.dataLayer.push(this.trackingData);
          if (window.logTracking) {
            console.info('added dataLayer data:', JSON.stringify(this.trackingData));
            console.info('Tracking Obj', this.trackingData);
          }
        }
      }
    },

    handlePillClick: function (e) {
      var $target = $(e.target);
      if (this.isPreselected) {
        this.$pillBtns.attr('disabled', true);
        return false;
      } else {
        this.isPreselected = true;
        $target.addClass(this.preselectClass);
        this.$pillBtns.attr('disabled', true);
        this.answer = $target.attr('data-answer-uuid');
        this.requestResult(this.answer);
        this.pushToDatalayer('answer');
      }
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'VotingComponent',
    View: ui.Voting,
    selector: '.ui-js-voting',
    reinitialize: false,
  });

  $(ui.bootstrapVotingComponent());
}).call(this);
