(function () {
  /**
   * @author: Nils von Rymon-Lipinski
   * Modified by: Fabio Tedesco
   *
   * Manage the navigation interrupter and display it on first load and/or
   * when its content changes or a new one is inserted
   * Managed via updateId in data-setup
   *
   * Hide it on click wherever it happens except on the warenrückruf
   *
   */

  'use strict';

  ui.FlipTeaserComponentView = ui.ComponentView.extend({
    name: 'ui.FlipTeaserComponentView',

    events: {
      'click .ui-js-flip-btn': 'flipFront',
      'click .ui-js-flip-back-btn': 'flipBack',
    },

    flipFront: function (e) {
      var $currentTarget = $(e.currentTarget);
      var $teaserTarget = $currentTarget.closest(this.$el);
      var currentRow = $teaserTarget.closest('.row');
      var flippedTeaser = currentRow.find('.is-flipped');

      flippedTeaser.removeClass('is-flipped');
      $teaserTarget.addClass('is-flipped');
    },

    flipBack: function (e) {
      var $target = $(e.currentTarget).closest(this.$el);

      $target.removeClass('is-flipped');
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FlipTeaserComponent',
    View: ui.FlipTeaserComponentView,
    selector: '.ui-js-flip-teaser',
  });

  $(ui.bootstrapFlipTeaserComponent());
}).call(this);
