/**
 * @author Henri Podolski
 */
window.immediateReload = function () {
  console.log('immediateReload triggered...');
  ui.trigger('immediateReload');
};

(function () {
  'use strict';

  // guid from login widget
  let loginId;

  // guid registered in magnolia
  let serverId;

  // login widget state
  let loginState;

  const logLogin = localStorage.getItem('logLogin') === 'true';

  /**
   * @author Frank Sommer
   * @type {{checkImmediateLogin: Function, checkImmediateLogout: Function, logToConsole: Function}}
   */
  const immediateLoginChecker = {
    checkImmediateLogin: function (immediateLoginURL, logoutURL) {
      this.logToConsole(
        '#######',
        'checkImmediateLogin',
        'immediateLoginURL',
        immediateLoginURL,
        'logoutURL',
        logoutURL
      );
      if (typeof loginId !== 'undefined' && typeof serverId !== 'undefined') {
        // server id and login id is the same, everything is fine
        if (serverId === loginId) {
          this.logToConsole(
            'Do not try immediate login in this session again. Is same user ' + loginId + '.'
          );
        } else {
          // server id is not empty and not the same from login widget (login id)
          if (serverId.length > 0) {
            if (logoutURL) {
              this.logToConsole(
                'Trigger global logout. Different user are detected ' +
                  serverId +
                  '/' +
                  loginId +
                  '.'
              );
              window.location.href = logoutURL;
            }
          } else {
            // server id is empty and login id is set, trigger immediate login at server in an hidden IFrame
            const jFrame = jQuery('iframe#mconnector');
            if (jFrame.length) {
              this.logToConsole(
                'Do not try immediate login again. IFrame immediate login was already triggered.'
              );
            } else {
              this.logToConsole('Check immediate login for loginId:' + loginId + ' ...');
              var $loginIframe = jQuery(
                '<iframe src="' +
                  immediateLoginURL +
                  '" class="hidden" id="mconnector" width="0" height="0">'
              );
              $loginIframe.appendTo('body');
            }
          }
        }
      }
    },

    checkImmediateLogout: function (immediateLogoutURL) {
      this.logToConsole('#######', 'checkImmediateLogin');
      if (typeof serverId !== 'undefined' && serverId.length > 0 && immediateLogoutURL) {
        this.logToConsole('Do logout for loginId:' + loginId);
        window.location.href = immediateLogoutURL;
      }
    },

    logToConsole: function (message) {
      if (console) {
        logLogin && console.info(message);
      }
    },
  };

  ui.DelegateLoginView = ui.ComponentView.extend({
    name: 'ui.DelegateLoginView',

    initialize: function (options) {
      immediateLoginChecker.logToConsole('#####', 'delegate-login', 'el', this.el);
      this.immediateLoginURL = this.$el.data('immediateLoginUrl');
      this.immediateLogoutURL = this.$el.data('immediateLogoutUrl');
      this.logoutURL = this.$el.data('logoutUrl');
      this.immediateLoginReload = this.$el.data('immediateLoginReload');
      this.loginStateUrl = this.$el.data('loginStateUrl');

      // init connector and events if url is present
      if (this.immediateLoginURL && this.loginStateUrl) {
        const that = this;

        jQuery.ajax({
          url: this.loginStateUrl,
          success: function (msg) {
            serverId = msg;
            immediateLoginChecker.logToConsole(
              'Got state from server serverId=' +
                serverId +
                ' and loginState from widget is ' +
                loginState +
                ' ...'
            );
            if (typeof loginState !== 'undefined') {
              if (loginState) {
                immediateLoginChecker.checkImmediateLogin(that.immediateLoginURL, that.logoutURL);
              } else {
                immediateLoginChecker.checkImmediateLogout(that.immediateLogoutURL);
              }
            }
          },
        });

        ui.on(ui.GlobalEvents.LOGIN_AUTHENTICATED, this.checkImmediateLogin.bind(this));
        ui.on(ui.GlobalEvents.LOGIN_ANONYMOUS, this.checkLogout.bind(this));
        ui.on('immediateReload', this.immediateReload.bind(this));
      }
    },

    checkImmediateLogin: function (evt) {
      immediateLoginChecker.logToConsole('#######', 'checkImmediateLogin');
      const user = evt.data;
      loginId = user.sub;
      loginState = true;
      immediateLoginChecker.logToConsole(
        'checkImmediateLogin',
        'user',
        user,
        'loginId',
        loginId,
        'loginState',
        loginState
      );
      immediateLoginChecker.logToConsole(
        'Got state from widget ' +
          loginState +
          ', check immediate login for ' +
          serverId +
          '/' +
          loginId +
          ' ...'
      );
      immediateLoginChecker.checkImmediateLogin(this.immediateLoginURL, this.logoutURL);
    },

    checkLogout: function (evt) {
      immediateLoginChecker.logToConsole('#######', 'checkLogout');
      loginState = false;
      immediateLoginChecker.logToConsole(
        'checkLogout',
        'loginId',
        loginId,
        'loginState',
        loginState
      );
      immediateLoginChecker.logToConsole(
        'Got state from widget ' +
          loginState +
          ', check immediate logout for ' +
          serverId +
          '/' +
          loginId +
          ' ...'
      );
      immediateLoginChecker.checkImmediateLogout(this.immediateLogoutURL);
    },

    immediateReload: function (evt) {
      immediateLoginChecker.logToConsole(
        '#######',
        'immediateReload',
        'this.immediateLoginReload',
        this.immediateLoginReload
      );
      if (this.immediateLoginReload) {
        immediateLoginChecker.logToConsole('Do immediate login reload ...');
        window.location.reload();
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'DelegateLoginComponent',
    View: ui.DelegateLoginView,
    selector: '[data-immediate-login-url]',
  });

  $(ui.bootstrapDelegateLoginComponent());
}).call(this);
