/**
 * @author Henri Podolski
 */

(function () {
  'use strict';

  /**
   * this provides a toggle based on login widget state
   * which displays a fallback for anonymous users or
   * a personalized version of a component
   *
   * It uses login widget api to display the name of the
   * current logged in user.
   *
   */

  ui.PersonalizedToggle = ui.ComponentView.extend({
    name: 'ui.PersonalizedToggle',

    /**
     * @member {boolean} whether it is an anonymous user or logged in
     */
    isLoggedin: false,

    initialize: function () {
      this.isLoggedin = false;
      this.$anonymousEl = this.$('.js-anonymous');
      this.$identifiedEl = this.$('.js-identified');
      this.$placeholderFirstEl = this.$('.js-placeholder-1');
      this.$placeholderSecondEl = this.$('.js-placeholder-2');

      ui.on(ui.GlobalEvents.LOGIN_AUTHENTICATED, this.onIdentified.bind(this));
      ui.on(ui.GlobalEvents.LOGIN_ANONYMOUS, this.onAnonymous.bind(this));
      ui.on(ui.GlobalEvents.LOGIN_ERROR, this.onAnonymous.bind(this));
    },

    onIdentified: function (evt) {
      if (evt.data && evt.data.profile) {
        this.personalize(evt.data.profile.given_name, evt.data.profile.family_name);
      }
    },

    personalize: function (val1, val2) {
      if (arguments.length > 0) {
        this.$anonymousEl.addClass('hidden');
        this.$identifiedEl.removeClass('hidden');

        if (this.$placeholderFirstEl.length > 0 && val1) {
          this.$placeholderFirstEl.removeClass('hidden');
          this.$placeholderFirstEl.text(val1);
        }

        if (this.$placeholderSecondEl.length > 0 && val2) {
          this.$placeholderSecondEl.removeClass('hidden');
          this.$placeholderSecondEl.text(val2);
        }
      }
    },

    onAnonymous: function (evt) {
      this.$placeholderFirstEl.addClass('hidden');
      this.$placeholderSecondEl.addClass('hidden');
      this.$identifiedEl.addClass('hidden');
      this.$anonymousEl.removeClass('hidden');
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'PersonalizedToggleComponent',
    View: ui.PersonalizedToggle,
    selector: '.ui-js-personalized-toggle',
    reinitialize: true,
  });

  $(ui.bootstrapPersonalizedToggleComponent());
}).call(this);
