(function ($) {
	'use strict';
	/**
	 * Coupons module implementation.
	 *
	 * @author ernscht <ernscht@gmail.com>
	 * @namespace T.Module
	 * @class Coupons
	 * @extends T.Module
	 */
	T.Module.Coupons = T.createModule({

		start: function (resolve) {
			var mod = this,
				$ctx = $(mod._ctx);

			//console.log('START Coupons');

			// bind
			mod._events.on('t.sync', mod._sync.bind(mod));

			// start
			if (mod.service.jqxhr && mod.service.jqxhr.readyState !== 4) {
				mod.service.jqxhr.abort(); // abort pending ajax requests
			}
			mod.service.jqxhr = $.ajax({
				url: mod.service.uri,
				type: 'get',
				timeout: mod.service.timeout,
				contentType: 'application/json',
				//dataType: 'json',
				async: true,
				cache: false
			})
				.done(function (data) {
					if (data) {
						mod.coupons = $.isArray(data) ? data : [data];
						if (mod.coupons.length) {
							mod._renderAll();
						} else {
							var info = {
								type: 'info',
								tpl: mod.template.info,
								msg: $ctx.data('msg-info-zero-coupons') || '',
								wrap: true
							};
							mod._renderInfo(info);
						}
					}
				})
				.fail(function (jqXHR, textStatus) {
					if (jqXHR.status === 200) {
						mod._renderInfo({
							type: 'info',
							tpl: mod.template.info,
							msg: $ctx.data('msg-info-coupon-not-available') || '',
							wrap: true
						});
					} else if ('abort' !== textStatus) {
						mod._renderInfo({
							type: 'error',
							tpl: mod.template.info,
							msg: $ctx.data('msg-error') || 'Error',
							wrap: true
						});
					}
				})
				.always(function () {
					resolve();
				});
		},

		_sync: function () {
			// implement in skin if necessary
		},

		_init: function () {
			var mod = this,
				$ctx = $(mod._ctx);

			mod.CONST = {
				// Global
				TYPE: {
					DETAIL: 'detail',
					OVERVIEW: 'overview',
					PREVIEW: 'preview'
				},
				SIZE: {
					BIG: 'BIG',
					SMALL: 'SMALL'
				},
				// Coupon
				STATE: {
					ACTIVE: 'ACTIVE',
					INACTIVE: 'INACTIVE',
					DISABLED: 'DISABLED', // Multi/Bonus-Coupons that are inactive but disabled (not activatable)
					REDEEMED: 'REDEEMED'
				},
				ACTION: {
					ACTIVATE: 'activate',
					DEACTIVATE: 'deactivate',
					NONE: 'none'
				},
				STORAGENAME: 'dirac.state.overview'
			};

			mod.service = {
				jqxhr: null,
				uri: $ctx.data('service-uri'),
				activateUri: $ctx.data('service-activate-uri'),
				deactivateUri: $ctx.data('service-deactivate-uri'),
				timeout: $ctx.data('service-timeout') || 10000
			};

			mod.template = {
				id: $ctx.data('tpl'),
				container: $ctx.data('tpl-container'),
				controls: $ctx.data('tpl-controls'),
				info: $ctx.data('tpl-info')
			};

			mod.content = {
				detailLink: $ctx.data('coupon-detail-link'),
				overviewLink: $ctx.data('coupon-overview-link')
			};

			mod.state = { // filled & used in decorator overview
				// tabId: null
				// tab1: {channel:'',sort:''}
				// ...
			};

			mod.backgroundColor = $ctx.data('bg-color') || '#eeeae8';

			mod.coupons = null;          // save all coupons
			mod.type = '';               // contains one of mod.CONST.TYPE

			mod.$containers = null;      // coupons container
			mod.$controls = null;        // controls container (only overview)
		},

		_bindStateActionEvents: function () {
			var mod = this,
				$ctx = $(mod._ctx);

			$ctx.on('click', '.js-coupons-detail, .js-coupons-overview', function (e) {
				//e.stopImmediatePropagation();
				e.stopPropagation();

				var $this = $(this),
					$coupon = $this.closest('.m-coupon'),
					$tab = $ctx.find('[data-tab-ref="' + mod.state.tabId + '"]'), // on detailpage length is null
					couponItemId = '' + $coupon.data('item-id'),
					model = mod._getModelByItemId(couponItemId),
					channelString = mod._getDistributionChannelString(model),
					couponAction = $this.hasClass('js-coupons-detail') ? 'mehr info' : 'zurueck zur uebersicht';

				mod._track({
					event: 'couponInteraction',
					couponAction: couponAction,
					ecommerce: {
						click: {
							actionField: {
								list: $tab.length ? $tab.data('tracking-key') : 'detail-ansicht'
							},
							products: [
								{
									name: model.type + '_' + model.discountAmount + '_' + model.name,
									id: model.ean || '',
									position: ($coupon.siblings(':visible').addBack().index($coupon) + 1) + '',
									price: '',
									brand: channelString,
									category: model.minimumPurchase,
									variant: 'gueltig_' + model.expirationDate
								}
							]
						}
					}
				});
			});

			$ctx.on('click', '.js-coupons-action', function (e) {
				e.preventDefault();
				e.stopPropagation();

				var $this = $(this),
					$coupon = $this.closest('.m-coupon'),
					$tab = $ctx.find('[data-tab-ref="' + mod.state.tabId + '"]'), // on detailpage length is null
					couponItemId = '' + $coupon.data('item-id'),
					model = mod._getModelByItemId(couponItemId),
					channelString = mod._getDistributionChannelString(model),

					couponId = '' + $coupon.data('id'),
					couponGroup = '' + $coupon.data('group'),
					couponCampaignId = '' + $coupon.data('campaign-id'),
					$fly = $coupon.find('.js-coupons__flyout'),
					doActivate = $this.hasClass('m-coupon__button--activate') ? true : false,
					serviceUri = doActivate ? mod.service.activateUri : mod.service.deactivateUri,
					couponAction = (doActivate) ? 'aktivierung' : 'deaktivierung',
					jqxhr, i, length;

				mod._track({
					event: 'couponInteraction',
					couponAction: couponAction,
					ecommerce: {
						click: {
							actionField: {
								list: $tab.length ? $tab.data('tracking-key') : 'detail-ansicht'
							},
							products: [
								{
									name: model.type + '_' + model.discountAmount + '_' + model.name,
									id: model.ean || '',
									position: ($coupon.siblings(':visible').addBack().index($coupon) + 1) + '',
									price: '',
									brand: channelString,
									category: model.minimumPurchase,
									variant: 'gueltig_' + model.expirationDate
								}
							]
						}
					}
				});

				serviceUri += couponId;
				$fly.addClass('state-coupon-flyout--load');


				/* Change state of all other coupons on click and revert on error.
				 * This prevents the ability to activate more than one bonus/multi-coupons,
				 * as described in DIRACW-119
				 */
				var coupons = mod.coupons,
					timeouts = [];

				for (i = 0, length = coupons.length; i < length; i++) {
					if (couponItemId === '' + coupons[i]._itemId) {
						coupons[i].state = doActivate ? mod.CONST.STATE.ACTIVE : mod.CONST.STATE.INACTIVE;
						coupons[i].action = doActivate ? mod.CONST.ACTION.DEACTIVATE : mod.CONST.ACTION.ACTIVATE;
					} else if (coupons[i].campaignId && couponCampaignId === '' + coupons[i].campaignId) {
						coupons[i]._itemCampaignDisabled = !coupons[i]._itemCampaignDisabled;
					} else if (coupons[i].group && couponGroup === '' + coupons[i].group && coupons[i].state === mod.CONST.STATE.INACTIVE) {
						coupons[i]._itemDisabled = !coupons[i]._itemDisabled;
					}
				}

				mod._stateAction($coupon, timeouts);

				jqxhr = $.ajax({
					url: '' + serviceUri,
					type: 'post',
					timeout: mod.service.timeout,
					contentType: 'application/json',
					//dataType: 'json',
					async: true,
					cache: false
				}).done(function () {
					if (doActivate) {
						$fly.addClass('state-coupon-flyout--activate');
					} else {
						$fly.addClass('state-coupon-flyout--deactivate');
					}

					$coupon.toggleClass('m-coupon--state-active');

					timeouts.push(
						window.setTimeout(function () {
							$fly.removeClass('state-coupon-flyout--load');
						}, 5000)
					);

					timeouts.push(
						window.setTimeout(function () {
							$fly.removeClass('state-coupon-flyout--activate state-coupon-flyout--deactivate');
						}, 6000)
					);
				}).fail(function (jqXHR, textStatus) {
					if ('abort' !== textStatus) {
						$fly.removeClass('state-coupon-flyout--activate state-coupon-flyout--deactivate');
						if (jqXHR.responseJSON && jqXHR.responseJSON.errorMessage) {
							$fly.find('.js-status-error-text').html(jqXHR.responseJSON.errorMessage);

							if (jqXHR.responseJSON.redirectUrl) {
								$fly.find('.js-status-error-confirmation').attr('href', jqXHR.responseJSON.redirectUrl);
							}
						}
						$fly.addClass('state-coupon-flyout--error');
						mod._stateAction($coupon, timeouts);
					}
				});
			});

			$ctx.on('click', '.js-status-error-confirmation', function (e) {
				var $link = $(this),
					href = $link.attr('href');

				if (href === '#') {
					e.preventDefault();
					e.stopPropagation();
					window.location.reload(true);
				}
			});
		},

		// state change action
		_stateAction: function () {
			// implement in skin if necessary
		},

		// enrich coupon data for GUI
		_prepareCouponData: function () {
			// implement in skin if necessary
		},

		// render everything for the first time
		_renderAll: function () {
			var mod = this,
				$ctx = $(mod._ctx),
				tpl;

			$ctx.hide();
			mod._prepareCouponData(); // add skin-specific data

			if (mod.template.container) {
				// additional parent container
				tpl = T.Utils.Template.get(mod.template.container);

				mod._render({'bgColor': mod.backgroundColor}, tpl, $ctx);

				mod.$containers = $ctx.find('.js-coupons__container');
				mod.$containers.each(function () {
					mod._renderCouponsContainer($(this));
				});

				if (mod.template.controls) {
					mod.$controls = $ctx.find('.js-coupons__controls');
					mod.$controls.each(function () {
						mod._renderControls($(this));
					});
				}
			} else {
				// eg. for detail (without parent container)
				mod.$containers = $ctx;
				var meta = mod._getBaseCouponsTemplateInfo();

				mod._renderCoupons($ctx, meta);
			}

			$ctx.fadeIn(300);
		},

		// check for meta values (of $container) and render coupons in $container
		_renderCouponsContainer: function ($container) {
			var mod = this,
				meta = mod._getBaseCouponsTemplateInfo();

			meta.size.small = mod.type === mod.CONST.TYPE.OVERVIEW || $container.data('size') === mod.CONST.SIZE.SMALL ? true : false;
			meta.size.big = mod.type === mod.CONST.TYPE.DETAIL || $container.data('size') === mod.CONST.SIZE.BIG ? true : false;

			mod._renderCoupons($container, meta);
		},

		// render coupons in $container with given meta values
		_renderCoupons: function ($container, meta) {
			var mod = this,
				$ctx = $(mod._ctx),
				coupons = mod.coupons,
				tpl = T.Utils.Template.get(mod.template.id),
				data = {
					coupons: coupons,
					meta: meta || {}
				},
				$_container = $container || $ctx,
				states = $_container.data('states'),
				showStates = states ? states.split(',') : []; // empty array shows all states;

			if (!!data.coupons) {
				var coupon, channel, couponLength, channelLength, i, j;

				for (i = 0, couponLength = data.coupons.length; i < couponLength; i++) {
					coupon = coupons[i];
					coupon._show = !showStates.length || $.inArray(coupon.state, showStates) !== -1;

					coupon._state = {
						active: coupon.state === mod.CONST.STATE.ACTIVE,
						redeemed: coupon.state === mod.CONST.STATE.REDEEMED,
						inactive: coupon.state === mod.CONST.STATE.INACTIVE,
						new: coupon.newCoupon,
						disabled: coupon._itemDisabled || coupon.state === mod.CONST.STATE.DISABLED || false,
						campaignDisabled: coupon._itemCampaignDisabled || false
					};

					coupon._channels = {
						masters: [],
						others: []
					};

					if (!!coupon.distributionChannels) {
						for (j = 0, channelLength = coupon.distributionChannels.length; j < channelLength; j++) {
							channel = coupon.distributionChannels[j];

							if (channel.master) {
								coupon._channels.masters.push(channel);
							} else {
								coupon._channels.others.push(channel);
							}
						}
					}

					///////////////////////////////////////////////////
					// more coupon logic for visualisation
					///////////////////////////////////////////////////
					coupon._prop = {};

					// description
					if (!coupon._state.redeemed) {
						if (coupon.minimumPurchase) {
							coupon._prop.descriptionMinimumPurchase = coupon.minimumPurchase;
						}
						if (data.meta.size.small && coupon.redeemableArea) {
							coupon._prop.descriptionRedeemableArea = coupon.redeemableArea;
						}
					}

					// links
					if (coupon._state.redeemed) {
						coupon._prop.detailLink = mod.content.detailLink + '?state=redeemed&id=' + coupon.id + '&redeemedDate=' + encodeURIComponent(coupon.redeemedDate);
					} else {
						coupon._prop.detailLink = mod.content.detailLink + '?id=' + coupon.id;
					}
					coupon._prop.overviewLink = mod.content.overviewLink;

					// action text
					coupon._prop.actionText = '';
					if ((data.meta.size.small && !coupon._state.redeemed) || (!data.meta.size.small && coupon._state.disabled)) {
						if (coupon.activationDisabledText) {
							coupon._prop.actionText = coupon.activationDisabledText;
						}
					} else if (!data.meta.size.small && coupon._state.redeemed) {
						if (coupon.redeemedText) {
							coupon._prop.actionText = coupon.redeemedText;
						}
					}

					// product image
					coupon._prop.image = {};
					var hasProductImage = coupon.image || coupon.imageRedeemed ? true : false,
						couponImage = hasProductImage ? (coupon._state.redeemed && coupon.imageRedeemed ? coupon.imageRedeemed : coupon.image) : (coupon._state.redeemed && coupon.signetRedeemed ? coupon.signetRedeemed : coupon.signet),
						signetImage = coupon._state.redeemed && coupon.signetRedeemed ? coupon.signetRedeemed : coupon.signet,
						w, h;

					if (couponImage) {
						if (couponImage.custom) {
							w = data.meta.size.small ? 200 : 425;
							h = data.meta.size.small ? 200 : 425;
							coupon._prop.image.src = mod._getImageCustomUrl({
								url: couponImage.custom,
								width: w,
								height: h
							});
						} else {
							coupon._prop.image.src = data.meta.size.small ? couponImage.medium : couponImage.large
						}
						if (!hasProductImage && !couponImage.rotated) {
							coupon._prop.image.rotate = true;
						}

						// MREL-5497 only signet is available and shown as image
						if (!hasProductImage) {
							coupon._prop.upscale = true;
						}
					}

					// badge image
					coupon._prop.badge = {};
					if (hasProductImage && signetImage) {
						w = data.meta.size.small ? 80 : 120;
						if (signetImage.custom) {
							coupon._prop.badge.src = mod._getImageCustomUrl({url: signetImage.custom, width: w});
						} else {
							coupon._prop.badge.src = data.meta.size.small ? signetImage.medium : signetImage.large;
							coupon._prop.badge.width = w;
						}
						if (!signetImage.rotated) {
							coupon._prop.badge.rotate = true;
						}
					}

					// logos
					var src = '',
						len,
						channels;
					coupon._logos = [];
					coupon._logoInsideInfo = false;

					if (data.meta.size.small) {
						channel = coupon._channels.masters[0] || coupon._channels.others[0];
						if (channel && channel.logo) {
							if (channel.logo.custom) {
								src = coupon._state.redeemed && channel.logoRedeemed ? channel.logoRedeemed.custom : channel.logo.custom;
								src = mod._getImageCustomUrl({url: src, height: 32});
							} else {
								src = coupon._state.redeemed && channel.logoRedeemed ? channel.logoRedeemed.medium : channel.logo.medium;
							}
							coupon._logos.push({
								src: src,
								alt: channel.name
							});
						}
					} else {
						if (coupon._channels.others.length === 0) {
							coupon._logoInsideInfo = true;
						}

						channels = coupon._channels.masters;
						for (j = 0, len = channels.length; j < len; j++) {
							channel = channels[j];
							if (channel && channel.logo) {
								if (channel.logo.custom) {
									src = coupon._state.redeemed && channel.logoRedeemed ? channel.logoRedeemed.custom : channel.logo.custom;
									src = mod._getImageCustomUrl({url: src, height: 30});
								} else {
									src = coupon._state.redeemed && channel.logoRedeemed ? channel.logoRedeemed.medium : channel.logo.medium;
								}
								coupon._logos.push({
									src: src,
									alt: channel.name
								});
							}
						}

						channels = coupon._channels.others;
						for (j = 0, len = channels.length; j < len; j++) {
							channel = channels[j];
							if (channel && channel.logo) {
								if (channel.logo.custom) {
									src = coupon._state.redeemed && channel.logoRedeemed ? channel.logoRedeemed.custom : channel.logo.custom;
									src = mod._getImageCustomUrl({url: src, height: 30});
								} else {
									src = coupon._state.redeemed && channel.logoRedeemed ? channel.logoRedeemed.medium : channel.logo.medium;
								}
								coupon._logos.push({
									src: src,
									alt: channel.name
								});
							}
						}

						// coupon._logos.reverse(); removed for DIRACW-134
					}
				}
			}
			//console.log(data);
			mod._render(data, tpl, $_container);

			// Handle multiline ellipsis
			if (mod.type === mod.CONST.TYPE.OVERVIEW) {
				window.setTimeout(function () {
					$_container.find('.js-coupon__title span:visible').each(function () {
						$(this).trunk8({lines: 3});
					});

					$_container.find('.js-coupon__subtitle').each(function () {
						$(this).trunk8({lines: 2});
					});

					$_container.find('.js-coupon__description--distribution').each(function () {
						$(this).trunk8({lines: 2});
					});
				}, 0);
			}
		},

		//render the controls in $container with client template
		_renderControls: function ($container) {

			var mod = this,
				ref = $container.data('ref'),
				tpl = T.Utils.Template.get(mod.template.controls),
				data = {
					state: ref && mod.state[ref] ? mod.state[ref] : {},
					ref: ref,
					hasChannelFilter: false,
					hasSortFilter: false,
					channels: [],
					sort: {},
					channel: {},
					channelName: ''
				},
				states = $container.data('states'),
				boxes = $container.data('boxes'),
				i;

			if (boxes) {
				if (boxes.indexOf('filter') !== -1) {
					data.hasChannelFilter = true;
					data.channels = mod._getStateChannels(states);
					data.channel[data.state.channel] = true;
					for (i = 0; i < data.channels.length; i++) {
						if (data.channels[i].id === data.state.channel) {
							data.channels[i].selected = true;
							data.channelName = data.channels[i].label;
							break;
						}
					}
				}
				if (boxes.indexOf('sort') !== -1) {
					data.hasSortFilter = true;
					data.sort[data.state.sort] = true;
				}
			}

			mod._render(data, tpl, $container);
		},

		// render an info message with doT template
		_renderInfo: function (info) {
			var mod = this,
				$ctx = $(mod._ctx),
				tpl = T.Utils.Template.get(info.tpl),
				data = {
					msg: info.msg || '',
					type: info.type || '',
					wrap: info.wrap || false
				};

			$ctx.hide();
			mod._render(data, tpl, $ctx);
			$ctx.fadeIn(300);
		},

		// render
		_render: function (data, tpl, $container) {
			var mod = this,
				$ctx = $(mod._ctx),
				content = tpl(data),
				$content = $(content),
				$_container = arguments[2] || $container || $ctx;

			$_container.html($content);

			// maybe: start modules
		},

		// get the meta defaults
		_getBaseCouponsTemplateInfo: function () {
			var mod = this;
			return {
				type: {
					overview: mod.type === mod.CONST.TYPE.OVERVIEW,
					detail: mod.type === mod.CONST.TYPE.DETAIL,
					preview: mod.type === mod.CONST.TYPE.PREVIEW
				},
				size: { // default is big
					big: true,
					small: false
				}
			};
		},

		// get the list of all channels for given states
		_getStateChannels: function (states) {
			var mod = this,
				coupons = mod.coupons,
				_states = states.split(','),
				filters = {},
				i, coupon, cou_len = coupons.length,
				y, channels, channel, cha_len;

			for (i = 0; i < cou_len; i++) {
				coupon = coupons[i];
				if ($.inArray(coupon.state, _states) !== -1) {
					channels = coupon.distributionChannels;
					cha_len = channels.length;
					for (y = 0; y < cha_len; y++) {
						channel = channels[y];
						if (!filters.hasOwnProperty(channel.id)) {
							filters[channel.id] = {
								id: channel.id,
								label: channel.label/*,
								filter_id: parseInt(channel.filterId, 10)*/
							};
						}
					}
				}
			}

			var keys = Object.keys(filters),
				valueObjects = [],
				length = keys.length,
				key, sorted;

			for (i = 0; i < length; i++) {
				key = keys[i];
				valueObjects.push(filters[key]);
			}

			sorted = valueObjects.sort(function (a, b) {
				return a.filter_id - b.filter_id;
			});

			return sorted;
		},

		// return the model by its itemId
		_getModelByItemId: function (itemId) {
			var mod = this,
				coupons = mod.coupons;

			if (coupons.length === 1) {
				return coupons[0];
			}

			for (var i = 0, length = coupons.length; i < length; i++) {
				var coupon = coupons[i];

				if (coupon._itemId === itemId) {
					return coupon;
				}
			}
		},

		// returns the channel string for tracking
		_getDistributionChannelString: function (model) {
			var channelString = '',
				i, length;

			for (i = 0, length = model.distributionChannels.length; i < length; i++) {
				var channelId = model.distributionChannels[i].id;
				if (i + 1 < length) {
					channelId += ';';
				}
				channelString += channelId;
			}

			return channelString;
		},

		_getImageCustomUrl: function (config) {
			var url = '';
			var width, height;

			if (!config || !$.isPlainObject(config)) {
				return '';
			}

			if (config.url && 'string' === typeof config.url) {
				url = config.url;

				if (url.indexOf('{width}') > -1 || url.indexOf('{height}') > -1) {
					// replace placeholders
					width = config.width || 10000;
					height = config.height || 10000;
					url = url.replace(/\{width\}/ig, width);
					url = url.replace(/\{height\}/ig, height);
				} else {
					// old processing
					if (config.width) {
						url += '&width=' + config.width
					}
					if (config.height) {
						url += '&height=' + config.height
					}
				}
			}

			return url;
		},

		// client tracking
		_track: function (data) {
			if (typeof window.dataLayer !== 'undefined') {
				window.dataLayer.push(data);
			} else {
				if (typeof window.console !== 'undefined') {
					window.console.log(data);
				}
			}
		}
	});
}(jQuery));

(function() {
	'use strict';
	/**
	 * Detail decorator implementation for the Coupons module.
	 *
	 * @author ernscht <ernscht@gmail.com>
	 * @namespace T.Module.Coupons
	 * @class Detail
	 * @extends T.Module
	 */
	T.Module.Coupons.Detail = T.createDecorator({
		start: function (resolve) {
			var mod = this;

			//console.log('START Coupons Detail');

			// init //////////////////////////////
			mod._init();
			mod.type = mod.CONST.TYPE.DETAIL;

			// bind //////////////////////////////
			mod._bindStateActionEvents();

			// parent //////////////////////////////
			mod._parent.start(resolve);
		},

		_sync: function () {
			var mod = this,
				model = mod.coupons[0],
				channelString = mod._getDistributionChannelString(model);

			//console.log('Coupons Detail - sync [id:' + $ctx.data('t-id') + ']');

			// Tracking on page load.
			mod._track({
				event:     'couponDetailView',
				ecommerce: {
					detail: {
						actionField : {
							list: 'detail-ansicht'
						},
						products: [
							{
								name:     model.type + '_' + model.discountAmount + '_' + model.name,
								id:       model.ean || '',
								price:    '',
								brand:    channelString,
								category: model.minimumPurchase,
								variant:  'gueltig_' + model.expirationDate
							}
						]
					}
				}
			});
		},

		// enrich coupon data for GUI
		_prepareCouponData: function () {

			// for the preview we need the _itemDisabled prop here
			//var mod = this,
			//	coupons = mod.coupons,
			//	length = coupons.length,
			//	coupon, i;
			//
			//for (i = 0; i < length; i++) {
			//	coupon = coupons[i];
			//	coupon._itemDisabled = (coupon.state === mod.CONST.STATE.DISABLED);
			//}

		}
	});
}(jQuery));

(function($) { // jshint ignore:line
	'use strict';

	/**
	 * Overview decorator implementation for the Coupons module.
	 *
	 * @author ernscht <ernscht@gmail.com>
	 * @namespace T.Module.Coupons
	 * @class Overview
	 * @extends T.Module
	 */
	T.Module.Coupons.Overview = T.createDecorator({
		start: function (resolve) {
			var mod = this,
				$ctx = $(mod._ctx);

			//console.log('START Coupons Overview');

			// init //////////////////////////////
			mod._init();
			mod.type = mod.CONST.TYPE.OVERVIEW;
			mod.CONST.TABIDS = ['tab1', 'tab2', 'tab3'];

			mod.state = T.Utils.Store.getState(mod.CONST.STORAGENAME);
			if (!mod.state) {
				// set default
				mod.state = {
					tabId: 'tab1',
					tab1: {channel:'alleShops',sort:'newest'},
					tab2: {channel:'alleShops',sort:'validity'},
					tab3: {channel:'alleShops'}
				};
			}

			// bind //////////////////////////////
			mod._bindStateActionEvents();

			// bind tab change
			$ctx.on('click', '.js-coupons__tab-link', function () {
				var $this = $(this);
				if (!$this.hasClass('state-active')) {
					mod._showTab($this.data('tab-ref'));
					mod._trackCoupons({action: 'tab-wechsel'});
				}
			});

			// bind filter/sort
			$ctx.on('click', '.js-coupons__dropdown-button', function (e) {
				e.preventDefault();
				e.stopPropagation();
				var $this = $(this),
					$dropdown = $this.closest('.js-coupons__dropdown');

				$ctx.find('.js-coupons__dropdown.open').not($dropdown).removeClass('open');  // close others
				$dropdown.toggleClass('open');
			});
			$ctx.on('click', function () {
				// close all filters
				$ctx.find('.js-coupons__dropdown.open').removeClass('open');
			});

			$ctx.on('click', '.js-coupons__filter-item', function (e) {
				e.preventDefault();
				var $item = $(this),
					$dropdown = $item.closest('.js-coupons__dropdown'),
					$menu = $dropdown.find('.js-coupons__dropdown-menu'),
					$button = $dropdown.find('.js-coupons__dropdown-button'),
					refs = $button.attr('id').split('_'),
					tabId = refs[0],
					isSort = refs[1] === 'sort',
					text = $item.text(),
					value = $item.data('value');

				if (e.originalEvent && $item.hasClass('is-active')) { // same clicked node
					return false;
				}

				$button.find('.js-coupons__dropdown-button__text').text(text);
				$menu.find('.js-coupons__filter-item').removeClass('is-active');
				$item.addClass('is-active');

				if (isSort) {
					mod._doSort(tabId, value);
					if (e.originalEvent) {
						mod._trackCoupons({action: 'sortieren'});
					}
				}
				else {
					mod._doFilter(tabId, value);
					if (e.originalEvent) {
						mod._trackCoupons({action: 'filter'});
					}
				}
			});

			$ctx.on('click', '.m-coupon', function(e) {
				var $coupon = $(this),
					$link = $coupon.find('.js-coupons-detail');

				// mobile: coupon click triggers detail link
				if ($link.is(':hidden') && !($link.parent().hasClass('m-coupon__link--multi') && $coupon.hasClass('m-coupon--state-disabled'))) {
					e.stopPropagation();
					$link.trigger('click');                     // do tracking
					window.location.href = $link.attr('href');  // follow link
				}
			});

			// parent //////////////////////////////
			mod._parent.start(resolve);
		},

		_sync: function () {
			var mod = this,
				$ctx = $(mod._ctx);

			//console.log('Coupons Overview - sync [id:' + $ctx.data('t-id') + ']');

			mod.$tabLinks = $ctx.find('.js-coupons__tab-link');
			mod.$tabContents = $ctx.find('.js-coupons__tab-content');

			// content is here
			mod._countCoupons();

			// default filter sort from state
			mod.$controls.each(function () {

				var $control = $(this),
					tabId = $control.closest('.js-coupons__controls').data('ref');

				// initial filter/sort
				if (mod.state[tabId].hasOwnProperty('channel')) {
					$control.find('.js-coupons__channels .js-coupons__filter-item[data-value="' + mod.state[tabId].channel + '"]').trigger('click');
				}
				if (mod.state[tabId].hasOwnProperty('sort')) {
					$control.find('.js-coupons__sort .js-coupons__filter-item[data-value="' + mod.state[tabId].sort + '"]').trigger('click');
				}
			});

			// sort redeemed
			$('#' + mod.CONST.TABIDS[2]).find('.m-coupon').sortElements(function (a, b) {
				return $(a).data('sort-redeemed') < $(b).data('sort-redeemed') ? 1 : -1;
			});

			// show tab for the first time
			mod._showTab(T.Utils.Url.getUrlParamValue('tab') || mod.state.tabId); // undocumented url param tab ;-)

			// track on page load
			mod._trackCoupons({action: 'seite-load'});
		},

		_doFilter: function (tabId, value) {
			var mod = this,
				$tab = $('#' + tabId),
				$container = $tab.find('.js-coupons__channels'),
				_value = arguments[1] ? value : $container.find('.js-coupons__filter-item.is-active').data('value');

			mod.state[tabId].channel = _value;
			T.Utils.Store.setState(mod.CONST.STORAGENAME, mod.state);

			$tab.find('.m-coupon').each(function () {
				var $coupon = $(this),
					channels = $coupon.data('channels').split(',');

				if (_value === 'alleShops' || $.inArray('' + _value, channels) !== -1) {
					$coupon.addClass('js-is-visible').fadeIn(200);
				}
				else {
					$coupon.removeClass('js-is-visible').fadeOut(200);
				}
			});

			//console.log('filter: ' +tabId + ' / ' + _value);
		},

		_doSort:function (tabId, value) {
			var mod = this,
				$tab = $('#' + tabId),
				$container = $tab.find('.js-coupons__sort'),
				_value = arguments[1] ? value : $container.find('.js-coupons__filter-item.is-active').data('value');

			mod.state[tabId].sort = _value;
			T.Utils.Store.setState(mod.CONST.STORAGENAME, mod.state);

			if (_value === 'newest') {
				$tab.find('.m-coupon').sortElements(function (a, b) {
					return $(a).data('sort-' + _value) < $(b).data('sort-' + _value) ? 1 : -1;
				});
			}
			else if (_value === 'validity') {
				$tab.find('.m-coupon').sortElements(function (a, b) {
					return $(a).data('sort-' + _value) > $(b).data('sort-' + _value) ? 1 : -1;
				});
			}

			//console.log('sort: ' + tabId + ' / ' + _value);
		},

		_countCoupons: function () {
			var mod = this;

			mod.$tabLinks.each(function (idx, ele) {
				mod._updateTabCount($(ele).data('tab-ref'));
			});
		},

		_updateTabCount: function (tabId) {
			var mod = this,
				$tabLink = mod.$tabLinks.filter('[data-tab-ref=' + tabId + ']'),
				count = $('#' + tabId).find('.m-coupon').length;

			$tabLink.find('.js-coupons__tab-nr').text(count);

			if (tabId === "tab2") {
				$('[data-target-id="dirac"]').text(count);
			}
		},

		_showTab: function (tabId) {
			var mod = this,
				$tabLinks = mod.$tabLinks,
				$contents = mod.$tabContents,
				$tabLink;

			$contents.hide();
			$tabLinks.removeClass('state-active');

			if (tabId) {
				$tabLink = $tabLinks.filter('[data-tab-ref=' + tabId + ']');
			}
			else { // first tab, optimisation: check if we need this
				$tabLink = $tabLinks.eq(0);
				tabId = $tabLink.data('tab-ref');
			}

			mod.state.tabId = tabId;
			T.Utils.Store.setState(mod.CONST.STORAGENAME, mod.state);

			$tabLink.addClass('state-active');
			$('#' + tabId).fadeIn(300);
			mod._handleFilters(tabId);
		},

		// skin specific state change action
		_stateAction: function ($coupon, timeouts) {
			var mod = this,
				thisTabId = $coupon.closest('.js-coupons__container').data('ref'),
				otherTabId = thisTabId === mod.CONST.TABIDS[0] ? mod.CONST.TABIDS[1] : mod.CONST.TABIDS[0],
				$otherTabContent = $('#' + otherTabId),
				$otherTabCouponsContainer = $otherTabContent.find('.js-coupons__container'),
				groupId = $coupon.data('group'),
				campaignId = $coupon.data('campaign-id'),
				couponId = $coupon.data('id');

			// render other tab
			mod._renderCouponsContainer($otherTabCouponsContainer);

			// clean up first or second tab after render
			if (thisTabId === mod.CONST.TABIDS[0]) {
				// we are in first tab
				// enable/disable same GROUP coupons
				$coupon
					.siblings()
					.filter('[data-group="' + groupId + '"]')
					.toggleClass('m-coupon--state-disabled')
					.not('[data-id="' + couponId + '"]')
					.toggleClass('has-detail-link');

				// disable same CAMPAIGN coupons
				if (campaignId) {
					$coupon
						.siblings()
						.filter('[data-campaign-id="' + campaignId + '"]')
						.toggleClass('m-coupon--state-campaign-disabled');
				}

				mod._renderControls($otherTabContent.find('.js-coupons__controls'));
				mod._doSort(otherTabId);
				mod._doFilter(otherTabId);
				mod._updateTabCount(otherTabId);
				mod._handleFilters(otherTabId);
			}
			else {
				// we are in second tab
				$.each(timeouts, function (idx, val) {
					window.clearTimeout(val);
				});

				// render new filters immediately before coupon is removed ...
				mod._renderControls($('#' + thisTabId).find('.js-coupons__controls'));

				// remove inactive coupon & refilter/resort
				window.setTimeout(function () {
					$coupon.fadeOut(300, function () {
						$(this).remove();
						mod._doSort(thisTabId);
						mod._doFilter(thisTabId);
						mod._updateTabCount(thisTabId);
						mod._handleFilters(thisTabId);
					});
				}, 4000);

				// filter and sort first tab
				mod._doSort(otherTabId);
				mod._doFilter(otherTabId);
			}
		},

		// enrich coupon data for GUI
		/* jshint maxstatements:32 */
		_prepareCouponData: function () {
			var mod = this,
				coupons = mod.coupons,
				len = coupons.length,
				i, j,
				coupon, channels, lenChannels,
				activatedGroups = [],
				activatedCampaigns = [],
				activatedCoupons = [],
				pubDate, expDate;

			// first loop, we need to collect some things for 2nd loop (activatedGroups)
			for (i = 0; i < len; i++) {
				coupon = coupons[i];
				channels = [];

				// collect channels & activatedGroups
				if (coupon.distributionChannels && coupon.distributionChannels.length) {
					lenChannels = coupon.distributionChannels.length;
					for (j = 0; j < lenChannels; j++) {
						channels.push(coupon.distributionChannels[j].id);
					}
				}

				// Multi/Bonus coupons
				coupon._itemMultiCoupon = (coupon.quantity > 1);
				coupon._itemBonusCoupon = (coupon.type === 'BONUS');

				if (coupon._itemMultiCoupon && coupon.state === mod.CONST.STATE.ACTIVE) {
					activatedGroups.push(coupon.group);
				}
				else if (coupon._itemBonusCoupon && coupon.state === mod.CONST.STATE.ACTIVE) {
					activatedGroups.push(coupon.group);
				}

				// Campaign coupons
				coupon._itemCampaignCoupon = (coupon.campaignId !== null);

				if (coupon._itemCampaignCoupon && coupon.state === mod.CONST.STATE.ACTIVE) {
					activatedCampaigns.push(coupon.campaignId);
				}

				if (coupon.state === mod.CONST.STATE.ACTIVE) {
					activatedCoupons.push(coupon.id);
				}

				coupon._itemId = coupon.id + '-' + Math.random().toString(24).substring(6);
				coupon._itemChannels = channels.join(',');

				pubDate = coupon.publicationDate ? parseInt(coupon.publicationDate.replace(/-/g, ''), 10) : 10000101; // default 1000 AD.
				expDate = coupon.expirationDate ? parseInt(coupon.expirationDate.replace(/-/g, ''), 10) : 90000101;    // default 9000 AD.
				coupon._itemSortNewest = pubDate * 10000000 - expDate;       // biggest number for newest (pubDate closer to today, else closest expiry date)
				coupon._itemSortValidity = expDate * 10000000 + pubDate;     // smallest number for first (expDate closer to today, else most distant pubDate)
				coupon._itemSortRedeemed = coupon.redeemedDate ? coupon.redeemedDate : null;               // smallesssssst ...
			}

			// second iteration
			for (i = 0; i < len; i++) {
				coupon = coupons[i];

				var belongsToActiveGroup = $.inArray(coupon.group, activatedGroups) !== -1;
				var belongsToActiveCampaign = $.inArray(coupon.campaignId, activatedCampaigns) !== -1;

				// check disabled coupons
				coupon._itemDisabled = !!(coupon.state === mod.CONST.STATE.INACTIVE && belongsToActiveGroup);
				coupon._itemCampaignDisabled = !!(coupon.state === mod.CONST.STATE.INACTIVE && belongsToActiveCampaign);
				// Used for group and campaign case
				coupon._hasDetailLink = ($.inArray(coupon.id, activatedCoupons) <= -1);
			}

			//console.log(coupons[0]);
			//console.log(coupons[1]);
		},
		/* jshint maxstatements:25 */

		_handleFilters: function (tabId) {
			var mod = this,
				$ctx = $(mod._ctx),
				$tabLinks = mod.$tabLinks,
				couponThreshold = 0,
				$tabLink,
				$tabContainer;

			if (tabId) {
				$tabLink = $tabLinks.filter('[data-tab-ref=' + tabId + ']');
				$tabContainer = $('#' + tabId);
			}
			else { // first tab
				$tabLink = $tabLinks.eq(0);
				tabId = $tabLink.data('tab-ref');
				$tabContainer = $('#' + tabId);
			}

			var coupons = parseInt($tabLink.find('.js-coupons__tab-nr').text());

			if (coupons <= couponThreshold) {
				$('#' + tabId).find('.js-coupons__controls').hide();
			}
			else {
				$('#' + tabId).find('.js-coupons__controls').show();
			}

			// show/hide "no coupon"-info
			if (coupons === 0) {
				var tpl = T.Utils.Template.get($ctx.data('tpl-info')),
					render = tpl({
						msg: $ctx.data('msg-info-zero-coupons')
					}),
					$render = $(render);

				$render.addClass('js-coupons_no-coupon-info');

				if (!$tabContainer.find('.js-coupons_no-coupon-info').length) {
					$tabContainer.append($render);
				}
			}
			else {
				if ($tabContainer.find('.js-coupons_no-coupon-info').length) {
					$tabContainer.find('.js-coupons_no-coupon-info').remove();
				}
			}
		},

		_trackCoupons: function (config) {
			config = config || {};

			var mod = this,
				$ctx = $(mod._ctx),
				$tab = $ctx.find('[data-tab-ref="' + mod.state.tabId + '"]'),
				$tabContainer = $('#' + mod.state.tabId),
				$coupons = $('.m-coupon', $tabContainer),

				availableCount = $('#' + mod.CONST.TABIDS[0]).find('.m-coupon').length,
				activeCount = $('#' + mod.CONST.TABIDS[1]).find('.m-coupon').length,
				usedCount = $('#' + mod.CONST.TABIDS[2]).find('.m-coupon').length,
				totalCount = availableCount + usedCount,
				inactiveCount = availableCount - activeCount,
				impressions = [],
				$coupon, model, channelString, channelId, j, length;

			// collect only visible coupons
			$coupons.filter('.js-is-visible').each(function (i) {
				$coupon = $(this);
				model = mod._getModelByItemId($coupon.data('item-id'));
				channelString = '';

				for (j = 0, length = model.distributionChannels.length; j < length; j++) {
					channelId = model.distributionChannels[j].id;
					if (j + 1 < length) {
						channelId += ';';
					}
					channelString += channelId;
				}

				impressions.push({
					name:     model.type + '_' + model.discountAmount + '_' + model.name,
					id:       model.ean || '',
					position: i + 1,
					price:    '',
					list:     $tab.data('tracking-key') || '',
					brand:    channelString,
					category: model.minimumPurchase,
					variant:  'gueltig_' + model.expirationDate
				});
			});

			mod._track({
				event:              'digitaleCoupons',
				action:             config.action || '',
				list:               $tab.data('tracking-key') || '',
				filterSelected:     $tabContainer.find('.js-coupons__channels .is-active').data('tracking-key') || '',
				sortSelected:       $tabContainer.find('.js-coupons__sort .is-active').data('tracking-key') || '',
				couponsSum:         totalCount + '',
				couponsActivated:   activeCount + '',
				couponsDeactivated: inactiveCount + '',
				couponsUsed:        usedCount + ''
			});

			mod._track({
				event:     'couponImpression',
				ecommerce: {
					impressions: impressions
				}
			});
		}
	});
}(jQuery));

(function($) {
	'use strict';
	/**
	 * Preview decorator implementation for the Coupons module.
	 *
	 * @author ernscht <ernscht@gmail.com>
	 * @namespace T.Module.Coupons
	 * @class Preview
	 * @extends T.Module
	 */
	T.Module.Coupons.Preview = T.createDecorator({
		start: function (resolve) {
			var mod = this;

			//console.log('START Coupons Preview');

			// init //////////////////////////////
			mod._init();
			mod.type = mod.CONST.TYPE.PREVIEW;

			// bind //////////////////////////////

			// parent //////////////////////////////
			mod._parent.start(resolve);
		},

		_sync: function () {
			var mod = this,
				$ctx = $(mod._ctx);

			// load iframes clientside (would be more save (better) to request a src serverside)
			$ctx.find('.js-coupons__preview-app').each(function(){
				var $orignial = $(this),
					$originalContent = $orignial.find('>div'),
					$iframe = $('<iframe sandbox="allow-same-origin allow-forms" seamless style="width:'+$originalContent.width()+'px; height:'+$originalContent.height()+'px;border:none;margin-bottom:50px;">'),
					$clonedContent = $($orignial.html()),
					$head = $clonedContent[0],// get first DOM node: style tag for head
					$body = $clonedContent[1] && $clonedContent[1].nodeType === 1 ? $clonedContent[1] : $clonedContent[2] ; // get second DOM node: div for body (but ignore a whitespace node)

				$iframe.on('load', function() {
					$iframe.contents().find('head').prepend('<meta charset="utf-8">').append($head);
					$iframe.contents().find('body').append($body);
				});

				$orignial.after($iframe).empty();    // add iframe and remove old content (incl. styles)
				$iframe.attr('src','about:blank');   // trigger load (FF)
			});
		},

		// enrich coupon data for GUI
		_prepareCouponData: function () {

			// for the preview we need the _itemDisabled prop here
			var mod = this,
				coupons = mod.coupons,
				length = coupons.length,
				coupon, i;

			for (i = 0; i < length; i++) {
				coupon = coupons[i];
				coupon._itemDisabled = (coupon.state === mod.CONST.STATE.DISABLED);
			}
		}
	});
}(jQuery));
