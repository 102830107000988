/**
 * @author Fabio Tedesco
 * Switch icons on circle teaser hover
 */

(function () {
  'use strict';

  ui.CircleTeaserComponentView = ui.ComponentView.extend({
    name: 'ui.CircleTeaserComponentView',

    events: {
      mouseover: 'handleHover',
      mouseleave: 'handleHoverLeave',
      touchstart: 'handleHover',
      touchend: 'handleHoverLeave',
    },

    initialize: function (options) {
      this.imageWrapper = this.$el.find('.circle-teaser__img-wrapper');
      this.imageBackground = this.imageWrapper[0].dataset.background
        ? this.imageWrapper[0].dataset.background
        : null;
      this.imageBackgroundHover = this.imageWrapper[0].dataset.backgroundhover
        ? this.imageWrapper[0].dataset.backgroundhover
        : null;
      this.setBackground(this.imageBackground);
    },

    iPhone: function () {
      return ['iPhone'].includes(navigator.platform);
    },

    handleHover: function (e) {
      const isIPhone = this.iPhone();
      const isMouseover = e.type === 'mouseover';

      if (!(isIPhone && isMouseover)) {
        this.imageWrapper.removeClass('is-default');
        this.setBackground(this.imageBackgroundHover);
      }
    },

    handleHoverLeave: function () {
      this.setBackground(this.imageBackground);
      this.imageWrapper.addClass('is-default');
    },

    setBackground: function (img) {
      if (img !== null) {
        this.imageWrapper[0].style.backgroundImage = 'url(' + img + ')';
      }
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'CircleTeaserComponent',
    View: ui.CircleTeaserComponentView,
    selector: '.ui-js-circle-teaser',
    reinitialize: false,
  });

  $(ui.bootstrapCircleTeaserComponent());
}).call(this);
