/**
 * @author Nils von Rymon-Lipinski (Aperto/IBM IX)
 */

(function () {
  'use strict';

  /**
   * JS component for a native slider that need to fill up with some
   * js logic that is not provided by css snap
   */

  ui.NativeSliderComponentView = ui.ComponentView.extend({
    name: 'ui.NativeSliderComponentView',
    sliderClass: '.ui-js-native-slider-list',
    sliderItemClass: '.ui-js-native-slider-item',
    paginationClass: '.ui-js-native-slider-pagination',
    paginationItemClass: '.ui-js-native-slider-pagination-item',
    intersectionObserver: null,
    activeClass: 'is-active',

    events: {
      'click .ui-js-native-slider-pagination-item': 'onPaginationItemClick',
    },

    initialize: function (options) {
      this.$slider = this.$el.find(this.sliderClass);
      this.$sliderItems = this.$el.find(this.sliderItemClass);
      this.$pagination = this.$el.find(this.paginationClass);
      this.$paginationItems = this.$el.find(this.paginationItemClass);
      if (this.$sliderItems.length > 0) {
        $(this.$paginationItems[0]).addClass(this.activeClass);
        this.initIntersectionObserver(this.$sliderItems);
      }
      this.scrollBehavior = 'scrollBehavior' in document.documentElement.style;
    },

    onPaginationItemClick: function (e) {
      var $targetPaginationItem = $(e.currentTarget);
      var $targetSliderItem = this.$el.find(
        '[data-target-id=' + $targetPaginationItem.attr('data-target') + ']'
      );
      if ($targetSliderItem.length > 0) {
        this.$paginationItems.removeClass(this.activeClass);
        $targetPaginationItem.addClass(this.activeClass);
        seamless.elementScrollIntoView($targetSliderItem[0], {
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    },

    handelIntersections: function (entries) {
      var _this = this;
      entries.forEach(function (entry) {
        if (entry.intersectionRatio >= 0.95) {
          var $targetPaginationItem = _this.$el.find(
            '[data-target=' + $(entry.target).attr('data-target-id') + ']'
          );
          _this.$paginationItems.removeClass(_this.activeClass);
          $targetPaginationItem.addClass(_this.activeClass);
        }
      });
    },

    initIntersectionObserver: function ($targets) {
      var _this = this;
      var options = {
        root: this.el,
        rootMargin: '0px',
        threshold: [0.95],
      };
      this.intersectionObserver = new IntersectionObserver(
        this.handelIntersections.bind(this),
        options
      );
      $targets.each(function (idx, item) {
        _this.intersectionObserver.observe(item);
      });
    },

    render: function () {
      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'NativeSliderComponent',
    View: ui.NativeSliderComponentView,
    selector: '.ui-js-native-slider',
  });

  $(ui.bootstrapNativeSliderComponent());
}).call(this);
