/**
 * @author Peter Dematté
 *
 * This component expects a form with <div class="ui-js-formpart"> containers that
 * seperate the different blocks of a muliple step form. It controlls the 'WEITER'
 * buttons and submit buttons to trigger validation (see form-validation.js) and switch
 * between block (incl. taking care of breadcrumb). See form-minfoline-steps.hbs
 */

(function () {
  'use strict';

  ui.FormBlocksComponentView = ui.ComponentView.extend({
    name: 'ui.FormBlocksComponentView',

    $disjunctCheckboxes: null,

    events: {
      'click :button': 'click',
      'click [data-next-subsection]': 'onClickSubsection',
      submit: 'submit',
    },

    // class name declarations are only found in initialize (code is className free)
    initialize: function (options) {
      var form;
      this.formGroupClass = 'form-group';
      this.blockClass = 'ui-js-formpart';
      this.activeBlockClass = 'formpart-active';
      this.stepsActiveClass = 'active';
      this.stepsClickableClass = 'clickable';
      this.topOfFormID = 'topOfForm';
      this.backButtonClass = 'is-back';
      this.nextButtonClass = 'is-forward';
      this.errorBoxClassSel = '.ui-js-formpart-error';
      this.errorBoxHiddenClass = 'is-hidden';
      this.disabledBlockClass = 'js-disabled-block';

      this.$disjunctCheckboxes = this.$('.ui-js-checkbox-disjunction');
      this.$blockSubsections = this.$('.ui-js-formpart-subsection');

      this.$blocks = this.$('.' + this.blockClass);
      this.blockItems = []; // will hold collections of formItems per $block
      this.activeBlockIndex = this.$blocks.index(
        this.$blocks.filter('.' + this.activeBlockClass)[0]
      );
      this.previousBlockIndex = this.activeBlockIndex;
      this.$form = this.$('form');
      this.$savedFields = [];

      form = this.$form.get(0);

      form.uiSubmitConditions = form.uiSubmitConditions || {};
      form.uiSubmitConditions.activateFormBlockFields = 'progress';

      // breadcrumb
      this.$steps = $('article.steps .pos-block');
      this.$srToggles = this.$steps.find('.js-toggle');
      // ... there is no further className decleration from this point on

      var _this = this;

      $('article.steps').on('click', '.clickable', function (e) {
        // TODO: only good for 2step form
        _this.activateBlock(_this.activeBlockIndex - 1);
      });

      // adds an Array of form elements ordered by blocks to blockItems
      // so, every block knows which formElements it contains
      (function ($form, $blocks, blockItems, blockClass) {
        var form = $form[0];
        var $item;
        var idx;
        var $parentBlock;

        // cycle throug form to get form elements
        for (var n = form.length; n--; ) {
          $item = $(form[n]);
          $parentBlock = $item.closest('.' + blockClass);

          if ($parentBlock.length && $item.attr('disabled') === undefined) {
            idx = $blocks.index($parentBlock);
            blockItems[idx] = blockItems[idx] || $();
            blockItems[idx].push($item[0]);
          }
        }
      })(this.$form, this.$blocks, this.blockItems, this.blockClass);

      var self = this;
      this.$blocks.each(function (idx, formPart) {
        if ($(formPart).data('saveFields') !== undefined) {
          self.fillFields(
            self.getSessionStorageValues($(formPart).data('saveFields')),
            $(formPart)
          );
        }
      });

      if (location.hash) {
        const $target = this.$el.find(`[data-hash="${location.hash.substring(1)}"]`);
        if ($target && $target.data('next-subsection')) $target.trigger('click');
      }
    },

    onClickSubsection: function (e) {
      const $currentTarget = $(e.target);
      const currentTargetLabelText = $currentTarget.next().find('.js-ff-radio-button-text').text();
      const $currentSubsectionEl = $currentTarget.closest('.ui-js-formpart-subsection');
      const currentSubsection = $currentSubsectionEl.data('subsection');
      const $currentTopSectionEl = $currentSubsectionEl
        .prev('[data-subsection]')
        .find(`[data-next-subsection="${currentSubsection}"]`);

      const nextSubsection = $currentTarget.data('next-subsection');

      const offset = /xs|ms/.test(ui.Bootstrap.activeView) ? 50 : 200;
      let currentScrollTop = $currentTarget.offset().top - offset;

      // for loading initially - when the second subsection is targeted
      if ($currentSubsectionEl.attr('hidden')) {
        $currentSubsectionEl.removeAttr('hidden');
        $currentTopSectionEl.prop('checked', true);
      }

      // needed to close the 3rd subsection when the first subsection is clicked
      if (this.$blockSubsections.eq(0).data('subsection') === currentSubsection) {
        this.$blockSubsections
          .not(':eq(0)')
          .attr('hidden', true)
          .find('[data-next-subsection]')
          .prop('checked', false);
      }

      if (nextSubsection) {
        // open next subsection
        const $nextBlockSubsection = this.$(`[data-subsection="${nextSubsection}"]`);
        const $nextBlockSubsectionButtons = $nextBlockSubsection.find('[data-next-subsection]');

        if ($nextBlockSubsectionButtons) $nextBlockSubsectionButtons.prop('checked', false);

        $nextBlockSubsection.removeAttr('hidden');
        currentScrollTop = $nextBlockSubsection.offset().top - offset;
      }

      // Set dynamic headline title
      const formTopicStrings =
        $currentTopSectionEl.length > 0
          ? [
              $currentTopSectionEl.next().find('.js-ff-radio-button-text').text(),
              currentTargetLabelText,
            ]
          : [currentTargetLabelText];
      this.$el.find('.js-topic-container').html(`${formTopicStrings.join('/')} – `);

      $('html, body').stop().animate(
        {
          scrollTop: currentScrollTop,
        },
        250
      );

      location.hash = $currentTarget.data('hash');
    },

    getSessionStorageValues: function (formPartName) {
      this.$savedFields.push(formPartName);
      return JSON.parse(sessionStorage.getItem(formPartName));
    },

    fillFields: function (fieldsData, $formPart) {
      if (!fieldsData) return false;

      var stepDirection = fieldsData.stepDirection;
      var hasFileInput = fieldsData.hasFileInput;
      var fields = fieldsData.fields;
      for (var i = 0; i < fields.length; i++) {
        var $field = $('[name="' + fields[i].name + '"]');
        if ($field.length > 0) {
          if ($field[0].type === 'radio' || $field[0].type === 'checkbox') {
            if ($field.length > 1) {
              $field.each(function (idx, valueField) {
                if (valueField.value === fields[i].value) {
                  valueField.checked = true;
                  return false;
                }
              });

              if ($field.data('next-subsection')) $field.trigger('click');
            }
          } else if ($field[0].type === 'textarea' || $field[0].type === 'text') {
            if ($field.length > 1) {
              var savedFields = fields.filter(function (field) {
                return field.name === $field[0].name;
              });
              $field.each(function (idx, field) {
                if (savedFields[idx] !== undefined) {
                  field.value = savedFields[idx].value;
                }
              });
            } else {
              $field[0].value = fields[i].value;
            }
          }
        }
      }
      if (!hasFileInput) {
        this.activateBlock(stepDirection);
      } else if (hasFileInput) {
        $formPart.find(this.errorBoxClassSel).removeClass(this.errorBoxHiddenClass);
      }
    },

    // with old validation input fields that where only disabled could escape validation
    // with new validaten items .is(':hidden, [readonly], [disabled]') are prevented from validation
    // this way, this function might not be needed any more
    toggleItemsReadonly: function ($elms, toggle) {
      var _this = this;
      $elms.each(function (idx, elm) {
        var isHidden = !!$(elm).closest('.' + _this.disabledBlockClass).length; // this is heavy but...
        if (isHidden) return;
        if (toggle) {
          // this avoids evaluation with http://jqueryvalidation.org/
          elm.setAttribute('disabled', '');
        } else {
          elm.removeAttribute('disabled');
        }
      });
    },

    activateBlock: function (idx) {
      if (!this.$blocks[idx]) return;

      $(document).trigger('form:stepchange', {
        type: 'form:stepchange',
        currentTarget: this.$blocks[this.activeBlockIndex],
      });

      this.previousBlockIndex = this.activeBlockIndex;
      this.activeBlockIndex = idx;

      ui.trigger(ui.GlobalEvents.Z_INDEX_ACTOR_OPEN);
      this.render();
    },

    click: function (e) {
      var $target = $(e.target);
      var isBackButton = $target.hasClass(this.backButtonClass);
      var isNextButton = $target.hasClass(this.nextButtonClass);
      var isSubmitButton = $target.is('[type="submit"]');
      var stepDirection = this.activeBlockIndex + (isBackButton ? -1 : isNextButton ? 1 : 0);

      if (isBackButton) {
        this.activateBlock(stepDirection);
      } else if (isNextButton) {
        var checked = this.oneCheckboxChecked();
        var formIsValid = this.$form.valid();

        // triggers validation on form (only once)...
        if (checked && formIsValid) {
          this.saveFormPartInSessionStorage($(this.$blocks[this.activeBlockIndex]), stepDirection);
          this.activateBlock(stepDirection);
        }
      } else if (!isSubmitButton) {
        // maybe copy button
      }
    },

    saveFormPartInSessionStorage: function ($formPart, stepDirection) {
      var formPartName = $formPart.data('saveFields');
      if (formPartName) {
        var $fields = $('input, select, textarea', $formPart).filter(
          ':not([name="MAX_FILE_SIZE"])'
        );
        var hasFileInput = false;
        $fields.filter('input[type="file"]').each(function (idx, fileInput) {
          if (fileInput.value !== '') {
            hasFileInput = true;
            return false;
          }
        });

        var sessionObj = {
          stepDirection,
          hasFileInput,
          fields: $fields.serializeArray(),
        };

        sessionStorage.setItem(formPartName, JSON.stringify(sessionObj));
        this.$savedFields.push(formPartName);
      }
    },

    /**
     * Special requirement that at least one checkbox must be checked (skipped if none available)
     * @returns {boolean} true if checked (or no .ui-js-checkbox-disjunction available)
     */
    oneCheckboxChecked: function () {
      if (this.$disjunctCheckboxes.length) {
        var $checkboxes = this.$disjunctCheckboxes.find('input[type="checkbox"]');
        var valid = false;
        var self = this;

        $checkboxes.each(function () {
          if ($(this).prop('checked')) {
            self.markCheckboxesInvalid(true);
            valid = true;
          }
        });
        this.markCheckboxesInvalid(valid);
        return valid;
      } else {
        return true;
      }
    },

    /**
     * Marks invalid and toggles error message (without use of validation-lib)
     * @param {Boolean} valid - false if both checkboxes unchecked
     */
    markCheckboxesInvalid: function (valid) {
      this.$disjunctCheckboxes.toggleClass('invalid', !valid);
      this.$disjunctCheckboxes.find('.error-box').toggleClass('hidden', valid);

      if (!valid) {
        var offset = this.$disjunctCheckboxes.offset();
        $('html, body').animate(
          {
            scrollTop: offset.top,
          },
          500
        );
      }
    },

    submit: function (evt) {
      var that = this;
      var form;
      evt.preventDefault();
      form = this.$form.get(0);
      $(this.blockItems).each(function (idx, elm) {
        that.toggleItemsReadonly($(elm), false);
      });
      window.deleteSavedFields = this.$savedFields;
      form.uiSubmitConditions.activateFormBlockFields = 'done';
      ui.formSubmitHandler(form);
    },

    render: function () {
      var that = this;
      var $activeSRToggle = $(this.$srToggles[this.activeBlockIndex]);
      var $previousSRToggle = $(this.$srToggles[this.previousBlockIndex]);
      var text = '';
      var formOffset;

      // toggle attribute 'readonly' on formElements (for validation purposes)
      // see docu @: toggleItemsReadonly
      $(this.blockItems).each(function (idx, item) {
        that.toggleItemsReadonly($(item), that.activeBlockIndex !== idx);
      });

      if (!this.renderedAllready) {
        // don't render the first time...
        this.renderedAllready = true;
        return this;
      }

      // show / hide blocks
      this.$blocks
        .removeClass(this.activeBlockClass)
        .filter(this.$blocks[this.activeBlockIndex])
        .addClass(this.activeBlockClass);

      // Change Screen Reader Text in breadcrumbs... and Classes of $steps
      text = $previousSRToggle.text();
      $previousSRToggle.text($activeSRToggle.text());
      $activeSRToggle.text(text);

      this.$steps
        .removeClass(this.stepsActiveClass)
        .removeClass(this.stepsClickableClass)
        .filter(this.$steps[this.activeBlockIndex])
        .addClass(this.stepsActiveClass)
        .removeClass(this.stepsClickableClass);

      for (var n = this.activeBlockIndex; n--; ) {
        this.$steps.eq(n).addClass(this.stepsClickableClass);
      }

      // window.location.href = '#topOfForm'; // ...or
      // ..scroll to spot with id="topOfForm"; Breadcrumb
      formOffset = $('#' + this.topOfFormID).offset();
      formOffset &&
        $('html, body').animate(
          {
            scrollTop: formOffset.top,
          },
          500
        );

      return this;
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'FormBlocksComponentView',
    View: ui.FormBlocksComponentView,
    selector: '.ui-js-formblocks',
  });

  $(ui.bootstrapFormBlocksComponentView('.ui-js-formblocks'));
}).call(this);
