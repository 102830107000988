/**
 * @author Jan Suwart
 */
(function () {
  'use strict';

  /**
   * Accessible version Bootstrap-Carousel
   * @see http://getbootstrap.com/javascript/#carousel
   *
   */
  ui.CarouselAccessComponentView = ui.ComponentView.extend({
    name: 'ui.CarouselAccessComponentView',

    $carousel: null,
    $indicators: null,
    $items: null,

    isPaused: false,

    events: {
      'slide.bs.carousel': function (e, data) {
        this.updateARIAStates(e, data);
      },
      'keydown .carousel-indicator-item': 'keydownOnIndicator',
      'keydown .carousel-inner': 'keydownOnSlide',
      focusin: 'pauseOnFocus',
    },

    initialize: function () {
      this.$carousel = this.$('.carousel').length ? this.$('.carousel') : this.$el;
      this.$indicators = this.$('.carousel-indicators .carousel-indicator-item');
      this.$items = this.$('.item');
      this.$modalGallery = this.$el.parents('.ui-js-modal-gallery').length
        ? $(this.$el.parents('.ui-js-modal-gallery')[0])
        : false;
      this.isGridGallery = this.$modalGallery.length
        ? this.$modalGallery.hasClass('is-grid-gallery')
        : false;
    },

    render: function () {
      return this;
    },

    /**
     * Simulate mouse-events on ENTER keypress and handles left/right arrow keys
     * on carousel-indicators - left/right moves slider but keeps focus on indicators
     * @param {Event} e - keydown event
     */
    keydownOnIndicator: function (e) {
      if (!this.isGridGallery) {
        var self = this;
        // ENTER key
        if (e.which === 13) {
          $(e.target).click();
          // Right/left arrow key
        } else if (e.which === 37 || e.which === 39) {
          var direction = e.which === 37 ? 'left' : 'right';
          var listener = function (e) {
            if (e.relatedTarget) {
              var position = $(e.relatedTarget).index();

              self.$indicators.eq(position).focus();
            }
            // Trigger custom event for tracking.js lib
            self.$el.trigger('carousel:slid-manually', {
              direction,
              currentTarget: self.el,
            });

            self.$carousel.off('slide.bs.carousel', listener);
          };
          this.$carousel.on('slide.bs.carousel', listener);
        }
      }
    },

    /**
     * Give focus to the first focusable element on the next shown slide
     * @param {Event} e - keydown event
     */
    keydownOnSlide: function (e) {
      if (!this.isGridGallery) {
        if (e.which === 37 || e.which === 39) {
          var self = this;
          var direction = e.which === 37 ? 'left' : 'right';

          var listener = function (e) {
            if (e.relatedTarget) {
              var position = $(e.relatedTarget).index();
              var $focusable = self.$items.eq(position).find(':input, a, [tabindex="0"]');

              $focusable.first().focus();

              // Trigger custom event for tracking.js lib
              self.$el.trigger('carousel:slid-manually', {
                direction,
                currentTarget: self.el,
              });
            }
            self.$carousel.off('slid.bs.carousel', listener);
          };
          this.$carousel.on('slid.bs.carousel', listener);
        }
      }
    },

    pauseOnFocus: function () {
      if (!this.isPaused && this.$carousel && typeof this.$carousel.carousel === 'function') {
        this.$carousel.carousel('pause');
        this.isPaused = true;
      }
    },

    /**
     * Updates aria-hidden and aria-selected on indicators and slider items
     * @param {Event} e - Bootstrap's slide event
     * @param {Object} customTarget - optional target (if event is triggered manually)
     */
    updateARIAStates: function (e, customTarget) {
      var position = 0;
      var target = e.relatedTarget || customTarget;

      if (target) {
        position = $(target).index();
      }
      this.$items.each(function (index, item) {
        $(item).attr('aria-hidden', index !== position);
      });
      this.$indicators.each(function (index, item) {
        $(item).attr('aria-selected', index === position);
      });
    },
  });

  ui.ComponentFactory.createPlugin({
    pluginMethodName: 'CarouselAccessComponent',
    View: ui.CarouselAccessComponentView,
    selector: '.ui-js-carousel',
  });

  $(ui.bootstrapCarouselAccessComponent());
}).call(this);
